<div class="container-fluid">
  <div class="container">
    <div class="row" style="margin-top: 15px;">
      <div class="col-md-12 col-lg-6 mb-4 align-items-stretch">
        <div class="col text-left">
          <p style="margin-top: 12px;font-size: 16px;"><i class="fa fa-arrow-left fa-theme mr-2 pointer" matTooltip="{{'Go Back' | translate}}" [matTooltipPosition]="'above'" [routerLink]="['/item']"></i><b>{{'Add Item' | translate}}</b></p>
          <!-- <h5 class="mb-4"><b>Add Item </b></h5> -->
        </div>
        <div class="form-group">
          <mat-form-field style="width: 100%;" appearance="outline">
            <mat-label>{{'Item Name' | translate}}</mat-label>
            <input matInput placeholder="{{'Enter Item Name' | translate}}" name="item_name" [(ngModel)]="ItemName" required>
          </mat-form-field>
        </div>
        <div class="row">
          <div class="col text-right">
            <button type="button" (click)="Cancel()" class="btn btn-outline-info rounded-0 mb-2 mr-2"><b>{{'Cancel' | translate}}</b></button>
            <button type="button" (click)="AddItem()" class="btn btn-info rounded-0 mb-2"><b>{{'Add Item' | translate}}</b></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>



<div class="container-fluid" style="margin-top: 10px;">
  <div class="Top_header">
    <div class="col text-left back_arrow" style="display: inline-block;">
      <p style="color: #000;margin-top: 12px"><i class="fa fa-arrow-left fa-theme mr-2 pointer"
          matTooltip="{{'Go Back' | translate}}" [matTooltipPosition]="'above'"
          [routerLink]="['/dasboard']"></i></p>
    </div>
  <button mat-raised-button color="primary" class="mr-5" (click)="downloadFile()">{{"Sample File Download" | translate }}</button>
</div>
    <div class="container">
        <div class="card">
          <h3>{{'Upload Bulk Orders' | translate}}</h3>
          <div class="drop_box">
            <header>
              <h4>{{'Select File here' | translate}}</h4>
            </header>
            <p>{{'Files Supported' | translate}} : xlsx</p>
            <input type="file" id="FileId" value="{{fileName}}" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            (change)="onFileChange($event)" />
            <button class="btn">{{'Choose File' | translate}}</button>
          </div>      
        </div>
      </div>
</div>

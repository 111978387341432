<app-cs-navbar></app-cs-navbar>
<div class="container-fluid form-container">
  <div class="row">
    <div class="col-md-6 content-center img-mobile-fit">
      <div class="custom-center">
        <h3 class="text-white text-center text-size mb-4">
          <img src="/assets/img/collections/phone.png" class="form-img mr-2">
          <strong>+917338773388</strong>
        </h3>
        <h3 class="text-white text-center text-size mb-4">
          <img src="/assets/img/collections/email.png" class="form-img mr-2">
          <strong>bd@manageteamz.com</strong>
        </h3>
        <!-- <h3 class="text-white text-center text-size mb-4">
          <a href="#" class="btn btn-outline-success">
            <img src="/assets/img/collections/live-chat.png" class="form-img mr-2">
            <strong>Live Chat</strong>
          </a>
        </h3> -->
      </div>
    </div>
    <div class="col-md-6 content-center">
      <div class="card rounded-card">
        <div class="card-body custom-size">
          <h4 class="text-center mb-4">
            <strong>{{'Delivery Account Login' | translate}}</strong>
          </h4>
          <form data-bitwarden-watching="1" (ngSubmit)="onSubmit()" #loginForm="ngForm">
            <div class="form-group">
              <label>{{'Mobile Number' | translate}}</label>
              <ngx-intl-tel-input class="custom-intl-tel-input"
                [preferredCountries]="preferredCountries"
                [enableAutoCountrySelect]="false"
                [enablePlaceholder]="false"
                [searchCountryFlag]="true"
                [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                [selectFirstCountry]="false"
                [selectedCountryISO]="selectedCountry"
                [maxLength]="15"
                [minLength]="6"
                [tooltipField]="TooltipLabel.Name"
                [separateDialCode]="true"
                [(ngModel)]="user.phone"
                #phone="ngModel"
                name="phone"
                required>
              </ngx-intl-tel-input>
              <div *ngIf="option.invalid && (option.dirty)">
                <p
                  class="error-box margin-top-0 text-danger"
                  *ngIf="phone.hasError('required')"
                >
                  {{'Mobile Number is Required' | translate}}
                </p>
              </div>
            </div>
            <div class="form-group">
              <mat-radio-group [(ngModel)]="user.option"
                #option="ngModel"
                name="option"
                required>
                <mat-radio-button value="sms" class="mr-4">{{'SMS' | translate}}</mat-radio-button>
                <mat-radio-button value="voice" class="mr-4">{{'Voice Call' | translate}}</mat-radio-button>
                <button class="btn btn-info btn-sm" [disabled]="!loginForm.valid" style="float: right;">{{'Request OTP' | translate}}</button>
              </mat-radio-group>
              <div *ngIf="option.invalid && (option.dirty)">
                <p
                  class="error-box margin-top-0 text-danger"
                  *ngIf="option.hasError('required')"
                >
                Option is Required
                </p>
              </div>
            </div>
          </form>
          <form data-bitwarden-watching="1" (ngSubmit)="loginSubmit()" #OTPForm="ngForm">
            <div class="form-group">
              <label>{{'OTP' | translate}}</label>
              <input type="text" style="width:30%" class="form-control"
                [(ngModel)]="verify.optpin"
                #optpin="ngModel"
                name="otp"
                [maxLength]="5"
                [disabled]="otpshow"
                (keypress)="numberOnly($event)"
                required>
              <button type="submit" class="btn btn-info btn-lg btn-block" style="width:50%;float:right;height: 41px;margin: -40px 0 0 0;padding: 0;" [disabled]="!optpin.valid">{{'Login' | translate}}</button>
              <div *ngIf="optpin.invalid && (optpin.dirty)">
                <p
                  class="error-box margin-top-0 text-danger"
                  *ngIf="optpin.hasError('required')"
                >
                  {{'OTP is Required' | translate}}
                </p>
                
              </div>
            </div><br>
          </form>
          <div class="forget-password">
            <a href="#" routerLink="/login" class="text-info m-left"> {{'Login with Email Address?' | translate}}</a>
            <a href="#" routerLink="/signup" style="float: right;" class="text-info m-left"> {{'Don`t have an Account?' | translate}}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- <div class="container-fluid area-container">
  <div class="row">
    <div class="col-md-4 area-left">
      <div class="row">
        <div class="wrap">
          <div class="ico-wrap">
            <span class="mbr-iconfont">
              <img src="/assets/img/collections/location.png" class="area-img mr-2">
            </span>
          </div>
          <div class="vcenter">
            <p class="text-white text-uppercase">
              No.1, 1ST FLOOR, mrk arcade, 200 feet radial road, thoraipakkam, chennai, tamil nadu, india 600097.
            </p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="wrap">
          <div class="ico-wrap">
            <span class="mbr-iconfont">
              <img src="/assets/img/collections/phone.png" class="area-img-phn mr-2">
            </span>
          </div>
          <div class="vcenter">
            <p class="text-white text-uppercase">
              +917338773388
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-4 area-left">
      <div class="row">
        <div class="wrap">
          <div class="ico-wrap">
            <span class="mbr-iconfont">
              <img src="/assets/img/collections/location.png" class="area-img mr-2">
            </span>
          </div>
          <div class="vcenter">
            <p class="text-white text-uppercase">
              9820 ivalenes hope dr, austin, texass tx 78717.
            </p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="wrap">
          <div class="ico-wrap">
            <span class="mbr-iconfont">
              <img src="/assets/img/collections/phone.png" class="area-img-phn mr-2">
            </span>
          </div>
          <div class="vcenter">
            <p class="text-white text-uppercase">
              +16302997737
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-4 area-left">
      <div class="row">
        <div class="wrap">
          <div class="ico-wrap">
            <span class="mbr-iconfont">
              <img src="/assets/img/collections/location.png" class="area-img mr-2">
            </span>
          </div>
          <div class="vcenter">
            <p class="text-white text-uppercase">
              5838 burbank road se, calgary, alberta, canada t2h1z3.
            </p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="wrap">
          <div class="ico-wrap">
            <span class="mbr-iconfont">
              <img src="/assets/img/collections/phone.png" class="area-img-phn mr-2">
            </span>
          </div>
          <div class="vcenter">
            <p class="text-white text-uppercase">
              +14039263660
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->
<app-cs-footer></app-cs-footer>

<div class="modal full-size" tabindex="-1" role="dialog"
[ngClass]="{ fade: !isDriverAsAdmin, 'd-flex': isDriverAsAdmin }">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title w-100">Confirmation</h5>
        <!-- <a class="btn btn-sm btn-white dialog-close" (click)="onClose()"><span aria-hidden="true">&times;</span></a> -->
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12" style="padding-bottom: 1px;">
            <b>You have a driver account with this mobile number.  Driver account does not have a web acccess. Would you like to signup as admin ?</b>
          </div>
        </div>
        <div class="col-md-12 text-right" style="padding-bottom: 1px; padding-top: 5px;">
          <button type="button" class="btn btn-sm btn-info rounded-0 mb-2 mr-2" (click)="onPromotion()">Ok</button>
          <button type="button" class="btn btn-sm btn-outline-info rounded-0 mb-2 mr-2" (click)="promotionCancel()">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</div>
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders, HttpErrorResponse } from "@angular/common/http";
import { FormGroup } from '@angular/forms';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import {
	SearchCountryField
} from 'custom-template/ngx-intl-tel-input/src/lib/enums/search-country-field.enum';
import { CountryISO } from 'custom-template/ngx-intl-tel-input/src/lib/enums/country-iso.enum';
import { TooltipLabel } from 'custom-template/ngx-intl-tel-input/src/lib/enums/tooltip-label.enum';
import { AuthService } from 'src/app/services/AuthService';
import { AppService } from 'src/app/services/AppService';
import { ApiService } from 'src/app/services/ApiService';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css'],
  providers: [AuthService]
})
export class ForgotPasswordComponent implements OnInit {
  public _token: any;
  submitted = false;
  user = {
    email: "",
  };
  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private loader: NgxSpinnerService,
    private toastr: ToastrService,
    private authService: AuthService,
    public appService: AppService,
    public apiService: ApiService
  ) {}

  ngOnInit() {
    if (this.authService.isAuthenticated()) {
      this.router.navigate(["/dashboard"]);
    }
  }
  onSubmit() {
    if (this.user) {
      this.loader.show();
      let payload = {
        data: {
          email: this.user.email
        }
      }
      this.apiService.forgetPassword(payload)
        .subscribe((response: any) => {
          if (response.status == 'ok') {
            this.toastr.success(response.data);
            this.router.navigate(["/login"]);
          } else {
            this.toastr.error(response.data);
          }
          this.loader.hide();
        },
          (err: HttpErrorResponse) => {
            this.toastr.error('Something went wrong please try again');
            this.loader.hide();
          }
        );
    }
  }
}

<aside class="sidebar shadow-sm mv-top">
  <!-- <div class="sidebar-profile d-flex flex-column justify-content-center">
    <div class="picture-wrapper">
      <div class="user-picture m-auto">
        <img src="{{profile}}" class="img-fluid" alt="">
      </div>
    </div>
    <div class="profile-details text-center">
      <p class="mb-0 text-uppercase name">Alvin</p>
      <small class="text-uppercase role">Web Devloper</small>
    </div>
  </div> -->
  <nav class="sidebar-menu pb-3">

    <div class="view-source mb-3" *ngIf="!superadmin">
      <a href="#" routerLink="/dashboard" routerLinkActive="active" class="btn-block btn-sb-custom rounded-0"
      (click)="setDocTitle('Dashboard')">
      <img src="./assets/img/dashboard.png" style="height: 20px;width:20px"><br><b>{{'Dashboard' | translate}}</b>
      </a>
      <a href="#" routerLink="/calendar" routerLinkActive="active" class="btn-block btn-sb-custom rounded-0"
      (click)="setDocTitle('Calendar')">
        <img src="./assets/img/calendar.png" style="height: 20px;width:20px"><br>{{'Calendar' | translate}}
      </a>
      <a href="#" routerLink="/delivery-agent" routerLinkActive="active" class="btn-block btn-sb-custom rounded-0"
      (click)="setDocTitle('Delivery Agent  ')">
      <img src="./assets/img/supervisor.png" style="height: 20px;width:20px"><br><b>{{'Delivery Agent' | translate}}</b>
      </a>
      <!-- <a href="#" routerLink="/customer-service" routerLinkActive="active" class="btn-block btn-sb-custom rounded-0" (click)="setDocTitle('Customer Service')">
        <i class="fa fa-user-circle mr-1"></i><br>Customer Service
      </a>
      <a href="#" routerLink="/agency" routerLinkActive="active" class="btn-block btn-sb-custom rounded-0" (click)="setDocTitle('Agency')">
        <i class="fa fa-handshake mr-1"></i><br>Agency
      </a>
      <a href="#" routerLink="/trucks" routerLinkActive="active" class="btn-block btn-sb-custom rounded-0" (click)="setDocTitle('Trucks')">
        <i class="fa fa-truck mr-1"></i><br>Trucks
      </a>
      <a href="#" routerLink="/riders" routerLinkActive="active" class="btn-block btn-sb-custom rounded-0" (click)="setDocTitle('Riders')">
        <i class="fa fa-motorcycle mr-1"></i><br>Riders
      </a>
      <a href="#" routerLink="/vehicle" routerLinkActive="active" class="btn-block btn-sb-custom rounded-0" (click)="setDocTitle('Vehicle Info')">
        <i class="fa fa-info-circle mr-1"></i><br>Vechicle Info
      </a>
      <a href="#" routerLink="/vehicle-map" routerLinkActive="active" class="btn-block btn-sb-custom rounded-0" (click)="setDocTitle('Vechicle Map')">
        <i class="fa fa-map-signs mr-1"></i><br>Vechicle Map
      </a> -->
      <a href="#" routerLink="/customer" routerLinkActive="active" class="btn-block btn-sb-custom rounded-0" (click)="setDocTitle('Customer')">
        <img src="./assets/img/customer.png" style="height: 20px;width:20px"><br><b>{{'Customer' | translate}}</b>
      </a>
      <ng-container *ngIf="managershow">
        <a href="#" routerLink="/manager" routerLinkActive="active" class="btn-block btn-sb-custom rounded-0" (click)="setDocTitle('Manager')">
          <img src="./assets/img/customer.png" style="height: 20px;width:20px"><br><b>{{'Manager' | translate}}</b>
        </a>
      </ng-container>
      <a href="#" routerLink="/item" routerLinkActive="active" class="btn-block btn-sb-custom rounded-0" (click)="setDocTitle('Item')">
        <img src="./assets/img/item.png" style="height: 20px;width:20px"><br><b>{{'Item' | translate}}</b>
      </a>
      <!-- <a href="#" routerLink="/payment-status" routerLinkActive="active" class="btn-block btn-sb-custom rounded-0" (click)="setDocTitle('Payment Status')">
        <img src="./assets/img/payment-status.png" style="height: 20px;width:20px"><br>Payment Status
      </a> -->
      <a href="#" routerLink="/customer-review" routerLinkActive="active" class="btn-block btn-sb-custom rounded-0" (click)="setDocTitle('Customer Rating')">
        <img src="./assets/img/review.png" style="height: 22px;width:23px"><br><b>{{'Customer Ratings' | translate}}</b>
      </a>
      <a class="btn-block btn-sb-custom rounded-0" [matMenuTriggerFor]="reports" style="cursor: pointer;">
        <img src="./assets/img/reports.png" style="height: 20px;width:20px"><br><b>{{'Reports' | translate}}</b><mat-icon>arrow_drop_down</mat-icon>
      </a>
      <a href="#" routerLink="/payment-history" routerLinkActive="active" class="btn-block btn-sb-custom rounded-0"
      (click)="setDocTitle('Payment History')">
        <img src="./assets/img/dashboard.png" style="height: 20px;width:20px"><br><b>{{'Payment History' | translate}}</b>
      </a>
      <a href="#" routerLink="/driving-zone" routerLinkActive="active" class="btn-block btn-sb-custom rounded-0"
      (click)="setDocTitle('Driving Zone')">
        <img src="./assets/img/dashboard.png" style="height: 20px;width:20px"><br><b>{{'Driving Zone' | translate}}</b>
      </a>
      <mat-menu #reports="matMenu" xPosition="after">
        <button mat-menu-item routerLink="/delivery-report" routerLinkActive="active" (click)="setDocTitle('Delivery Report')">
          {{'Delivery Report' | translate}}
        </button>
        <button mat-menu-item routerLink="/mileage-report" routerLinkActive="active" (click)="setDocTitle('Mileage Report')">
          {{'Mileage Report' | translate}}
        </button>
        <button mat-menu-item routerLink="/gps-history" routerLinkActive="active" (click)="setDocTitle('GPS History')">
          {{'GPS History' | translate}}
        </button>
      </mat-menu>
    </div>
    <div class="view-source mb-3" *ngIf="superadmin">
      <a href="#" routerLink="/manage-users" routerLinkActive="active" class="btn-block btn-sb-custom rounded-0"
      (click)="setDocTitle('Manage Users')">
      <img src="./assets/images/2.employee.png" style="height: 20px;width:20px"><br><b>{{'Manage Users' | translate}}</b>
      </a>

      <a href="#" routerLink="/package-information" routerLinkActive="active" class="btn-block btn-sb-custom rounded-0"
      (click)="setDocTitle('Manage Users')">
      <img src="./assets/img/dashboard.png" style="height: 20px;width:20px"><br><b>{{'Package Info' | translate}}</b>
      </a>

      <a href="#" routerLink="/payment-history" routerLinkActive="active" class="btn-block btn-sb-custom rounded-0"
      (click)="setDocTitle('Payment History')">
      <img src="./assets/img/dashboard.png" style="height: 20px;width:20px"><br><b>{{'Payment History' | translate}}</b>
      </a>
    </div>
  </nav>
</aside>

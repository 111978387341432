import { Component, OnInit, HostBinding, ChangeDetectionStrategy, NgZone, ChangeDetectorRef, ViewChild, ElementRef,} from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import { GeneralService } from 'src/app/services/GeneralService';
import { Location, Appearance, GermanAddress } from '@angular-material-extensions/google-maps-autocomplete';
import { FormControl, FormGroup } from '@angular/forms';
import { Image_Path } from 'src/app/config/config';
import { MatDialog } from '@angular/material/dialog';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/AuthService';
import { AppService } from 'src/app/services/AppService';
import { ApiService } from 'src/app/services/ApiService';
import { DatePipe } from '@angular/common';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { SharedFunctionService } from 'src/app/services/SharedFunctionService';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import PlaceResult = google.maps.places.PlaceResult;
import { TimezoneService } from 'src/app/services/TimezoneService';
import * as moment from 'moment-timezone';
import { SocketService } from 'src/app/services/SocketService';
import { MapsAPILoader } from '@agm/core';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core'

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
  encapsulation: ViewEncapsulation.None,
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardComponent implements OnInit {
  @HostBinding('attr.dir') dir = 'ltr';
  isLeftArrow = false;
  isRightArrow = true;
  panelOpenState = false;
  isGroupObj: any = 'pickup';
  unallocated: any = [];
  allocated: any = [];
  completed: any = [];
  loaderOne = false;
  isTaskDate: any;
  userlist: any = [];
  isAvailability: any = 'all';
  isViewAll: any = true;
  S3BaseUrl = Image_Path.S3_BASE_URL;
  timeZoneModel = false;
  timezones: any;
  isExpiredTwodays = false;
  isExpired = false;
  public appearance = Appearance;
  //public zoom: number;
  latitude: any;
  longitude: any;
  public selectedAddress: PlaceResult;
  userTimezone: any = {
    country_code: "",
    timezone: "",
    businestype: "",
    businessname: "",
    address: "",
  };
  public demo1TabIndex = 1;
  locationsPoint: { latitude: number; longitude: number; };
  // timezoneForm: FormGroup;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';
  onlineList: any;
  userData: any;
  checkUserExpires: any;
  sendDATA: any;
  socketData: any;
  broadcast: any;
  countries: any;
  countryBasedTimezones: any;
  orderList: any = [];
  onlineListCopy: any;

  zoom: number;
  address: string;
  private geoCoder;
  company_lat: number;
  company_lng: number;

  agmshowmap = 0;
  ButtonShow = true;

  @ViewChild('search')
  public searchElementRef: ElementRef;
  SelectLang: any;
  Country: string;
  TimezineReq: string;
  BusiReq: string;
  BusiNameReq: string;
  AddressReq: string;
  Close: string;

  constructor(
    public dialog: MatDialog,
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private loader: NgxSpinnerService,
    private toastr: ToastrService,
    private snackBar: MatSnackBar,
    private datePipe: DatePipe,
    public authService: AuthService,
    public appService: AppService,
    public apiService: ApiService,
    public generalService: GeneralService,
    public sharedfunction: SharedFunctionService,
    public timezone: TimezoneService,
    public socketService: SocketService,
    public ngZone: NgZone,
    public changeDetector: ChangeDetectorRef,
    private mapsAPILoader: MapsAPILoader,
    public translate: TranslateService
  ) { 
    translate.addLangs(['English', 'Arabic']);
   }

  ngOnInit() {
    this.SelectLang = localStorage.getItem("language");
    if(this.SelectLang == '' || this.SelectLang == null){
      localStorage.setItem('language','English');
      this.SelectLang = localStorage.getItem('language')
      this.translate.use(this.SelectLang);
    }
    this.translate.use(this.SelectLang);
    if(this.SelectLang != null && this.SelectLang == 'Arabic') {
      this.dir = 'rtl';
    } else {
      this.dir = 'ltr';
    }
    this.SelectLang = localStorage.getItem("language");
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      if(event.lang == 'Arabic') {
        this.dir = 'rtl';
      } else {
        this.dir = 'ltr';
      }
    });

    this.translate.get('Country is Required').subscribe((text: string) => {
      this.Country = text
    });
    this.translate.get('Timezone is Required').subscribe((text: string) => {
      this.TimezineReq = text
    });
    this.translate.get('Business Type is Required').subscribe((text: string) => {
      this.BusiReq = text
    });
    this.translate.get('Business Name is Required').subscribe((text: string) => {
      this.BusiNameReq = text
    });
    this.translate.get('Address is Required').subscribe((text: string) => {
      this.AddressReq = text
    });
    this.translate.get('Close').subscribe((text: string) => {
      this.Close = text
    });
    this.translate.use(this.SelectLang);
    const tabCount = 3;
    this.demo1TabIndex = (this.demo1TabIndex + -1) % tabCount;
    // const countriesTZ = require('countries-and-timezones');
    // const propertyNames = countriesTZ.getAllTimezones();
    // this.timezones = Object.keys(propertyNames);
    this.userData = JSON.parse(localStorage.getItem("user_data"));
    this.zoom = this.userData.profile.zoom_control;
    this.isTaskDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    if(this.userData.profile.role_id == 4){
      this.userTimezone.address = this.userData.profile.street;
    }else{
      this.userTimezone.address = "";
    }
    this.loadOrders();
    this.loadOnlineList();
    this.checkUserExpired();
    if(this.userData){
      if(!this.userData.profile.timezone || this.userData.profile.timezone == "NA"){
        this.timeZoneModel = true;
        this.loadCountries();
      }
    }
    this.mapsAPILoader.load().then(() => {
      this.setCurrentLocation();
      this.geoCoder = new google.maps.Geocoder;
      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();
          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          //set latitude, longitude and zoom
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.zoom = 12;
        });
      });
    });

    if(this.userData.profile.role_id == 4){
      this.apiService.EmployeeLimitCheckforMgr(this.userData.profile.role_id).subscribe((response: any) => {
        if(response.status == "ok"){
          this.ButtonShow = true;
        }else{
          this.ButtonShow = false;
        }
      });
      // this.managershow = false;
    }else{
      this.apiService.EmployeeLimitCheck().subscribe((response: any) => {
        if(response.status == "ok"){
          this.ButtonShow = true;
        }else{
          this.ButtonShow = false;
        }
      });
      // this.managershow = true;
    }

    // this.apiService.EmployeeLimitCheck().subscribe((response: any) => {
    //   if(response.status == "ok"){
    //     this.ButtonShow = true;
    //   }else{
    //     this.ButtonShow = false;
    //   }
    // });
  }
  switchLang(lang: string) {
    localStorage.setItem("language",lang)
    this.translate.use(lang);
  }
  showmap(value:number){
    this.agmshowmap = value;
  }
  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        if((this.latitude == "" || this.latitude == undefined) || (this.longitude == "" || this.longitude == undefined)){
          this.zoom = 8;
          this.latitude = position.coords.latitude;
          this.longitude = position.coords.longitude;
          // this.getAddress(this.latitude, this.longitude);
        }else{
          this.zoom = 8;
          // this.getAddress(this.latitude, this.longitude);
        }
      });
    }
  }
  onMapReady(map) {
    map.setOptions({
        zoomControl: 'true',
        zoomControlOptions: {
            position: google.maps.ControlPosition.RIGHT_CENTER,
            style: google.maps.ZoomControlStyle.DEFAULT
        }
    });
  }
  markerDragEnd($event: google.maps.MouseEvent) {
    this.latitude = $event.latLng.lat();
    this.longitude = $event.latLng.lng();
    this.getAddress(this.latitude, this.longitude);
  }
  getAddress(latitude, longitude) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;
          this.userTimezone.address = results[0].formatted_address;
        } else {
          console.log('No results found');
        }
      } else {
        console.log('Geocoder failed due to: ' + status);
      }

    });
  }
  ngAfterViewInit() {
    this.ngZone.run(() =>{
      this.socketService.userAvailabilitySocket.subscribe((data: any) => {
        if(data.data != undefined){
          this.apiService
          .getOrders(this.isTaskDate)
          .subscribe((response: any) => {
            this.orderList = response.data;
            let resUnallocated = [];
            let resAllocated = [];
            let resCompleted = [];
            response.data.forEach(element => {
              if(element.status == 'Unallocated'){
                resUnallocated.push(this.sharedfunction.processOrderData(element));
              }else if(element.status == 'Allocated' || element.status == 'In-Progress'|| element.status == 'Accepted'){
                resAllocated.push(this.sharedfunction.processOrderData(element));
              } else if (element.status == 'Delivered'){
                resCompleted.push(this.sharedfunction.processOrderData(element));
              }
            });
            this.unallocated = resUnallocated;
            this.allocated = resAllocated;
            this.completed = resCompleted;
            this.loadUserListSocket(this.orderList);
          });
          this.changeDetector.detectChanges();
        }
      });
    });
    this.ngZone.run(() =>{
      this.socketService.locationUpdateSocket.subscribe((data: any) => {
        if(data.data != undefined){
          // this.onlineList = null;
          this.changeDetector.detectChanges();
          // let tempData = [];
          // if(this.onlineListCopy){
          //   this.onlineListCopy.forEach(element => {
          //     if(element.user_id == data.data['user_id']){
          //       element.lat = data.data['lat'];
          //       element.lng = data.data['lng'];
          //     }
          //     tempData.push(element);
          //   });
          // }
          // this.onlineList = tempData;
          this.generalService.dashboardLocationUpdate(data['data']);
          this.changeDetector.detectChanges();
        }
      })
    })
  }
  checkUserExpired(){
    this.loader.show();
    this.apiService
      .checkUsers()
      .subscribe((response: any) => {
        this.checkUserExpires = response[0];
        var _date = new Date();
        var current_date = moment(_date).format("YYYY-MM-DD");
        var end_date = moment(this.checkUserExpires.end_date);
        var end_date1 = end_date.format("YYYY-MM-DD");
        var two_days_b4 = end_date.subtract(2, "days").format("YYYY-MM-DD");
        var one_days_b4 = end_date.add(1, "days").format("YYYY-MM-DD");
        var checkBetween = moment(current_date).isBetween(two_days_b4, end_date1);
        var checkIsSame = moment(end_date1).isSame(current_date);
        var checkIsBefore = moment(end_date1).isBefore(current_date);
        localStorage.setItem('is_expired', 'false');
        if(checkBetween || checkIsSame){
          this.isExpired = false;
          this.isExpiredTwodays = true;
        }else{
          if(checkIsBefore){
            this.isExpired = true;
            this.isExpiredTwodays = false;
            localStorage.setItem('is_expired', 'true');
          }
        }
        this.apiService.getUserPackageInfo(this.userData.profile.user_id).subscribe((limitCheckResponse: any) => {
          let userPackageInfo = limitCheckResponse['data'];
          if(userPackageInfo['remaining_task'] <=0 || userPackageInfo['remaining_task'] ==null){
            this.isExpired = true;
          }if(userPackageInfo['total_task'] ==-1){
            this.isExpired = false;
          }
        })
        this.loader.hide();
      });
  }
  loadTimeZonesData(){
    this.loader.show();
    this.apiService
      .getTimezone()
      .subscribe((response: any) => {
        this.timezones = response;
        this.loader.hide();
      });
  }
  loadCountries(){
    this.loader.show();
    this.apiService
      .getCountries()
      .subscribe((response: any) => {
        this.countries = response.data;
        this.loader.hide();
      });
  }
  countryChanged(){
    if(this.userTimezone.country_code){
      this.loadCountryByTimezone(this.userTimezone.country_code);
    }else{
      this.countryBasedTimezones = undefined;
      this.userTimezone.timezone = "";
    }
  }
  loadCountryByTimezone(code){
    this.loader.show();
    this.apiService
      .getTimezoneByCountryCode(code)
      .subscribe((response: any) => {
        this.countryBasedTimezones = response.data;
        this.loader.hide();
      });
  }
  loadOrders(){
    this.loader.show();
    this.apiService
      .getOrders(this.isTaskDate)
      .subscribe((response: any) => {
        this.orderList = response.data;
        let resUnallocated = [];
        let resAllocated = [];
        let resCompleted = [];
        response.data.forEach(element => {
          if(element.status == 'Unallocated'){
            resUnallocated.push(this.sharedfunction.processOrderData(element));
          }else if(element.status == 'Allocated' || element.status == 'In-Progress'|| element.status == 'Accepted'){
            resAllocated.push(this.sharedfunction.processOrderData(element));
          } else if (element.status == 'Delivered'){
            resCompleted.push(this.sharedfunction.processOrderData(element));
          }
        });
        this.unallocated = resUnallocated;
        this.allocated = resAllocated;
        this.completed = resCompleted;
        this.loader.hide();
        this.loadUserList(this.orderList);
      });
  }
  loadUserList(orderData){
    this.orderList = orderData;
    this.loader.show();
    this.apiService
      .userActiveLists()
      .subscribe((response: any) => {
        let totalOrders = [];
        if(response.data){
          response.data.forEach(element => {
            if(this.orderList.length){
              this.orderList.forEach(list => {
                if(element.emp_id == list.allocated_emp_id){
                  if(list.status == "In-Progress" || list.status == 'Started Ride' || list.status == 'In Supplier Place' || list.status == 'Products Picked up'){
                    element.isUserFor = 'busy';
                    element.isTaskCount = list.order_count ? list.order_count : 0;
                  }
                }
              });
              if(element.isUserFor){
                element.isUserFor = 'busy';
              }else{
                element.isUserFor = 'free';
                element.isTaskCount = 0;
              }
              totalOrders.push(element);
            }else{
              element.isUserFor = 'free';
              element.isTaskCount = 0;
              totalOrders.push(element);
            }
          });
        }
        if(totalOrders){
          totalOrders.forEach(element => {
            if(element?.profile_image){
              if(element.profile_image.length > 2){
                var result = element.profile_image.charAt(0);
                if(result == "["){
                  var getProfile = JSON.parse(element.profile_image);
                  element.profile_image = getProfile[0];
                }
              }
            }
          });
        }
        this.userlist = totalOrders;
        this.loader.hide();
      });
  }
  loadUserListSocket(orderData){
    this.orderList = orderData;
    this.apiService
      .userActiveLists()
      .subscribe((response: any) => {
        let totalOrders = [];
        if(response.data){
          response.data.forEach(element => {
            if(this.orderList.length){
              this.orderList.forEach(list => {
                if(element.emp_id == list.allocated_emp_id){
                  if(list.status == "In-Progress" || list.status == 'Started Ride' || list.status == 'In Supplier Place' || list.status == 'Products Picked up'){
                    element.isUserFor = 'busy';
                    element.isTaskCount = list.order ? list.order.length : 0;
                  }
                }
              });
              if(element.isUserFor){
                element.isUserFor = 'busy';
              }else{
                element.isUserFor = 'free';
                element.isTaskCount = 0;
              }
              totalOrders.push(element);
            }else{
              element.isUserFor = 'free';
              element.isTaskCount = 0;
              totalOrders.push(element);
            }
          });
        }
        if(totalOrders){
          totalOrders.forEach(element => {
            if(element?.profile_image){
              if(element.profile_image.length > 2){
                var result = element.profile_image.charAt(0);
                if(result == "["){
                  var getProfile = JSON.parse(element.profile_image);
                  element.profile_image = getProfile[0];
                }
              }
            }
          });
        }
        this.userlist = totalOrders;
        this.changeDetector.detectChanges();
      });
  }
  loadOnlineList(){
    this.loader.show();
    this.apiService
      .locationOnlineEmp()
      .subscribe((response: any) => {
        this.onlineList = response.data;
        this.onlineListCopy = this.onlineList;
        this.loader.hide();
        this.zoom = 10;
      });
  }
  callForStillMore(order: any, isFor:string){
    if(isFor == 'popOver'){
      let outString = '';
      let i = 0;
      if(order){
        order.forEach(element => {
          if(i>1){
            if(element.receiver_name){
              outString += `${element.receiver_name}`;
              if(order.length !== i+1){
                outString += `, `;
              }
            }
          }
          i = i+1;
        });
      }
      return outString;
    }
    if(isFor == 'isStill'){
      let outString = '';
      let i = 0;
      if(order.length > 2){
        outString += `+${order.length - 2}more`;
        return outString;
      }else{
        return outString;
      }
    }
  }
  onAutocompleteSelected(result: PlaceResult) {
    this.agmshowmap = 1;
    this.userTimezone.address = result.formatted_address;
  }
  onLocationSelected(location: Location) {
    this.latitude = location.latitude;
    this.longitude = location.longitude;
    this.locationsPoint = {
      latitude: this.latitude,
      longitude: this.longitude,
    }
  }
  onChange(result: PlaceResult){
    let val = result['target']['value'];
    if(val == ''){
      this.userTimezone.address = "";
    }
  }
  changeDate(event: MatDatepickerInputEvent<Date>){
    this.isTaskDate = this.datePipe.transform(event.value, 'yyyy-MM-dd');
    this.isAvailability = 'all';
    this.isViewAll = true;
    this.loadOrders();
  }
  reback(obj: any){
  }
  leftArrow(){
    this.isLeftArrow = !this.isLeftArrow;
  }
  rightArrow(){
    this.isRightArrow = !this.isRightArrow;
  }
  isGroup(obj :any) {
    this.isGroupObj = obj;
  }
  emitDashboard() {
    let payload = {
      "data": [{
        "type": "articles",
        "id": "1",
        "attributes": {
          "title": "JSON:API paints my bikeshed!",
          "body": "The shortest article. Ever.",
          "created": "2015-05-22T14:56:29.000Z",
          "updated": "2015-05-22T14:56:28.000Z"
        },
        "relationships": {
          "author": {
            "data": {"id": "42", "type": "people"}
          }
        }
      }],
      "included": [
        {
          "type": "people",
          "id": "42",
          "attributes": {
            "name": "John",
            "age": 80,
            "gender": "male"
          }
        }
      ]
    }
    this.generalService.DashboardModule(payload);
  }
  availability(obj: any) {
    if(obj == 'busy'){
      this.isViewAll = false;
      this.isAvailability = obj;
    }else if(obj == 'free'){
      this.isViewAll = false;
      this.isAvailability = obj;
    }else if (obj == 'all' && this.isViewAll){
      this.isAvailability = 'all';
    } else if (obj == 'all' && !this.isViewAll) {
      this.isAvailability = 'free';
    }
    this.loadOrders();
  }
  OrderDetails(orderID){
    this.router.navigate(['/order-details']);
    localStorage.setItem(
      "order_detail_id",
      orderID
    );
  }
  editOrder(obj){
    this.router.navigate(['/order-edit'],{queryParams: {id: obj['resource'].id}});
    localStorage.setItem(
      "order_detail_id",
      obj.resource.id
    );
    // this.generalService.EditOrderModule(obj.resource.id);
    // this.router.navigate(['/order-edit'],{queryParams: {id: obj['resource'].id}});
  }
  driverDetails(driverId,empID,freeOrBusy){
    let request = {
      free_busy_status: freeOrBusy,
      driver_detail_id: driverId,
      driver_employee_id: empID,
      date: this.isTaskDate,
    }
    this.router.navigate(['/driver-detail']);
    localStorage.setItem(
      "driver_details",
      JSON.stringify(request)
    );
  }
  onClose(){
    this.timeZoneModel = false;
  }
  send_mail(){
    console.log('send_mail');
  }
  nameConcat(fn,ln){
    return `${fn} ${ln}`;
  }
  onSubmit(){
    if(!this.userTimezone.country_code){
      this.snackBar.open(this.Country, this.Close, {
        duration: 1000,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      });
    } else if (!this.userTimezone.timezone){
      this.snackBar.open(this.TimezineReq, this.Close, {
        duration: 1000,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      });
    } else if (!this.userTimezone.businestype){
      this.snackBar.open(this.BusiReq, this.Close, {
        duration: 1000,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      });
    } else if (!this.userTimezone.businessname){
      this.snackBar.open(this.BusiNameReq, this.Close, {
        duration: 1000,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      });
    } else if(this.userTimezone.businessname.trim() === ""){
      this.snackBar.open('Business filed should be valid Information', 'Close', {
        duration: 1000,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      });
    }else if (!this.userTimezone.address){
      this.snackBar.open(this.AddressReq, this.Close, {
        duration: 1000,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      });
    }else {
      let payload = {
        data: {
          timezone: this.userTimezone.timezone,
          country_code: this.userTimezone.country_code,
          business_type: this.userTimezone.businestype,
          business_name: this.userTimezone.businessname,
          street: this.userTimezone.address,
          mailnote: "",
          smsnote: "",
          is_multipick: "",
          lat: this.latitude,
          lng: this.longitude,
        }
      }
      this.loader.show();
      this.apiService
        .updateBasicInfo(payload)
        .subscribe((response: any) => {
          if(response.status == 'ok'){
            this.generalService.refreshHeader({
              timeZone: this.userTimezone.timezone,
              status: true
            });
            localStorage.setItem(
              "user_data",
              JSON.stringify(response.data)
            );
            this.zoom = 12;
            localStorage.setItem("zoom_control","10")
            this.onlineList = undefined;
            this.loadOnlineList();
            this.timeZoneModel = false;
          }
          this.loader.hide();
        });
    }
  }
}
<div class="container-fluid" style="margin-top: 10px;">
  <div class="col text-left">
    <h5 class="mb-4 text-muted"><b>{{'Item' | translate}} </b></h5>
  </div>
  <div class="col text-right">
    <button mat-raised-button color="primary" routerLink="/add-item" matTooltip="click here"><b>{{'Add Item' | translate}}</b></button>
  </div>
</div>
<div class="container-fluid">
  <div class="card-body">
    <div class="row px-3 py-0">
      <div class="col-12 bg-light py-3">
        <div class="row justify-content-between">
          <div class="col-md">
            <div class="row">
            </div>
          </div>
          <div class="col-auto">
            <div class="row">
              <div class="col-auto">
                <div class="input-group mb-3">
                  <input type="text" class="form-control" (keyup)="ApplyFilter($event)" placeholder="{{'Search' | translate}}" autocomplete="off">
                  <div class="input-group-append">
                    <span class="fa fa-search input-group-text pointer"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="card-body">
          <div class="table-responsive">
            <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8" style="width: 100%;border: 1px solid #ccc;">
              <!-- Position Column -->
              <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="background-color:#1e9e95; color: white; font-size: 15px;"> {{'Serial No' | translate}} </th>
                <td mat-cell *matCellDef="let element; let i = index"> {{i + 1}} </td>
              </ng-container>
              <!-- Name Column -->
              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="background-color:#1e9e95; color: white; font-size: 15px;"> 	{{'Item Name' | translate}}  </th>
                <td mat-cell *matCellDef="let element"> {{element.name}} </td>
              </ng-container>
              <!-- Date Column -->
              <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="background-color:#1e9e95; color: white; font-size: 15px;"> 	{{'Date' | translate}}  </th>
                <td mat-cell *matCellDef="let element"> {{element.updated_at | date}} </td>
              </ng-container>
              <ng-container matColumnDef="Actions">
                <th mat-header-cell *matHeaderCellDef style="background-color:#1e9e95; color: white; font-size: 15px;"> {{'Actions' | translate}} </th>
                <td mat-cell *matCellDef="let element">
                  <mat-icon title="{{'Edit Item' | translate}}" class="action-icons pointer clickme"
                  (click)="EditItem(element.id)">edit</mat-icon>
                  <!-- <mat-icon title="Delete Item" class="action-icons pointer clickme" 
                  (click)="DeleteModel(element.id)">delete</mat-icon> -->
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <div style="padding-top: 10px;" *ngIf="dataSource?.filteredData.length === 0"> 
              <p class="text-center">No Records Found!</p><hr>
            </div>
            <mat-paginator #paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal full-size" tabindex="-1" role="dialog"
[ngClass]="{ fade: !isDeleteModel, 'd-flex': isDeleteModel }">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title w-100">Confirmation</h5>
        <a class="btn btn-sm btn-white dialog-close" (click)="onClose()"><span aria-hidden="true">&times;</span></a>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12" style="padding-bottom: 1px;">
            <b>Are you sure to delete this Item?</b>
          </div>
        </div>
        <div class="col-md-12 text-right" style="padding-bottom: 1px; padding-top: 5px;">
          <button type="button" class="btn btn-sm btn-info rounded-0 mb-2 mr-2" (click)="onClose()">Cancel</button>
          <button type="button" class="btn btn-sm btn-outline-info rounded-0 mb-2 mr-2" (click)="DeleteItem()">Yes, Delete</button>
        </div>
      </div>
    </div>
  </div>
</div>
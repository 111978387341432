
<div class="container-fluid">
    <div class="row" style="margin-top: 10px;">
      <div class="col text-left">
        <h5 class="mb-4 text-muted"><b>{{'Package Information' | translate}} </b></h5>
      </div>
      <div class="col text-right">
        <!-- <button mat-raised-button color="primary" *ngIf="ButtonShow == true" routerLink="/add-manager" matTooltip="click here"><b>{{'Add Manager' | translate}}</b></button> -->
        <!-- <p *ngIf="ButtonShow == false" style="color: Red;"><b>{{'You Reached Maximum Limit' | translate}}</b></p> -->
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <div class="card-body">
      <div class="row px-3 py-0">
        <div class="col-12 bg-light py-3">
          <div class="row justify-content-between">
            <div class="col-md">
              <div class="row">
                
              </div>
            </div>
            <div class="col-auto">
              <div class="row">
                <div class="col-auto">
                  <div class="input-group mb-3">
                    <input type="text" class="form-control" (keyup)="applyFilter($event)" placeholder="{{'Search' | translate}}" autocomplete="off">
                    <div class="input-group-append">
                      <span class="fa fa-search input-group-text pointer"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="card-body">
            <div class="table-responsive">
            
              <table mat-table [dataSource]="packageInformationListSource" matSort class="mat-elevation-z8" style="width: 100%;border: 1px solid #ccc;">
  
                <!--- Note that these columns can be defined in any order.
                      The actual rendered columns are set as a property on the row definition" -->
              
                <ng-container matColumnDef="id">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="tablehead" style="background-color: #1e9e95;color: #fff;font-size: 15px;"> {{'Package ID' | translate}} </th>
                    <td mat-cell *matCellDef="let element"> {{element.id}} </td>
                </ng-container>

                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="tablehead" style="background-color: #1e9e95;color: #fff;font-size: 15px;"> {{'Package Name' | translate}} </th>
                    <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                </ng-container>

                <ng-container matColumnDef="price">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header class="tablehead" style="background-color: #1e9e95;color: #fff;font-size: 15px;"> {{'Price' | translate}} </th>
                  <td mat-cell *matCellDef="let element"> ${{element.price}} (AED {{element.price_in_aed}})</td>
                </ng-container>
              
                <ng-container matColumnDef="month">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header class="tablehead" style="background-color: #1e9e95;color: #fff;font-size: 15px;"> {{'No of Days' | translate}}</th>
                  <td mat-cell *matCellDef="let element"> {{element.no_of_days}} </td>
                </ng-container>

                <ng-container matColumnDef="no_of_emp">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="tablehead" style="background-color: #1e9e95;color: #fff;font-size: 15px;"> {{'No of Employees' | translate}}</th>
                    <td mat-cell *matCellDef="let element"> {{element.no_of_emp !== "-1" ? element.no_of_emp : "Unlimited"}} </td>
                </ng-container>

                <ng-container matColumnDef="no_of_cust">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="tablehead" style="background-color: #1e9e95;color: #fff;font-size: 15px;"> {{'No of Customers' | translate}}</th>
                    <td mat-cell *matCellDef="let element"> {{element.no_of_cust}} </td>
                </ng-container>

                <ng-container matColumnDef="no_of_task">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="tablehead" style="background-color: #1e9e95;color: #fff;font-size: 15px;"> {{'No of Tasks' | translate}}</th>
                    <td mat-cell *matCellDef="let element"> {{element.no_of_task}} </td>
                </ng-container>

                <ng-container matColumnDef="no_of_mgr">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="tablehead" style="background-color: #1e9e95;color: #fff;font-size: 15px;"> {{'No of Managers' | translate}}</th>
                    <td mat-cell *matCellDef="let element"> {{element.no_of_mgr}} </td>
                </ng-container>
  
                <!-- <ng-container matColumnDef="Actions">
                  <th mat-header-cell *matHeaderCellDef class="tablehead" style="background-color: #1e9e95;color: #fff;font-size: 15px;text-align: center;"> {{'Actions' | translate}} </th>
                  <td mat-cell *matCellDef="let element" style="text-align: center;">
                        <mat-icon title="{{'View Payment History' | translate}}" class="action-icons" style="cursor: pointer;color: green;vertical-align:middle;" (click)="ViewManager(element.user_id)">visibility</mat-icon>
                        <mat-icon title="{{'Extension Action' | translate}}" class="action-icons" style="cursor: pointer;color: green;vertical-align:middle;" (click)="ViewManager(element.user_id)">visibility</mat-icon>
                  </td>
                </ng-container> -->
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </table>
              <div style="padding-top: 10px;" *ngIf="packageInformationListSource?.filteredData.length === 0"> 
                <p class="text-center">{{'No Records Found!' | translate}}</p><hr>
              </div>
              <mat-paginator #paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
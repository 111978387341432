import { Component, OnInit, ViewChild, QueryList, ViewChildren, ElementRef, Inject, ChangeDetectorRef } from "@angular/core";
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders, HttpErrorResponse } from "@angular/common/http";
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import $ from "jquery";
import { AuthService } from 'src/app/services/AuthService';
import { AppService } from 'src/app/services/AppService';
import { ApiService } from 'src/app/services/ApiService';
import { Image_Path } from 'src/app/config/config';
import { GeneralService } from 'src/app/services/GeneralService';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ThemePalette } from '@angular/material/core';
import * as moment from 'moment';

export interface UpdateDialogData {
  user_id: any;
  update_data: any;
  param: any;
}
@Component({
  selector: 'app-view-user-package',
  templateUrl: './view-user-package.component.html',
  styleUrls: ['./view-user-package.component.css']
})
export class ViewUserPackageComponent implements OnInit {
  loaderOne = false;
  isOpen = false;
  modelTitle: any;
  ProfileData: any;
  Firstname: any;
  Lastname: any;
  Phoneno: any;
  Email: any;
  PickupAddress: any;
  Role: string;
  UserData: any;
  PackageDetails: any;
  PackageName: any;
  PackActivateDate: any;
  PackEndDate: any;
  TotalAllocatedEmployees: any;
  Remainingloyees: any;
  SelectedTask: string;
  auto_checked:boolean = false;
  autoallocshow: boolean;
  Timezone: any;
  Location: any;
  ReqExpires: any;
  MaxRadius: any;
  alloclogic: any;
  autochecked: number;
  S3BaseUrl = Image_Path.S3_BASE_URL;
  headers: HttpHeaders;
  ProfileImage: any;
  color: any;
  disabled: any;
  fileToUpload: File;
  profileImage: any;
  userData: any;
  packDataInfo: any;
  total_emp: number;
  remaining_emp: number;
  total_mgr: any;
  remaining_mgr: any;
  managershow: boolean;
  UserID: string;
  userPackageInfo: any;
  popupForm: FormGroup;
  popupFormExpiry: FormGroup;
  popupFormCust: FormGroup;
  popupFormTask: FormGroup;
  popupFormEmp: FormGroup;
  isChecked:boolean=false;
  constructor(
    public dialog: MatDialog,
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private loader: NgxSpinnerService,
    private toastr: ToastrService,
    private authService: AuthService,
    public appService: AppService,
    public apiService: ApiService,
    public generalService: GeneralService,
    private snackBar:MatSnackBar,
    public ref: ChangeDetectorRef,
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit() {
    this.userData = JSON.parse(localStorage.getItem("user_data"));
    this.UserID = this.route.snapshot.paramMap.get('user_id');
    if(this.UserID){
      this.viewUserPackage()
    }
    this.popupFormExpiry = this.formBuilder.group({
      date: ['', Validators.required]
    })
    this.popupFormCust = this.formBuilder.group({
      no_of_cust: ['', Validators.required]
    })
    this.popupFormTask = this.formBuilder.group({
      total_task: ['', Validators.required]
    })
    this.popupFormEmp = this.formBuilder.group({
      total_emp: ['', Validators.required]
    })
    
  }

  viewUserPackage(){
    this.apiService.getUserPackageInfo(this.UserID).subscribe((response)=>{
      this.userPackageInfo = response['data']
        if(this.userPackageInfo.user_package.userinfo.is_active ==1)
        {
          this.isChecked=true;
        }
    })
  }
  onChange(event:any,userid)
  {
    console.log(event.checked,"toggle")
    let user_status;
    if(event.checked==true)
    {
      user_status=1;
    }
    else{
      user_status=0;
    }
   var payload=
    {
      data:{
      "active_status":user_status
      }
    }
    this.apiService.update_useractivestatus(userid,payload).subscribe((response:any)=>{
      if(response.status == "ok"){
      this.toastr.success('Status updated successfully');
      }
    })
    
    
  }
  openDialog(user_id,update_data,param): void {
    const dialogRef = this.dialog.open(DialogExtendAction, {
      width: '300px',
      data: {'user_id': user_id, 'update_data': update_data,'param':param}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.apiService.getUserPackageInfo(this.UserID).subscribe((response)=>{
        this.userPackageInfo = response['data']
        if(this.userPackageInfo.user_package.userinfo.is_active ==1)
        {
          this.isChecked=true;
        }
      })
    });
  }
}

@Component({
  selector: 'dialog-extend-action',
  templateUrl: './dialog-extend-action.html',
})
export class DialogExtendAction {
  date:any;
  minDate: Date;
  inputReadonly: boolean=true;
  
  public color: ThemePalette = 'primary';
  public stepSecond = 1;
  no_of_cust: any;
  total_emp: any;
  total_task: any;
  UserID: string;
  no_of_cust_checked:boolean=false;
  total_emp_checked: boolean=false;
  total_task_checked: boolean=false;
  constructor(
    public dialogRef: MatDialogRef<DialogExtendAction>,
    @Inject(MAT_DIALOG_DATA) public data: UpdateDialogData,
    public apiService: ApiService,
    private route: ActivatedRoute,
    ) {
      this.UserID = this.route.snapshot.paramMap.get('user_id');
      if(this.data.param == 'expiry_date'){
        this.date = new Date(this.data.update_data)
      }else if(this.data.param =='no_of_cust'){
        this.no_of_cust = this.data.update_data
      }else if(this.data.param == 'total_emp'){
        if(this.data.update_data !== -1) {
          this.total_emp = this.data.update_data;
          this.total_emp_checked = false;
        } else {
          this.total_emp = "";
          this.total_emp_checked = true;
        }
      }else if(this.data.param == 'total_task'){
        this.total_task = this.data.update_data
      }
    }

  onNoClick(): void {
    this.dialogRef.close();
  }
  updateData(){
    let payload= {'data':{'user_id':this.data.user_id}}
    if(this.data.param == 'expiry_date'){
      // this.date = this.date.toString("YYYY-MM-DD")
      this.date = moment(this.date).format('YYYY-MM-DD')
      payload['data']['date'] = this.date
    }else if(this.data.param == 'no_of_cust'){
      payload['data']['no_of_cust'] = this.no_of_cust
      if(this.no_of_cust_checked ==true){
        payload['data']['no_of_cust'] = -1
      }
    }else if(this.data.param == 'total_emp'){
      payload['data']['no_of_emp'] = this.total_emp
      if(this.total_emp_checked ==true){
        payload['data']['no_of_emp'] = -1
      }
    }else if(this.data.param == 'total_task'){
      payload['data']['no_of_task'] = this.total_task
      if(this.total_task_checked ==true){
        payload['data']['no_of_task'] = -1
      }
    }
    
    this.apiService.updatePackageExtension(payload).subscribe((response)=>{
      console.log(response);
      this.dialogRef.close();
    })
  }
  ngOnInit(): void {
    
    const now = new Date();
    this.minDate = new Date();
    this.minDate.setDate(now.getDate());
    
    
    
  }
  checkboxClick(){
    console.log("test",this.no_of_cust_checked)
  }
}

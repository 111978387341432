import { Component, OnInit, ViewChild, ElementRef, NgZone } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders, HttpErrorResponse } from "@angular/common/http";
import { NgxSpinnerService } from "ngx-spinner";
import { FormControl } from '@angular/forms'; 
import { ToastrService } from 'ngx-toastr';
import { FormGroup } from '@angular/forms';
import { AppService } from './../../../services/AppService';
import { ApiService } from './../../../services/ApiService';
import { AuthService } from './../../../services/AuthService';
import { Config } from '../../../config/config';
import { Location, Appearance, GermanAddress } from '@angular-material-extensions/google-maps-autocomplete';
import PlaceResult = google.maps.places.PlaceResult;
import { MatSnackBar } from '@angular/material/snack-bar';
import { GeneralService } from 'src/app/services/GeneralService';
import { MapsAPILoader } from '@agm/core';
import { TranslateService } from '@ngx-translate/core';

interface TaskSelect {
  value: number;
  Task: string;
}
interface BusiType {
  value: string;
  name: string;
}

@Component({
  selector: 'app-profile-edit',
  templateUrl: './profile-edit.component.html',
  styleUrls: ['./profile-edit.component.css'],
})
export class ProfileEditComponent implements OnInit {
  latitude: any;
  longitude: any;
  public bankFilterCtrl: FormControl = new FormControl();
  ProfileData: any;
  Firstname: any;
  Lastname: any;
  Phoneno: any;
  Email: any;
  PickupAddress: any;
  task_based: boolean;
  is_redline: boolean;
  pdpa_ompilance: boolean;
  Timezone: any;
  Location: any;
  DefPickAddress: any;
  userid: any;
  ProfileImage: any;
  SelectedCountry:any;
  Selection: TaskSelect[] = [
    {value: 1, Task: 'Single Pickup Multi Delivery'},
    {value: 2, Task: 'Multi Pickup Single Delivery'},
    {value: 3, Task: 'Single Pickup Single Delivery'}
  ];
  BusinessType: BusiType[] = [
    {value: 'FMCGBusiness', name: 'FMCG ( Food and Beverages ) Business'},
    {value: 'LogisticsBusiness', name: 'Logistics Business'},
    {value: 'CourierBusiness', name: 'Courier Business'}
  ];
  SelectedTask: any;
  Role: string;
  ReqExpires: any;
  MaxRadius: any;
  taskbased: string;
  isredline: string;
  latitude1: number;
  longitude1: number;
  ispdpaompilance: string;
  CountryList: any;
  CountryBasedTimezones: any;
  timezoneshow: boolean;
  SelectedTimezone:any;
  Business_Type:any;
  BusinessName:any;
  MapApiKey: any;

  zoom: number;
  address: string;
  private geoCoder;
  company_lat: number;
  company_lng: number;

  agmshowmap = 0;

  @ViewChild('search')
  public searchElementRef: ElementRef;
  agmshowmap1: number = 0;
  pick_latitude: number;
  pick_longitude: number;
  SelectLang: string;
  Country: string;
  BusiName: string;

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private loader: NgxSpinnerService,
    private toastr: ToastrService,
    private authService: AuthService,
    public appService: AppService,
    public apiService: ApiService,
    private snackBar:MatSnackBar,
    public generalService: GeneralService,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    public translate: TranslateService
  ) {}

  ngOnInit() {
    this.SelectLang = localStorage.getItem("language");
    this.translate.get('Business Name is Required').subscribe((text: string) => {
      this.BusiName = text
    });
    this.translate.use(this.SelectLang);
    this.apiService
      .profileList()
      .subscribe((response: any) => {
        this.ProfileData = response.data;
        this.userid = this.ProfileData.user_id;
        this.Firstname = this.ProfileData.first_name;
        this.Lastname = this.ProfileData.last_name;
        this.Phoneno = this.ProfileData.phone;
        this.Email = this.ProfileData.email;
        this.Location = this.ProfileData.street;
        this.latitude = parseFloat(this.ProfileData.company_lat);
        this.longitude = parseFloat(this.ProfileData.company_lng);
        this.DefPickAddress = this.ProfileData.pickup_address;
        this.geoCoder.geocode({ 'address': this.DefPickAddress }, (results, status) => {
          this.pick_latitude = parseFloat(results[0].geometry.location.lat());
          this.pick_longitude = parseFloat(results[0].geometry.location.lng());
          this.zoom = 12;
        });
        this.SelectedCountry = this.ProfileData.country_code ;
        this.apiService
          .getTimezoneByCountryCode(this.SelectedCountry)
          .subscribe((response: any) => {
            this.CountryBasedTimezones = response.data;
          });
        this.BusinessName = this.ProfileData.business_name;
        this.MapApiKey = this.ProfileData.map_api_key;
        this.Business_Type = this.ProfileData.business_type;
        this.Timezone = this.ProfileData.timezone;
        this.ProfileImage = this.ProfileData.profile_image;
        this.task_based = this.ProfileData.is_task_track
        if(this.ProfileData.is_task_track == "1"){
          this.task_based = true;
        }else if(this.ProfileData.is_task_track == "0"){
          this.task_based = false;
        }
        if(this.ProfileData.is_redline == "1"){
          this.is_redline = true;
        }else if(this.ProfileData.is_redline == "0"){
          this.is_redline = false;
        }
        if(this.ProfileData.pdpa_compilance == "1"){
          this.pdpa_ompilance = true;
        }else if(this.ProfileData.pdpa_compilance == "0"){
          this.pdpa_ompilance = false;
        }
        this.SelectedTask = this.ProfileData.delivery_logic;
        if(this.ProfileData.role_id == 1){
          this.Role = "Driver"
        }else if(this.ProfileData.role_id == 2){
          this.Role = "Manager"
        }
        this.ReqExpires = this.ProfileData.auto_allocation_expires_in;
        this.MaxRadius = this.ProfileData.auto_allocation_max_radius_in_km;
      })
      this.apiService
      .getCountries()
      .subscribe((response: any) => {
        this.CountryList = response.data;
      })

      this.mapsAPILoader.load().then(() => {
      this.setCurrentLocation();
      
      this.geoCoder = new google.maps.Geocoder;

      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();

          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          //set latitude, longitude and zoom
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.zoom = 12;
        });
      });
    });
  }

  switchLang(lang: string) {
    localStorage.setItem("language",lang)
    this.translate.use(lang);
  }

  showmap(value:number){
    this.agmshowmap = value;
  }

  showmap1(value:number){
    this.agmshowmap1 = value;
  }

  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        if((this.latitude == "" || this.latitude == undefined) || (this.longitude == "" || this.longitude == undefined)){
          this.zoom = 8;
          this.latitude = position.coords.latitude;
          this.longitude = position.coords.longitude;
          // this.getAddress(this.latitude, this.longitude);
        }else{
          this.zoom = 8;
          // this.getAddress(this.latitude, this.longitude);
        }
      });
    }
  }


  markerDragEnd($event: google.maps.MouseEvent) {
    this.latitude = $event.latLng.lat();
    this.longitude = $event.latLng.lng();
    this.getAddress(this.latitude, this.longitude);
  }

  markerDragEndNew($event: google.maps.MouseEvent) {
    this.pick_latitude = $event.latLng.lat();
    this.pick_longitude = $event.latLng.lng();
    this.getAddress_pick(this.latitude, this.longitude);
  }

  getAddress(latitude, longitude) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;
          this.Location = results[0].formatted_address;
        } else {
          console.log('No results found');
        }
      } else {
        console.log('Geocoder failed due to: ' + status);
      }

    });
  }

  getAddress_pick(latitude, longitude) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;
          this.DefPickAddress = results[0].formatted_address;
        } else {
          console.log('No results found');
        }
      } else {
        console.log('Geocoder failed due to: ' + status);
      }

    });
  }
  
  onAutocompleteSelected(result: PlaceResult) {
    this.Location = result.formatted_address;
  }
  onLocationSelected(location: Location) {
    this.latitude = location.latitude;
    this.longitude = location.longitude;
  }
  onAutocompleteSelected1(result: PlaceResult) {
    this.DefPickAddress = result.formatted_address;
  }
  onLocationSelected1(location: Location) {
    this.latitude1 = location.latitude;
    this.longitude1 = location.longitude;
  }

  onChange(result: PlaceResult){
    let val = result['target']['value']
    if(val ==''){
      this.DefPickAddress = result.formatted_address
    }
    else{
      this.DefPickAddress = null
    }
  }
  
  // onKey(value) { 
  //   this.CountryList = this.search(value);
  //   }

  //   search(value: string) { 
  //     let filter = value.toLowerCase();
  //     return this.CountryList.filter(option => option.toLowerCase().startsWith(filter));
  //   }

  TimezoneChange(event:any){
    var countrycode = event.value;
    this.loader.show();
    this.apiService
      .getTimezoneByCountryCode(countrycode)
      .subscribe((response: any) => {
        this.timezoneshow = true;
        this.CountryBasedTimezones = response.data;
        this.loader.hide();
      });
  }
    

  UpdateProfile(){
    if(this.Firstname == undefined || this.Firstname == ''){
      this.snackBar.open("First Name is Required","Close",{
        duration: 4000,
        verticalPosition:'bottom'
      })
      return 0;
    }
    if(this.Lastname == undefined || this.Lastname == ''){
      this.snackBar.open("Last Name is Required","Close",{
        duration: 4000,
        verticalPosition:'bottom'
      })
      return 0;
    }
    if(this.BusinessName == undefined || this.BusinessName == ''){
      this.snackBar.open(this.BusiName,"Close",{
        duration: 4000,
        verticalPosition:'bottom'
      })
      return 0;
    }
    if(this.Location == undefined || this.Location == ''){
      this.snackBar.open("Company Location is Required","Close",{
        duration: 4000,
        verticalPosition:'bottom'
      })
      return 0;
    }
    if(this.task_based == true){
      this.taskbased = "1";
    } else {
      this.taskbased = "0";
    }
    if(this.is_redline == true){
      this.isredline = "1";
    } else {
      this.isredline = "0";
    }
    if(this.pdpa_ompilance == true){
      this.ispdpaompilance = "1";
    } else {
      this.ispdpaompilance = "0";
    }
    this.loader.show();
    var data = {
      "is_multipick":0,
      "mailnote": "true",
      "notification_time_in_minutes":60,
      "smsnote":0,
      "timezone":this.Timezone,
      data:{
        "user_id": this.userid,
        "role_id": 2,
        "first_name": this.Firstname,
        "last_name": this.Lastname,
        "phone": this.Phoneno,
        "street": this.Location,
        "zipcode": "1",
        "email": this.Email,
        "pdpa_compilance": this.ispdpaompilance,
        "timezone": this.Timezone,
        "is_task_track": this.taskbased,
        "is_redline": this.isredline,
        "notification_time_in_minutes": 60,
        "pickup_address": this.DefPickAddress,
        "delivery_logic":this.SelectedTask,
        "employee_lat" : this.latitude,
        "employee_lng" : this.longitude,
        "country_code" : this.SelectedCountry,
        "business_name" : this.BusinessName,
        "business_type" : this.Business_Type,
        "map_api_key": this.MapApiKey
      }
    }
    this.apiService
      .editProfile(data)
      .subscribe((response: any) => {
        if(response.status == "ok"){
          this.generalService.refreshHeader({
            timeZone: this.Timezone,
            status: true
          });
          this.router.navigate(["/profile"]);
          this.toastr.success('Profile Details Updated Successfully');
          this.loader.hide();
        }
      });
  }
  Cancel(){
    this.router.navigate(["/profile"]);
  }
}


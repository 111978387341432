import { ChangeDetectorRef, Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders, HttpErrorResponse } from "@angular/common/http";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { FormGroup } from '@angular/forms';
import { AuthService } from './../../services/AuthService';
import { AppService } from './../../services/AppService';
import { ApiService } from './../../services/ApiService';
import { Image_Path } from 'src/app/config/config';
import { SocketService } from 'src/app/services/SocketService';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { GeneralService } from 'src/app/services/GeneralService';

@Component({
  selector: 'app-driver-detail',
  templateUrl: './driver-detail.component.html',
  styleUrls: ['./driver-detail.component.css'],
})
export class DriverDetailComponent implements OnInit {
  @ViewChild('paginator') paginator: MatPaginator;
  driverList: any;
  driverInfo: any;
  S3BaseUrl = Image_Path.S3_BASE_URL;
  driverSocketList: any;
  isOrderLogic: any = "All";
  orderFilterData: any = [];
  dataSource: any;
  page = 1;
  limit = 5;
  filterOption = { itemsPerPage: this.limit, currentPage : this.page};
  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private loader: NgxSpinnerService,
    private toastr: ToastrService,
    private authService: AuthService,
    public appService: AppService,
    public apiService: ApiService,
    public socketService: SocketService,
    public ngZone: NgZone,
    public changeDetector: ChangeDetectorRef,
    public generalService: GeneralService,
  ) { }

  ngOnInit() {
    this.driverInfo = JSON.parse(localStorage.getItem("driver_details"));
    this.loader.show();
    this.apiService
      .driverActiveList()
      .subscribe((response: any) => {
        let driverData = response.data.find(element => element.id == this.driverInfo.driver_detail_id);
        if(driverData.profile_image.length > 2){
          var result = driverData.profile_image.charAt(0);
          if(result == "["){
            var getProfile = JSON.parse(driverData.profile_image);
            driverData.profile_image = getProfile[0];
          }
        }
        this.driverList = driverData;
      });
    this.getMyOrderList();
    this.getMyLocations();
  }
  ngAfterViewInit() {
    this.ngZone.run(() =>{
      this.socketService.locationUpdateSocket.subscribe((data: any) => {
        if(data.data != undefined){
          if(data['data']['user']){
            if(data['data'].user_id == this.driverInfo.driver_employee_id){
              data['data']['user']['battery_status'] = data['data']['battery_status'];
              data['data']['user']['speed'] = data['data']['speed'];
              data['data']['lat'] = data['data']['lat'];
              data['data']['lng'] = data['data']['lng'];
              this.driverList = data['data']['user'];
              this.generalService.driverLocationUpdate(data['data']);
            }
            this.changeDetector.detectChanges();
          }
        }
      });
    });
  }
  getMyOrderList(){
    this.selectTable();
    this.loader.show();
    var payload = {
      data: {
        driver_id: this.driverInfo.driver_employee_id,
        filter_type: this.isOrderLogic,
      }
    }
    this.apiService
    .GetFilterOrderList(payload)
    .subscribe((response: any) => {
      let tempData = [];
      Object.keys(response.data).forEach(function(key) {
        tempData.push(response.data[key][0]);
      }); 
      this.orderFilterData = tempData;
      this.dataSource = new MatTableDataSource(this.orderFilterData);
      setTimeout(() => this.dataSource.paginator = this.paginator);
      this.loader.hide();
    });  
  }
  getMyLocations(){
    this.loader.show();
    this.apiService
      .getMyLocation(this.driverInfo.date, this.driverInfo.driver_employee_id)
      .subscribe((response: any) => {
        this.loader.hide();
      });
  }
  selectTable(){
    this.page = 1;
    this.filterOption = { itemsPerPage: this.limit, currentPage : this.page};
  }
  onPageChange(event){
    this.page = event;
    this.filterOption = { itemsPerPage: this.limit, currentPage : this.page};
  }
  OrderDetails(orderID){
    this.router.navigate(['/order-details']);
    localStorage.setItem(
      "order_detail_id",
      orderID
    );
  }
  reback(obj){ }
}

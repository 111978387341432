import { Component, OnInit, ViewChild, ElementRef, NgZone } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders, HttpErrorResponse } from "@angular/common/http";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { FormGroup } from '@angular/forms';
import { ApiService } from './../../services/ApiService';
import { AppService } from './../../services/AppService';
import { AuthService } from './../../services/AuthService';
import { Config } from '../../config/config';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Location, Appearance, GermanAddress } from '@angular-material-extensions/google-maps-autocomplete';
import PlaceResult = google.maps.places.PlaceResult;
import { SearchCountryField } from 'custom-template/ngx-intl-tel-input/src/lib/enums/search-country-field.enum';
import { TooltipLabel } from 'custom-template/ngx-intl-tel-input/src/lib/enums/tooltip-label.enum';
import { CountryISO } from 'custom-template/ngx-intl-tel-input/src/lib/enums/country-iso.enum';
import { MapsAPILoader } from '@agm/core';

@Component({
  selector: 'app-edit-customer',
  templateUrl: './edit-customer.component.html',
  styleUrls: ['./edit-customer.component.css']
})
export class EditCustomerComponent implements OnInit {

  SearchCountryField = SearchCountryField;
	TooltipLabel = TooltipLabel;
  CountryISO = CountryISO;
	preferredCountries: CountryISO[] = [
    CountryISO.India,
    CountryISO.Canada,
  ];
  latitude: any;
  longitude: any;
  role_id: number;
  Customerdetails: any;
  CustName: any;
  CustPhone: any;
  CustEmail: any;
  CustAddress: any;
  Cust_ID: any;
  Emp_id: any;
  CustomerAddress: any;
  Location: Location;

  zoom: number;
  address: string;
  private geoCoder;
  company_lat: number;
  company_lng: number;

  agmshowmap = 0;

  @ViewChild('search')
  public searchElementRef: ElementRef;

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private loader: NgxSpinnerService,
    private toastr: ToastrService,
    private authService: AuthService,
    public appService: AppService,
    public apiService: ApiService,
    private snackBar:MatSnackBar,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
  ) { }

  baseUrl = Config.BASE_URL;
  apiUrl = this.baseUrl + "api/";

  ngOnInit(): void {
    var role = localStorage.getItem("user_data");
    var roleid = JSON.parse(role);
    if(roleid.role == 'manager'){
      this.role_id = 2;
    } else if(roleid.role == 'driver'){
      this.role_id = 1
    }
    var cust_id = localStorage.getItem("cust_id");
    this.apiService
      .customerListById(cust_id)
      .subscribe((response: any) => {
        this.Customerdetails = response.data;
        this.Cust_ID = this.Customerdetails.id;
        this.Emp_id = this.Customerdetails.emp_id;
        this.CustName = this.Customerdetails.name;
        this.CustPhone = this.Customerdetails.contact_no;
        this.CustEmail = this.Customerdetails.email;
        this.CustAddress = this.Customerdetails.address;
        this.CustomerAddress = this.Customerdetails.address;
        this.latitude   = parseFloat(this.Customerdetails.loc_lat);
        this.longitude = parseFloat(this.Customerdetails.loc_lng);
      });

      this.mapsAPILoader.load().then(() => {
        // this.setCurrentLocation();
        
        this.geoCoder = new google.maps.Geocoder;
  
        let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
        autocomplete.addListener("place_changed", () => {
          this.ngZone.run(() => {
            //get the place result
            let place: google.maps.places.PlaceResult = autocomplete.getPlace();
  
            //verify result
            if (place.geometry === undefined || place.geometry === null) {
              return;
            }
  
            //set latitude, longitude and zoom
            this.latitude = place.geometry.location.lat();
            this.longitude = place.geometry.location.lng();
            this.zoom = 12;
          });
        });
      });
      // navigator.geolocation.getCurrentPosition((position) => {
      //   if((this.latitude == "" || this.latitude == undefined) || (this.longitude == "" || this.longitude == undefined)){
      //     this.zoom = 8;
      //     this.latitude = position.coords.latitude;
      //     this.longitude = position.coords.longitude;
      //     this.getAddress(this.latitude, this.longitude);
      //   }else{
      //     this.zoom = 8;
      //     this.getAddress(this.latitude, this.longitude);
      //   }
      // });

  }

  ngAfterViewInit() {
    
  }

  showmap(value:number){
    this.agmshowmap = value;
  }

  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        if((this.latitude == "" || this.latitude == undefined) || (this.longitude == "" || this.longitude == undefined)){
          this.zoom = 8;
          this.latitude = position.coords.latitude;
          this.longitude = position.coords.longitude;
          this.getAddress(this.latitude, this.longitude);
        }else{
          this.zoom = 8;
          this.getAddress(this.latitude, this.longitude);
        }
      });
    }
  }


  markerDragEnd($event: google.maps.MouseEvent) {
    this.latitude = $event.latLng.lat();
    this.longitude = $event.latLng.lng();
    this.getAddress(this.latitude, this.longitude);
  }

  getAddress(latitude, longitude) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;
          this.CustAddress = results[0].formatted_address;
          this.CustomerAddress = results[0].formatted_address;
        } else {
          console.log('No results found');
        }
      } else {
        console.log('Geocoder failed due to: ' + status);
      }

    });
  }

  onAutocompleteSelected(result: PlaceResult) {
    this.CustomerAddress = result.formatted_address
    // navigator.geolocation.getCurrentPosition((position) => {
    //   if((this.latitude == "" || this.latitude == undefined) || (this.longitude == "" || this.longitude == undefined)){
    //     this.zoom = 8;
    //     this.latitude = position.coords.latitude;
    //     this.longitude = position.coords.longitude;
    //     this.getAddress(this.latitude, this.longitude);
    //   }else{
    //     this.zoom = 8;
    //     this.getAddress(this.latitude, this.longitude);
    //   }
    // });
  }
  onLocationSelected(location: Location) {
    this.latitude = this.Location.latitude;
    this.longitude = this.Location.longitude;
  }

  UpadteCustomer(){
    if(this.CustName == null){
      this.snackBar.open("Invalid Phone Number","Close",{
        duration: 4000,
        verticalPosition:'bottom'
      })
      return 0;
    }
    if(this.CustName == undefined || this.CustName == ''){
      this.snackBar.open("Customer Name is Required","Close",{
        duration: 4000,
        verticalPosition:'bottom'
      })
      return 0;
    }
   
    if(this.CustEmail != ''){
      if(this.CustEmail != undefined){
        if (!/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/.test(this.CustEmail)) {
          this.snackBar.open("Invalid Email","Close",{
            duration: 4000,
            verticalPosition:'bottom'
          })
          return 0;
        }
      }
    }
    if(this.CustPhone == undefined || this.CustPhone == ''){
      this.snackBar.open("Phone Number is Required","Close",{
        duration: 4000,
        verticalPosition:'bottom'
      })
      return 0;
    }
    if(this.CustAddress == undefined || this.CustAddress == ''){
      this.snackBar.open("Address is Required","Close",{
        duration: 4000,
        verticalPosition:'bottom'
      })
      return 0;
    }

    var data = { 
      "data" : {
        "id" : this.Cust_ID,
        "emp_id":this.Emp_id,
        "uploads":[],
        "name":this.CustName,
        "contact_no":this.CustPhone,
        "email":this.CustEmail,
        "address":this.CustomerAddress,
        "loc_lat" : this.latitude,
        "loc_lng" : this.longitude
      }
    }
    this.apiService
      .editCustomer(this.Cust_ID, data)
      .subscribe((response: any) => {
        // this.Userlist = response.data;
        if(response.status == "ok"){
          this.toastr.success(response.data);
        this.loader.hide();
        this.router.navigate(["customer"]);
        }else{
          this.toastr.warning(response.data);
        this.loader.hide();
        }
      });
  }

  changePreferredCountries() {
		this.preferredCountries = [
      CountryISO.India,
      CountryISO.Canada,
    ];
  }

  Cancel(){
    this.router.navigate(["customer"]);
  }

  omit_char(event: any) {
    const keyChar = event.key;
    let allowCharacter: boolean;
    if(event.target.value.length < 0 || event.target.value.length > 11 ){
      event.preventDefault();
    }
    if (keyChar === '-' && event.target.selectionStart !== 0) {
      allowCharacter = false;
    } else if (
      keyChar === 'Tab' ||
      keyChar === 'Enter' ||
      keyChar === 'Backspace' ||
      keyChar === 'ArrowLeft' ||
      keyChar === 'ArrowRight' ||
      keyChar === 'Delete'
    ) {
      allowCharacter = true;
    } else {
      allowCharacter = keyChar >= '0' && keyChar <= '9';
    }
    if (!allowCharacter) {
      event.preventDefault();
    }
  }
}

<nav class="navbar navbar-expand-md fixed-top flex-fill" style="background-color:#000" *ngIf="profileList">
  <!-- header -->
  <div class="navbar-header pl-2 pr-2 ml-3 text-center">
    <ul class="navbar-nav flex-fill mt-1 align-items-center left-nav">
      <li class="nav-item navicon">
        <a class="btn nav-link" (click)="toggleSidebarPin()"><i class="fa fa-bars"></i></a>
      </li>
    </ul>
     <!-- sidebar toggler -->
  <div class="sidebar-toggler ml-auto mr-3">
    <a class="btn nav-link" (click)="toggleSidebar()"><i class="fa fa-bars"></i></a>
  </div>
    <!-- <a class="navbar-brand m-0 w-100" href="#" *ngIf="isToggleSidebarPined">
      <img src="./assets/img/logo.png" alt="logo" class="mv-logo docs-navbar-hide-small">
      <img src="./assets/img/logo.png" alt="logo" class="mv-logo docs-navbar-show-small">
    </a> -->
    <a class="navbar-brand m-0 w-100" href="#">
      <img src="./assets/img/md-logo.png" alt="logo" class="crop-logo">
    </a>
    <a class="navbar-brand m-0 w-100" href="#">
      <img src="./assets/img/logo.png" alt="logo" class="mv-logo">
    </a>
  </div>
 
  <!-- right menu toggler -->
  <!-- <div class="nav-toggler-right mr-4 d-md-none">
    <button class="" type="button" (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed"
      aria-controls="collapseBasic">
      <img src="{{profile}}" class="img-fluid" alt="">
    </button>
    <span class="user-profile nav-alert bg-success"></span>
  </div> -->
  <!-- left box -->
  <!-- right menu -->
  <div class="flex-spacer"></div>
  <!-- <span class="form-inline nav-link">
    <label style="float: left;margin: 0px 12px 0px 0px;font-weight: bold;">{{'Select Language' | translate}}</label>
    <select 
        class="form-control" 
        #selectedLang
        style="float: left;margin: -5px 0px 0px 0px;" 
        [(ngModel)]="SelectLang"
        (change)="switchLang(selectedLang.value)">
      <option *ngFor="let language of translate.getLangs()" 
        [value]="language"
        [selected]="language === translate.currentLang">
        {{ language }}
      </option>
    </select>
  </span> -->
  <p class="text-white mv-fix" style="padding: 0px 50px 0px 0px;"><small>{{'Your Time Zone is' | translate}}</small> <b>  {{isTimeZone}}</b></p>
  <p class="text-white mv-fix m-center" style="padding: 0px 50px 0px 0px;display: none;">{{isTimeZone}}</p>
  <button mat-icon-button style="color:#fff;padding: 0px 60px 0px 0px;" class="noticon" data-target="#modalVerticalRight" data-toggle="modal" [matMenuTriggerFor]="notify">
    <mat-icon matBadge="{{notifyCount}}" [matBadgeHidden] = "notifyCount == 0" matBadgeColor="warn">notification_important</mat-icon>
  </button>
  <mat-menu #notify="matMenu" yPosition="below">
    <div class="container">
      <h6 class="text-danger" style="font-size: 13px;margin-bottom:0px;"><strong>{{'NOTIFICATIONS' | translate}}</strong></h6>
    </div>
    <mat-list style="padding: 10px;max-height: 300px;overflow: auto;">
      <ng-container *ngFor="let notification of notifyList; let index=i;">
        <mat-list-item class="highlight" style="cursor:pointer;height:auto !important;" (click)="readNotify(notification.id)">
          <div class="row">
            <div class="col-2" style="padding-top: 5px;padding-right: 0px;padding-left: 0px">
              <i class="far fa-envelope mr-1" style="font-size: 18px !important;height: auto;width: auto;background-color: #eee;padding: 5px 15px;border-radius: 50%;"></i>
            </div>
            <!-- <h5 style="font-size: 11px;">
              {{notification.type}}
            </h5>
            <p style="font-size: 10px;">
              To Agent : {{notification.data.data.allocated_emp}} At : {{notification.data.data.cust_address}}
            </p> -->
            <div class="col-10" style="padding-top: 5px;">
              <ng-container *ngIf="notification.type == 'App\\Notifications\\TaskCompleted'">
                <h5 style="font-weight: 400;font-size: 14px;">
                  {{notification.data.data.notes}} - {{notification.data.data.method}}
                </h5>
                <p style="font-size: 12px;color: #5d5d5d;font-weight: 400;">
                  <span>
                    at {{notification.data.data.cust_address}}
                  </span>
                  <span>
                    {{'By Agent' | translate}} : {{notification.data.data.allocated_emp}}
                  </span>
                </p>
              </ng-container>
              <ng-container *ngIf="notification.type == 'App\\Notifications\\OrderReminder'">
                <h5 style="font-size: 14px;font-weight: 400;">
                  {{'Order Reminder' | translate}}
                </h5>
                <p style="font-size: 12px;color: #5d5d5d;font-weight: 400;">
                  <span>
                    {{'To Agent' | translate}} : {{notification.data.data.allocated_emp}}
                  </span>
                  <span>{{'At' | translate}} : {{notification.data.data.cust_address}}</span>
                </p>
              </ng-container>
              <ng-container *ngIf="notification.type == 'App\\Notifications\\TrailPeriod'">
                <h5 style="font-size: 14px;font-weight: 400;">
                  {{'Trail Period Expires' | translate}}
                </h5>
                <p style="font-size: 12px;color: #5d5d5d;font-weight: 400;">
                  {{'Please Upgrade your account.' | translate}}
                  <span>{{'at' | translate}} : {{notification.created_at | date: 'medium' }}</span>
                </p>
              </ng-container>
              <ng-container *ngIf="notification.type == 'App\\Notifications\\SpeedLimit'">
                <h5 style="font-size: 14px;font-weight: 400;">
                  {{'Speed Limit Violation' | translate}}  - {{notification.data.data.speed}} km/h
                </h5>
                <p style="font-size: 12px;color: #5d5d5d;font-weight: 400;">
                  <span *ngFor="let location of locations | filter:{'id': parseInt(notification.data.data.cust_id)}:true">
                    {{'at' | translate}} {{location.name}}
                  </span>
                  <span *ngFor="let user of users | filter:{'user_id':  notification.data.data.allocated_emp_id}:true">
                    {{'By Agent' | translate}} : {{user.last_name }} {{user.first_name }}
                  </span>
                </p>
              </ng-container>
              <ng-container *ngIf="notification.type == 'App\\Notifications\\TaskAllocated'">
                <h5 style="font-size: 14px;font-weight: 400;">
                  {{'New Task Allocated' | translate}}
                </h5>
                <p style="font-size: 12px;color: #5d5d5d;font-weight: 400;">
                  <span>
                    {{'To Agent' | translate}} : {{notification.data.data.allocated_emp}}
                  </span>
                  <span>{{'At' | translate}} : {{notification.data.data.cust_address}}</span>
                </p>
              </ng-container>
              <ng-container *ngIf="notification.type == 'App\\Notifications\\AutoAllocated'">
                <h5 style="font-size: 14px;font-weight: 400;">
                  {{'Auto Allocation Task' | translate}}
                </h5>
                <p style="font-size: 12px;color: #5d5d5d;font-weight: 400;">
                  <span>To Agent : {{notification.data.data.allocated_emp}}</span>
                  <span>{{'At' | translate}} : {{notification.data.data.cust_address}}</span>
                </p>
              </ng-container>
              <ng-container *ngIf="notification.type == 'App\\Notifications\\TaskUpdated'">
                <h5 style="font-size: 14px;font-weight: 400;">
                  {{'Task Updated' | translate}}
                </h5>
                <p style="font-size: 12px;color: #5d5d5d;font-weight: 400;">
                  <span>{{'To  Agent' | translate}} : {{notification.data.data.allocated_emp}}</span>
                  <span>{{'At' | translate}} : {{notification.data.data.cust_address}}</span>
                </p>
              </ng-container>
              <ng-container *ngIf="notification.type == 'App\\Notifications\\EmpWelcome'">
                <h5 style="font-size: 14px;font-weight: 400;">
                  {{'New Delivery Agent Registered' | translate}}
                </h5>
                <p style="font-size: 12px;color: #5d5d5d;font-weight: 400;">
                  <span *ngFor="let user of users | filter:{'user_id':  notification.notifiable_id}:true">
                    {{'Delivery Agent' | translate}} : {{user.last_name }} {{user.first_name }}
                  </span>
                  <span>{{'At' | translate}} : {{notification.created_at }}</span>
                </p>
              </ng-container>
              <ng-container *ngIf="notification.type == 'App\\Notifications\\EmpPasswordReset'">
                <h5 style="font-size: 14px;font-weight: 400;">
                  {{'Delivery Agent Password Reset Alert' | translate}}
                </h5>
                <p style="font-size: 12px;color: #5d5d5d;font-weight: 400;">
                  <span *ngFor="let user of users | filter:{'user_id':  notification.notifiable_id}:true">
                   {{' Delivery Agent' | translate}}  : {{user.last_name }} {{user.first_name }}
                  </span>
                  <span>{{'At' | translate}} : {{notification.created_at }}</span>
                </p>
              </ng-container>
              <ng-container *ngIf="notification.type == 'App\\Notifications\\AutoAllocatedNoDriver'">
                <h5 style="font-size: 14px;font-weight: 400;">
                  {{'No Driver available' | translate}}
                </h5>
                <p style="font-size: 12px;color: #5d5d5d;font-weight: 400;">
                  <span>{{'Order id' | translate}} : {{notification.data.data.mt_order_id}}</span>
                </p>
              </ng-container>
            </div>
          </div>
        </mat-list-item>
        <mat-divider></mat-divider>
      </ng-container>
    </mat-list>
    <div class="row" *ngIf="notifyList.length == 0">
      <div class="col-12">
        <p style="font-size: 13px;">{{'No new notifications. Keep on working' | translate}}</p>
      </div>
    </div>
    <mat-list *ngIf="notifyList.length > 0">
      <div class="col-6" style="display: inline-block;">
        <button class="sec" style="height: auto;line-height: 35px;" mat-menu-item (click)="showMore()">
          {{'Show more' | translate}}
        </button>
      </div>
      <div class="col-6" style="display: inline-block;">
        <button class="sec" style="height: auto;line-height: 35px;background-color: #46a9a1 !important;color:#fff !important;" mat-menu-item (click)="readNotify(000)">
          {{'Clear All' | translate}}
        </button>
      </div>
    </mat-list>
  </mat-menu>

  <button mat-button class="docs-button docs-navbar-hide-small" style="color: #6e7899;    margin: 0 100px 0px 0px;">
    <!-- <img src="https://ca.slack-edge.com/T04P6EUTA-U019B83GEBA-3cd52f6c9967-48" class="profile-img" alt="profile" matTooltip="ManageTeamz Account&#13;GPS Active :{{this.userData.gps_active}}&#13;Role: {{this.userData.role}}" matTooltipClass="allow-cr" [matTooltipPosition]="'below'"><strong> My Account</strong> -->
    <div class="avatar-circle" *ngIf="profileList.profile_image.length == 2" [matMenuTriggerFor]="accountPicker">
      <span class="initials">{{profileList?.first_name[0] | uppercase }}</span>
      <b class="myaccount">{{'My Account' | translate}}</b>
    </div>
    <div class="avatar-circle-img" *ngIf="profileList.profile_image.length > 2" 
      [ngStyle]="{'background-image': 'url(' + profileList.profile_image + ')', 
      'background-size': 'cover', 'background-position': 'center'}" [matMenuTriggerFor]="accountPicker">
      <b class="myaccount">{{'My Account' | translate}}</b>
    </div>
    
  </button>
  <mat-menu #accountPicker="matMenu" xPosition="after">
    <button mat-menu-item routerLink="/profile" (click)="goProfile()">
      {{'Profile' | translate}}
    </button>
    <mat-divider></mat-divider>
    <a href="#" mat-menu-item (click)="logout()" style="color: #212121;
    text-decoration: none;">
      {{'Logout' | translate}}
    </a>
  </mat-menu>

  <a mat-button  class="docs-button docs-navbar-show-small" style="text-decoration: none;position: absolute;right: 0;">
    <!-- <img class="docs-angular-logo"
      src="https://ca.slack-edge.com/T04P6EUTA-U019B83GEBA-3cd52f6c9967-48" class="profile-img"
      alt="Profile" [matMenuTriggerFor]="mvaccountPicker" matTooltip="ManageTeamz Account&#13;GPS Active :{{this.userData.gps_active}}&#13;Role: {{this.userData.role}}" matTooltipClass="allow-cr" [matTooltipPosition]="'below'"> -->
    <div class="avatar-circle" *ngIf="profileList.profile_image.length == 2" [matMenuTriggerFor]="mvaccountPicker">
      <span class="initials">{{profileList?.first_name[0] | uppercase }}</span>
    </div>
    <div class="avatar-circle-img" *ngIf="profileList.profile_image.length > 2" 
      [ngStyle]="{'background-image': 'url(' + profileList.profile_image + ')', 
      'background-size': 'cover', 'background-position': 'center'}" [matMenuTriggerFor]="mvaccountPicker">
    </div>
  </a>
  <mat-menu #mvaccountPicker="matMenu" yPosition="below">
    <button mat-menu-item routerLink="/profile" (click)="goProfile()">
      {{'Profile' | translate}}
    </button>
    <mat-divider></mat-divider>
    <a href="#" mat-menu-item (click)="logout()" style="color: #212121;
    text-decoration: none;">
      {{'Logout' | translate}}
    </a>
  </mat-menu>
</nav>



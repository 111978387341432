<div class="loader d-flex justify-content-center" *ngIf="loaderOne">
  <div class="spinner-border text-white d-flex align-self-center" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>
<div class="row">
  <div class="col-md-12 mb-4">
    <div class="card border-0 rounded-0">
      <div class="card-body md-below">
        <div class="row" style="margin-top: 15px;">
          <div class="col-lg-2 col-md-12">
            <i class="fa fa-arrow-left fa-theme mr-2 pointer new" matTooltip="{{'Go Back' | translate}}"
              [matTooltipPosition]="'above'" [routerLink]="['/dashboard']"></i>
            <div class="profile-img" *ngIf="ProfileData">
              <div class="avatar-square" *ngIf="profileImage.length === 2">
                <span class="initials">{{ProfileData?.first_name[0] | uppercase }}{{ProfileData?.last_name[0] |
                  uppercase }}</span>
              </div>
              <div class="avatar-square-img" *ngIf="profileImage.length > 2" [ngStyle]="{'background-image': 'url(' + profileImage + ')', 
                  'background-size': 'cover', 'background-position': 'center'}">
              </div>
              <div class="highlightme pointer">
                <div class="file btn btn-lg btn-primary pointer">
                  {{'Change Photo' | translate}}
                  <input type="file" name="file" style="cursor: pointer;"
                    (change)="profileImageSelected($event.target.files)" />
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-10 col-md-12 mv-check">
            <div class="row">
              <div class="col right_alignt">
                <h5>{{Firstname}} {{Lastname}} </h5>
                <p class="text-info">{{Role}}</p>
              </div>
              <div class="col text-right left_align">
                <button mat-raised-button class="left_align" style="float: right;" color="primary"
                  routerLink="/profile-edit" matTooltip="click here" [matTooltipPosition]="'below'"><b>{{'Edit Profile'
                    | translate}}</b></button>
              </div>
            </div>
            <div class="row">
              <div class="col-auto">
                <p><img src="./assets/img/phone.png" alt="Mobile" class="profilephone">{{'Mobile' | translate}} :
                  {{Phoneno}}</p>
                <p><img src="./assets/img/email.png" alt="Email" class="profileemail">{{'Email' | translate}} :
                  {{Email}}</p>
              </div>
              <div class="col-auto">
                <p><img src="./assets/img/time-zone.png" alt="Time" class="profiletime">{{'Time Zone' | translate}} :
                  {{Timezone}}</p>
                <p><img src="./assets/img/address.png" alt="Address" class="profileaddress">{{'Address' | translate}} :
                  {{Location}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row" *ngIf="!superadminmenu">
  <div class="col-md-12 mb-4">
    <div class="card border-0 rounded-0 h-100">
      <div class="card-title-hide">
        <h4>{{'Profile Details' | translate}}</h4>
      </div>
      <div class="card-body">
        <div class="card md-below mb-1 p-3">
          <h5 class="text-info">{{'Package Information' | translate}}</h5>
          <div class="col-md-12">
            <ul class="list-group list-group-flush">
              <li class="list-group-item">
                <b>{{'Package Name' | translate}}</b>
                <span style="text-transform: uppercase;">{{PackageName}}</span>
              </li>
              <li class="list-group-item">
                <b>{{'Package Activated Date' | translate}}</b>
                <span style="text-transform: uppercase;">{{PackActivateDate | date}}</span>
              </li>
              <li class="list-group-item">
                <b>{{'Package Validity Date' | translate}}</b>
                <span style="text-transform: uppercase;">{{PackEndDate | date}}</span>
              </li>
              <li class="list-group-item">
                <b>{{'Total Allocated Delivery Agents' | translate}}</b>
                <span style="text-transform: uppercase;" *ngIf="userPackageInfo?.total_emp ==-1">Unlimited</span>
                <span style="text-transform: uppercase;"
                  *ngIf="userPackageInfo?.total_emp !=-1">{{userPackageInfo?.total_emp}}</span>
              </li>
              <li class="list-group-item">
                <b>{{'Remaining Delivery Agents' | translate}}</b>
                <span style="text-transform: uppercase;" *ngIf="userPackageInfo?.total_emp ==-1">Unlimited</span>
                <span style="text-transform: uppercase;"
                  *ngIf="userPackageInfo?.total_emp !=-1">{{userPackageInfo?.remaining_emp}}</span>
              </li>
              <li class="list-group-item">
                <b>{{'Total Tasks' | translate}}</b>
                <span style="text-transform: uppercase;" *ngIf="userPackageInfo?.total_task ==-1">Unlimited</span>
                <span style="text-transform: uppercase;"
                  *ngIf="userPackageInfo?.total_task !=-1">{{userPackageInfo?.total_task}}</span>
              </li>
              <li class="list-group-item">
                <b>{{'Remaining Tasks' | translate}}</b>
                <span style="text-transform: uppercase;" *ngIf="userPackageInfo?.total_task ==-1">Unlimited</span>
                <span style="text-transform: uppercase;"
                  *ngIf="userPackageInfo?.total_task !=-1">{{userPackageInfo?.remaining_task}}</span>
              </li>
              <!-- <li class="list-group-item" *ngIf="managershow">
                <b>{{'Total Managers' | translate}}</b><span style="float: right;text-transform: uppercase;">{{userPackageInfo?.total_mgr}}</span>
              </li>
              <li class="list-group-item" *ngIf="managershow">
                <b>{{'Remaining Managers' | translate}}</b><span style="float: right;text-transform: uppercase;">{{userPackageInfo?.remaining_mgr}}</span>
              </li> -->
              <!-- <li class="list-group-item">
                <b>{{'Auto Pickup Remainder Time' | translate}}</b>
                <span>
                  <mat-form-field>
                    <input matInput formControlName="roleName" placeholder="MINS" name="MINS" [(ngModel)]="remainderTime">
                  </mat-form-field>
                  <button type="button" class="btn btn-success" style="margin-left: 10px;" (click)="onSavetime()">save</button>
                </span>                
              </li> -->

              <li class="list-group-item">
                <b>{{'Selected Task' | translate}}</b>
                <span style="text-transform: uppercase;">{{SelectedTask}}</span>
              </li>
              <li class="list-group-item">
                <b>{{'Auto Allocation' | translate}}</b>
                <span>
                  <mat-slide-toggle class="example-margin" [color]="color" [checked]="auto_checked"
                    [(ngModel)]="auto_checked" [disabled]="disabled" (change)="autochange($event)">
                  </mat-slide-toggle>
                </span><br>
                <p style="color:#077aa9;margin-top:5px;text-align:justify">{{'Enable this option to automatically assign
                  Task to your Agent. You can select the assignment logic that best suits your business needs.' |
                  translate}}</p>
              </li>
              <li class="list-group-item">
                <b>{{'Merge Orders' | translate}}</b>
                <span>
                  <mat-slide-toggle class="example-margin" [color]="color" [checked]="merge_orders_checked"
                    [(ngModel)]="merge_orders_checked" [disabled]="disabled" (change)="mergeOrderChange($event)">
                  </mat-slide-toggle>
                </span><br>
                <p style="color:#077aa9;margin-top:5px;text-align:justify">{{'Enable this option to automatically
                  merging the multiple SPSD orders into one SPMD orders.' | translate}}</p>
              </li>
            </ul>
          </div>
          <div class="col-md-12" *ngIf="autoallocshow">
            <div class="fill">
              <div class="card-body">
                <h6 class="">{{'Select a method to auto-allocate the tasks' | translate}}</h6>
              </div>
              <div class="box-body" style="background-color: #fff;">
                <div class="cc-selector-2">
                  <input style="margin: 5px 0 0 20px;position: absolute;" type="radio" name="radio1" id="radio1"
                    class="css-checkbox" [value]="alloclogic" [checked]="alloclogic" [(ngModel)]="alloclogic"
                    name="radioModel" />
                  <label class="drinkcard-cc mastercard effect7" for="mastercard2"
                    style="border:2px solid #ccc;margin-left: 15px;"></label><br>
                  <span style="font-size: 15px;margin: 0 0 0 8%;font-weight: bold;">{{'One By One' |
                    translate}}</span><br><br>
                  <span style="color:#212529">{{'Sends the task request notification to the Agent nearest to the task
                    Location. If the Agent doesn’t accept the task within the request expiry time, the task request is
                    sent to the next nearest Agent. If no Agent accepts the task, it remains unassigned.' |
                    translate}}</span>
                  <div class="row">
                    <div class="col-lg-6 col-md-12">
                      <div class="form-group">
                        <mat-form-field style="width: 100%;" appearance="outline">
                          <mat-label>{{'Request Expires in Sec' | translate}} </mat-label>
                          <input matInput placeholder="{{'Request Expires in Sec' | translate}}" name="reqexp"
                            [(ngModel)]="ReqExpires" (keypress)="omit_char($event);" required>
                        </mat-form-field>
                      </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                      <div class="form-group">
                        <mat-form-field style="width: 100%;" appearance="outline">
                          <mat-label>{{'Maximum Radius in Km' | translate}} </mat-label>
                          <input matInput placeholder="{{'Maximum Radius in Km' | translate}}" name="maxradius"
                            [(ngModel)]="MaxRadius" (keypress)="omit_char($event);" required>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                  <button *ngIf="!merge_orders_options_show" mat-raised-button style="float: right;" color="primary" (click)="AutoAllocation()"><b>{{'SAVE'
                      | translate}}</b></button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12" *ngIf="merge_orders_options_show">
            <div class="fill">
              <div class="card-body">
                <h6 class="">{{'Define a parameters to merge your orders' | translate}}</h6>
              </div>
              <div class="box-body" style="background-color: #fff;">
                <div class="cc-selector-2">
                  <div class="row">
                    <div class="col-lg-3 col-md-12">
                      <div class="form-group">
                        <mat-form-field style="width: 100%;" appearance="outline">
                          <mat-label>{{'Minimum Merge Order Limit' | translate}} </mat-label>
                          <input matInput placeholder="{{'Minimum Merge Order Limit' | translate}}" name="minorderlimit"
                            [(ngModel)]="mergeMinimumOrderLimit" (keypress)="omit_char($event);" required>
                        </mat-form-field>
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-12">
                      <div class="form-group">
                        <mat-form-field style="width: 100%;" appearance="outline">
                          <mat-label>{{'Maximum Merge Order Limit' | translate}} </mat-label>
                          <input matInput placeholder="{{'Maximum Merge Order Limit' | translate}}" name="maxorderlimit"
                            [(ngModel)]="mergeMaximumOrderLimit" (keypress)="omit_char($event);" required>
                        </mat-form-field>
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-12">
                      <div class="form-group">
                        <mat-form-field style="width: 100%;" appearance="outline">
                          <mat-label>{{'Maximum Merge Wait Time in Mins' | translate}} </mat-label>
                          <input matInput placeholder="{{'Maximum Merge Wait Time in Mins' | translate}}"
                            name="maxwaittime" [(ngModel)]="mergeMaxWaitTime" (keypress)="omit_char($event);" required>
                        </mat-form-field>
                      </div>
                    </div>
                    <!-- <div class="col-lg-3 col-md-12">
                      <div class="form-group">
                        <mat-form-field style="width: 100%;" appearance="outline">
                          <mat-label>{{'Maximum Merge Geo Fence Radius in Kms' | translate}} </mat-label>
                          <input matInput placeholder="{{'Maximum Merge Geo Fence Radius in Kms' | translate}}" name="maxwaittime" [(ngModel)]="mergeMaxGeoFenceLimit" (keypress)="omit_char($event);" required>
                        </mat-form-field>
                      </div>
                    </div> -->
                  </div>
                  <button mat-raised-button style="float: right;" color="primary"
                    (click)="MergeOrderUpdate()"><b>{{'SAVE' | translate}}</b></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="card md-below mb-1 p-3">
          <h5 class="text-info">{{'Recent Payment Information' | translate}}</h5>
          <div class="col-md-12">
            <ul class="list-group list-group-flush">
              <li class="list-group-item">
                <b>{{'Sales Order ID' | translate}}</b>
                <span style="text-transform: uppercase;">{{paymentDetails?.sales_order_id}}</span>
              </li>
              <li class="list-group-item">
                <b>{{'Status' | translate}}</b>
                <span style="text-transform: uppercase;">{{paymentDetails?.status}}</span>
              </li>
              <li class="list-group-item" *ngIf="paymentDetails?.status != 'Success'">
                <b>{{'Failure Message' | translate}}</b>
                <span style="text-transform: uppercase;">{{paymentDetails?.status_message}}</span>
              </li>
              <li class="list-group-item">
                <b>{{'Purchased Plan' | translate}}</b>
                <span style="text-transform: uppercase;">{{paymentDetails?.package?.name}}</span>
              </li>
              <li class="list-group-item">
                <b>{{'Amount' | translate}}</b>
                <span style="text-transform: uppercase;">{{paymentDetails?.amount}}</span>
              </li>
              <li class="list-group-item">
                <b>{{'Currency' | translate}}</b>
                <span style="text-transform: uppercase;">{{paymentDetails?.currency}}</span>
              </li>
              <li class="list-group-item">
                <b>{{'Payment Date' | translate}}</b>
                <span style="text-transform: uppercase;">{{paymentDetails?.created_at | date}}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="card-body" *ngIf="isPaymentRequired == true">
        <div class="card md-below mb-1 p-3">
          <h5 class="text-info">{{'Package Pricing' | translate}}</h5>
          <div class="row text-center">
            <div class="col-md-6 price_box" *ngFor="let package of packageInfo;let i=index;"
              [ngClass]="package.price > 0 ? 'd-block' : 'd-none'">
              <div [ngClass]="i%2 == 0 ? 'box_1' : 'box_2'">
                <div class="border">
                  <h4>{{package.name}}</h4>
                  <div class="contentheight">
                    <span class="price">${{package.price}} (AED {{package.price_in_aed}})<span
                        style="font-size:18px;">/month.</span></span>
                    <p><a href="https://www.manageteamz.com/pricing/" target="_blank"
                        style="background-color:#ffffff;">Learn more...</a></p>
                  </div>
                  <div class="box_btn_cont">
                    <a (click)="makePayment(package.id, package.price_in_aed)" class="trial_btn">Pay</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div style="width: 100%;">
              <iframe id="payment_window" *ngIf="paymentURLData" [src]="paymentURLData" frameborder="0" width="100%"
                style="min-height: 700px;"></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal full-size" tabindex="-1" role="dialog" [ngClass]="{ fade: !isOpen, 'd-flex': isOpen }">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title w-100">{{modelTitle}}</h5>
        <!-- <a class="btn btn-sm btn-white dialog-close" (click)="onClose()"><span aria-hidden="true">&times;</span></a> -->
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12" style="padding-bottom: 1px;">
            <div class="form-group">
              <label>Business Name <span class="text-danger">*</span></label>
              <input type="text" class="form-control" placeholder="Enter Business Name">
            </div>
            <div class="form-group">
              <label>Pickup Location <span class="text-danger">*</span></label>
              <input type="text" class="form-control" placeholder="Enter Pickup Location">
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <div class="row">
          <div class="col-md-12" style="padding-bottom: 1px;">
            <button type="button" class="btn btn-outline-info rounded-0 mb-2 mr-2"
              (click)="onClose()"><b>Cancel</b></button>
            <button type="button" class="btn btn-info rounded-0 mb-2 mr-2"><b>Add</b></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- <button mat-button [matMenuTriggerFor]="menu">Menu</button>
<mat-menu #menu="matMenu">
  <button mat-menu-item>Item 1</button>
  <button mat-menu-item>Item 2</button>
</mat-menu> -->
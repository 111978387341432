import { Component, OnInit, HostBinding } from '@angular/core';
import { AppService } from './../../services/AppService';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

@Component({
  selector: 'app-cs-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class CSNavbarComponent implements OnInit {
  SelectLang: any;
  @HostBinding('attr.dir') dir = 'ltr';
  constructor(private appService: AppService,
    public translate: TranslateService) { 
      translate.addLangs(['English', 'Arabic']);
      translate.setDefaultLang('English');
    }
  isCollapsed = true;
  ngOnInit() {
    this.SelectLang = localStorage.getItem("language");
    if(this.SelectLang == '' || this.SelectLang == null){
      localStorage.setItem('language','English');
      this.SelectLang = localStorage.getItem('language')
      this.translate.use(this.SelectLang);
    }
    this.translate.use(this.SelectLang);
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      if(event.lang == 'Arabic') {
        this.dir = 'rtl';
      } else {
        this.dir = 'ltr';
      }
    });
    if(this.SelectLang != null && this.SelectLang == 'Arabic') {
      this.dir = 'rtl';
    } else {
      this.dir = 'ltr';
    }
  }

  switchLang(lang: string) {
    localStorage.setItem("language",lang)
    this.translate.use(lang);
  }
  toggleSidebarPin() {
    this.appService.toggleSidebarPin();
  }
  toggleSidebar() {
    this.appService.toggleSidebar();
  }
}

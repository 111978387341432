import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders, HttpErrorResponse } from "@angular/common/http";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { FormGroup } from '@angular/forms';
import { ApiService } from '../../services/ApiService';
import { AppService } from '../../services/AppService';
import { AuthService } from '../../services/AuthService';
import { Multi_Select_Config } from 'src/app/config/config';
import { FormBuilder } from '@angular/forms';
import { Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
@Component({
  selector: 'app-edit-driving-zone',
  templateUrl: './edit-driving-zone.component.html',
  styleUrls: ['./edit-driving-zone.component.css'],
})
export class EditDrivingZoneComponent implements OnInit {

  pointList: { lat: number; lng: number }[] = [];
  selectedShape: any;
  selectedArea = 0;
  availableDrivers: any = [];
  Config = Multi_Select_Config.config;
  ZoneName: any;
  DriverList: any;
  drivingZoneGroup: FormGroup;
  dataModel: any;
  zoneId: any;
  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};
  dataList: any;

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private loader: NgxSpinnerService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private authService: AuthService,
    public appService: AppService,
    public apiService: ApiService
  ) {
    this.route
      .queryParams
      .subscribe(v =>
        this.zoneId = v.id);
  }

  ngOnInit() {
    this.drivingZoneGroup = this.formBuilder.group({
      zoneName: ['', Validators.required],
      driverList: ['', Validators.required],
    });
    this.getAvailabledriver()

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
  }


  getAvailabledriver() {
    this.apiService
      .getDriverDetails(this.zoneId)
      .subscribe((response: any) => {
        let tempRes = [];
        response.data.forEach(element => {
          tempRes.push({ id: element.user_id, name: element.first_name });
        });
        this.availableDrivers = tempRes;

        this.apiService
          .drivingZoneDetail(this.zoneId)
          .subscribe((response: any) => {
            this.setDrivingzondet(response.data)

          });
      });
  }

  Cancel() {
    this.router.navigate(["driving-zone"]);
  }

  onItemSelect(item: any) {
  }

  onSelectAll(items: any) {
    this.dataModel = items
    console.log(items);
  }

  setDrivingzondet(data) {

    let tempRes = [];
    data.selected_drivers.forEach(element => {
      tempRes.push({ id: element.driver_id, name: element.driver_detail });
    });

    this.dataModel = tempRes
    let areaList = data.selected_area.map(function (x) {
      return { lat: x.point_lat, lng: x.point_lng }
    });

    this.dataList = areaList;
    this.pointList = areaList;

    this.drivingZoneGroup.patchValue({
      zoneName: data.name,
      driverList: tempRes
    })
  }

  Submit() {
    if (this.drivingZoneGroup.value.zoneName == "") {
      this.snackBar.open("Zone Name is required", "Close", {
        duration: 4000,
        verticalPosition: 'bottom'
      });
      return false;
    }

    if (this.drivingZoneGroup.value.driverList == null) {
      this.snackBar.open("Driver List should not be empty", "Close", {
        duration: 4000,
        verticalPosition: 'bottom'
      });
      return false;
    }

    if (this.dataModel != null) {
      this.dropdownList = []
      for (var i = 0; i < this.dataModel.length; i++) {
        this.dropdownList.push(this.dataModel[i].id)
      }
      console.log('this.dropdownList', this.dropdownList)
    }
    let payload = {
      "data": {
        "name": this.drivingZoneGroup.value.zoneName,
        "selected_area": this.pointList,
        "selected_drivers": this.dropdownList
      }
    }

    this.apiService
      .updateDrivingZone(payload, this.zoneId)
      .subscribe((response: any) => {
        if(response.status == 'ok'){
          this.toastr.success("Zone updated successfully");
          this.router.navigate(["/driving-zone"]);
        } else {
          this.toastr.warning(response.data);
        }
      });
  }
/* 
  onDelete(){
      this.apiService
         .deleteDrivingZone(this.zoneId)
         .subscribe((response: any)=>{
           this.router.navigate(["/driving-zone"]);
         })
  } */

  reback(obj: any) {
    this.pointList = [];
    const len = obj.getLength();
    for (let i = 0; i < len; i++) {
      this.pointList.push(
        obj.getAt(i).toJSON()
      );
    }
    this.selectedArea = google.maps.geometry.spherical.computeArea(
      obj
    );
  }
}

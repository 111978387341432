import { Component, OnInit, HostBinding, HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders, HttpErrorResponse } from "@angular/common/http";
import { FormGroup } from '@angular/forms';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import {
	SearchCountryField
} from 'custom-template/ngx-intl-tel-input/src/lib/enums/search-country-field.enum';
import { CountryISO } from 'custom-template/ngx-intl-tel-input/src/lib/enums/country-iso.enum';
import { TooltipLabel } from 'custom-template/ngx-intl-tel-input/src/lib/enums/tooltip-label.enum';
import { AuthService } from 'src/app/services/AuthService';
import { AppService } from 'src/app/services/AppService';
import { ApiService } from 'src/app/services/ApiService';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

@Component({
  selector: 'app-mobile-login',
  templateUrl: './mobile-login.component.html',
  styleUrls: ['./mobile-login.component.css'],
  providers: [AuthService]
})
export class MobileLoginComponent implements OnInit {
  public _token: any;
  @HostBinding('attr.dir') dir = 'ltr';
  loginForm: FormGroup;
  submitted = false;
  user = {
    phone: "",
    option: "sms",
  };
  verify = {
    optpin: "",
  };
	SearchCountryField = SearchCountryField;
	TooltipLabel = TooltipLabel;
	CountryISO = CountryISO;
	preferredCountries: CountryISO[] = [
    CountryISO.India,
    CountryISO.Canada,
  ];
  selectedCountry: any = CountryISO.India;
  isVerify = "Request OTP";
  otpshow: boolean;
  isDriverAsAdmin = false;
  language: any;
  languageSubject: any;
  SelectLang:any;
  GetSms: string;
  GetCall: string;
  Resend: string;
  Request: string;
  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private loader: NgxSpinnerService,
    private toastr: ToastrService,
    private authService: AuthService,
    public appService: AppService,
    public apiService: ApiService,
    public translate: TranslateService
  ) {}
  
  ngOnInit() {
    this.SelectLang = localStorage.getItem("language");
    if(this.SelectLang != null && this.SelectLang == 'Arabic') {
      this.dir = 'rtl';
    } else {
      this.dir = 'ltr';
    }
    this.translate.get('You will receive the otp via SMS').subscribe((text: string) => {
      this.GetSms = text
    });
    this.translate.get('You will receive the otp via Voice Call').subscribe((text: string) => {
      this.GetCall = text
    });
    this.translate.get('Resend OTP').subscribe((text: string) => {
      this.Resend = text
    });
    this.translate.get('Request OTP').subscribe((text: string) => {
      this.Request = text
    });
    this.translate.use(this.SelectLang);
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      if(event.lang == 'Arabic') {
        this.dir = 'rtl';
      } else {
        this.dir = 'ltr';
      }
    });
    this.otpshow = true;
    if (this.authService.isAuthenticated()) {
      var role = localStorage.getItem("user_data");
      var userData = JSON.parse(role);
      var role_id = userData.profile.role_id
      if(role_id ==3){
        this.router.navigate(["manage-users"]);
      }else{
        this.router.navigate(["/dashboard"]);
      }
      
    }
    this.apiService
      .getIpInfo()
      .subscribe((response: any) => {
        if(response.country != '') {
          this.preferredCountries = [
            response.country.toLowerCase()
          ];
          this.selectedCountry = response.country.toLowerCase();
        } else {
          this.preferredCountries = [
            CountryISO.India,
            CountryISO.Singapore,
            CountryISO.UnitedArabEmirates,
            CountryISO.Canada,
          ];
          this.selectedCountry = CountryISO.India;
        }
      });
  }

  switchLang(lang: string) {
    localStorage.setItem("language",lang)
    this.translate.use(lang);
  }
  
  onSubmit() {
    if (this.user) {
      this.loader.show();
      let payload = {
        data: {
          gateway: this.user.option,
          phone: this.user.phone['e164Number']
        }
      }
      this.otpshow = false
      this.apiService
        .mobileLoginOTP(payload)
        .subscribe((response: any) => {
          if (response.status == 'ok') {
            if(this.user.option == 'sms'){
              this.toastr.success(this.GetSms);
            }else if(this.user.option == 'voice'){
              this.toastr.success(this.GetCall);
            }
            this.isVerify = this.Resend;
          } else {
            this.toastr.error(response.data);
          }
          this.loader.hide();
        },
          (err: HttpErrorResponse) => {
            this.toastr.error('Something went wrong please try again');
            this.loader.hide();
          }
        );
    }
  }
  loginSubmit() {
    this.loader.show();
    let payload = {
      data: {
        phone: this.user.phone['e164Number'],
        otp: this.verify.optpin,
      }
    }
    this.apiService
      .mobileLogin(payload)
      .subscribe((response: any) => {
        if(response.status == 'ok'){
          this._token = response.data.token;
          localStorage.setItem(
            "access_token",
            this._token
          );
          localStorage.setItem(
            "client_data",
            this.appService.getClientData()
          );
          delete response.data.token;
          localStorage.setItem(
            "user_data",
            JSON.stringify(response.data)
          );
          localStorage.setItem(
            "zoom_control",
            JSON.stringify(response.data.profile.zoom_control)
          );
          localStorage.removeItem("temp_client_data");
          if(response['data']['profile']['role_id'] ==3){
            this.router.navigate(["/manage-users"]);
          }else{
            this.router.navigate(["/dashboard"]);
          }
          this.toastr.success('Login Successfully');
        } else {
          if(response.data == 'You have a driver account with this mobile number.  Driver account does not have a web acccess. Would you like to signup as admin ?'){
            this.isDriverAsAdmin = true;
          }else{
            this.toastr.error(response.data);
          }
          this.loader.hide();
        }
      },
      (err: HttpErrorResponse) => {
        this.toastr.error('Something went wrong please try again');
        this.loader.hide();
      }
    );
  }
  onPromotion(){
    if (this.user) {
      this.loader.show();
      let payload = {
        data: {
          phone: this.user.phone['e164Number'],
        }
      }
      this.isDriverAsAdmin = false;
      this.apiService
        .promoteToAdmin(payload)
        .subscribe((response: any) => {
          if(response.status == 'ok'){
            this._token = response.data.token;
            localStorage.setItem(
              "access_token",
              this._token
            );
            localStorage.setItem(
              "client_data",
              this.appService.getClientData()
            );
            delete response.data.token;
            localStorage.setItem(
              "user_data",
              JSON.stringify(response.data)
            );
            localStorage.setItem(
              "zoom_control",
              JSON.stringify(response.data.profile.zoom_control)
            );
            localStorage.removeItem("temp_client_data");
            this.toastr.success('Login Successfully');
            this.router.navigate(["/dashboard"]);
          } else {
            this.toastr.error(response.data);
            this.loader.hide();
          }
        },
        (err: HttpErrorResponse) => {
          this.toastr.error('Something went wrong please try again');
          this.loader.hide();
        }
      );
    }
  }
  promotionCancel(){
    this.isDriverAsAdmin = false;
    this.otpshow = true;
    this.isVerify = this.Request;
    this.user = {
      phone: "",
      option: "sms",
    };
    this.verify = {
      optpin: "",
    };
    this.router.navigate(["/mobile-login"]);
  }
  password_visit() {
    let x = document.getElementById("login-password") as HTMLInputElement;
    if (x.type == "password") {
      document.getElementById("eye-open").className = "fa fa-lg fa-eye field-icon toggle-password view-hide";
      x.type = "text";
    } else {
      document.getElementById("eye-open").className = "fa fa-lg fa-eye-slash field-icon toggle-password view-hide";
      x.type = "password";
    }
  }
  changePreferredCountries() {
		this.preferredCountries = [
      CountryISO.India,
      CountryISO.Canada,
    ];
  }

  numberOnly(event) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }

}

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SharedFunctionService {

  constructor() { }

  processOrderData(data: any) {
    let dataOUTSET = [];
    if (data.delivery_logic == 1) {
      let Pickup = {...data.order[0]};
      Pickup.isFor = 'Pickup';
      dataOUTSET.push(Pickup);
      data.order.forEach(element => {
        element.isFor = 'Delivery';
        dataOUTSET.push(element);
      });
    }else if(data.delivery_logic == 2){
        data.order.forEach(element => {
          element.isFor = 'Pickup';
          dataOUTSET.push(element);
        });
        let Pickup = {...data.order[0]};
        Pickup.isFor = 'Delivery';
        dataOUTSET.push(Pickup);
    }else if(data.delivery_logic == 3){
        let Delivery  = {...data.order[0]};
        let Pickup = {...Delivery};
        Delivery.isFor = 'Delivery';
        Pickup.isFor = 'Pickup';
        dataOUTSET.push(Pickup);
        dataOUTSET.push(Delivery);
    }
    delete data.order; 
    let shareDATA = {
      "resource": data,
      "order": dataOUTSET
    }
    return shareDATA;
  }
  processOrderDetailsData(orderData: any){
    console.log(orderData);
    var shareDATA = {};
    if(orderData.status != "Unallocated"){
      if(orderData.profile_image){
        if(orderData.profile_image.length > 2){
          var result = orderData.profile_image.charAt(0);
          if(result == "["){
            var getProfile = JSON.parse(orderData.profile_image);
            orderData.profile_image = getProfile[0];
          }
        }
      }
    }

    let cutomerImages = [];
    if(orderData.delivery_logic == 1){
      orderData.order.forEach(element => {
        if(element.idproof && element.signature){
          cutomerImages.push([{
            image : element.idproof,
            thumbImage : element.idproof,
            title : `Delivered Item`,
          },{
            image : element.signature,
            thumbImage : element.signature,
            title : `Signature of ${orderData.cust_name[0]}`,
          }]);
        }
      });
      console.log(cutomerImages);
    }else if(orderData.delivery_logic == 2){
      if(orderData.order[0].idproof && orderData.order[0].signature){
        cutomerImages.push([{
          image : orderData.order[0].idproof,
          thumbImage : orderData.order[0].idproof,
          title : `Delivered Item`,
        },{
          image : orderData.order[0].signature,
          thumbImage : orderData.order[0].signature,
          title : `Signature of ${orderData.cust_name[0]}`,
        }]);
      }
    }else if(orderData.delivery_logic == 3){
      if(orderData.order[0].idproof && orderData.order[0].signature){
        cutomerImages.push([{
          image : orderData.order[0].idproof,
          thumbImage : orderData.order[0].idproof,
          title : `Delivered Item`,
        },{
          image : orderData.order[0].signature,
          thumbImage : orderData.order[0].signature,
          title : `Signature of ${orderData.cust_name[0]}`,
        }]);
      }
    }

    if(orderData.order.length){
      if(orderData.delivery_logic == 1){
        shareDATA['pickupDetails'] = [orderData.order[0]];
        shareDATA['deliveryDetails'] = orderData.order;
        shareDATA['deliveryImages'] = cutomerImages;
      }else if(orderData.delivery_logic == 2){
        shareDATA['pickupDetails'] = orderData.order;
        shareDATA['deliveryDetails'] = [orderData.order[0]];
        shareDATA['deliveryImages'] = cutomerImages;
      }else if(orderData.delivery_logic == 3){
        shareDATA['pickupDetails'] = [orderData.order[0]];
        shareDATA['deliveryDetails'] = [orderData.order[0]];
        shareDATA['deliveryImages'] = cutomerImages;
      }
      delete orderData.order;
      shareDATA['otherDetails'] = orderData;

      if(orderData.delivery_logic == 2){
        let deliveryDropDown = false;
        shareDATA['pickupDetails'].forEach(element => {
          if(element.status == 'Started Ride' || element.status == 'In Supplier Place'){
            element['pickupDropdown'] = true;
            deliveryDropDown = false;
          }else{
            element['pickupDropdown'] = false;
            deliveryDropDown = true;
          }
          if(element.status == 'In Supplier Place'){
            element['hidePickupOption'] = true;
          }else{
            element['hidePickupOption'] = false;
          }
          if(element.status == 'Products Picked up'){
            element['hidePickupOption'] = true;
          }else{
            element['hidePickupOption'] = false;
          }
          if(element.status == 'Unallocated' || element.status == 'Allocated'){
            shareDATA['orderStatusDropdown'] = true;
            deliveryDropDown = false
          }else{
            shareDATA['orderStatusDropdown'] = false;
          }
        });
        shareDATA['deliveryDetails'][0].deliveryDropDown = deliveryDropDown;
      }
      if(orderData.delivery_logic == 3){
        let deliveryDropDown = false;
        shareDATA['pickupDetails'].forEach(element => {
          if(element.status == 'Started Ride' || element.status == 'In Supplier Place'){
            element['pickupDropdown'] = true;
            deliveryDropDown = false;
          }else{
            element['pickupDropdown'] = false;
            deliveryDropDown = true;
          }
          if(element.status == 'In Supplier Place'){
            element['hidePickupOption'] = true;
          }else{
            element['hidePickupOption'] = false;
          }
          if(element.status == 'Products Picked up'){
            element['hidePickupOption'] = true;
          }else{
            element['hidePickupOption'] = false;
          }
          if(element.status == 'Unallocated' || element.status == 'Allocated'){
            deliveryDropDown = false;
            shareDATA['orderStatusDropdown'] = true;
          }else{
            shareDATA['orderStatusDropdown'] = false;
          }
        });
        shareDATA['deliveryDetails'][0].deliveryDropDown = deliveryDropDown;
      }
      if(orderData.delivery_logic == 1){
        let deliveryDropDown = false;
        shareDATA['pickupDetails'].forEach(element => {
          if(element.status == 'Started Ride' || element.status == 'In Supplier Place'){
            element['pickupDropdown'] = true;
            deliveryDropDown = false;
          }else{
            element['pickupDropdown'] = false;
            deliveryDropDown = true;
          }
          if(element.status == 'Unallocated' || element.status == 'Allocated'){
            shareDATA['orderStatusDropdown'] = true;
          }else{
            shareDATA['orderStatusDropdown'] = false;
          }
        });
        shareDATA['deliveryDetails'].forEach(element => {
          // if(element.status == 'Started Ride' || element.status == 'In Supplier Place'){
          //   element['deliveryDropDown'] = false;
          //   // deliveryDropDown = false;
          // }else{
          //   // element['deliveryDropDown'] = true;
          //   // deliveryDropDown = true;
          // }
          if(element.status == 'Products Picked up'){
            element['deliveryDropDown'] = true;
            // deliveryDropDown = true;
          }
          if(element.status == 'Unallocated' || element.status == 'Allocated'){
            shareDATA['orderStatusDropdown'] = true;
          }else{
            shareDATA['orderStatusDropdown'] = false;
          }
        });
        // shareDATA['deliveryDetails'][0].deliveryDropDown = deliveryDropDown;
      }
    }else{
      console.log('No Orders Found');
    }
    console.log(shareDATA);
    return shareDATA;
  }
}

import { Injectable, EventEmitter } from "@angular/core";

@Injectable()
export class GeneralService {
  emitter: EventEmitter<string> = new EventEmitter();
  public changeModule(type: string) {
    this.emitter.emit(type);
  }
  dashboardEvent: EventEmitter<string> = new EventEmitter();
  public DashboardModule(data) {
    this.dashboardEvent.emit(data);
  }
  editorderEvent: EventEmitter<string> = new EventEmitter();
  public EditOrderModule(data) {
    this.editorderEvent.emit(data);
  }
  profileHeaders: EventEmitter<object> = new EventEmitter();
  public refreshHeader(data : any) {
    this.profileHeaders.emit(data);
  }
  dashboardLocationData: EventEmitter<string> = new EventEmitter();
  public dashboardLocationUpdate(data) {
    this.dashboardLocationData.emit(data);
  }
  driverLocationLATLNG: EventEmitter<string> = new EventEmitter();
  public driverLocationUpdate(data) {
    this.driverLocationLATLNG.emit(data);
  }
  orderDriverLATLNG: EventEmitter<object> = new EventEmitter();
  public orderDriverLatLng(data : any) {
    console.log(data);
    this.orderDriverLATLNG.emit(data);
  }
  gpsDetailsLATLNG: EventEmitter<object> = new EventEmitter();
  public gpsDetailsLatLng(data : any) {
    console.log(data);
    this.gpsDetailsLATLNG.emit(data);
  }
}

<div class="container-fluid" style="margin-top: 10px;">
  <div class="col text-left">
    <h5 class="mb-4 text-muted"><b>{{'Delivery Report' | translate}} </b></h5>
  </div>
</div>
<div class="container-fluid">
  <div class="card-body">
    <div class="row px-3 py-0">
      <div class="col-12 bg-light py-3">
        <div class="row justify-content-between">
          <div class="col-md">
            <div class="row">
              <div class="col-md-12 col-lg-auto">
                <mat-form-field>
                  <mat-label>{{'Select Delivery Agent' | translate}}</mat-label>
                  <mat-select [(ngModel)]="employeeID">
                    <mat-option value="all">{{'All Delivery Agents' | translate}}</mat-option>
                    <mat-option *ngFor="let list of employeeList" [value]="list.emp_id">{{list.first_name}} {{list.last_name}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-md-12 col-lg-auto">
                <mat-form-field>
                  <mat-label>{{'Select Date Range' | translate}}</mat-label>
                  <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
                    <input matStartDate formControlName="start" placeholder="Start date">
                    <input matEndDate formControlName="end" placeholder="End date">
                  </mat-date-range-input>
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-date-range-picker #picker></mat-date-range-picker>
                  <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">{{'Invalid start date' | translate}}</mat-error>
                  <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">{{'Invalid end date' | translate}}</mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-12 col-lg-auto">
                <button mat-raised-button color="primary" class="mr-2" (click)="filterData()"><b>{{'Filter' | translate}}</b></button>
                <button mat-raised-button color="primary" matTooltip="Export as CSV" (click)="downloadCustomertable()"><b>{{'Export' | translate}}</b></button>
              </div>
            </div>
          </div>
          <div class="col-auto">
            <div class="row">
              <div class="col-auto">
                <div class="input-group mb-3">
                  <input type="text" class="form-control" (keyup)="ApplyFilter($event)" placeholder="{{'Search' | translate}}" aria-controls="example">
                  <div class="input-group-append">
                    <span class="fa fa-search input-group-text pointer"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="table-responsive">
      <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8" style="width: 100%;border: 1px solid #ccc;">
        <!-- Position Column -->
        <ng-container matColumnDef="employee">
          <th mat-header-cell *matHeaderCellDef  mat-sort-header style="background-color:#1e9e95; color: white; font-size: 15px;"> {{'Delivery Agent' | translate}} </th>
          <td mat-cell *matCellDef="let element; let i = index"> {{element.task.allocated_emp}} 
          </td>
        </ng-container>
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef mat-sort-header style="background-color:#1e9e95; color: white; font-size: 15px;"> {{'Status' | translate}} </th>
          <td mat-cell *matCellDef="let element; let i = index">
            <span class="badge" 
            [ngClass]="{
            'badge-success': element.task.status =='Completed' || element.task.status =='Delivered',
            'badge-info': element.task.status =='Delivered back',
            'badge-primary': element.task.status =='Allocated',
            'badge-warning': element.task.status =='Started Ride' || element.task.status =='Products Picked up' || element.task.status =='In Supplier Place',
            'badge-danger': element.task.status =='Unallocated'}"
            >{{element.task.status}}</span> 
          </td>
        </ng-container>
        <!-- Name Column -->
        <ng-container matColumnDef="customer">
          <th mat-header-cell *matHeaderCellDef mat-sort-header style="background-color:#1e9e95; color: white; font-size: 15px;"> {{'Customer' | translate}} </th>
          <td mat-cell *matCellDef="let element"> {{element.task.receiver_name}} </td>
        </ng-container>
        <!-- Date Column -->
        <ng-container matColumnDef="order id">
          <th mat-header-cell *matHeaderCellDef style="background-color:#1e9e95; color: white; font-size: 15px;"> {{'Order ID' | translate}} </th>
          <td mat-cell *matCellDef="let element"> {{element.task.order_id}} </td>
        </ng-container>
        <!-- Date Column -->
        <ng-container matColumnDef="pickup time">
          <th mat-header-cell *matHeaderCellDef mat-sort-header style="background-color:#1e9e95; color: white; font-size: 15px;"> {{'Pickup Time' | translate}} </th>
          <td mat-cell *matCellDef="let element"> {{element.task.picktime | date :'short'}} </td>
        </ng-container>
        <!-- Date Column -->
        <ng-container matColumnDef="delivery time">
          <th mat-header-cell *matHeaderCellDef mat-sort-header style="background-color:#1e9e95; color: white; font-size: 15px;">	{{'Delivery Time' | translate}} </th>
          <td mat-cell *matCellDef="let element"> {{element.task.schedule_date_time | date :'short'}} </td>
        </ng-container>
        <!-- Date Column -->
        <ng-container matColumnDef="delivery location">
          <th mat-header-cell *matHeaderCellDef mat-sort-header style="background-color:#1e9e95; color: white; font-size: 15px;">	{{'Delivery Location' | translate}} </th>
          <td mat-cell *matCellDef="let element"> {{element.task.cust_address}} </td>
        </ng-container>
        <!-- Date Column -->
        <!-- <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef style="background-color:#1e9e95; color: white; font-size: 15px;">	Status </th>
          <td mat-cell *matCellDef="let element"> {{element.task.status}} </td>
        </ng-container> -->
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      <div style="padding-top: 10px;" *ngIf="dataSource?.filteredData.length === 0"> 
        <p class="text-center">{{'No Records Found!' | translate}}</p><hr>
      </div>
      <mat-paginator #paginator [pageSizeOptions]="[10, 25, 50, 100]"></mat-paginator>
    </div>
  </div>
</div>

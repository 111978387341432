<div class="row" style="margin:0">
  <h4 style="color: #6c757d;">Edit Delivery Agent</h4>
   </div>
   <div class="container">
    <div class="row" style="margin-top: 15px;">
      <form [formGroup]="EmployeeGroup" class="col-md-12">
        <div class="col-md-12 col-lg-6 mb-4 align-items-stretch" style="float: left;">
          <div class="form-group">
            <mat-form-field style="width: 100%;" appearance="outline">
              <mat-label>First  Name</mat-label>
              <input matInput placeholder="Enter First Name" name="first_name" formControlName="FirstName" [(ngModel)]="FirstName" required>
            </mat-form-field>
          </div>
    
          <div class="form-group">
            <mat-form-field style="width: 100%;" appearance="outline">
              <mat-label>Phone Number</mat-label>
              <!-- <input matInput placeholder="Enter Phone Number" name="sup_phone" formControlName="SupPhone" [(ngModel)]="SupPhone" (keypress)="omit_char($event);" required> -->
              <ngx-mat-intl-tel-input 
                formControlName="SupPhone"
                [preferredCountries]="preferredCountries"
                [enableAutoCountrySelect]="false"
                [enablePlaceholder]="true"
                [searchCountryFlag]="true"
                [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                [selectFirstCountry]="false"
                [selectedCountryISO]="CountryISO.India"
                [maxLength]="15"
                [tooltipField]="TooltipLabel.Name"
                [(ngModel)]="SupPhone"
                required
                [separateDialCode]="false" [enableSearch]="true"
                name="sup_phone" #phone
                >
              </ngx-mat-intl-tel-input>
            </mat-form-field>
          </div>
          <div class="form-group">
            <mat-form-field style="width: 100%;" appearance="outline">
              <mat-label>Email</mat-label>
              <input matInput placeholder="Enter Email" name="sup_email" formControlName="SupEmail" [(ngModel)]="SupEmail">
            </mat-form-field>
          </div>
          <!-- <div class="form-group">
            <mat-form-field style="width: 100%;" appearance="outline">
              <mat-label>Status</mat-label>
              <mat-select name="sup_status" formControlName="SupStatus" [(ngModel)]="SupStatus">
                <mat-option *ngFor="let stat of Status" [value]="stat.value">
                  {{stat.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div> -->
          <!-- <div class="form-group">
            <label>Password <span class="text-danger">*</span></label>
            <input type="text" class="form-control" name="sup_password" [(ngModel)]="SupPassword" placeholder="Enter Password">
          </div> -->
          <div class="form-group">
            <mat-form-field style="width: 100%;" appearance="outline">
              <mat-label>Permanent Address</mat-label>
              <!-- <input matInput placeholder="Enter Permanent Address" name="sup_address" [(ngModel)]="SupAddress" required> -->
              <input type="text" matInput name="sup_address" formControlName="SupAddress" placeholder="Enter Permanent Address" [value]="SupAddress" [(ngModel)]="SupAddress" matGoogleMapsAutocomplete
                (onAutocompleteSelected)="onAutocompleteSelected($event)"
                (onLocationSelected)="onLocationSelected($event)" required>
            </mat-form-field>
          </div>
          <div class="form-group">
            <mat-form-field style="width: 100%;" appearance="outline">
              <mat-label>Vehicle Model</mat-label>
              <input matInput placeholder="Enter Vehicle Model" name="vehicle_model" formControlName="VehicleModel" [(ngModel)]="VehicleModel">
            </mat-form-field>
          </div>
          <div class="form-group">
            <label>Profile Upload <span class="text-danger">*</span></label>
            <div class="uploads-strip">
              <input hidden type="file" [disable]="true" name="image" formControlName="image" [(ngModel)]="image" #uploader multiple accept="video/*,image/*" (change)="fileupload($event)" />
              <button class="upload-btn upload" style="margin-top: 35px;cursor:pointer;" id="btn" >
                <!-- <img src="assets/images/upload.png">  -->
                Upload Profile Picture
            </button><br><br>
            <ng-container *ngIf="ProfileImage.length > 0">
              <img src="{{ProfileImage}}" class="profilepic">
            </ng-container>
            <!-- <ng-container *ngIf="!ProfileImage.length > 0"> -->
              <!-- <img src="{{Profile_Image}}" class="profilepic"> -->
            <!-- </ng-container> -->
            </div>
          </div>
        </div>
        <div class="col-md-12 col-lg-6 mb-4 align-items-stretch" style="float: left;">
          <div class="form-group">
            <mat-form-field style="width: 100%;" appearance="outline">
              <mat-label>Last Name</mat-label>
              <input matInput placeholder="Enter Last Name" name="last_name" formControlName="LastName" [(ngModel)]="LastName">
            </mat-form-field>
          </div>
          <div class="form-group">
            <mat-form-field style="width: 100%;" appearance="outline">
              <mat-label>WhatsApp Number</mat-label>
              <!-- <input matInput placeholder="Enter WhatsApp Number" name="sup_whatsapp" formControlName="SupWhatsapp" [(ngModel)]="SupWhatsapp" (keypress)="omit_char($event);" required> -->
              <ngx-mat-intl-tel-input formControlName="SupWhatsapp"
                [preferredCountries]="preferredCountries"
                [enableAutoCountrySelect]="false"
                [enablePlaceholder]="true"
                [searchCountryFlag]="true"
                [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                [selectFirstCountry]="false"
                [selectedCountryISO]="CountryISO.India"
                [maxLength]="15"
                [tooltipField]="TooltipLabel.Name"
                [(ngModel)]="SupWhatsapp"
                required
                [separateDialCode]="false" [enableSearch]="true"
                name="sup_whatsapp" #sup_whatsapp
                >
              </ngx-mat-intl-tel-input>
            </mat-form-field>
          </div>
          <div class="form-group">
            <mat-form-field style="width: 100%;" appearance="outline">
              <mat-label>Active</mat-label>
              <mat-select name="sup_active" formControlName="SupActive" [(ngModel)]="SupActive">
                <mat-option *ngFor="let act of Actives" [value]="act.value">
                  {{act.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="form-group">
            <mat-form-field style="width: 100%;" appearance="outline">
              <mat-label>GPS Tracking</mat-label>
              <mat-select name="sup_gpstrack" formControlName="SupGpsTrack" [(ngModel)]="SupGpsTrack" required>
                <mat-option *ngFor="let gps of GPSTracking" [value]="gps.value">
                  {{gps.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <!-- <label>GPS Tracking <span class="text-danger">*</span></label>
            <select class="form-control" name="sup_gpstrack" [(ngModel)]="SupGpsTrack">
              <option value="1">On</option>
              <option value="0">Off</option>
            </select> -->
          </div>
          <div class="form-group">
            <mat-form-field style="width: 100%;" appearance="outline">
              <mat-label>License Plate</mat-label>
              <input matInput placeholder="Enter License Plate" name="license_plate" formControlName="LicensePlate" [(ngModel)]="LicensePlate">
            </mat-form-field>
          </div>
          <div class="form-group">
            <label>Vehicle Type <span class="text-danger">*</span></label><br>
            <mat-radio-group
              aria-labelledby="example-radio-group-label"
              [disable]="true"
              class="example-radio-group"
              formControlName="VehicleType" [(ngModel)]="VehicleType">
              <mat-radio-button [disable]="true" style="margin:6px 10px 0px 0px" class="example-radio-button" *ngFor="let vehicle of Vehicles" [value]="vehicle">
                {{vehicle}}
              </mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
      </form>
    </div>
  </div>
   <div class="row">
     <div class="col text-right">
       <button type="button" (click)="Cancel()" class="btn btn-sm btn-outline-info rounded-0 mb-2 mr-2">Cancel</button>
       <button type="button" (click)="UpdateEmployee()" class="btn btn-sm btn-info rounded-0 mb-2">Update Delivery Agent</button>
     </div>
    </div>
   
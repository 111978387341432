<ng-container *ngIf="driverList">
<div class="row">
  <div class="col text-left">
    <p><i class="fa fa-arrow-left fa-theme mr-2 pointer" matTooltip="{{'Go Back' | translate}}" [matTooltipPosition]="'above'" [routerLink]="['/dasboard']"></i><b>{{driverList.first_name}} {{driverList.last_name}}</b></p>
  </div>
</div>
<div class="row">
  <div class="col-lg-12 col-md-12" style="padding: 0px;">
    <app-map-part [resource]="driverList" [fromWhere]="'driverDetails'" (changeResource)="reback($event)"></app-map-part>
  </div>
</div>

<div class="row">
  <div class="col-lg-6 col-md-12" style="border-right: 1px solid rgb(250, 225, 225);padding-top:10px;">
    <div class="row pl-4">
      <span class="badge badge-success">{{driverInfo.free_busy_status | uppercase}}</span>
    </div>
    <br>
    <div class="row">
      <div class="col-2">
        <div class="avatar-circle" *ngIf="driverList.profile_image.length === 2">
          <span class="initials">{{driverList?.first_name[0] | uppercase }}</span>
        </div>
        <div class="avatar-circle-img" *ngIf="driverList.profile_image.length > 2" 
          [ngStyle]="{'background-image': 'url(' + driverList.profile_image + ')', 
          'background-size': 'cover', 'background-position': 'center'}">
        </div>
      </div>
      <div class="col-lg-6 col-md-6">
        <h6 class="text-info" style="font-weight: 600;">{{'Emp ID' | translate}} : {{driverList.id}} </h6>
        <h6><i class="fa fa-user fa-theme mr-1"></i>{{'Name' | translate}} : {{driverList.first_name}} {{driverList.last_name}}</h6>
        <h6><i class="fa fa-phone fa-theme mr-1" style="transform: rotate(90deg);"></i>{{'Mobile' | translate}} : {{driverList.phone}}</h6>
        <h6><i class="fa fa-envelope fa-theme mr-1"></i>{{'Email' | translate}} : {{driverList.email}}</h6>
      </div>
      <div class="col-lg-4 col-md-12">
        <h6>{{'Active' | translate}} : {{driverList.activity ? driverList.activity : 'No Data' | translate}}</h6>
        <h6>{{'Seen' | translate}} : {{driverList.activated_ozn ? driverList.activated_on : 'No Data' | translate}}</h6>
        <h6>{{'Speed' | translate}} : {{driverList.speed ? driverList.speed : 'No Data' | translate}}</h6>
        <h6>{{'Battery' | translate}} : {{driverList.battery_status ? driverList.battery_status : 'No Data' | translate}}</h6>
      </div>
    </div>
  </div>
  <div class="col-lg-6 col-md-12" style="padding-top:10px;">
    <div class="row">
      <div class="col-auto">
        <div class="form-group">
          <select class="form-control" [(ngModel)]="isOrderLogic"
            (change)="getMyOrderList()">
            <option value="All">{{'All Orders' | translate}}</option>
            <option value="1">{{'Single Pickup and Multi Delivery' | translate}}</option>
            <option value="2">{{'Multi Pickup and Single Delivery' | translate}}</option>
            <option value="3">{{'Single Pickup and Single Delivery' | translate}}</option>
          </select>
        </div>
      </div>
      <div class="col-auto" *ngIf="orderFilterData?.length">
        <div class="form-group">
          <select class="form-control" [(ngModel)]="limit"
            (change)="selectTable()">
            <option value="5" selected>5</option>
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12" style="padding-right: 0px!important; padding-left: 0px!important;">
        <ul class="list-group list-group-flush" style="width: 100%;">
          <ng-container *ngIf="orderFilterData?.length;else errFilterData">
            <li class="list-group-item" *ngFor="let list of orderFilterData | paginate: filterOption; let i=index;">
              <div class="row">
                <div class="col-5">
                  <h6 (click)="OrderDetails(list.order_id)" class="pointer" style="color: blue;cursor: pointer;">{{'Order ID' | translate}} : {{list.order_id}}</h6>
                  <small>
                    <ng-container *ngIf="list.delivery_logic == 1">
                      {{list.multiple_delivery.length}} Tasks
                    </ng-container>
                    <ng-conatiner *ngIf="list.delivery_logic == 2">
                      {{list.multiple_pickup.length}} Tasks
                    </ng-conatiner>
                    <ng-container *ngIf="list.delivery_logic == 3">
                      {{list.multiple_delivery.length}} Task
                    </ng-container>
                  </small>
                </div>
                <div class="col-5">
                  <h6>{{list.schedule | date:'fullDate'}}</h6>
                </div>
                <div class="col-2">
                  <span class="badge" 
                  [ngClass]="{'badge-success': list.status =='Completed','badge-info': list.status =='Accepted','badge-secondary': list.status =='Approved','badge-primary': list.status =='Allocated','badge-warning': list.status =='In-Progress','badge-success': list.status =='Delivered','badge-danger': list.status =='Unallocated'}"
                  >{{list.status}}</span>
                </div>
              </div>
            </li>
          </ng-container>
          <ng-template #errFilterData>
            <li class="list-group-item">
              <p>{{'No Orders Found' | translate}}</p>
            </li>
          </ng-template>
        </ul>
      </div>
    </div>
    <br>
    <div class="row">
      <div class="col">
        <pagination-controls *ngIf="orderFilterData?.length" (pageChange)="onPageChange($event)" style="float: right;"></pagination-controls>
      </div>
    </div>
  </div>
</div>
</ng-container>


import { Component,Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core'



@Component({
  selector: 'app-bulk-order-pop',
  templateUrl: './bulk-order-pop.component.html',
  styleUrls: ['./bulk-order-pop.component.css']
})
export class BulkOrderPopComponent {

  constructor(
    public dialog: MatDialog,
    public translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data:any
   
  ) { }

}

<div class="row">
  <div class="col text-left">
    <h4 class="mb-4 text-muted">Payment Status </h4>
  </div>
</div>

<div class="container-fluid">
  <div class="card-body">
    <div class="row px-3 py-0 ">
      <div class="col-12 bg-light py-3">
        <div class="row justify-content-between">
          <div class="col-md">
            <div class="row">
              <div class="col-auto pr-0 pt-2">
                <label class="d-flex align-top">Show</label>
              </div>
              <div class="col-auto pr-0">
                <select name="example_length" aria-controls="example"
                  class="form-control w-auto-select mx-2">
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div>
              <div class="col pt-2">
                <label class="d-flex align-top">entries</label>
              </div>
            </div>
          </div>
          <div class="col-auto">
            <div class="row">
              <div class="col-auto">
                <div class="input-group mb-3">
                  <input type="text" class="form-control" placeholder="Search" aria-controls="example">
                  <div class="input-group-append">
                    <span class="fa fa-search input-group-text pointer"></span>
                  </div>
                </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="card-body">
          <div class="table-responsive">
            <table class="table">
              <thead class="text-primary pointer">
                <th>
                  <a href="#" class="sort">
                    <i class="fas fa-sort-amount-down"></i>
                  </a>
                  Vehicle Name
                </th>
                <th class="w-15">
                  <a href="#" class="sort">
                    <i class="fas fa-sort-amount-down"></i>
                  </a>
                  Vehicle Model
                </th>
                <th class="w-5">
                  <span>Action</span>
                </th>
              </thead>
              <tbody>
                <tr *ngFor="let sample of samples">
                  <td>{{sample.vehicle_name}}</td>
                  <td>{{sample.vehicle_model}}</td>
                  <td>
                    <a class="btn btn-sm icon-btn rounded-0 mr-2 sort" [matMenuTriggerFor]="menu" matTooltip="More">
                      <i class="fa fa-ellipsis-v"></i>
                    </a>
                    <mat-menu #menu="matMenu">
                      <button mat-menu-item (click)="Edit(sample)">Edit</button>
                      <button mat-menu-item (click)="Delete(sample)">Delete</button>
                      <button mat-menu-item (click)="View(sample)">View</button>
                    </mat-menu>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row">
 <p><i class="fa fa-arrow-left fa-theme mr-2 pointer" matTooltip="Go Back to profile" [matTooltipPosition]="'below'" routerLink="/profile"></i>Add Employee</p>
</div>
<div class="container">
  <div class="row">
    <div class="col-md-12 col-lg-6 mb-4 align-items-stretch">
      <div class="form-group">
        <label>First Name <span class="text-danger">*</span></label>
        <input type="text" class="form-control" placeholder="Enter First Name">
      </div>
      <div class="form-group">
        <label>Phone Number <span class="text-danger">*</span></label>
        <input type="text" class="form-control" placeholder="Enter Phone Number">
      </div>
      <div class="form-group">
        <label>Email <span class="text-danger">*</span></label>
        <input type="text" class="form-control" placeholder="Enter Email">
      </div>
      <div class="form-group">
        <label>Status <span class="text-danger">*</span></label>
        <input type="text" class="form-control" placeholder="Enter Status">
      </div>
      <div class="form-group">
        <label>Password <span class="text-danger">*</span></label>
        <input type="text" class="form-control" placeholder="Enter Password">
      </div>
    </div>
    <div class="col-md-12 col-lg-6 mb-4 align-items-stretch">
      <div class="form-group">
        <label>Last Name <span class="text-danger">*</span></label>
        <input type="text" class="form-control" placeholder="Last Name">
      </div>
      <div class="form-group">
        <label>WhatsApp Number <span class="text-danger">*</span></label>
        <input type="text" class="form-control" placeholder="Enter WhatsApp Number">
      </div>
      <div class="form-group">
        <label>Active <span class="text-danger">*</span></label>
        <select class="form-control">
          <option>Active</option>
          <option>In Active</option>
        </select>
      </div>
      <div class="form-group">
        <label>GPS Tracking <span class="text-danger">*</span></label>
        <select class="form-control">
          <option>On</option>
          <option>Off</option>
        </select>
      </div>
      <div class="form-group">
        <label>Permanent Address <span class="text-danger">*</span></label>
        <input type="text" class="form-control" placeholder="Enter Permanent Address">
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col text-right">
    <button type="button" class="btn btn-sm btn-outline-info rounded-0 mb-2 mr-2">Cancel</button>
    <button type="button" class="btn btn-sm btn-info rounded-0 mb-2">Add Employee</button>
  </div>
 </div>

<div class="container-fluid" style="margin-top: 10px;">
  <div class="col text-left">
    <h5 class="mb-4 text-muted"><b>{{'Delivery Agent' | translate}} </b></h5>
  </div>
  <div class="col text-right" *ngIf="isExpired == false">
    <button mat-raised-button color="primary" routerLink="/add-delivery-agent" matTooltip="click here"
      *ngIf="ButtonShow == true"><b>{{'Add Delivery Agent' | translate}}</b></button>
    <p *ngIf="ButtonShow == false" style="color: Red;"><b>{{'You Reached Maximum Limit' | translate}}</b></p>
  </div>
</div>
<div class="container-fluid">
  <div class="card-body">
    <div class="row px-3 py-0">
      <div class="col-12 bg-light py-3">
        <div class="row justify-content-between">
          <div class="col-md">
            <div class="row">
              <!-- <div class="col-auto pr-0 pt-2">
                <label class="d-flex align-top">Show</label>
              </div>
              <div class="col-auto pr-0">
                <select name="example_length" aria-controls="example"
                  class="form-control w-auto-select mx-2">
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div>
              <div class="col pt-2">
                <label class="d-flex align-top">entries</label>
              </div> -->
            </div>
          </div>
          <div class="col-auto">
            <div class="row">
              <div class="col-auto">
                <div class="input-group mb-3">
                  <input type="text" class="form-control" (keyup)="applyFilter($event)"
                    placeholder="{{'Search' | translate}}" autocomplete="off">
                  <div class="input-group-append">
                    <span class="fa fa-search input-group-text pointer"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="card-body">
          <div class="table-responsive">
            <!-- <table class="table">
              <thead class="text-primary pointer">
                <th>
                  <a href="#" class="sort">
                    <i class="fas fa-sort-amount-down"></i>
                  </a>
                  Emp ID
                </th>
                <th class="w-15">
                  <a href="#" class="sort">
                    <i class="fas fa-sort-amount-down"></i>
                  </a>
                  First Name
                </th>
                <th class="w-15">
                  <a href="#" class="sort">
                    <i class="fas fa-sort-amount-down"></i>
                  </a>
                  Last Name
                </th>
                <th class="w-10">
                  <a href="#" class="sort">
                    <i class="fas fa-sort-amount-down"></i>
                  </a>
                  Email
                </th>
                <th class="w-10">
                  <a href="#" class="sort">
                    <i class="fas fa-sort-amount-down"></i>
                  </a>
                  Contact No
                </th>
                <th class="w-5">
                  <span>Action</span>
                </th>
              </thead>
              <tbody>
                <tr *ngFor="let obj of Supervisordata">
                  <td>{{obj.id}}</td>
                  <td>{{obj.first_name}}</td>
                  <td>{{obj.last_name}}</td>
                  <td>{{obj.email}}</td>
                  <td>{{obj.phone}}</td>
                  <td>
                    <a class="btn btn-sm icon-btn rounded-0 mr-2 sort" [matMenuTriggerFor]="menu"  matTooltip="More">
                      <i class="fa fa-ellipsis-v"></i>
                    </a>
                    <mat-menu #menu="matMenu">
                      <button mat-menu-item (click)="password_change('Password Change')">Password</button>
                      <button mat-menu-item (click)="Edit(obj)">Edit</button>
                      <button mat-menu-item (click)="Delete(obj)">Delete</button>
                      <button mat-menu-item (click)="View(obj)">View</button>
                    </mat-menu>
                  </td>
                </tr>
              </tbody>
            </table> -->
            <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8"
              style="width: 100%;border: 1px solid #ccc;" *ngIf="!managershow">

              <!--- Note that these columns can be defined in any order.
                    The actual rendered columns are set as a property on the row definition" -->

              <!-- Position Column -->
              <ng-container matColumnDef="sup_id">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="tablehead"
                  style="background-color: #1e9e95;color: #fff;font-size: 15px;"> {{'EmpID' | translate}} </th>
                <td mat-cell *matCellDef="let element"> {{element.user_id}} </td>
              </ng-container>

              <!-- Name Column -->
              <ng-container matColumnDef="first_name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="tablehead"
                  style="background-color: #1e9e95;color: #fff;font-size: 15px;"> {{'First Name' | translate}} </th>
                <td mat-cell *matCellDef="let element"> {{element.first_name}} </td>
              </ng-container>

              <!-- Weight Column -->
              <ng-container matColumnDef="last_name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="tablehead"
                  style="background-color: #1e9e95;color: #fff;font-size: 15px;"> {{'Last Name' | translate}} </th>
                <td mat-cell *matCellDef="let element"> {{element.last_name}} </td>
              </ng-container>

              <!-- Symbol Column -->
              <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="tablehead"
                  style="background-color: #1e9e95;color: #fff;font-size: 15px;"> {{'Email' | translate}} </th>
                <td mat-cell *matCellDef="let element"> {{element.email}} </td>
              </ng-container>

              <ng-container matColumnDef="contact_no">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="tablehead"
                  style="background-color: #1e9e95;color: #fff;font-size: 15px;"> {{'Contact No' | translate}} </th>
                <td mat-cell *matCellDef="let element"> {{element.phone}} </td>
              </ng-container>

              <ng-container matColumnDef="manager">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="tablehead"
                  style="background-color: #1e9e95;color: #fff;font-size: 15px;"> {{'Manager' | translate}} </th>
                <td mat-cell *matCellDef="let element"> {{element.mmanager_first_name}} {{element.mmanager_last_name}}
                </td>
              </ng-container>

              <ng-container matColumnDef="Actions">
                <th mat-header-cell *matHeaderCellDef class="tablehead"
                  style="background-color: #1e9e95;color: #fff;font-size: 15px;text-align: center;">
                  {{'Actions' | translate}} </th>
                <td mat-cell *matCellDef="let element" style="text-align: center;">
                  <!-- <mat-icon title="Reset Password" class="action-icons" style="cursor: pointer;color: green;" (click)="password_change('Password Change',element.user_id)">lock</mat-icon> -->
                  <!-- <mat-icon title="Edit Delivery Agent" class="action-icons" style="cursor: pointer;color: green;" (click)="EditSupervisor(element.user_id)">edit</mat-icon> -->
                  <!-- <mat-icon title="Delete Delivery Agent" class="action-icons" style="cursor: pointer;color: green;" (click)="DeleteModel(element.user_id)">delete</mat-icon> -->
                  <mat-icon title="{{'View Delivery Agent' | translate}}" class="action-icons"
                    style="cursor: pointer;color: green;vertical-align:middle;"
                    (click)="ViewSupervisor(element.user_id)">visibility</mat-icon>
                </td>
              </ng-container>
              <ng-container matColumnDef="Status">
                <th mat-header-cell *matHeaderCellDef class="tablehead"
                  style="background-color: #1e9e95;color: #fff;font-size: 15px;text-align: center;">
                  {{'Status' | translate}} </th>
                <td mat-cell *matCellDef="let element" style="text-align: center;">
                  <span class="badge"
                    style="margin-left:10px;height: 25px;padding: 0;width: 60px;cursor: unset;color:red;"
                    class="ng-star-inserted" *ngIf="element.is_active == 0"><b>{{'Delete' | translate}}</b></span>
                  <span class="badge"
                    style="margin-left:10px;height: 25px;padding: 0;width: 60px;cursor: unset;color:green;"
                    class="ng-star-inserted"
                    *ngIf="element.role_id != 2 && element.is_active == 1"><b>{{'Active' | translate}}</b></span>
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8"
              style="width: 100%;border: 1px solid #ccc;" *ngIf="managershow">

              <!--- Note that these columns can be defined in any order.
                    The actual rendered columns are set as a property on the row definition" -->

              <!-- Position Column -->
              <ng-container matColumnDef="sup_id">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="tablehead"
                  style="background-color: #1e9e95;color: #fff;font-size: 15px;"> {{'EmpID' | translate}} </th>
                <td mat-cell *matCellDef="let element"> {{element.user_id}} </td>
              </ng-container>

              <!-- Name Column -->
              <ng-container matColumnDef="first_name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="tablehead"
                  style="background-color: #1e9e95;color: #fff;font-size: 15px;"> {{'First Name' | translate }} </th>
                <td mat-cell *matCellDef="let element"> {{element.first_name}} </td>
              </ng-container>

              <!-- Weight Column -->
              <ng-container matColumnDef="last_name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="tablehead"
                  style="background-color: #1e9e95;color: #fff;font-size: 15px;"> {{'Last Name' | translate}} </th>
                <td mat-cell *matCellDef="let element"> {{element.last_name}} </td>
              </ng-container>

              <!-- Symbol Column -->
              <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="tablehead"
                  style="background-color: #1e9e95;color: #fff;font-size: 15px;"> {{'Email' | translate}} </th>
                <td mat-cell *matCellDef="let element"> {{element.email}} </td>
              </ng-container>

              <ng-container matColumnDef="contact_no">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="tablehead"
                  style="background-color: #1e9e95;color: #fff;font-size: 15px;"> {{'Contact No' | translate}} </th>
                <td mat-cell *matCellDef="let element"> {{element.phone}} </td>
              </ng-container>

              <ng-container matColumnDef="Actions">
                <th mat-header-cell *matHeaderCellDef class="tablehead"
                  style="background-color: #1e9e95;color: #fff;font-size: 15px;text-align: center;">
                  {{'Actions' | translate}} </th>
                <td mat-cell *matCellDef="let element" style="text-align: center;">
                  <!-- <mat-icon title="Reset Password" class="action-icons" style="cursor: pointer;color: green;" (click)="password_change('Password Change',element.user_id)">lock</mat-icon> -->
                  <!-- <mat-icon title="Edit Delivery Agent" class="action-icons" style="cursor: pointer;color: green;" (click)="EditSupervisor(element.user_id)">edit</mat-icon> -->
                  <!-- <mat-icon title="Delete Delivery Agent" class="action-icons" style="cursor: pointer;color: green;" (click)="DeleteModel(element.user_id)">delete</mat-icon> -->
                  <mat-icon title="{{'View Delivery Agent' | translate}}" class="action-icons"
                    style="cursor: pointer;color: green;vertical-align:middle;"
                    (click)="ViewSupervisor(element.user_id)">visibility</mat-icon>
                </td>
              </ng-container>
              <ng-container matColumnDef="Status">
                <th mat-header-cell *matHeaderCellDef class="tablehead"
                  style="background-color: #1e9e95;color: #fff;font-size: 15px;text-align: center;">
                  {{'Status' | translate}} </th>
                <td mat-cell *matCellDef="let element" style="text-align: center;">
                  <span class="badge"
                    style="margin-left:10px;height: 25px;padding: 0;width: 60px;cursor: unset;color:red;"
                    class="ng-star-inserted" *ngIf="element.is_active == 0"><b>{{'Delete' | translate}}</b></span>
                  <span class="badge"
                    style="margin-left:10px;height: 25px;padding: 0;width: 60px;cursor: unset;color:green;"
                    class="ng-star-inserted"
                    *ngIf="element.role_id != 2 && element.is_active == 1"><b>{{'Active' | translate}}</b></span>
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns1"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns1;"></tr>
            </table>
            <div style="padding-top: 10px;" *ngIf="dataSource?.filteredData.length === 0">
              <p class="text-center">{{'No Records Found!' | translate}}</p>
              <hr>
            </div>
            <mat-paginator #paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal full-size" tabindex="-1" role="dialog" [ngClass]="{ fade: !isOpen, 'd-flex': isOpen }">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <label class="modal-title w-100">{{modelTitle}}</label>
        <a class="btn btn-sm btn-white dialog-close" (click)="onClosed()"><span aria-hidden="true">&times;</span></a>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12" style="padding-bottom: 1px;">
            <div class="form-group">
              <label>New Password <span class="text-danger">*</span></label>
              <div class="float-label-control">
                <input type="password" class="form-control" id="new-password" data-toggle="newpasswd"
                  name="new_password" [(ngModel)]="NewPassword" required placeholder="New Password">
                <span class="fa fa-sm fa-eye-slash field-icon toggle-password new-view-hide" id="new-eye-open"
                  toggle="#newpasswd" (click)="new_password()">
                </span>
              </div>
            </div>
            <div class="form-group">
              <label>Confirm New Password <span class="text-danger">*</span></label>
              <div class="float-label-control">
                <input type="password" class="form-control" id="confirm-password" data-toggle="confirmpasswd"
                  name="conf_password" [(ngModel)]="ConfPassword" required placeholder="Confirm Password">
                <span class="fa fa-sm fa-eye-slash field-icon toggle-password same-view-hide" id="confirm-eye-open"
                  toggle="#confirmpasswd" (click)="confirm_password()">
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <div class="row">
          <div class="col-md-12" style="padding-bottom: 1px;">
            <button type="button" class="btn btn-sm btn-outline-info rounded-0 mb-2 mr-2"
              (click)="onClosed()">Cancel</button>
            <button type="button" class="btn btn-sm btn-info rounded-0 mb-2 mr-2" (click)="ChangePassword()">Change
              Password</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal full-size" tabindex="-1" role="dialog" [ngClass]="{ fade: !isDeleteModel, 'd-flex': isDeleteModel }">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title w-100">Confirmation</h5>
        <a class="btn btn-sm btn-white dialog-close" (click)="onClose()"><span aria-hidden="true">&times;</span></a>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12" style="padding-bottom: 1px;">
            <b>Are you sure to delete this Agent?</b>
          </div>
        </div>
        <div class="col-md-12 text-right" style="padding-bottom: 1px; padding-top: 5px;">
          <button type="button" class="btn btn-sm btn-info rounded-0 mb-2 mr-2" (click)="onClose()">No</button>
          <button type="button" class="btn btn-sm btn-outline-info rounded-0 mb-2 mr-2"
            (click)="DeleteSupervisor()">Yes</button>
        </div>
      </div>
    </div>
  </div>
</div>
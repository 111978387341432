<app-cs-navbar></app-cs-navbar>
<div class="container-fluid form-container">
  <div class="row">
    <div class="col-md-6 content-center img-mobile-fit">
      <div class="custom-center">
        <h3 class="text-white text-center text-size mb-4">
          <img src="/assets/img/collections/phone.png" class="form-img mr-2">
          <strong>+917338773388</strong>
        </h3>
        <h3 class="text-white text-center text-size mb-4">
          <img src="/assets/img/collections/email.png" class="form-img mr-2">
          <strong>bd@manageteamz.com</strong>
        </h3>
        <!-- <h3 class="text-white text-center text-size mb-4">
          <a href="#" class="btn btn-outline-success">
            <img src="/assets/img/collections/live-chat.png" class="form-img mr-2">
            <strong>Live Chat</strong>
          </a>
        </h3> -->
      </div>
    </div>
    <div class="col-md-6 content-center">
      <div class="card rounded-card">
        <div class="card-body custom-size">
          <h4 class="text-center mb-4">
            <strong>{{'Delivery Account Recovery' | translate}}</strong>
          </h4>
          <form data-bitwarden-watching="1" (ngSubmit)="onSubmit()" #passwordForm="ngForm">
            <div class="form-group">
              <label>{{'Email Address' | translate}}</label>
              <input type="text" class="form-control"
                [(ngModel)]="user.email"
                #email="ngModel"
                name="email"
                required
                email>
              <div *ngIf="email.invalid && (email.dirty)">
                <p
                  class="error-box margin-top-0 text-danger"
                  *ngIf="email.hasError('required')"
                >
                  {{'Email is Required' | translate}}
                </p>
                <p class="error-box margin-top-0 text-danger" *ngIf="email.errors.email">
                  {{'Email must be a valid email address' | translate}}
                </p>
                <p class="error-box margin-top-0 text-danger" *ngIf="submitted && email.errors">
                  email address incorrect
                </p>
              </div>
            </div>
            <br>
            <div class="form-group">
              <button type="submit" [disabled]="!passwordForm.valid" class="btn btn-info btn-lg btn-block">{{'Submit' | translate}}</button>
            </div>
          </form>
          <div class="form-group forget-password">
            <div class="row">
              <div class="col-6 form-text-size">
                <a href="#" routerLink="/login" class="text-info">{{'Login with Email Address?' | translate}}</a>
              </div>
              <div class="col-6 form-text-size">
                <a href="#" routerLink="/signup" class="text-info float-right">{{'Don`t have an Account?' | translate}}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid area-container">
  <div class="row">
    <div class="col-md-4 area-left">
      <div class="row">
        <div class="wrap">
          <div class="ico-wrap">
            <span class="mbr-iconfont">
              <img src="/assets/img/collections/location.png" class="area-img mr-2">
            </span>
          </div>
          <div class="vcenter">
            <p class="text-white text-uppercase">
              {{'No.1, 1ST FLOOR, mrk arcade, 200 feet radial road, thoraipakkam, chennai, tamil nadu, india 600097.' | translate}}
            </p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="wrap">
          <div class="ico-wrap">
            <span class="mbr-iconfont">
              <img src="/assets/img/collections/phone.png" class="area-img-phn mr-2">
            </span>
          </div>
          <div class="vcenter">
            <p class="text-white text-uppercase">
              +917338773388
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-4 area-left">
      <div class="row">
        <div class="wrap">
          <div class="ico-wrap">
            <span class="mbr-iconfont">
              <img src="/assets/img/collections/location.png" class="area-img mr-2">
            </span>
          </div>
          <div class="vcenter">
            <p class="text-white text-uppercase">
              9820 ivalenes hope dr, austin, texass tx 78717.
            </p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="wrap">
          <div class="ico-wrap">
            <span class="mbr-iconfont">
              <img src="/assets/img/collections/phone.png" class="area-img-phn mr-2">
            </span>
          </div>
          <div class="vcenter">
            <p class="text-white text-uppercase">
              +16302997737
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-4 area-left">
      <div class="row">
        <div class="wrap">
          <div class="ico-wrap">
            <span class="mbr-iconfont">
              <img src="/assets/img/collections/location.png" class="area-img mr-2">
            </span>
          </div>
          <div class="vcenter">
            <p class="text-white text-uppercase">
              5838 burbank road se, calgary, alberta, canada t2h1z3.
            </p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="wrap">
          <div class="ico-wrap">
            <span class="mbr-iconfont">
              <img src="/assets/img/collections/phone.png" class="area-img-phn mr-2">
            </span>
          </div>
          <div class="vcenter">
            <p class="text-white text-uppercase">
              +14039263660
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-cs-footer></app-cs-footer>



<div class="row">
  <div class="col text-left">
    <h4 class="mb-4 text-muted">Customer Service </h4>
  </div>
</div>

<div class="container-fluid">
  <div class="card-body">
    <div class="row px-3 py-0 ">
      <div class="col-12 bg-light py-3">
        <div class="row justify-content-between">
          <div class="col-md">
            <div class="row">
              <div class="col-auto pr-0 pt-2">
                <label class="d-flex align-top">Show</label>
              </div>
              <div class="col-auto pr-0">
                <select name="example_length" aria-controls="example"
                  class="form-control w-auto-select mx-2">
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div>
              <div class="col pt-2">
                <label class="d-flex align-top">entries</label>
              </div>
            </div>
          </div>
          <div class="col-auto">
            <div class="row">
              <div class="col-auto">
                <div class="input-group mb-3">
                  <input type="text" class="form-control" placeholder="Search" aria-controls="example">
                  <div class="input-group-append">
                    <span class="fa fa-search input-group-text pointer"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="card-body">
          <div class="table-responsive">
            <table class="table">
              <thead class="text-primary pointer">
                <th>
                  <a href="#" class="sort">
                    <i class="fas fa-sort-amount-down"></i>
                  </a>
                  Emp ID
                </th>
                <th class="w-15">
                  <a href="#" class="sort">
                    <i class="fas fa-sort-amount-down"></i>
                  </a>
                  First Name
                </th>
                <th class="w-15">
                  <a href="#" class="sort">
                    <i class="fas fa-sort-amount-down"></i>
                  </a>
                  Last Name
                </th>
                <th class="w-10">
                  <a href="#" class="sort">
                    <i class="fas fa-sort-amount-down"></i>
                  </a>
                  Email
                </th>
                <th class="w-10">
                  <a href="#" class="sort">
                    <i class="fas fa-sort-amount-down"></i>
                  </a>
                  Contact No
                </th>
                <th class="w-5">
                  <span>Action</span>
                </th>
              </thead>
              <tbody>
                <tr *ngFor="let sample of samples">
                  <td>{{sample.id}}</td>
                  <td>{{sample.supervisor_fname}}</td>
                  <td>{{sample.supervisor_lname}}</td>
                  <td>{{sample.supervisor_email}}</td>
                  <td>{{sample.supervisor_contact}}</td>
                  <td>
                    <a class="btn btn-sm icon-btn rounded-0 mr-2 sort" [matMenuTriggerFor]="menu"  matTooltip="More">
                      <i class="fa fa-ellipsis-v"></i>
                    </a>
                    <mat-menu #menu="matMenu">
                      <button mat-menu-item (click)="password_change('Password Change')">Password</button>
                      <button mat-menu-item (click)="Edit(sample)">Edit</button>
                      <button mat-menu-item (click)="Delete(sample)">Delete</button>
                      <button mat-menu-item (click)="View(sample)">View</button>
                    </mat-menu>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal full-size" tabindex="-1" role="dialog"
[ngClass]="{ fade: !isOpen, 'd-flex': isOpen }">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <label class="modal-title w-100">{{modelTitle}}</label>
        <a class="btn btn-sm btn-white dialog-close" (click)="onClose()"><span aria-hidden="true">&times;</span></a>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12" style="padding-bottom: 1px;">
            <div class="form-group">
              <label>New Password <span class="text-danger">*</span></label>
              <div class="float-label-control">
                <input type="password" class="form-control" id="new-password" data-toggle="newpasswd"
                name="password"
                required
                placeholder="New Password">
                <span class="fa fa-sm fa-eye-slash field-icon toggle-password new-view-hide"
                  id="new-eye-open" toggle="#newpasswd"
                  (click)="new_password()">
                </span>
              </div>
            </div>
            <div class="form-group">
              <label>Confirm New Password <span class="text-danger">*</span></label>
              <div class="float-label-control">
                <input type="password" class="form-control" id="confirm-password" data-toggle="confirmpasswd"
                name="password"
                required
                placeholder="Confirm Password">
                <span class="fa fa-sm fa-eye-slash field-icon toggle-password same-view-hide"
                  id="confirm-eye-open" toggle="#confirmpasswd"
                  (click)="confirm_password()">
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <div class="row">
          <div class="col-md-12" style="padding-bottom: 1px;">
            <button type="button" class="btn btn-sm btn-outline-info rounded-0 mb-2 mr-2" (click)="onClose()">Cancel</button>
            <button type="button" class="btn btn-sm btn-info rounded-0 mb-2 mr-2">Change Password</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container">
  <div class="row">
    <div class="col-md-12 col-lg-6 mb-4 align-items-stretch">
      <div class="form-group">
        <label>Old Password <span class="text-danger">*</span></label>
        <div class="float-label-control">
          <input type="password" class="form-control" id="old-password" data-toggle="oldpasswd"
          name="old_password" [(ngModel)]="OldPassword"
          required
          placeholder="Enter Old Password">
          <span class="fa fa-sm fa-eye-slash field-icon toggle-password old-view-hide"
            id="old-eye-open" toggle="#oldpasswd"
            (click)="old_password()">
          </span>
        </div>
      </div>
      <div class="form-group">
        <label>New Password <span class="text-danger">*</span></label>
        <div class="float-label-control">
          <input type="password" class="form-control" id="new-password" data-toggle="newpasswd"
          name="new_password" [(ngModel)]="NewPassword"
          required
          placeholder="Enter New Password">
          <span class="fa fa-sm fa-eye-slash field-icon toggle-password new-view-hide"
            id="new-eye-open" toggle="#newpasswd"
            (click)="new_password()">
          </span>
        </div>
      </div>
      <div class="form-group">
        <label>Confirm New Password <span class="text-danger">*</span></label>
        <div class="float-label-control">
          <input type="password" class="form-control" id="confirm-password" data-toggle="confirmpasswd"
          name="conf_password" [(ngModel)]="ConfPassword"
          required
          placeholder="Enter Confirm Password">
          <span class="fa fa-sm fa-eye-slash field-icon toggle-password same-view-hide"
            id="confirm-eye-open" toggle="#confirmpasswd"
            (click)="confirm_password()">
          </span>
        </div>
      </div>
      <div class="col text-right">
        <button type="button" class="btn btn-sm btn-outline-info rounded-0 mb-2 mr-2" (click)="Cancel()">Cancel</button>
        <button type="button" class="btn btn-sm btn-info rounded-0 mb-2" (click)="ChangePassword()">Save Changes</button>
      </div>
    </div>
  </div>
</div>
<app-cs-navbar></app-cs-navbar>
<div class="container-fluid form-container">
  <div class="row">
    <!-- <div class="col-md-6 content-center img-mobile-fit">
      <div class="custom-center">
        <h3 class="text-white text-center text-size mb-4">
          <img src="/assets/img/collections/phone.png" class="form-img mr-2">
          <strong>+917338773388</strong>
        </h3>
        <h3 class="text-white text-center text-size mb-4">
          <img src="/assets/img/collections/email.png" class="form-img mr-2">
          <strong>bd@manageteamz.com</strong>
        </h3>
       
      </div>
    </div> -->
    <div class="col-md-12 content-center">
      <div class="card rounded-card">
        <div class="card-body custom-size">
          <!-- <h4 class="text-center mb-4">
            <strong>Thank you !!!</strong>
          </h4> -->
          <p style="text-align: center;">Thanks for your Interest with ManageTeamz. We will get back to you shortly to setup a demo. </p>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid service-container" style="background-color: #2e3c52;color: #fff;">
  <div class="row">
    <div class="col-md-6 text-left">
      <ul class="list-unstyled mt-3">
        <li>
          <div class="wrap">
            <div class="ico-wrap">
              <span class="mbr-iconfont">
                <img src="/assets/img/location.png" class="service-img mr-4" style="margin-top: 11px;">
              </span>
            </div>
            <div class="vcenter">
              <h4 class="text-left">
                <strong>{{'Real Time Tracking' | translate}}</strong>
              </h4>
              <p>
                {{'Monitor your delivery business with real time tracking; Customer will be informed with real time notifications with web link.' | translate}}
              </p>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="col-md-6 text-left">
      <ul class="list-unstyled mt-3">
        <li>
          <div class="wrap">
            <div class="ico-wrap">
              <span class="mbr-iconfont">
                <img src="/assets/img/vehicle.png" class="service-img mr-4" style="margin-top: 12px;">
              </span>
            </div>
            <div class="vcenter">
              <h4 class="text-left">
                <strong>{{'Easy Delivery Management' | translate}}</strong>
              </h4>
              <p>
                {{'Manage real time status and locations of your delivery on the map. You can assign new tasks and manage your business productively.' | translate}}
              </p>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6 text-left">
      <ul class="list-unstyled mt-3">
        <li>
          <div class="wrap">
            <div class="ico-wrap">
              <span class="mbr-iconfont">
                <img src="/assets/img/reviews.png" class="service-img mr-4" style="margin-top: 11px;">
              </span>
            </div>
            <div class="vcenter">
              <h4 class="text-left">
                <strong>{{'Customer Satisfaction' | translate}}</strong>
              </h4>
              <p>
                {{'Customer will receive a web link as SMS and track delivery on real time. Rating Feedback can be provided by customer easily.' | translate}}
              </p>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="col-md-6 text-left">
      <ul class="list-unstyled mt-3">
        <li>
          <div class="wrap">
            <div class="ico-wrap">
              <span class="mbr-iconfont">
                <img src="/assets/img/web.png" class="service-img mr-4" style="margin-top: 12px;">
              </span>
            </div>
            <div class="vcenter">
              <h4 class="text-left">
                <strong>{{'Web, iPhone and Android App' | translate}}</strong>
              </h4>
              <p>
               {{'Business management is easy through web. Delivery agent shall use iPhone and Android App to manage their orders without being in the office.' | translate}}
              </p>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>

<app-cs-footer></app-cs-footer>

import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders, HttpErrorResponse } from "@angular/common/http";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { FormGroup } from '@angular/forms';
import { ApiService } from './../../services/ApiService';
import { AppService } from './../../services/AppService';
import { AuthService } from './../../services/AuthService';
import { Config } from '../../config/config';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

export interface PeriodicElement {
  sip_id: number;
  first_name: string;
  last_name: string;
  email: string;
  contact_no: string
}

export interface DialogData {
  animal: string;
  name: string;
}

@Component({
  selector: 'app-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.css'],
})
export class ItemComponent implements OnInit {
  @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns = ['id', 'name', 'date', 'Actions'];
  itemsList: any;
  dataSource: MatTableDataSource<any>;
  isDeleteModel = false;
  deleteItem: any;
  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private loader: NgxSpinnerService,
    private toastr: ToastrService,
    private authService: AuthService,
    public appService: AppService,
    public apiService: ApiService,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
  ) { }

  ngOnInit() {
    this.apiService
      .itemList()
      .subscribe((response: any) => {
        this.itemsList = response.data;
        this.dataSource = new MatTableDataSource(this.itemsList);
        setTimeout(() => {this.dataSource.paginator = this.paginator},2000);
        this.dataSource.sort = this.sort;
      })
  }
  EditItem(id: any) {
    localStorage.setItem("item_id", id);
    this.router.navigate(["item-edit"]);
  }
  onClose(){
    this.deleteItem = undefined;
    this.isDeleteModel = false;
  }
  ApplyFilter(event: Event) {
    setTimeout(() => {
      const filterValue = (event.target as HTMLInputElement).value;
      this.dataSource.filter = filterValue.trim().toLowerCase();
      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
    },2000);
  }
  DeleteModel(element: any) {
    this.deleteItem = element;
    this.isDeleteModel = true;
  }
  DeleteItem(){
    this.loader.show();
    this.apiService
      .itemDelete(this.deleteItem)
      .subscribe((response: any) => {
        if(response.status == "ok"){
          this.loadItems();
          this.toastr.success(response.data);
        }else{
          this.toastr.warning(response.data);
          this.loader.hide();
        }
        this.isDeleteModel = false;
      });
  }
  loadItems(){
    this.loader.show();
    this.apiService
      .itemList()
      .subscribe((response: any) => {
        this.itemsList = response.data;
        this.dataSource = new MatTableDataSource(this.itemsList);
        setTimeout(() => this.dataSource.paginator = this.paginator);
        this.loader.hide();
      });
  }
}

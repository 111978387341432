import { Config } from '../config/config';
import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';


@Injectable()
export class ApiService {

  constructor(
    private http: HttpClient,
  ) { }

  baseUrl = Config.BASE_URL;
  apiUrl = this.baseUrl + "api";
  signupUrl = this.baseUrl;

  // Login
  public emailLogin(payload){
    return this.http.post<{success: object}>(`${this.apiUrl}/login?api=web`, payload);
  }
  public mobileLoginOTP(payload){
    return this.http.post<{success: object}>(`${this.apiUrl}/generate_otp?api=web`, payload);
  }
  public mobileLogin(payload){
    return this.http.post<{success: object}>(`${this.apiUrl}/login?api=web`, payload);
  }
  public forgetPassword(payload){
    return this.http.post<{success: object}>(`${this.apiUrl}/forget`, payload);
  }

  // Signup
  public signupOPTGenerator(payload){
    return this.http.post<{success: object}>(`${this.apiUrl}/generate_otp_web`, payload);
  }
  public signup(payload){
    return this.http.put<{success: object}>(`${this.signupUrl}/dashboard/signup`, payload);
  }
  public promoteToAdmin(payload){
    return this.http.post<{success: object}>(`${this.apiUrl}/promote_to_admin?api=web`, payload);
  }

  // Notifications
  public notificationList(pageNo){
    return this.http.get<{success: object}>(`${this.apiUrl}/GetMyNotifications?page=${pageNo}`);
  }
  public readNotify(notifyId){
    return this.http.get<{success: object}>(`${this.apiUrl}/ReadMyNotifications/${notifyId}`);
  }
  public sentMail(){
    return this.http.get<{success: object}>(`${this.apiUrl}/send_mail/`);
  }

  // Calendar
  public calendarList(getByDate){
    return this.http.get<{success: object}>(`${this.apiUrl}/calander_orderInfo/${getByDate}`);
  }

  // Profile
  public checkUsers(){
    return this.http.get<{success: object}>(`${this.apiUrl}/checkusers`);
  }
  public profileList(){
    return this.http.get<{success: object}>(`${this.apiUrl}/profile`);
  }
  public editProfile(payload){
    return this.http.put<{success: object}>(`${this.apiUrl}/updateprofile`, payload);
  }
  public autoAllocation(payload){
    return this.http.post<{success: object}>(`${this.apiUrl}/auto_allocation_update`, payload);
  }
  public mergeOrders(payload){
    return this.http.post<{success: object}>(`${this.apiUrl}/merge_orders_option_update`, payload);
  }
  public profileUpload(payload){
    return this.http.post<{success: object}>(`${this.apiUrl}/upload?type=img`, payload);
  }
  public profileImageUpdate(userId,payload){
    return this.http.put<{success: object}>(`${this.apiUrl}/profile_image/${userId}`, payload);
  }
  public RemainingEmp(){
    return this.http.get<{success: object}>(`${this.apiUrl}/remaining_emp`);
  }

  //Timezone
  public getTimezone(){
    return this.http.get<{success: object}>(`${this.apiUrl}/gettimezone`);
  }
  //getCountries
  public getCountries(){
    return this.http.get<{success: object}>(`${this.apiUrl}/getCountries`);
  }
  //getCountries
  public getTimezoneByCountryCode(countryCode){
    return this.http.get<{success: object}>(`${this.apiUrl}/getTimezoneByCountryCode/${countryCode}`);
  }
  public updateBasicInfo(payload){
    return this.http.post<{success: object}>(`${this.apiUrl}/updatebasicinfo/`, payload);
  }

  public getEstimatedTime(orderId){
    return this.http.post<{success: object}>(`${this.apiUrl}/estimate_arrival_time`,orderId);
  }

  // Dashboard
  public userLists(){
    return this.http.get<{success: object}>(`${this.apiUrl}/user`);
  }
  public locationOnlineEmp(){
    return this.http.get<{success: object}>(`${this.apiUrl}/location_online_emp`);
  }
  public UpdateZoom(payload){
    return this.http.post<{success: object}>(`${this.apiUrl}/updatezoom`, payload);
  }

  // Order
  public addNewOrder(payload){
    return this.http.post<{success: object}>(`${this.apiUrl}/new_order`, payload);
  }
  public editOrder(orderId,payload){
    return this.http.put<{success: object}>(`${this.apiUrl}/new_order/${orderId}`, payload);
  }
  public getOrders(date){
    return this.http.get<{success: object}>(`${this.apiUrl}/new_order/?date=${date}`);
  }
  public orderDetails(orderId){
    return this.http.get<{success: object}>(`${this.apiUrl}/new_task/${orderId}`);
  }
  public AllocateAgent(payload,EmpID){
    return this.http.post<{success: object}>(`${this.apiUrl}/allocateTask/${EmpID}`, payload);
  }
  public UpdateTaskStatus(payload){
    return this.http.post<{success: object}>(`${this.apiUrl}/order_status_update`, payload);
  }
  public UpdateTaskViseStatus(payload,TaskID){
    return this.http.post<{success: object}>(`${this.apiUrl}/updateTaskStatus/${TaskID}`, payload);
  }
  public UpdateTaskViseDeliverStatus(payload,TaskID){
    return this.http.post<{success: object}>(`${this.apiUrl}/emp_task/${TaskID}`, payload);
  }
  public UpdateTaskViseUnDeliverStatus(payload,TaskID){
    return this.http.post<{success: object}>(`${this.apiUrl}/emp_order/${TaskID}`, payload);
  }
  public DriverDetails(empId){
    return this.http.get<{success: object}>(`${this.apiUrl}/user/${empId}`);
  }

  public orderSummary(orderId){
    return this.http.get<{success: object}>(`${this.apiUrl}/getOrderSummary/${orderId}`);
  }

  public orderDetailView(orderId){
    return this.http.get<{success: object}>(`${this.apiUrl}/order_details/${orderId}`);
  }

  public orderActiveDetails(){
    return this.http.get<{success: object}>(`${this.apiUrl}/new_task?active=yes`);
  }

  public deleteOrder(payload){
    return this.http.post<{success: object}>(`${this.apiUrl}/deleteTask`, payload);
  }

  //Manager
  public ManagerLists(){
    return this.http.get<{success: object}>(`${this.apiUrl}/get_managers`);
  }
  public ManagerActiveLists(){
    return this.http.get<{success: object}>(`${this.apiUrl}/get_managers?active=yes`);
  }
  public ManagerDetails(supId){
    return this.http.get<{success: object}>(`${this.apiUrl}/manager_details/${supId}`);
  }

  // Customer
  public addCustomer(payload){
    return this.http.post<{success: object}>(`${this.apiUrl}/customer`, payload);
  }
  public customerList(){
    return this.http.get<{success: object}>(`${this.apiUrl}/customer`);
  }
  public customerDelete(custID){
    return this.http.delete<{success: object}>(`${this.apiUrl}/customer/${custID}`);
  }
  public customerListById(custID){
    return this.http.get<{success: object}>(`${this.apiUrl}/customer/${custID}`);
  }
  public editCustomer(custID, payload){
    return this.http.put<{success: object}>(`${this.apiUrl}/customer/${custID}`, payload);
  }

  // Item
  public addItem(payload){
    return this.http.post<{success: object}>(`${this.apiUrl}/item/`, payload);
  }
  public itemList(){
    return this.http.get<{success: object}>(`${this.apiUrl}/item/`);
  }
  public itemDelete(itemId){
    return this.http.delete<{success: object}>(`${this.apiUrl}/item/${itemId}`);
  }
  public itemListById(itemId){
    return this.http.get<{success: object}>(`${this.apiUrl}/item/${itemId}`);
  }
  public editItem(itemId,payload){
    return this.http.put<{success: object}>(`${this.apiUrl}/item/${itemId}`, payload);
  }
  public userActiveLists(){
    return this.http.get<{success: object}>(`${this.apiUrl}/user?active=yes`);
  }

  public EmployeeLimitCheck(){
    return this.http.get<{success: object}>(`${this.apiUrl}/check_limit_crossed_or_not`);
  }

  public EmployeeLimitCheckforMgr(roleid){
    return this.http.get<{success: object}>(`${this.apiUrl}/check_limit_crossed_or_not?role_id=${roleid}`);
  }

  public ManagerLimitCheck(){
    return this.http.get<{success: object}>(`${this.apiUrl}/check_mgr_limit_crossed_or_not`);
  }

  public ManagerLimitCheckforMgr(roleid){
    return this.http.get<{success: object}>(`${this.apiUrl}/check_mgr_limit_crossed_or_not?role_id=${roleid}`);
  }

  // Supervisor
  public supervisorFileUpload(payload){
    return this.http.post<{success: object}>(`${this.apiUrl}/upload?type=img`, payload);
  }
  public addSupervisor(payload){
    return this.http.post<{success: object}>(`${this.apiUrl}/user`, payload);
  }
  public supervisorBelongsList(supId){
    return this.http.get<{success: object}>(`${this.apiUrl}/user/${supId}?belongs=yes`);
  }
  public supervisorListById(supId){
    return this.http.get<{success: object}>(`${this.apiUrl}/user/${supId}`);
  }
  public GetEmpByPhone(supId){
    return this.http.get<{success: object}>(`${this.apiUrl}/getEmployeeByPhone/${supId}`);
  }
  public GetMgrByPhone(supId){
    return this.http.get<{success: object}>(`${this.apiUrl}/getEmployeeByPhone/${supId}?manager_id=true`);
  }
  public editSupervisor(supId, payload){
    return this.http.put<{success: object}>(`${this.apiUrl}/user/${supId}`, payload);
  }
  public StatusUpdateSupervisor(supId, payload){
    return this.http.put<{success: object}>(`${this.apiUrl}/status_update/${supId}`, payload);
  }
  public StatusUpdateSupervisorMgr(supId, payload,mgrId){
    return this.http.put<{success: object}>(`${this.apiUrl}/status_update/${supId}?manager_id=${mgrId}`, payload);
  }
  public supervisorDelete(supId){
    return this.http.delete<{success: object}>(`${this.apiUrl}/user/${supId}`);
  }
  public adminResetPassword(payload){
    return this.http.post<{success: object}>(`${this.apiUrl}/adminresetpassword`, payload);
  }
  public getIpInfo() {
    return this.http.get<{success: object}>(`https://ipinfo.io/json`);
  }

  // Driver
  public driverActiveList(){
    return this.http.get<{success: object}>(`${this.apiUrl}/user?active=yes`);
  }
  public GetFilterOrderList(payload){
    return this.http.post<{success: object}>(`${this.apiUrl}/driver_info`, payload);
  }
  public getMyLocation(date,driverId){
    return this.http.get<{success: object}>(`${this.apiUrl}/getMyLocations?date=${date}&emp=${driverId}`);
  }

  // Review
  public userReview(uesrid){
    return this.http.get<{success: object}>(`${this.apiUrl}/customer_review/${uesrid}`);
  }

  public EmployeeReview(){
    return this.http.get<{success: object}>(`${this.apiUrl}/employee-reviews/`);
  }

  // Reports
  // -- delivery-report --
  public locationEmployee(payload,empId){
    return this.http.post<{success: object}>(`${this.apiUrl}/location_emp_api/${empId}`, payload);
  }
  // -- milage-report --
  public milageReport(payload,empId){
    return this.http.post<{success: object}>(`${this.apiUrl}/empgpsdata/${empId}`, payload);
  }
  public milageReportOrderFilter(payload,empId){
    return this.http.post<{success: object}>(`${this.apiUrl}/milagereport/${empId}`, payload);
  }
  // -- Add-bulk-upload --
  public AddBulkUpload(payload){
    return this.http.post<{success: object}>(`${this.apiUrl}/bulkorders`, payload);
  }
  // -- gps-history --
  public filterTravelMap(startdate,enddate,empId){
    return this.http.get<{success: object}>(`${this.apiUrl}/new_task?start=${startdate}&end=${enddate}&emp=${empId}`);
  }
  public getMyLocationsweb(startdate,enddate,empId){
    return this.http.get<{success: object}>(`${this.apiUrl}/getMyLocationsweb?start=${startdate}&end=${enddate}&emp=${empId}`);
  }
  public getAdminUsersList(param){
    return this.http.get<{success: object}>(`${this.apiUrl}/get_admin_users?offset=${param['offset']}&limit=${param['limit']}`);
  }
  public getPackageInformation(){
    return this.http.get<{success: object}>(`${this.apiUrl}/get_packages`);
  }
  public getUserPackageInfo(id){
    return this.http.get<{success: object}>(`${this.apiUrl}/get_user_package_info/${id}`);
  }
  public update_useractivestatus(userid, payload){
    return this.http.put<{success: object}>(`${this.apiUrl}/update_activestatus/${userid}`, payload);
  }
  public updatePackageExtension(param){
    return this.http.post<{success: object}>(`${this.apiUrl}/update_expiry/`,param);
  }
  public getPaymentHistoryList(param){
    let url = `${this.apiUrl}/paymenthistory`
    if(param !=''){
      url = `${url}?user_id=${param}`
    }
    return this.http.get<{success: object}>(url);
  }
  public getPaymentDetailsById(id){
    return this.http.get<{success: object}>(`${this.apiUrl}/paymenthistory/${id}`);
  }
  public getRecentPaymentHistory(){
    return this.http.get<{success: object}>(`${this.apiUrl}/recent_payment_history`);
  }
  public makePayment(data){
    return this.http.post<{success: object}>(`${this.apiUrl}/make_payment`,data);
  }

  public getDriverDetails(id){
    return this.http.get<{success: object}>(`${this.apiUrl}/getAvailableDrivers?zone_id=${id}`);
  }

  public createDrivingZone(data){
    return this.http.post<{success: object}>(`${this.apiUrl}/driving_zone`,data);
  }
  public drivingZoneList(){
    return this.http.get<{success: object}>(`${this.apiUrl}/driving_zone`);
  }
  public drivingZoneDetail(id){
    return this.http.get<{success: object}>(`${this.apiUrl}/driving_zone/${id}`);
  }
  public updateDrivingZone(data, id) {
    return this.http.put<{success: object}>(`${this.apiUrl}/driving_zone/${id}`,data);
  }
  public deleteDrivingZone(id){
    return this.http.delete<{success: object}>(`${this.apiUrl}/driving_zone/${id}`);
  }
}


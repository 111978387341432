import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, UrlTree, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './AuthService';
import { Observable } from 'rxjs';

@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(
    public auth: AuthService,
    public router: Router
  ) { }
  canActivate(next: ActivatedRouteSnapshot,route: RouterStateSnapshot):  Observable<boolean> | Promise<boolean> | boolean{
    let token,client_data,request;
    token = next.queryParamMap.has('access_token');
    client_data = next.queryParamMap.has('client_data');
    const allowedRoles = next.data.allowedRoles;
    if(token && client_data){
      request = next.queryParamMap;
      localStorage.setItem(
        "access_token",
        request.params.access_token
      );
      localStorage.setItem(
        "client_data",
        request.params.client_data
      );
      
    } 
    if(!this.auth.isAuthenticated()){
      console.log('I am redirecting to login');
      this.router.navigate(['mobile-login']);
      return false;
    } else{
      var role = localStorage.getItem("user_data");
      var userData = JSON.parse(role);
      var role_id = userData.profile.role_id
      if(allowedRoles.findIndex(rols => rols === role_id) >= 0){
        
      }else{
        this.router.navigate(['mobile-login']);
        return false;
      }
    }
    return true;
  }
}

<div class="container-fluid">
  <div class="container">
    <div class="row" style="margin:0">
      <p style="margin-top: 12px;font-size: 16px;"><i class="fa fa-arrow-left fa-theme mr-2 pointer" matTooltip="{{'Go Back' | translate}}" [matTooltipPosition]="'above'" [routerLink]="['/customer']"></i><b>{{'Edit Customer' | translate}}</b></p>
      <!-- <h5 style="color: #6c757d;"><b>Edit Customer </b></h5> -->
    </div>
    <div class="row" style="margin-top: 15px;">
      <div class="col-md-12 col-lg-6 mb-4 align-items-stretch">
        <div class="form-group">
        <mat-form-field class="col-md-12" appearance="outline">
          <mat-label>{{'Name' | translate}}</mat-label>
          <input matInput placeholder="{{'Enter Name' | translate}}" name="cus_name" [(ngModel)]="CustName" required>
        </mat-form-field>
        </div>
        <div class="form-group">
        <mat-form-field class="col-md-12" appearance="outline">
          <label style="position: absolute;top: -5px;">{{'Phone Number' | translate}}<span> *</span></label>
          <!-- <input matInput placeholder="Enter Phone Number" name="cus_phone" [(ngModel)]="CustPhone" required> -->
          <ngx-mat-intl-tel-input
            [preferredCountries]="preferredCountries"
            [enableAutoCountrySelect]="false"
            [enablePlaceholder]="true"
            [searchCountryFlag]="true"
            [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
            [selectFirstCountry]="false"
            [selectedCountryISO]="CountryISO.India"
            [maxLength]="15"
            [tooltipField]="TooltipLabel.Name"
            [(ngModel)]="CustPhone"
            (keypress)="omit_char($event);"
            required
            [separateDialCode]="false" [enableSearch]="true"
            name="cus_phone" #phone
            >
          </ngx-mat-intl-tel-input>
        </mat-form-field>
        </div>
      </div>
      <div class="col-md-12 col-lg-6 mb-4 align-items-stretch">
        <div class="form-group">
          <mat-form-field class="col-md-12" appearance="outline">
            <mat-label>{{'Email' | translate}}</mat-label>
            <input matInput placeholder="{{'Enter Email' | translate}}" name="cus_email" pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" [(ngModel)]="CustEmail">
          </mat-form-field>
          </div>
        <div class="form-group">
          <mat-form-field class="col-md-12" appearance="outline">
            <mat-label>{{'Address' | translate}}</mat-label>
            <input matInput placeholder="{{'Enter Address' | translate}}" name="cus_address" value="{{CustomerAddress}}" required  matGoogleMapsAutocomplete
            (onAutocompleteSelected)="onAutocompleteSelected($event)" #search
            (onLocationSelected)="onLocationSelected($event)" style="width: 90%; border: none !important;outline: none !important;box-shadow: none !important;background: none !important;overflow: hidden;">
            <!-- <input matInput type="text" class="form-control" (keydown.enter)="$event.preventDefault()" placeholder="Enter Address" autocorrect="off" autocapitalize="off" spellcheck="off" type="text" #search [value]="CustomerAddress" [(ngModel)]="CustomerAddress" name="cus_address" required style="width: 90%; border: none !important;outline: none !important;box-shadow: none !important;background: none !important;overflow: hidden;"> -->
            
          </mat-form-field>
          <img src="./assets/img/location.svg" alt="Profile Edit" style="height: 30px;width: 30px;float: right;margin-top: -70px;overflow: hidden;position: relative;right:20px;" class="map-icon" (click)="showmap(1)" *ngIf="agmshowmap == 0">
            <img src="./assets/img/location-disabled.svg" alt="Profile Edit" style="height: 30px;width: 30px;float: right;margin-top: -70px;overflow: hidden;position: relative;right:20px;" class="map-icon" (click)="showmap(0)" *ngIf="agmshowmap == 1">
            <agm-map [latitude]="latitude" [longitude]="longitude" [zoom]="zoom" *ngIf="agmshowmap == 1">
              <agm-marker [latitude]="latitude" [longitude]="longitude" [markerDraggable]="true"
                (dragEnd)="markerDragEnd($event)"></agm-marker>
            </agm-map>
        </div>
      </div>
    </div>
    <div class="col text-right">
      <button type="button" (click)="Cancel()" class="btn btn-outline-info rounded-0 mb-2 mr-2"><b>{{'Cancel' | translate}}</b></button>
      <button type="button" (click)="UpadteCustomer()" class="btn btn-info rounded-0 mb-2"><b>{{'Update Customer' | translate}}</b></button>
    </div>
  </div>
</div>

import { Component, OnInit, ViewChild, QueryList, ViewChildren, ElementRef, Inject, ChangeDetectorRef } from "@angular/core";
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders, HttpErrorResponse } from "@angular/common/http";
import { FormGroup } from '@angular/forms';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import $ from "jquery";
import { AuthService } from 'src/app/services/AuthService';
import { AppService } from 'src/app/services/AppService';
import { ApiService } from 'src/app/services/ApiService';
import { Image_Path } from 'src/app/config/config';
import { GeneralService } from 'src/app/services/GeneralService';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Config } from '../../config/config';
import * as moment from 'moment-timezone';

interface Packages {
  value: string;
  name: string;
}
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  loaderOne = false;
  isOpen = false;
  modelTitle: any;
  ProfileData: any;
  Firstname: any;
  Lastname: any;
  Phoneno: any;
  Email: any;
  PickupAddress: any;
  Role: string;
  UserData: any;
  PackageDetails: any;
  PackageName: any;
  PackActivateDate: any;
  PackEndDate: any;
  TotalAllocatedEmployees: any;
  Remainingloyees: any;
  SelectedTask: string;
  auto_checked: boolean = false;
  merge_orders_checked: boolean = false;
  merge_orders_options_show: boolean;
  autoallocshow: boolean;
  Timezone: any;
  Location: any;
  countryCode: any;
  ReqExpires: any;
  MaxRadius: any;
  alloclogic: any;
  autochecked: number;
  S3BaseUrl = Image_Path.S3_BASE_URL;
  headers: HttpHeaders;
  ProfileImage: any;
  color: any;
  disabled: any;
  fileToUpload: File;
  profileImage: any;
  userData: any;
  packDataInfo: any;
  total_emp: number;
  remaining_emp: number;
  total_mgr: any;
  remaining_mgr: any;
  managershow: boolean;
  superadminmenu: boolean = false;
  PackageTypes: Packages[] = [
    { value: 'DriverBasedPlan', name: 'Driver Based Plan' },
    { value: 'TaskBasedPlan', name: 'Task Based Plan' }
  ];
  userPackageInfo: any;
  packageInfo: any;
  paymentURLData: any;
  isPaymentRequired: boolean;
  paymentDetails: any;
  paymentResponse: string;
  mergeMinimumOrderLimit: any;
  mergeMaximumOrderLimit: any;
  mergeMaxWaitTime: any;
  mergeMaxGeoFenceLimit: any;
  remainderTime: number;

  constructor(
    public dialog: MatDialog,
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private loader: NgxSpinnerService,
    private toastr: ToastrService,
    private authService: AuthService,
    public appService: AppService,
    public apiService: ApiService,
    public generalService: GeneralService,
    private snackBar: MatSnackBar,
    public ref: ChangeDetectorRef,
    protected sanitizer: DomSanitizer
  ) { }


  ngOnInit() {
    this.isPaymentRequired = false;
    this.userData = JSON.parse(localStorage.getItem("user_data"));
    this.getUserPackageInfo(this.userData.profile.user_id);
    this.getPackageInfo();

    this.apiService
      .userActiveLists()
      .subscribe((response: any) => {
        let tempData = [];
        response.data.forEach(element => {
          if (element.is_delete != 'true') {
            tempData.push(element);
          }
        });
        this.packDataInfo = tempData;
        this.ref.detectChanges();
      });

    this.apiService
      .checkUsers()
      .subscribe((response: any) => {
        this.UserData = response[0];
        this.PackageDetails = this.UserData.packageinfo[0];
        this.PackageName = this.PackageDetails.name;
        this.PackActivateDate = this.UserData.beg_date;
        this.PackEndDate = this.UserData.end_date;
        var _date = new Date();
        var current_date = moment(_date).format("YYYY-MM-DD");
        var end_date = moment(this.UserData.end_date);
        var end_date1 = end_date.format("YYYY-MM-DD");
        var before_expire = end_date.subtract(3, "days").format("YYYY-MM-DD");
        var checkBetween = moment(current_date).isBetween(before_expire, end_date1);
        var checkIsSame = moment(end_date1).isSame(current_date);
        var checkIsBefore = moment(end_date1).isBefore(current_date);

        if (checkBetween || checkIsSame || checkIsBefore) {
          this.isPaymentRequired = true;
        }

        this.UserData.packageinfo[0].no_of_emp = parseInt(this.UserData.no_of_emp);
        this.UserData.no_of_emp = parseInt(this.UserData.no_of_emp) - parseInt(this.packDataInfo ? this.packDataInfo.length : 0);
        if (this.UserData.no_of_emp <= -1) {
          this.UserData.no_of_emp = 0;
        }
        this.TotalAllocatedEmployees = this.UserData.packageinfo[0].no_of_emp;
        this.Remainingloyees = this.UserData.no_of_emp;
      });

    this.apiService
      .profileList()
      .subscribe((response: any) => {
        if (response.data.profile_image.length > 2) {
          var result = response.data.profile_image.charAt(0);
          if (result == "[") {
            var getProfile = JSON.parse(response.data.profile_image);
            response.data.profile_image = getProfile[0];
          }
        }
        this.profileImage = response.data.profile_image;
        this.userData.profile.profile_image = response.data.profile_image;
        this.ProfileData = response.data;
        let localdata = JSON.parse(localStorage.getItem("user_data"));
        localdata.profile = response.data;
        localStorage.setItem("user_data", JSON.stringify(localdata));
        this.Firstname = this.ProfileData.first_name;
        this.Lastname = this.ProfileData.last_name;
        this.Phoneno = this.ProfileData.phone;
        this.Email = this.ProfileData.email;
        this.PickupAddress = this.ProfileData.pickup_address;

        if (this.ProfileData.role_id == 2) {
          this.Role = "Administrator";
          this.managershow = true;
        } else if (this.ProfileData.role_id == 4) {
          this.Role = "Manager";
          this.managershow = false;
        } else if (this.ProfileData.role_id == 3) {
          this.superadminmenu = true;
        }

        if (this.ProfileData.delivery_logic == 1) {
          this.SelectedTask = "Single Pickup Multi Delivery"
        } else if (this.ProfileData.delivery_logic == 2) {
          this.SelectedTask = "Multi Pickup Single Delivery"
        } else if (this.ProfileData.delivery_logic == 3) {
          this.SelectedTask = "Single Pickup Single Delivery"
        }

        if (this.ProfileData.is_auto_allocation_enable == 1) {
          this.auto_checked = true;
          this.autoallocshow = true;
        } else if (this.ProfileData.is_auto_allocation_enable == 0) {
          this.auto_checked = false;
          this.autoallocshow = false;
        }

        if (this.ProfileData.is_merge_orders_enabled == 1) {
          this.merge_orders_checked = true;
          this.merge_orders_options_show = true;
        } else {
          this.merge_orders_checked = false;
          this.merge_orders_options_show = false;
        }

        this.Timezone = this.ProfileData.timezone;
        this.Location = this.ProfileData.street;
        this.countryCode = this.ProfileData.country_code;
        this.alloclogic = this.ProfileData.is_auto_allocation_logic;
        this.ReqExpires = this.ProfileData.auto_allocation_expires_in;
        this.MaxRadius = this.ProfileData.auto_allocation_max_radius_in_km;
        this.mergeMaxGeoFenceLimit = this.ProfileData.merge_max_geo_fence_limit;
        this.mergeMaxWaitTime = this.ProfileData.merge_max_wait_time;
        this.mergeMinimumOrderLimit = this.ProfileData.merge_minimum_order_limit;
        this.mergeMaximumOrderLimit = this.ProfileData.merge_maximum_order_limit;
      });

    this.apiService
      .RemainingEmp()
      .subscribe((response: any) => {
        if (response.status == "ok") {
          this.total_emp = response.data.total_emp;
          this.remaining_emp = response.data.remaining_emp;
          this.total_mgr = response.data.total_mgr;
          this.remaining_mgr = response.data.remaining_mgr;
        }
      });

    this.getRecentPaymentHistory();
  }

  getUserPackageInfo(user_id) {
    this.apiService.getUserPackageInfo(user_id).subscribe(
      (response) => {
        this.userPackageInfo = response['data']
      }
    )
  }

  getPackageInfo() {
    this.apiService.getPackageInformation().subscribe(
      (response) => {
        this.packageInfo = response['data']
      }
    )
  }

  getRecentPaymentHistory() {
    this.apiService.getRecentPaymentHistory().subscribe((response) => {
      this.paymentDetails = response['data'];
      if (this.paymentDetails.status == 'Success') {
        this.paymentResponse = "Thank you for making the payment. We received your payment and your account has been renewed";
      } else {
        this.paymentResponse = "Sorry! Your last payment was not received successfully. Reason: " + this.paymentDetails.failure_message;
      }
    })
  }

  loadProfileData() {
    this.loader.show();
    this.apiService
      .profileList()
      .subscribe((response: any) => {
        let localdata = JSON.parse(localStorage.getItem("user_data"));
        localdata.profile = response.data;
        localStorage.setItem("user_data", JSON.stringify(localdata));
        this.loader.hide();
      });
  }
  AutoAllocation() {

    if (this.ReqExpires == undefined || this.ReqExpires == '') {
      this.snackBar.open("Request Expires is Required", "Close", {
        duration: 4000,
        verticalPosition: 'bottom'
      })
      return 0;
    }

    if (this.MaxRadius == undefined || this.MaxRadius == '') {
      this.snackBar.open("Maximum Radius is Required", "Close", {
        duration: 4000,
        verticalPosition: 'bottom'
      })
      return 0;
    }

    if (this.auto_checked == true) {
      this.autochecked = 1;
      this.alloclogic = 1;
    } else if (this.auto_checked == false) {
      this.autochecked = 0;
      this.alloclogic = 0;
    }
    var data = {
      data: {
        "is_auto_allocation_enable": this.autochecked,
        "is_auto_allocation_logic": parseInt(this.alloclogic),
        "auto_allocation_expires_in": parseInt(this.ReqExpires),
        "auto_allocation_max_radius_in_km": parseInt(this.MaxRadius)
        
      }
    }
    
    this.loader.show();
        
    this.apiService
    .autoAllocation(data)
    .subscribe((response: any) => {
      if (response.status == "ok") {
        if(this.merge_orders_options_show==false){
          this.toastr.success(response.data);
          this.loader.hide();
        }else{
          this.toastr.success("Merge order details are updated");
          this.loader.hide();
        }
        
      }
    });  
  }

  onSavetime(){

  }

  MergeOrderUpdate() {

    if (this.mergeMinimumOrderLimit == undefined || this.mergeMinimumOrderLimit == '') {
      this.snackBar.open("Minimum Merge Order Limit is Required", "Close", {
        duration: 4000,
        verticalPosition: 'bottom'
      })
      return 0;
    }

    if (this.mergeMaxWaitTime == undefined || this.mergeMaxWaitTime == '') {
      this.snackBar.open("Maximum Merge Wait Time is Required", "Close", {
        duration: 4000,
        verticalPosition: 'bottom'
      })
      return 0;
    }

    if (this.mergeMaximumOrderLimit == undefined || this.mergeMaximumOrderLimit == '') {
      this.snackBar.open("Maximum Merge Order Limit is Required", "Close", {
        duration: 4000,
        verticalPosition: 'bottom'
      })
      return 0;
    }

    /* if (this.mergeMaxGeoFenceLimit == undefined || this.mergeMaxGeoFenceLimit == '') {
      this.snackBar.open("Maximum Merge Geo Fence Radius is Required", "Close", {
        duration: 4000,
        verticalPosition: 'bottom'
      })
      return 0;
    } */

    var data = {
      data: {
        "is_merge_orders_enabled": this.merge_orders_checked ? 1 : 0,
        "merge_minimum_order_limit": parseInt(this.mergeMinimumOrderLimit),
        "merge_max_wait_time": parseFloat(this.mergeMaxWaitTime),
        "merge_maximum_order_limit": parseFloat(this.mergeMaximumOrderLimit)
        //"merge_max_geo_fence_limit": parseFloat(this.mergeMaxGeoFenceLimit)
      }
    }
    this.loader.show();
    this.apiService
      .mergeOrders(data)
      .subscribe((response: any) => {
        if (response.status == "ok") {
          this.AutoAllocation();
         // this.toastr.success(response.data);
         // this.loader.hide();
        }
      });
  }

  openModel(obj: any) {
    this.modelTitle = obj;
    this.isOpen = true;
  }
  onClose() {
    this.modelTitle = undefined;
    this.isOpen = false;
  }

  omit_char(event: any) {
    const keyChar = event.key;
    let allowCharacter: boolean;
    if (keyChar === '-' && event.target.selectionStart !== 0) {
      allowCharacter = false;
    } else if (
      keyChar === 'Tab' ||
      keyChar === 'Enter' ||
      keyChar === 'Backspace' ||
      keyChar === 'ArrowLeft' ||
      keyChar === 'ArrowRight' ||
      keyChar === 'Delete'
    ) {
      allowCharacter = true;
    } else {
      allowCharacter = keyChar >= '0' && keyChar <= '9';
    }
    if (!allowCharacter) {
      event.preventDefault();
    }
  }

  autochange(event: any) {
    var payload;
    if (event.checked == true) {
      this.alloclogic = 1;
      this.autoallocshow = true;
      if (this.auto_checked == true) {
        this.autochecked = 1;
      } else if (this.auto_checked == false) {
        this.autochecked = 0;
      }
      payload = {
        data: {
          "is_auto_allocation_enable": this.autochecked,
          "is_auto_allocation_logic": parseInt(this.alloclogic),
          "auto_allocation_expires_in": parseInt(this.ReqExpires),
          "auto_allocation_max_radius_in_km": parseInt(this.MaxRadius)
        }
      }
    } else if (event.checked == false) {
      this.alloclogic = 0;
      this.autoallocshow = false;
      if (this.auto_checked == true) {
        this.autochecked = 1;
      } else if (this.auto_checked == false) {
        this.autochecked = 0;
      }
      payload = {
        data: {
          "is_auto_allocation_enable": this.autochecked,
          "is_auto_allocation_logic": parseInt(this.alloclogic),
          "auto_allocation_expires_in": parseInt(this.ReqExpires),
          "auto_allocation_max_radius_in_km": parseInt(this.MaxRadius)
        }
      }
    }
    this.apiService
      .autoAllocation(payload)
      .subscribe((response: any) => {
        this.loader.hide();
        this.loadProfileData();
      });
  }

  mergeOrderChange(event: any) {
    var payload;
    if (event.checked == true) {
      this.merge_orders_options_show = true;

      payload = {
        data: {
          "is_merge_orders_enabled": this.merge_orders_checked ? 1 : 0,
          "merge_minimum_order_limit": parseInt(this.mergeMinimumOrderLimit),
          "merge_maximum_order_limit": parseInt(this.mergeMaximumOrderLimit),
          "merge_max_wait_time": parseFloat(this.mergeMaxWaitTime),
          "merge_max_geo_fence_limit": 0
        }
      }
    } else if (event.checked == false) {
      this.merge_orders_options_show = false;

      payload = {
        data: {
          "is_merge_orders_enabled": this.merge_orders_checked ? 1 : 0,
          "merge_minimum_order_limit": parseInt(this.mergeMinimumOrderLimit),
          "merge_maximum_order_limit": parseInt(this.mergeMaximumOrderLimit),
          "merge_max_wait_time": parseFloat(this.mergeMaxWaitTime),
          "merge_max_geo_fence_limit": 0
        }
      }
    }
    this.apiService
      .mergeOrders(payload)
      .subscribe((response: any) => {
        this.loader.hide();
        this.loadProfileData();
      });
  }

  openDialog() {
    const dialogRef = this.dialog.open(DialogProfileDialog);
    dialogRef.afterClosed().subscribe(result => {
    });
  }
  profileImageSelected(files: FileList) {
    const fileType = files.item(0);
    var typeFile = fileType.type.split('/');
    if (typeFile[0] == 'image') {
      this.fileToUpload = files.item(0);
      const formData: FormData = new FormData();
      if (this.fileToUpload) {
        formData.append('files[]', this.fileToUpload);
      }
      this.loader.show();
      this.apiService
        .profileUpload(formData)
        .subscribe((response: any) => {
          this.toastr.success('Profile Image Updated');
          this.profileImage = response.data[0];
          if (this.profileImage) {
            this.generalService.refreshHeader({
              profileUrl: this.profileImage,
              status: true
            });
            this.manualUpdate(this.profileImage);
          }
          this.loader.hide();
        });
    } else {
      this.toastr.warning('Non image file format not accepted')
      return;
    }
  }

  makePayment(package_id, amount) {
    let baseUrl = Config.BASE_URL;

    let data = {
      "data": {
        "order_id": Number(new Date()),
        "amount": amount,
        "currency": "AED",
        "language": "EN",
        "redirect_url": baseUrl + "payment-response",
        "cancel_url": baseUrl + "payment-response",
        "billing_name": this.Firstname + " " + this.Lastname,
        "billing_address": this.Location,
        "billing_city": this.countryCode,
        "billing_country": this.countryCode,
        "billing_tel": this.Phoneno,
        "billing_email": this.Email,
        "integration_type": "iframe_normal",
        "merchant_param1": this.userData?.profile?.user_id,
        "merchant_param2": package_id
        // "package_id":package_id,
        // "user_id":this.userData?.profile?.user_id
      }
    }

    this.loader.show();
    this.apiService.makePayment(data).subscribe(
      (response) => {
        // 'paymentwindow'
        this.paymentURLData = this.sanitizer.bypassSecurityTrustResourceUrl(response['data'].url);
        this.loader.hide();
      }
    )
  }

  resizeIframe(obj) {
    let iFrameID: any = document.getElementById('payment_window');
    if (iFrameID) {
      // this.counter = true;
      // here you can make the height, I delete it first, then I make it again
      iFrameID.height = "";
      let height = iFrameID.contentWindow.document.body.scrollHeight;
      iFrameID.height = height ? height + "px" : 600 + 'px';
    }
  }

  manualUpdate(image) {
    this.loader.show();
    let payload = {
      data: {
        "profile_image": image
      }
    }
    this.apiService
      .profileImageUpdate(this.ProfileData.user_id, payload)
      .subscribe((response: any) => {
        this.loader.hide();
      });
  }
}
@Component({
  selector: 'dialog-profile-dialog',
  templateUrl: 'dialog-profile-dialog.html',
})
export class DialogProfileDialog { }

<div class="loader d-flex justify-content-center" *ngIf="loaderOne">
    <div class="spinner-border text-white d-flex align-self-center" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
  
  <div class="row">
    <div class="col-md-12 mb-4">
      <div class="card border-0 rounded-0 h-100">
        <div class="card-title-hide">
          <!-- <h4>{{'Profile Details' | translate}}</h4> -->
        </div>
        <div class="card-body">
          <div class="card md-below mb-1 p-3">
            <p style="margin-top: 12px;font-size: 16px;"><i class="fa fa-arrow-left fa-theme mr-2 pointer" matTooltip="{{'Go Back' | translate}}" [matTooltipPosition]="'above'" [routerLink]="['/manage-users']"></i><b>{{'Package Information' | translate}}</b></p>
            <div class="col-md-12">
              <ul class="list-group list-group-flush">
                <li class="list-group-item">
                  <b>{{'Account Status' | translate}}</b>
                  <mat-slide-toggle style="float: right;" *ngIf="isChecked==true" (change)="onChange($event,userPackageInfo?.user_package.user_id)"
                  [(ngModel)]="isChecked"><span style="color: #1e9e95;">Active</span> </mat-slide-toggle>
                  <mat-slide-toggle style="float: right;" *ngIf="isChecked==false" (change)="onChange($event,userPackageInfo?.user_package.user_id)"
                  [(ngModel)]="isChecked"><span style="color: #1e9e95;">Inactive</span></mat-slide-toggle>
                </li>
                <li class="list-group-item">
                  <b>{{'Package Name' | translate}}</b><span style="float: right;text-transform: uppercase;">{{userPackageInfo?.user_package.packageinfo[0]?.name}}</span>
                </li>
                <li class="list-group-item">
                  <b>{{'Package Activated Date' | translate}}</b><span style="float: right;text-transform: uppercase;">{{userPackageInfo?.user_package?.beg_date | date}}
                  </span>
                </li>
                <li class="list-group-item">
                  <b>{{'Package Validity Date' | translate}}</b><span style="float: right;text-transform: uppercase;">{{userPackageInfo?.user_package?.end_date | date}}
                    <mat-icon (click)="openDialog(userPackageInfo?.user_package?.user_id,userPackageInfo?.user_package?.end_date,'expiry_date')" style="cursor: pointer;">edit</mat-icon>
                  </span>
                </li>
                <li class="list-group-item">
                  <b>{{'Total Allocated Delivery Agents' | translate}}</b><span style="float: right;text-transform: uppercase;">{{userPackageInfo?.used_emp}}
                  </span>
                </li>
                <li class="list-group-item">
                  <b>{{'Total Delivery Agents' | translate}}</b><span style="float: right;text-transform: uppercase;">{{userPackageInfo?.total_emp !== -1 ? userPackageInfo?.total_emp : "Unlimited"}}
                    <mat-icon (click)="openDialog(userPackageInfo?.user_package?.user_id,userPackageInfo.total_emp,'total_emp')" style="cursor: pointer;">edit</mat-icon>
                  </span>
                </li>
                <li class="list-group-item">
                  <b>{{'Total Tasks' | translate}}</b><span style="float: right;text-transform: uppercase;">{{userPackageInfo?.total_task}}
                    <mat-icon (click)="openDialog(userPackageInfo?.user_package?.user_id,userPackageInfo.total_task,'total_task')" style="cursor: pointer;">edit</mat-icon>
                  </span>
                </li>
                <!-- <li class="list-group-item">
                  <b>{{'Total Customer' | translate}}</b><span style="float: right;text-transform: uppercase;">{{userPackageInfo?.no_of_cust}}
                    <mat-icon (click)="openDialog(userPackageInfo?.userinfo?.user_id,userPackageInfo.no_of_cust,'no_of_cust')" style="cursor: pointer;">edit</mat-icon>
                  </span>
                </li> -->
                <li class="list-group-item">
                  <b>{{'Total Managers' | translate}}</b><span style="float: right;text-transform: uppercase;">{{userPackageInfo?.total_mgr}}
                  </span>
                </li>
                <li class="list-group-item">
                  <b>{{'Remaining Managers' | translate}}</b><span style="float: right;text-transform: uppercase;">{{userPackageInfo?.remaining_mgr}}</span>
                </li>
                <li class="list-group-item">
                  <b>{{'Remaining Delivery Agents' | translate}}</b><span style="float: right;text-transform: uppercase;">{{userPackageInfo?.total_emp !== -1 ? userPackageInfo?.remaining_emp : "Unlimited"}}</span>
                </li>
                <li class="list-group-item">
                  <b>{{'Remaining Tasks' | translate}}</b><span style="float: right;text-transform: uppercase;">{{userPackageInfo?.remaining_task}}</span>
                </li>
              </ul>
            </div>
            
          </div>
        </div>
        <div class="card-body">
          <div class="card md-below mb-1 p-3">
            <h5 class="text-info">{{'User Package Information' | translate}}</h5>
            <div class="col-md-12">
              <ul class="list-group list-group-flush">
                <li class="list-group-item">
                  <b>{{'Username' | translate}}</b><span style="float: right;text-transform: uppercase;">{{userPackageInfo?.user_package?.userinfo?.first_name}}</span>
                </li>
                <li class="list-group-item">
                  <b>{{'Phone Number' | translate}}</b><span style="float: right;text-transform: uppercase;">{{userPackageInfo?.user_package?.userinfo?.phone}}</span>
                </li>
                <li class="list-group-item">
                  <b>{{'Email' | translate}}</b><span style="float: right;text-transform: uppercase;">{{userPackageInfo?.user_package?.userinfo?.email}}</span>
                </li>
                
              </ul>
            </div>
            
          </div>
        </div>
      </div>
    </div>
  </div>
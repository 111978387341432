
<div class="container-fluid" style="margin-top: 10px;">
    <div class="col text-left">
      <h5 class="mb-4 text-muted"><i class="fa fa-arrow-left fa-theme mr-2 pointer" matTooltip="{{'Go Back' | translate}}" [matTooltipPosition]="'above'" [routerLink]="['/customer-review']"></i><b>{{'Customer Ratings' | translate}} </b></h5>
    </div>
  </div>
  <div class="container-fluid">
    <div class="card-body">
      <div class="row px-3 py-0">
        <div class="col-12 bg-light py-3">
          <div class="row justify-content-between">
            <div class="col-md">
              <div class="row">
                <ng-container *ngIf="userReviewList != []">
                  <h5 class="mb-4 text-muted" style="margin: 3px 0px 0px 10px;"><b>{{AgentName}} </b></h5>
                </ng-container>
                <ng-container *ngIf="userReviewList == []">
                  <h5 class="mb-4 text-muted"></h5>
                </ng-container>
              </div>
            </div>
            <div class="col-auto">
              <div class="row">
                <div class="col-auto">
                  <div class="input-group mb-3">
                    <input type="text" class="form-control" (keyup)="ApplyFilter($event)" placeholder="{{'Search' | translate}}">
                    <div class="input-group-append">
                      <span class="fa fa-search input-group-text pointer"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="card-body">
            <div class="table-responsive">
              <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8" style="width: 100%;border: 1px solid #ccc;">
                <ng-container matColumnDef="order_id">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header style="background-color:#1e9e95; color: white; font-size: 15px;">	{{'Order ID' | translate}} </th>
                  <td mat-cell *matCellDef="let element"> {{element.task_id}} </td>
                </ng-container>
                <!-- Date Column -->
                <ng-container matColumnDef="date">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header style="background-color:#1e9e95; color: white; font-size: 15px;"> {{'Date' | translate}} </th>
                  <td mat-cell *matCellDef="let element"> {{element.date | date}} </td>
                </ng-container>
                <!-- Name Column -->
                <!-- Date Column -->
                <ng-container matColumnDef="customer">
                  <th mat-header-cell *matHeaderCellDef  mat-sort-header style="background-color:#1e9e95; color: white; font-size: 15px;"> {{'Customer' | translate}} </th>
                  <td mat-cell *matCellDef="let element"> {{element.cust.name}} </td>
                </ng-container>
                <!-- Date Column -->
                <ng-container matColumnDef="rating">
                  <th mat-header-cell *matHeaderCellDef style="background-color:#1e9e95; color: white; font-size: 15px;"> {{'Ratings' | translate}} </th>
                  <td mat-cell *matCellDef="let element" >
                      <div class="stars">
                        <!-- <ngx-star-rating [(ngModel)]="element.stars" [disabled] ="true" [id]="'rating3'" ></ngx-star-rating > -->
                          <ngx-stars [readonly]="true" [size]="1" [initialStars]="element.stars" [customStarIcons]="heartIcons" matTooltip={{element.stars}} [matTooltipPosition]="'below'"></ngx-stars>
                      </div>
                  </td>
                </ng-container>
                <!-- Date Column -->
                <ng-container matColumnDef="review">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header style="background-color:#1e9e95; color: white; font-size: 15px;width: 30%;"> {{'Review' | translate}}  </th>
                  <td mat-cell *matCellDef="let element"> {{element.review}} </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </table>
              <div style="padding-top: 10px;" *ngIf="dataSource?.filteredData.length === 0"> 
                <p class="text-center">{{'No Records Found!' | translate}}</p><hr>
              </div>
              <mat-paginator #paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
<div class="container">
  <div class="row">
    <div class="col text-left">
      <!-- <p><i class="fa fa-arrow-left fa-theme mr-2 pointer" matTooltip="Go Back to profile" [matTooltipPosition]="'below'" routerLink="/profile"></i>Edit Profile</p> -->
    </div>
    <!--<div class="col text-right">
      <button type="button" routerLink="/change-password" class="btn btn-sm btn-outline-info rounded-0 mb-2 mr-2" matTooltip="click here"><i class="far fa-edit mr-1"></i>Change Password</button>
    </div>-->
  </div>
</div>
<div class="container" style="margin-top: 15px;">
  <div class="col text-left">
    <h5 class="mb-4"><b>{{'Edit Profile' | translate}} </b></h5>
  </div>
  <div class="row" style="margin-top: 15px;">
    <div class="col-md-12 col-lg-6 mb-4 align-items-stretch">
      <div class="form-group">
        <mat-form-field class="col-md-12" appearance="outline">
          <mat-label>{{'First Name' | translate}}</mat-label>
          <input matInput placeholder="{{'First Name' | translate}}" name="firstname" [(ngModel)]="Firstname" required>
        </mat-form-field>
      </div>
      <div class="form-group">
        <mat-form-field class="col-md-12" appearance="outline">
          <mat-label>{{'Phone Number' | translate}} </mat-label>
          <input matInput placeholder="{{'Phone Number' | translate}}" name="phone" [disabled]="true" [(ngModel)]="Phoneno" required>
        </mat-form-field>
      </div>
      <!-- <div class="form-group">
        <mat-form-field class="col-md-12" appearance="outline">
          <mat-label>Time Zone </mat-label>
          <input matInput placeholder="Time Zone" name="zone" [(ngModel)]="Timezone" required>
        </mat-form-field>
      </div> -->
      <div class="form-group">
        <mat-form-field class="col-md-12" appearance="outline">
          <mat-label>{{'Select Country' | translate}}</mat-label>
          <mat-select [(ngModel)]="SelectedCountry" [disabled]="false" name="countryselect" (selectionChange)="TimezoneChange($event)" required>
            <!-- <mat-option>
              <ngx-mat-select-search [formControl]="bankFilterCtrl"></ngx-mat-select-search>
            </mat-option> -->
            <mat-option *ngFor="let country of CountryList" [value]="country.country_code">
              {{country.country_name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="form-group">
        <mat-form-field class="col-md-12" appearance="outline">
          <mat-label>{{'Select Timezone' | translate}}</mat-label>
          <mat-select [(ngModel)]="Timezone" name="timezoneselect" [disabled]="false" [value]="Timezone" required>
            <mat-option *ngFor="let zone of CountryBasedTimezones" [value]="zone.desc">
              {{zone.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="form-group">
        <mat-form-field class="col-md-12" appearance="outline">
          <mat-label>{{'Default Pickup Address' | translate}} </mat-label>
          <!-- <input matInput placeholder="Pickup Address" name="pick_address" [(ngModel)]="DefPickAddress" required> -->
          <input matInput placeholder="{{'Pickup Address' | translate}}" name="pick_address" [value]="DefPickAddress" matGoogleMapsAutocomplete
          (onAutocompleteSelected)="onAutocompleteSelected1($event)"
          (onLocationSelected)="onLocationSelected1($event)" (input)="onChange($event)" style="width: 90%;">
        </mat-form-field>
        <img src="./assets/img/location.svg" alt="Profile Edit" style="height: 30px;width: 30px;float: right;margin-top: -70px;overflow: hidden;position: relative;right:20px;" class="map-icon" (click)="showmap1(1)" *ngIf="agmshowmap1 == 0">
        <img src="./assets/img/location-disabled.svg" alt="Profile Edit" style="height: 30px;width: 30px;float: right;margin-top: -70px;overflow: hidden;position: relative;right:20px;" class="map-icon" (click)="showmap1(0)" *ngIf="agmshowmap1 == 1">
          <agm-map style="padding: 0px 15px;" [latitude]="pick_latitude" [longitude]="pick_longitude" [zoom]="zoom" *ngIf="agmshowmap1 == 1">
            <agm-marker [latitude]="pick_latitude" [longitude]="pick_longitude" [markerDraggable]="true"
              (dragEnd)="markerDragEndNew($event)"></agm-marker>
          </agm-map>
      </div>
      <div class="form-group">
        <mat-form-field class="col-md-12" appearance="outline">
          <mat-label>{{'Google Map API Key' | translate}}</mat-label>
          <input matInput placeholder="{{'Google Map API Key' | translate}}" [disabled]="false" name="mapApiKey" [(ngModel)]="MapApiKey">
        </mat-form-field>
      </div>
      <div class="form-group col-md-12">
        <mat-checkbox color='primary' [(ngModel)]='pdpa_ompilance'> {{'PDPA Compliance' | translate}}</mat-checkbox>
      </div>
      <!-- <div class="form-group col-md-12">
        <mat-checkbox color='primary' [(ngModel)]='task_based' > Is Task Based Tracking</mat-checkbox>
      </div>
      <div class="form-group col-md-12">
        <mat-checkbox checked="is_redline" color='primary' [(ngModel)]='is_redline' > Is Redline Flag Checked</mat-checkbox>
      </div> -->
    </div>
    <div class="col-md-12 col-lg-6 mb-4 align-items-stretch">
      <div class="form-group">
        <mat-form-field class="col-md-12" appearance="outline">
          <mat-label>{{'Last Name' | translate}} </mat-label>
          <input matInput placeholder="{{'Last Name' | translate}}" name="lastname" [(ngModel)]="Lastname" required>
        </mat-form-field>
      </div>
      <div class="form-group">
        <mat-form-field class="col-md-12" appearance="outline">
          <mat-label>{{'Email' | translate}} </mat-label>
          <input matInput placeholder="{{'Email' | translate}}" name="email" [(ngModel)]="Email" [disabled]="true" required>
        </mat-form-field>
      </div>
      <div class="form-group">
        <mat-form-field class="col-md-12" appearance="outline">
          <mat-label>{{'Business Name' | translate}}</mat-label>
          <input matInput placeholder="{{'Business Name' | translate}}" [disabled]="false" name="businessname" [(ngModel)]="BusinessName" required>
        </mat-form-field>
      </div>
      <div class="form-group">
        <mat-form-field class="col-md-12" appearance="outline">
          <mat-label>{{'Business Type' | translate}}</mat-label>
          <mat-select [(ngModel)]="Business_Type"  name="busitype" required>
            <mat-option *ngFor="let busi of BusinessType" [value]="busi.value">
              {{busi.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="form-group">
        <mat-form-field class="col-md-12" appearance="outline">
          <mat-label>{{'Select Task' | translate}}</mat-label>
          <mat-select [(ngModel)]="SelectedTask" name="taskselect">
            <mat-option *ngFor="let task of Selection" [value]="task.value">
              {{task.Task}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="form-group">
        <mat-form-field class="col-md-12" appearance="outline">
          <mat-label>{{'Company Location' | translate}} </mat-label>
          <!-- <input matInput placeholder="Enter Loction" name="location" [(ngModel)]="Location" required> -->
          <!-- <input matInput placeholder="Enter Loction" name="location" [value]="Location" [(ngModel)]="Location" required matGoogleMapsAutocomplete
          (onAutocompleteSelected)="onAutocompleteSelected($event)"
          (onLocationSelected)="onLocationSelected($event)" required> -->
          <input matInput type="text" class="form-control map-icon-input" matGoogleMapsAutocomplete
          (onAutocompleteSelected)="onAutocompleteSelected($event)"
          (onLocationSelected)="onLocationSelected($event)" placeholder="{{'Enter Location' | translate}}" autocorrect="off" autocapitalize="off" spellcheck="off" type="text" #search [value]="Location" name="location" required style="width: 90%; border: none !important;outline: none !important;box-shadow: none !important;background: none !important;overflow: hidden;">
          <!-- <input matInput type="text" class="form-control map-icon-input" (keydown.enter)="$event.preventDefault()" placeholder="Enter Loction" autocorrect="off" autocapitalize="off" spellcheck="off" type="text" #search [value]="Location" [(ngModel)]="Location" name="location" required style="width: 90%; border: none !important;outline: none !important;box-shadow: none !important;background: none !important;overflow: hidden;"> -->
        
        </mat-form-field>
        <img src="./assets/img/location.svg" alt="Profile Edit" style="height: 30px;width: 30px;float: right;margin-top: -80px;overflow: hidden;position: relative;right:20px;" class="map-icon" (click)="showmap(1)" *ngIf="agmshowmap == 0">
        <img src="./assets/img/location-disabled.svg" alt="Profile Edit" style="height: 30px;width: 30px;float: right;margin-top: -80px;overflow: hidden;position: relative;right:20px;" class="map-icon" (click)="showmap(0)" *ngIf="agmshowmap == 1">
        <agm-map style="padding: 0px 15px;" [latitude]="latitude" [longitude]="longitude" [zoom]="zoom" *ngIf="agmshowmap == 1">
          <agm-marker [latitude]="latitude" [longitude]="longitude" [markerDraggable]="true"
            (dragEnd)="markerDragEnd($event)"></agm-marker>
        </agm-map>
      </div>
    </div>
  </div>
</div>
<div class="container">
  <div class="row">
    <div class="col text-right">
      <button type="button" class="btn btn-outline-info rounded-0 mb-2 mr-2" (click)="Cancel()"><b>{{'Cancel' | translate}}</b></button>
      <button type="button" class="btn btn-info rounded-0 mb-2" (click)="UpdateProfile()"><b>{{'Save Changes' | translate}}</b></button>
    </div>
  </div>
</div>
import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders, HttpErrorResponse } from "@angular/common/http";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { FormGroup } from '@angular/forms';
import { ApiService } from './../../services/ApiService';
import { AppService } from './../../services/AppService';
import { AuthService } from './../../services/AuthService';
import { Config } from '../../config/config';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as moment from 'moment-timezone';

export interface PeriodicElement {
  sip_id: number;
  first_name: string;
  last_name: string;
  email: string;
  contact_no: string
}

export interface DialogData {
  animal: string;
  name: string;
}

@Component({
  selector: 'app-supervisor',
  templateUrl: './supervisor.component.html',
  styleUrls: ['./supervisor.component.css'],
})
export class SupervisorComponent implements OnInit {

  @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
 
  isOpen: boolean = false;
  modelTitle: any;
  Supervisordata: any = [];
  displayedColumns = ['sup_id', 'first_name', 'last_name', 'email', 'contact_no', 'manager', 'Actions','Status'];  
  displayedColumns1 = ['sup_id', 'first_name', 'last_name', 'email', 'contact_no', 'Actions','Status'];  
  // page: number = 1;
  // limit: number = 10;
  Supervisorlist: any;
  dataSource: MatTableDataSource<any>;
  NewPassword: any;
  ConfPassword: any;
  Emp_ID: string;
  ButtonShow = true;
  checkUserExpires: any;
  isExpiredTwodays = false;
  isExpired = false;
  userData: any;
  managershow: boolean;

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private loader: NgxSpinnerService,
    private toastr: ToastrService,
    private authService: AuthService,
    public appService: AppService,
    public apiService: ApiService,
    public dialog: MatDialog,
    private snackBar:MatSnackBar,
  ) {}

  baseUrl = Config.BASE_URL;
  apiUrl = this.baseUrl + "api/";
  animal: string;
  name: string;
  isDeleteModel = false;
  deleteItem: any;
  Search:any = "";
  ngOnInit() {
    this.apiService.userLists().subscribe((response: any) => {
      this.Supervisorlist = response.data;
      this.dataSource = new MatTableDataSource(this.Supervisorlist);
      setTimeout(() => this.dataSource.paginator = this.paginator);
      this.dataSource.sort = this.sort;
    })

    this.userData = JSON.parse(localStorage.getItem("user_data"));
    if(this.userData.profile.role_id == 4){
      this.apiService.EmployeeLimitCheckforMgr(this.userData.profile.role_id).subscribe((response: any) => {
        if(response.status == "ok"){
          this.ButtonShow = true;
        }else{
          this.ButtonShow = false;
        }
      });
      this.managershow = true;
    }else{
      this.apiService.EmployeeLimitCheck().subscribe((response: any) => {
        if(response.status == "ok"){
          this.ButtonShow = true;
        }else{
          this.ButtonShow = false;
        }
      });
      this.managershow = false;
    }

   

      this.apiService
        .checkUsers()
        .subscribe((response: any) => {
          this.checkUserExpires = response[0];
          var _date = new Date();
          var current_date = moment(_date).format("YYYY-MM-DD");
          var end_date = moment(this.checkUserExpires.end_date);
          var end_date1 = end_date.format("YYYY-MM-DD");
          var two_days_b4 = end_date.subtract(2, "days").format("YYYY-MM-DD");
          var one_days_b4 = end_date.add(1, "days").format("YYYY-MM-DD");
          var checkBetween = moment(current_date).isBetween(two_days_b4, end_date1);
          var checkIsSame = moment(end_date1).isSame(current_date);
          var checkIsBefore = moment(end_date1).isBefore(current_date);
          if(checkBetween || checkIsSame){
            this.isExpired = false;
            this.isExpiredTwodays = true;
          }else{
            if(checkIsBefore){
              this.isExpired = true;
              this.isExpiredTwodays = false;
            }
          }
          this.loader.hide();
        });
    }

  ngAfterViewInit() {

  }
  // pageCount(limit: any) {
  //   this.limit = limit;
  //   this.page = 1;
  //   let params = {
  //     limit: this.limit,
  //     page: this.page
  //   }
  //   this.loadData(params);
  // }
  // pageNumber(page: any) {
  //   if (page >= 1 && page <= this.sourceData.last_page) {
  //     this.page = page;
  //   }
  //   let params = {
  //     limit: this.limit,
  //     page: this.page
  //   }
  //   this.loadData(params);
  // }
  loadData(params) {
  
  }
  password_change(obj: any,id:any){
    localStorage.setItem("sup_id",id);
    this.modelTitle = obj;
    this.isOpen = true;
  }
  EditSupervisor(id:any){
    localStorage.setItem("sup_id",id);
    this.router.navigate(["edit-delivery-agent"]);
  }

  ViewSupervisor(id:any){
    localStorage.setItem("sup_id",id);
    this.router.navigate(["delivery-agent-detail"]);
  }

  
  DeleteModel(element: any) {
    this.deleteItem = element;
    this.isDeleteModel = true;
  }

  DeleteSupervisor(){
    this.loader.show();
    this.apiService
      .supervisorDelete(this.deleteItem)
      .subscribe((response: any) => {
        if(response.status == "ok"){
          this.loadItems();
          this.toastr.success(response.data);
        }else{
          this.toastr.warning(response.data);
          this.loader.hide();
        }
        this.isDeleteModel = false;
      });
  }

  onClose(){
    this.deleteItem = undefined;
    this.isDeleteModel = false;
  }

  onClosed(){
    this.modelTitle = undefined;
    this.isOpen = false;
  }

  loadItems(){
    this.loader.show();
    this.apiService
      .userLists()
      .subscribe((response: any) => {
        this.Supervisorlist = response.data;
        this.dataSource = new MatTableDataSource(this.Supervisorlist);
        setTimeout(() => this.dataSource.paginator = this.paginator);
        this.loader.hide();
      });
  }

  ChangePassword(){

    if(this.NewPassword == undefined || this.NewPassword == ''){
      this.snackBar.open("New Password is Required","Close",{
        duration: 4000,
        verticalPosition:'bottom'
      })
      return 0;
    }

    if(this.ConfPassword == undefined || this.ConfPassword == ''){
      this.snackBar.open("Confirm Password is Required","Close",{
        duration: 4000,
        verticalPosition:'bottom'
      })
      return 0;
    }

    if(this.NewPassword != this.ConfPassword){
      this.snackBar.open("Password does not Match","Close",{
        duration: 4000,
        verticalPosition:'bottom'
      })
      return 0;
    }

    this.Emp_ID = localStorage.getItem("sup_id")
    var data = {
      data: {
          "new_password": this.NewPassword,
          "confirm_password": this.ConfPassword,
          "user_id": this.Emp_ID
      }
  }
  this.apiService.adminResetPassword(data).subscribe((response: any) => {
    if(response.status == "ok"){
      this.toastr.success(response.data);
    this.loader.hide();
    this.isOpen = false;
    this.ngOnInit();
    }else{
      this.toastr.warning(response.data);
    this.loader.hide();
    }
  })
}

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  //   DeleteModel(element: any) {
  //   this.deleteItem = element;
  //   this.isDeleteModel = true;
  // }


  new_password() {
    let x = document.getElementById("new-password") as HTMLInputElement;
    if (x.type == "password") {
      document.getElementById("new-eye-open").className = "fa fa-sm fa-eye field-icon toggle-password new-view-hide";
      x.type = "text";
    } else {
      document.getElementById("new-eye-open").className = "fa fa-sm fa-eye-slash field-icon toggle-password new-view-hide";
      x.type = "password";
    }
  }
  confirm_password() {
    let x = document.getElementById("confirm-password") as HTMLInputElement;
    if (x.type == "password") {
      document.getElementById("confirm-eye-open").className = "fa fa-sm fa-eye field-icon toggle-password same-view-hide";
      x.type = "text";
    } else {
      document.getElementById("confirm-eye-open").className = "fa fa-sm fa-eye-slash field-icon toggle-password same-view-hide";
      x.type = "password";
    }
  }
}
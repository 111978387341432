<div class="loader d-flex justify-content-center" *ngIf="loaderOne">
    <div class="spinner-border text-white d-flex align-self-center" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
  
  <div class="row">

    <div class="invoice-box" style="width:100%;">
		<p style="margin-top: 12px;font-size: 16px;"><i class="fa fa-arrow-left fa-theme mr-2 pointer" matTooltip="{{'Go Back' | translate}}" [matTooltipPosition]="'above'" [routerLink]="['/payment-history']"></i><b>{{'Payment Information' | translate}}</b></p>
			<table cellpadding="0" cellspacing="0">
				<tr class="top">
					<td colspan="2">
						<table>
							<tr>
								<td class="title">
									<img src="./assets/img/logo.png" style="width: 100%; max-width: 150px" />
								</td>

								<td>
									Invoice #: {{paymentDetails?.id}}<br />
									Created: {{paymentDetails?.created_at | date}}<br />
									Due: {{paymentDetails?.due_date | date}}
								</td>
							</tr>
						</table>
					</td>
				</tr>

				<tr class="information">
					<td colspan="2">
						<table>
							<tr>
								<td>
                                    ManageTeamz<br />
                                    +91 73387 73388 <br/>
                                    bd@manageteamz.com

									<!-- 12345 Sunny Road<br /> -->
									<!-- Sunnyville, CA 12345 -->
								</td>

								<td>
									{{paymentDetails?.user?.first_name}} {{paymentDetails?.user?.last_name}}<br />
									{{paymentDetails?.user?.phone}}<br />
									{{paymentDetails?.user?.email}}
								</td>
							</tr>
						</table>
					</td>
				</tr>

				<tr class="heading">
					<td>Payment Method</td>

					<td>Online #</td>
				</tr>

				<tr class="details">
					<td>Amount</td>

					<td>{{paymentDetails?.currency}} {{paymentDetails?.amount}} </td>
				</tr>

				<tr class="heading">
					<td>Item</td>

					<td>Price</td>
				</tr>

				<tr class="item">
					<td>{{paymentDetails?.package?.name}}</td>

					<td>$ {{paymentDetails?.package?.price}} (AED {{paymentDetails?.package?.price_in_aed}})</td>
				</tr>

				<tr class="total">
					<td></td>
					<td>Total: {{paymentDetails?.currency}} {{paymentDetails?.amount}}</td>
				</tr>
			</table>
		</div>



    <!-- <div class="col-md-12 mb-4">
      <div class="card border-0 rounded-0 h-100">
        <div class="card-title-hide">
        </div>
        <div class="card-body">
          <div class="card md-below mb-1 p-3">
            <h5 class="text-info">{{'Payment Information' | translate}}</h5>
            <div class="col-md-12">
              <ul class="list-group list-group-flush">
                <li class="list-group-item">
                  <b>{{'Payment ID' | translate}}</b><span style="float: right;text-transform: uppercase;">{{paymentDetails?.id}}</span>
                </li>
                <li class="list-group-item">
                  <b>{{'Merchant ID' | translate}}</b><span style="float: right;text-transform: uppercase;">{{paymentDetails?.merchant_id}}
                  </span>
                </li>
                
                <li class="list-group-item">
                  <b>{{'Sales Order ID' | translate}}</b><span style="float: right;text-transform: uppercase;">{{paymentDetails?.sales_order_id}}
                  </span>
                </li>
                <li class="list-group-item">
                  <b>{{'Status' | translate}}</b><span style="float: right;text-transform: uppercase;">{{paymentDetails?.status}}
                  </span>
                </li>
                <li class="list-group-item">
                  <b>{{'Username' | translate}}</b><span style="float: right;text-transform: uppercase;">{{paymentDetails?.userinfo?.first_name}}</span>
                </li>
                <li class="list-group-item">
                    <b>{{'Purchased Plan' | translate}}</b><span style="float: right;text-transform: uppercase;">{{paymentDetails?.packageinfo?.name}}</span>
                  </li>
                <li class="list-group-item">
                  <b>{{'Amount' | translate}}</b><span style="float: right;text-transform: uppercase;">{{paymentDetails?.amount}}</span>
                </li>
                <li class="list-group-item">
                  <b>{{'Currency' | translate}}</b><span style="float: right;text-transform: uppercase;">{{paymentDetails?.currency}}</span>
                </li>
                <li class="list-group-item">
                  <b>{{'Payment Date' | translate}}</b><span style="float: right;text-transform: uppercase;">{{paymentDetails?.created_at | date}}</span>
                </li>

              </ul>
            </div>
            
          </div>
        </div>
      </div>
    </div> -->
  </div>
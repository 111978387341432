<div class="container-fluid">
    <div class="container">
      <div class="row" style="margin-top: 15px;">
        <div class="col-md-10 col-lg-10 mb-4 align-items-stretch">
          <p style="margin-top: 12px;font-size: 16px;"><i class="fa fa-arrow-left fa-theme mr-2 pointer" matTooltip="{{'Go Back' | translate}}" [matTooltipPosition]="'above'" [routerLink]="['/manager']"></i><b>{{'Manager Detail' | translate}}</b></p>
          <!-- <h5 class="mb-4"><b>Delivery Agent Detail </b></h5> -->
        </div>
        <!-- <div class="col-md-2 col-lg-2 mb-4 align-items-stretch" *ngIf="userData.profile.user_id != sup_id">
          <button type="button" class="btn btn-sm rounded-0 mb-2 mr-2 float-right"
            matTooltip="{{Active == 0 ? 'Active Agent' : 'Delete Agent'}}" 
            [ngClass]="{'btn-success': Active == 0 ,'btn-danger': Active == 1 }"  
            (click)="Active == 0 ? ActiveAgent(0) : ActiveAgent(1)">
            {{Active == 0 ? 'Mark as Active': 'Mark as Delete'}}
          </button>
        </div> -->
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="col-md-12 col-lg-6 mb-4 align-items-stretch" style="float: left;">
            <div class="form-group">
              <mat-form-field style="width: 100%;" appearance="outline">
                <mat-label>{{'Emp Id' | translate}}</mat-label>
                <input matInput value="{{User_id}}" disabled>
              </mat-form-field>
            </div>
          </div>
          <div class="col-md-12 col-lg-6 mb-4 align-items-stretch" style="float: left;">
            <div class="form-group" style="color: black;">
              <mat-form-field style="width: 100%;" appearance="outline">
                <mat-label>{{'First Name' | translate}}</mat-label>
                <input matInput value="{{FirstName}}" disabled>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <div class="col-md-12 col-lg-6 mb-4 align-items-stretch" style="float: left;">
            <div class="form-group">
              <mat-form-field style="width: 100%;" appearance="outline">
                <mat-label>{{'Last Name' | translate}}</mat-label>
                <input matInput value="{{LastName}}" disabled>
              </mat-form-field>
            </div>
          </div>
          <div class="col-md-12 col-lg-6 mb-4 align-items-stretch" style="float: left;">
            <div class="form-group" style="color: black;">
              <mat-form-field style="width: 100%;" appearance="outline">
                <mat-label>{{'Contact No' | translate}}</mat-label>
                <input matInput value="{{Phone}}" disabled>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <div class="col-md-12 col-lg-6 mb-4 align-items-stretch" style="float: left;">
            <div class="form-group">
              <mat-form-field style="width: 100%;" appearance="outline">
                <mat-label>{{'WhatsApp Number' | translate}}</mat-label>
                <input matInput value="{{WhatsApp}}" disabled>
              </mat-form-field>
            </div>
          </div>
          <div class="col-md-12 col-lg-6 mb-4 align-items-stretch" style="float: left;">
            <div class="form-group" style="color: black;">
              <mat-form-field style="width: 100%;" appearance="outline">
                <mat-label>{{'Email' | translate}}</mat-label>
                <input matInput value="{{Email}}" disabled>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <!-- <div class="col-md-12 col-lg-6 mb-4 align-items-stretch" style="float: left;">
            <div class="form-group">
              <mat-form-field style="width: 100%;" appearance="outline">
                <mat-label>GPS Tracking</mat-label>
                <input matInput value="{{GPSTracking}}" disabled>
              </mat-form-field>
            </div>
          </div> -->
          <div class="col-md-12 col-lg-6 mb-4 align-items-stretch" style="float: left;">
            <div class="form-group" style="color: black;">
              <mat-form-field style="width: 100%;" appearance="outline">
                <mat-label>{{'Permanent Address' | translate}}</mat-label>
                <input matInput value="{{Address}}" disabled title="{{Address}}">
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <div class="col-md-12 col-lg-6 mb-4 align-items-stretch" style="float: left;">
            <mat-label>{{'Profile Picture' | translate}}</mat-label>
            <div class="profile-img" *ngIf="ProfileImage">
              <div class="avatar-square" *ngIf="ProfileImage.length === 2">
                <span class="initials">{{FirstName[0] | uppercase }}{{LastName[0] | uppercase }}</span>
              </div>
              <div class="avatar-square-img" *ngIf="ProfileImage.length > 2" 
                [ngStyle]="{'background-image': 'url(' + ProfileImage + ')', 
                'background-size': 'cover', 'background-position': 'center'}">
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  </div>  
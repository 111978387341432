import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, tap } from "rxjs/operators";
import { Router } from '@angular/router';
import { AuthService } from '../AuthService';
import { AppService } from '../AppService';

@Injectable()
export class TokenInterceptorService implements HttpInterceptor {
  constructor(
    public auth: AuthService,
    public appService: AppService,
    public router: Router
  ) { }

  private cache = new Map<string, any>();
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.auth.getToken()) {
      request = request.clone({
        setHeaders: {
          "x-client-data": `${this.auth.getClientData()}`,
          "Authorization": `${this.auth.getToken()}`,
        }
      });
    } else {
      let randomString = `${this.appService.getRandomString(10)}`;
      request = request.clone({
        setHeaders: {
          "x-client-data": `${randomString}`,
          "x-client-date": new Date().toString(),
          "Mobile": "android",
          "x-location-filter": "true",
        }
      });
      localStorage.setItem(
        "temp_client_data", randomString
      );
    }
    // return next.handle(request);
    return next.handle(request).pipe(tap(() => {},(err: any) => {
      if(err instanceof HttpErrorResponse){
        if(err.status == 401){
          localStorage.removeItem("access_token");
          localStorage.removeItem("user_data");
          localStorage.removeItem("client_data");
          localStorage.removeItem("driver_details");
          localStorage.removeItem("cust_id");
          localStorage.removeItem("order_detail_id");
          localStorage.removeItem("sup_id");
          localStorage.removeItem("item_id");
          localStorage.removeItem("zoom_control");
          window.location.reload();
        } 
      }
    }));
  }
}
import { Component, OnInit } from '@angular/core';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { DatePipe } from '@angular/common';
import { AppService } from 'src/app/services/AppService';
import { ApiService } from 'src/app/services/ApiService';
import { SharedFunctionService } from 'src/app/services/SharedFunctionService';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-edit-order',
  templateUrl: './edit-order.component.html',
  styleUrls: ['./edit-order.component.css'],
})
export class EditOrderComponent implements OnInit {
  userData: any;
  orderId: any;
  isDeliveryLogic:any;
  constructor(
    public appService: AppService,
    public apiService: ApiService,
    public sharedfunction: SharedFunctionService,
    public datepipe: DatePipe,
    private actRoute: ActivatedRoute
    
  ) {
    this.actRoute
      .queryParams
      .subscribe(v =>
        this.orderId = v.id);
  }

  ngOnInit(){
    this.apiService
      .orderDetails(this.orderId)
      .subscribe((response: any) => {
        console.log(response,'api response');
        console.log(response.data.delivery_logic, 'delivery_logic');
        this.isDeliveryLogic = response.data.delivery_logic;

      });
  }
  changeOption(){
    console.log(this.isDeliveryLogic);
  }
  changeBack(obj: any){
    console.log(obj);
  }
}

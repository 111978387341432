import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from './../../services/ApiService';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FormGroup } from '@angular/forms';
import { FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-customer-rating',
  templateUrl: './customer-rating.component.html',
  styleUrls: ['./customer-rating.component.css']
})
export class CustomerRatingComponent implements OnInit {

  @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  rating3: number;
  public form: FormGroup;
  displayedColumns = ['order_id', 'date', 'customer', 'rating', 'review'];
  userReviewList: any;
  dataSource: MatTableDataSource<any>;
  AgentName: any;

  constructor(
    public apiService: ApiService,
    private fb: FormBuilder
  ) {
    this.rating3 = 0;
    this.form = this.fb.group({
      rating1: ['', Validators.required],
      rating2: [4]
    });
   }

  ngOnInit() {
    var uesrid = localStorage.getItem('custuser_id')
    this.apiService
      .userReview(uesrid)
      .subscribe((response: any) => {
        this.userReviewList = response.data;
        if(this.userReviewList.length == 0){
          this.AgentName = "";
        }else{
          this.AgentName = this.userReviewList[0].emp_info;
        }
        this.dataSource = new MatTableDataSource(this.userReviewList);
        setTimeout(() => this.dataSource.paginator = this.paginator);
        this.dataSource.sort = this.sort;
      })
  }

  ApplyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

}

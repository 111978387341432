<app-cs-navbar></app-cs-navbar>
<div class="container-fluid form-container">
  <div class="row">
    <div class="col-md-6 content-center img-mobile-fit">
      <div class="custom-center">
        <h3 class="text-white text-center text-size mb-4">
          <img src="/assets/img/collections/phone.png" class="form-img mr-2">
          <strong>+917338773388</strong>
        </h3>
        <h3 class="text-white text-center text-size mb-4">
          <img src="/assets/img/collections/email.png" class="form-img mr-2">
          <strong>bd@manageteamz.com</strong>
        </h3>
        <!-- <h3 class="text-white text-center text-size mb-4">
          <a href="#" class="btn btn-outline-success">
            <img src="/assets/img/collections/live-chat.png" class="form-img mr-2">
            <strong>Live Chat</strong>
          </a>
        </h3> -->
      </div>
    </div>
    <div class="col-md-6 content-center">
      <div class="card rounded-card">
        <div class="card-body custom-size">
          <h4 class="text-center mb-4">
            <strong>{{'Get Started for Free' | translate}}</strong>
          </h4>
          <form data-bitwarden-watching="1" (ngSubmit)="onSubmit()" #signupForm="ngForm">
            <i class="fa fa-arrow-left fa-theme mr-2 pointer" *ngIf="isVerify" matTooltip="{{'Go Back' | translate}}" [matTooltipPosition]="'above'" (click)="GoBack()"></i>
            <div class="form-group" *ngIf="!isVerify">
              <label>{{'First Name' | translate}}</label>
              <input type="text" class="form-control"
                [(ngModel)]="user.first_name"
                #first_name="ngModel"
                name="first_name"
                required>
              <div *ngIf="first_name.invalid && (first_name.dirty)">
                <p
                  class="error-box margin-top-0 text-danger"
                  *ngIf="first_name.hasError('required')"
                >
                  {{'Firstname is Required' | translate}}
                </p>
              </div>
            </div>
            <div class="form-group" *ngIf="!isVerify">
              <label>{{'Last Name' | translate}}</label>
              <input type="text" class="form-control"
                [(ngModel)]="user.last_name"
                #last_name="ngModel"
                name="last_name"
                required>
              <div *ngIf="last_name.invalid && (last_name.dirty)">
                <p
                  class="error-box margin-top-0 text-danger"
                  *ngIf="last_name.hasError('required')"
                >
                  {{'Lastname is Required' | translate}}
                </p>
              </div>
            </div>
            <div class="form-group" *ngIf="!isVerify">
              <label>{{'Email Address' | translate}}</label>
              <input type="text" class="form-control"
                [(ngModel)]="user.email" (input)="validateEmail($event)"
                name="email"
                required>
              <div>
                <p
                  class="error-box margin-top-0 text-danger"
                  *ngIf="invalid_email"
                >
                {{'Email must be a valid email address' | translate}}
                </p>
                <p class="error-box margin-top-0 text-danger" *ngIf="email_required">
                  {{'Email is Required' | translate}}
                </p>
              </div>
            </div>
            <div class="form-group">
              <label>{{'Mobile Number' | translate}}</label>
              <ngx-intl-tel-input class="custom-intl-tel-input"
                [preferredCountries]="preferredCountries"
                [enableAutoCountrySelect]="false"
                [enablePlaceholder]="false"
                [searchCountryFlag]="true"
                [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                [selectFirstCountry]="false"
                [selectedCountryISO]="selectedCountry"
                [maxLength]="15"
                [tooltipField]="TooltipLabel.Name"
                [separateDialCode]="true"
                [(ngModel)]="user.phone"
                [disabled]="phoneshow"
                #phone="ngModel"
                name="phone"
                required
                >
              </ngx-intl-tel-input>
              <div *ngIf="phone.invali">
                <p
                  class="error-box margin-top-0 text-danger"
                  *ngIf="phone.hasError('required')"
                >
                  {{'Mobile number is required' | translate}}
                </p>
              </div>
            </div>
            <br>
            <div class="form-group" *ngIf="!isVerify">
              <button type="submit" [disabled]="!signupForm.valid || email_required || invalid_email" class="btn btn-info btn-lg btn-block">{{'Free Sign Up' | translate}}</button>
            </div>
          </form>
          <form data-bitwarden-watching="2" #signupFormVerify="ngForm">
            
            <!-- <div class="form-group">
              <label>Mobile Number</label>
              <ngx-intl-tel-input class="custom-intl-tel-input"
                [preferredCountries]="preferredCountries"
                [enableAutoCountrySelect]="false"
                [enablePlaceholder]="false"
                [searchCountryFlag]="true"
                [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                [selectFirstCountry]="false"
                [selectedCountryISO]="CountryISO.India"
                [maxLength]="15"
                [tooltipField]="TooltipLabel.Name"
                [separateDialCode]="true"
                [(ngModel)]="user.phone"
                #phone="ngModel"
                name="phone"
                required>
              </ngx-intl-tel-input>
              <div *ngIf="phone.invalid">
                <p
                  class="error-box margin-top-0 text-danger"
                  *ngIf="phone.hasError('required')"
                >
                  Mobile number is required
                </p>
              </div>
            </div>            -->
            <div class="form-group" style="float: left;width: 30%;" *ngIf="isVerify">
              <label>{{'Enter OTP' | translate}}</label>
              <input type="text" class="form-control"
                [(ngModel)]="verify.otppin"
                #otppin="ngModel"
                (keypress)="omit_char($event);"
                name="otppin"
                maxlength="5"
                required>
                
                <!-- <button type="submit" class="btn btn-info btn-lg btn-block resendotp">Resend OTP</button> -->
              <div *ngIf="otppin.invalid && (otppin.dirty)">
                <p
                  class="error-box margin-top-0 text-danger"
                  *ngIf="otppin.hasError('required')"
                >
                  {{'OTP is Required' | translate}}
                </p>
              </div>
              
            </div>
            <div class="form-group" style="float: left;margin: 9% 0 0 2%;" *ngIf="isVerify">
              <mat-radio-group [(ngModel)]="otpsign.option"
                #option="ngModel"
                name="option"
                style="float: left;"
                required>
                <mat-radio-button value="sms" class="mr-4">{{'SMS' | translate}}</mat-radio-button>
                <mat-radio-button value="voice" class="mr-4">{{'Voice Call' | translate}}</mat-radio-button>
            </mat-radio-group>
            </div>
            <br>
            <button class="btn btn-info btn-sm" *ngIf="isVerify" style="float: right;" (click)="ResendOTP()" style="margin: 3% 0 0 0;">{{'Resend OTP' | translate}}</button>
            
            <div class="form-group" style="margin-top: 20px;" *ngIf="isVerify">
              <button [disabled]="!signupFormVerify.valid" (click)="verifySubmit()" class="btn btn-info btn-lg btn-block">{{'Verify OTP' | translate}}</button>
            </div>
          </form>
          <div class="form-group forget-password">
            <div class="row">
              <div class="col-6 form-text-size">
              </div>
              <div class="col-6 form-text-size">
                <a href="#" routerLink="/mobile-login" class="text-info float-right">{{'Go to Login?' | translate}}</a>              
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid service-container" style="background-color: #2e3c52;color: #fff;">
  <div class="row">
    <div class="col-md-6 text-left">
      <ul class="list-unstyled mt-3">
        <li>
          <div class="wrap">
            <div class="ico-wrap">
              <span class="mbr-iconfont">
                <img src="/assets/img/location.png" class="service-img mr-4" style="margin-top: 11px;">
              </span>
            </div>
            <div class="vcenter">
              <h4 class="text-left">
                <strong>{{'Real Time Tracking' | translate}}</strong>
              </h4>
              <p>
                {{'Monitor your delivery business with real time tracking; Customer will be informed with real time notifications with web link.' | translate}}
              </p>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="col-md-6 text-left">
      <ul class="list-unstyled mt-3">
        <li>
          <div class="wrap">
            <div class="ico-wrap">
              <span class="mbr-iconfont">
                <img src="/assets/img/vehicle.png" class="service-img mr-4" style="margin-top: 12px;">
              </span>
            </div>
            <div class="vcenter">
              <h4 class="text-left">
                <strong>{{'Easy Delivery Management' | translate}}</strong>
              </h4>
              <p>
                {{'Manage real time status and locations of your delivery on the map. You can assign new tasks and manage your business productively.' | translate}}
              </p>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6 text-left">
      <ul class="list-unstyled mt-3">
        <li>
          <div class="wrap">
            <div class="ico-wrap">
              <span class="mbr-iconfont">
                <img src="/assets/img/reviews.png" class="service-img mr-4" style="margin-top: 11px;">
              </span>
            </div>
            <div class="vcenter">
              <h4 class="text-left">
                <strong>{{'Customer Satisfaction' | translate}}</strong>
              </h4>
              <p>
                {{'Customer will receive a web link as SMS and track delivery on real time. Rating Feedback can be provided by customer easily.' | translate}}
              </p>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="col-md-6 text-left">
      <ul class="list-unstyled mt-3">
        <li>
          <div class="wrap">
            <div class="ico-wrap">
              <span class="mbr-iconfont">
                <img src="/assets/img/web.png" class="service-img mr-4" style="margin-top: 12px;">
              </span>
            </div>
            <div class="vcenter">
              <h4 class="text-left">
                <strong>{{'Web, iPhone and Android App' | translate}}</strong>
              </h4>
              <p>
               {{'Business management is easy through web. Delivery agent shall use iPhone and Android App to manage their orders without being in the office.' | translate}}
              </p>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>
<!-- <div class="container-fluid area-container">
  <div class="row">
    <div class="col-md-4 area-left">
      <div class="row">
        <div class="wrap">
          <div class="ico-wrap">
            <span class="mbr-iconfont">
              <img src="/assets/img/collections/location.png" class="area-img mr-2">
            </span>
          </div>
          <div class="vcenter">
            <p class="text-white text-uppercase">
              {{'No.1, 1ST FLOOR, mrk arcade, 200 feet radial road, thoraipakkam, chennai, tamil nadu, india 600097.' | translate}}
            </p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="wrap">
          <div class="ico-wrap">
            <span class="mbr-iconfont">
              <img src="/assets/img/collections/phone.png" class="area-img-phn mr-2">
            </span>
          </div>
          <div class="vcenter">
            <p class="text-white text-uppercase">
              +917338773388
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-4 area-left">
      <div class="row">
        <div class="wrap">
          <div class="ico-wrap">
            <span class="mbr-iconfont">
              <img src="/assets/img/collections/location.png" class="area-img mr-2">
            </span>
          </div>
          <div class="vcenter">
            <p class="text-white text-uppercase">
              {{'9820 ivalenes hope dr, austin, texass tx 78717.' | translate}}
            </p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="wrap">
          <div class="ico-wrap">
            <span class="mbr-iconfont">
              <img src="/assets/img/collections/phone.png" class="area-img-phn mr-2">
            </span>
          </div>
          <div class="vcenter">
            <p class="text-white text-uppercase">
              +16302997737
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-4 area-left">
      <div class="row">
        <div class="wrap">
          <div class="ico-wrap">
            <span class="mbr-iconfont">
              <img src="/assets/img/collections/location.png" class="area-img mr-2">
            </span>
          </div>
          <div class="vcenter">
            <p class="text-white text-uppercase">
              {{'5838 burbank road se, calgary, alberta, canada t2h1z3.' | translate}}
            </p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="wrap">
          <div class="ico-wrap">
            <span class="mbr-iconfont">
              <img src="/assets/img/collections/phone.png" class="area-img-phn mr-2">
            </span>
          </div>
          <div class="vcenter">
            <p class="text-white text-uppercase">
              +14039263660
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->
<app-cs-footer></app-cs-footer>

import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders, HttpErrorResponse } from "@angular/common/http";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { FormGroup } from '@angular/forms';
import { ApiService } from './../../services/ApiService';
import { AppService } from './../../services/AppService';
import { AuthService } from './../../services/AuthService';
import { Config } from '../../config/config';


@Component({
  selector: 'app-customer-details',
  templateUrl: './customer-details.component.html',
  styleUrls: ['./customer-details.component.css']
})
export class CustomerDetailsComponent implements OnInit {
  Customerdetails: any;
  CustName: any;
  CustPhone: any;
  CustEmail: any;
  CustAddress: any;
  Cust_ID: any;

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private loader: NgxSpinnerService,
    private toastr: ToastrService,
    private authService: AuthService,
    public appService: AppService,
    public apiService: ApiService
  ) { }

  baseUrl = Config.BASE_URL;
  apiUrl = this.baseUrl + "api/";

  ngOnInit(): void {
    var cust_id =  localStorage.getItem("cust_id");
    this.apiService
      .customerListById(cust_id)
      .subscribe((response: any) => {
        this.Customerdetails = response.data;
        this.Cust_ID = this.Customerdetails.id;
        this.CustName = this.Customerdetails.name;
        this.CustPhone = this.Customerdetails.contact_no;
        this.CustEmail = this.Customerdetails.email;
        this.CustAddress = this.Customerdetails.address;
      })
  }

}

import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders, HttpErrorResponse } from "@angular/common/http";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { FormGroup } from '@angular/forms';
import { ApiService } from './../../services/ApiService';
import { AppService } from './../../services/AppService';
import { AuthService } from './../../services/AuthService';
import { Config } from '../../config/config';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as moment from 'moment-timezone';

export interface PeriodicElement {
  sip_id: number;
  first_name: string;
  last_name: string;
  email: string;
  contact_no: string
}

@Component({
  selector: 'app-package-information',
  templateUrl: './package-information.component.html',
  styleUrls: ['./package-information.component.css']
})
export class PackageInformationComponent implements OnInit {

  @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
 
  isOpen: boolean = false;
  modelTitle: any;
  Supervisordata: any = [];
  displayedColumns = ['id','name', 'price', 'month','no_of_emp','no_of_cust','no_of_task','no_of_mgr'];  
  // page: number = 1;
  // limit: number = 10;
  Supervisorlist: any;
  dataSource: MatTableDataSource<any>;
  NewPassword: any;
  ConfPassword: any;
  Emp_ID: string;
  ButtonShow = true;
  checkUserExpires: any;
  isExpiredTwodays = false;
  isExpired = false;
  userData: any;
  packageInformation: any=[];
  packageInformationCount: any=0;
  packageInformationListSource: MatTableDataSource<any>;


  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private loader: NgxSpinnerService,
    private toastr: ToastrService,
    private authService: AuthService,
    public appService: AppService,
    public apiService: ApiService,
    public dialog: MatDialog,
    private snackBar:MatSnackBar,
  ) { }

  baseUrl = Config.BASE_URL;
  apiUrl = this.baseUrl + "api/";
  animal: string;
  name: string;
  isDeleteModel = false;
  deleteItem: any;
  Search:any = "";
  offset:number=0;
  limit:number=10;

  ngOnInit(): void {
    let param = {
      offset:0,
      limit:10
    }
    this.getPackageInformation(param);
    
    this.userData = JSON.parse(localStorage.getItem("user_data"));
    if(this.userData.profile.role_id == 4){
      this.apiService.ManagerLimitCheckforMgr(this.userData.profile.role_id).subscribe((response: any) => {
        if(response.status == "ok"){
          this.ButtonShow = true;
        }else{
          this.ButtonShow = false;
        }
      });
      // this.managershow = false;
    }else{
      this.apiService.ManagerLimitCheck().subscribe((response: any) => {
        if(response.status == "ok"){
          this.ButtonShow = true;
        }else{
          this.ButtonShow = false;
        }
      });
      // this.managershow = true;
    }
      // this.apiService
      //   .checkUsers()
      //   .subscribe((response: any) => {
      //     this.checkUserExpires = response[0];
      //     var _date = new Date();
      //     var current_date = moment(_date).format("YYYY-MM-DD");
      //     var end_date = moment(this.checkUserExpires.end_date);
      //     var end_date1 = end_date.format("YYYY-MM-DD");
      //     var two_days_b4 = end_date.subtract(2, "days").format("YYYY-MM-DD");
      //     var one_days_b4 = end_date.add(1, "days").format("YYYY-MM-DD");
      //     var checkBetween = moment(current_date).isBetween(two_days_b4, end_date1);
      //     var checkIsSame = moment(end_date1).isSame(current_date);
      //     var checkIsBefore = moment(end_date1).isBefore(current_date);
      //     if(checkBetween || checkIsSame){
      //       this.isExpired = false;
      //       this.isExpiredTwodays = true;
      //     }else{
      //       if(checkIsBefore){
      //         this.isExpired = true;
      //         this.isExpiredTwodays = false;
      //       }
      //     }
      //     this.loader.hide();
      //   });
  }
  
  getPackageInformation(param){
    this.apiService.getPackageInformation().subscribe((response)=>{
      this.packageInformation = response['data']
      this.packageInformationCount = response['count']
      this.packageInformationListSource = new MatTableDataSource(this.packageInformation);
      setTimeout(() => this.packageInformationListSource.paginator = this.paginator);
      this.packageInformationListSource.sort = this.sort;
    })
  }
  
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.packageInformationListSource.filter = filterValue.trim().toLowerCase();

    if (this.packageInformationListSource.paginator) {
      this.packageInformationListSource.paginator.firstPage();
    }
  }

}

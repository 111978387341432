<mat-nav-list>
  <a href="https://keep.google.com/" mat-list-item (click)="openLink($event)">
    <span mat-line>Google Keep</span>
  </a>
  <a href="https://docs.google.com/" mat-list-item (click)="openLink($event)">
    <span mat-line>Google Docs</span>
  </a>
  <a href="https://plus.google.com/" mat-list-item (click)="openLink($event)">
    <span mat-line>Google Plus</span>
  </a>
  <a href="https://hangouts.google.com/" mat-list-item (click)="openLink($event)">
    <span mat-line>Google Hangouts</span>
  </a>
</mat-nav-list>

  <!-- <mat-list>
    <mat-list-item>Item 1</mat-list-item>
    <mat-divider></mat-divider>
    <mat-list-item>Item 2</mat-list-item>
    <mat-divider></mat-divider>
    <mat-list-item>Item 3</mat-list-item>
  </mat-list> -->
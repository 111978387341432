<nav class="navbar navbar-expand-lg navbar-dark header-bg">
  <div class="container">
    <a class="navbar-brand" href="#"><img src="/assets/img/fr-logo.png" class="form-img mr-2"></a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
      <span class="fa fa-bars"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarResponsive">
      <ul class="navbar-nav ml-auto">
        <li class="nav-item active">
          <a class="nav-link" href="#">{{'Home' | translate}}
            <span class="sr-only">(current)</span>
          </a>
        </li>
        <!-- <li class="nav-item active dropdown">
          <an class="nav-link" routerLink="/asd">Verticals</an>
          <ul class="dropdown-menu" aria-labelledby="dropdown1">
            <li class="dropdown-item"><a routerLink="/asd">Verticals-Link</a></li>
          </ul>
        </li> -->
        <li class="nav-item active dropdown">
          <a class="nav-link" routerLink="/login">{{'Login' | translate}}</a>
          <!-- <ul class="dropdown-menu" aria-labelledby="dropdown2">
            <li class="dropdown-item"><a routerLink="/login">Login-Link</a></li>
          </ul> -->
        </li>
        <li class="nav-item active dropdown">
          <a class="nav-link" routerLink="/signup">{{'Sign Up' | translate}}</a>
          <!-- <ul class="dropdown-menu" aria-labelledby="dropdown3">
            <li class="dropdown-item"><a routerLink="/signup">Sign Up-Link</a></li>
          </ul> -->
        </li>
        <li class="nav-item active">
          <a class="nav-link" href="#">{{'Contact' | translate}}</a>
        </li>
        <li class="nav-item active">
          <span class="form-inline nav-link">
            <label style="float: left;margin: 0px 12px 0px 0px;font-weight: bold;">{{'Select Language' | translate}}</label>
            <select 
                class="form-control" 
                #selectedLang
                style="float: left;margin: -5px 0px 0px 0px;" 
                [(ngModel)]="SelectLang"
                (change)="switchLang(selectedLang.value)">
              <option *ngFor="let language of translate.getLangs()" 
                [value]="language"
                [selected]="language === translate.currentLang">
                {{ language }}
              </option>
            </select>
          </span>
        </li>
      </ul>
    </div>
  </div>
</nav>
import { Injectable } from "@angular/core";

@Injectable()
export class AuthService {
  public isAuthenticated(): Boolean {
    const token = localStorage.getItem("access_token");
    // Check whether the token is expired and return
    // true or false
    return token ? true : false;
  }
  public getToken(): string {
    return localStorage.getItem('access_token');
  }
  public getClientData(): string {
    return localStorage.getItem('client_data');
  }
}

import { AppPage } from './../../e2e/src/app.po';
import { NgModule } from '@angular/core';
import { Routes, RouterModule, CanActivate, ExtraOptions} from '@angular/router';
import { ReactiveFormsModule, FormsModule } from "@angular/forms";

import { AuthGuardService as AuthGuard } from "./services/AuthGuardService";

import { SignupComponent } from './signup/signup.component';
import { welcomeComponent } from './welcome/welcome.component';
import { EmailLoginComponent } from './login/email-login/email-login.component';
import { MobileLoginComponent } from './login/mobile-login/mobile-login.component';
import { HomeComponent } from './home/home.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { CalendarComponent } from './pages/calendar/calendar.component';
import { SupervisorComponent } from './pages/supervisor/supervisor.component';
import { VehicleComponent } from './pages/vehicle/vehicle.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { ProfileEditComponent } from './pages/profile/profile-edit/profile-edit.component';
import { ChangePasswordComponent } from './pages/profile/change-password/change-password.component';
import { AddEmployeeComponent } from './pages/add-employee/add-employee.component';
import { AddSupervisorComponent } from './pages/add-supervisor/add-supervisor.component';
import { EditSupervisorComponent } from './pages/edit-supervisor/edit-supervisor.component';
import { AddVehicleComponent } from './pages/add-vehicle/add-vehicle.component';
import { SupervisorDetailComponent } from './pages/add-supervisor/supervisor-detail/supervisor-detail.component';
import { VehicleDetailComponent } from './pages/add-vehicle/vehicle-detail/vehicle-detail.component';
import { DriverDetailComponent } from './pages/driver-detail/driver-detail.component';
import { AddOrderComponent } from './pages/add-order/add-order.component';
import { EditOrderComponent } from './pages/edit_order/edit-order.component';
import { CustomerServiceComponent } from './pages/customer-service/customer-service.component';
import { AgencyComponent } from './pages/agency/agency.component';
import { TrucksComponent } from './pages/trucks/trucks.component';
import { RidersComponent } from './pages/riders/riders.component';
import { VehicleMapComponent } from './pages/vehicle-map/vehicle-map.component';
import { CustomerComponent } from './pages/customer/customer.component';
import { PaymentStatusComponent } from './pages/payment-status/payment-status.component';
import { DeliveryReportComponent } from './pages/reports/delivery-report/delivery-report.component';
import { MileageReportComponent } from './pages/reports/mileage-report/mileage-report.component';
import { GpsHistoryComponent } from './pages/reports/gps-history/gps-history.component';
import { AddCustomerComponent } from './pages/add-customer/add-customer.component';
import { CustomerDetailsComponent } from './pages/customer-details/customer-details.component';
import { EditCustomerComponent } from './pages/edit-customer/edit-customer.component';
import { OrderDetailsComponent } from './pages/order-details/order-details.component';
import { ItemComponent } from './pages/item/item.component';
import { AddItemComponent } from './pages/add-item/add-item.component';
import { EditItemComponent } from './pages/edit-item/edit-item.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { CustomerReviewComponent } from './pages/customer-review/customer-review.component';
import { CustomerRatingComponent } from './pages/customer-rating/customer-rating.component';
import { ManagerComponent } from './pages/manager/manager.component';
import { AddManagerComponent } from './pages/add-manager/add-manager.component';
import { EditManagerComponent } from './pages/edit-manager/edit-manager.component';
import { ManagerDetailsComponent } from './pages/manager-details/manager-details.component';
import { ManageUsersComponent } from './pages/manage-users/manage-users.component';
import { PackageInformationComponent } from './pages/package-information/package-information.component';
import { ViewUserPackageComponent } from './pages/view-user-package/view-user-package.component';
import { PaymentHistoryComponent } from './pages/payment-history/payment-history.component';
import { PaymentDetailInfoComponent } from './pages/payment-detail-info/payment-detail-info.component';
import { PaymentResponseComponent } from './pages/payment-response/payment-response.component';
import { AddDrivingZoneComponent } from './pages/add-driving-zone/add-driving-zone.component';
import { DrivingZoneComponent } from './pages/driving-zone/driving-zone.component';
import { EditDrivingZoneComponent } from './pages/edit-driving-zone/edit-driving-zone.component';
import { AddBulkOrderComponent } from './pages/add-bulk-order/add-bulk-order.component'

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    canActivate: [ AuthGuard ],data:{allowedRoles: [1,2,3,4]},
    children: [
      { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
      { path: 'dashboard', component: DashboardComponent,canActivate:[AuthGuard],data: {allowedRoles: [2,4]} },
      { path: 'calendar', component: CalendarComponent,canActivate:[AuthGuard],data: {allowedRoles: [2,4]}  },
      { path: 'profile', component: ProfileComponent,canActivate:[AuthGuard],data: {allowedRoles: [2,3,4]}  },
      { path: 'profile-edit', component: ProfileEditComponent,canActivate:[AuthGuard],data: {allowedRoles: [2,3,4]}  },
      { path: 'change-password', component: ChangePasswordComponent,canActivate:[AuthGuard],data: {allowedRoles: [2,3,4]} },
      { path: 'add-order', component: AddOrderComponent,canActivate:[AuthGuard],data: {allowedRoles: [2,4]}  },
      { path: 'order-edit', component: EditOrderComponent,canActivate:[AuthGuard],data: {allowedRoles: [2,4]}  },
      { path: 'order-details', component: OrderDetailsComponent,canActivate:[AuthGuard],data: {allowedRoles: [2,4]}  },
      { path: 'add-employee', component: AddEmployeeComponent,canActivate:[AuthGuard],data: {allowedRoles: [2,4]}  },
      { path: 'driving-zone', component: DrivingZoneComponent,canActivate:[AuthGuard],data: {allowedRoles: [2,4]}  },
      { path: 'add-driving-zone', component: AddDrivingZoneComponent,canActivate:[AuthGuard],data: {allowedRoles: [2,4]}  },
      { path: 'edit-driving-zone', component: EditDrivingZoneComponent,canActivate:[AuthGuard],data: {allowedRoles: [2,4]}  },
      { path: 'add-delivery-agent', component: AddSupervisorComponent,canActivate:[AuthGuard],data: {allowedRoles: [2,4]}  },
      { path: 'add-item', component: AddItemComponent,canActivate:[AuthGuard],data: {allowedRoles: [2,4]}  },
      { path: 'edit-delivery-agent', component: EditSupervisorComponent,canActivate:[AuthGuard],data: {allowedRoles: [2,4]}  },
      { path: 'item-edit', component: EditItemComponent,canActivate:[AuthGuard],data: {allowedRoles: [2,4]}  },
      { path: 'add-vehicle', component: AddVehicleComponent,canActivate:[AuthGuard],data: {allowedRoles: [2,4]}  },
      { path: 'driver-detail', component: DriverDetailComponent,canActivate:[AuthGuard],data: {allowedRoles: [2,4]}  },
      { path: 'delivery-agent-detail', component: SupervisorDetailComponent,canActivate:[AuthGuard],data: {allowedRoles: [2,4]}  },
      { path: 'vehicle-detail', component: VehicleDetailComponent,canActivate:[AuthGuard],data: {allowedRoles: [2,4]}  },
      { path: 'delivery-agent', component: SupervisorComponent,canActivate:[AuthGuard],data: {allowedRoles: [2,4]}  },
      { path: 'item', component: ItemComponent,canActivate:[AuthGuard],data: {allowedRoles: [2,4]}  },
      { path: 'customer-service', component: CustomerServiceComponent,canActivate:[AuthGuard],data: {allowedRoles: [2,4]}  },
      { path: 'agency', component: AgencyComponent,canActivate:[AuthGuard],data: {allowedRoles: [2,4]}  },
      { path: 'trucks', component: TrucksComponent,canActivate:[AuthGuard],data: {allowedRoles: [2,4]}  }, 
      { path: 'riders', component: RidersComponent,canActivate:[AuthGuard],data: {allowedRoles: [2,4]}  },
      { path: 'vehicle', component: VehicleComponent,canActivate:[AuthGuard],data: {allowedRoles: [2,4]}  },
      { path: 'vehicle-map', component: VehicleMapComponent,canActivate:[AuthGuard],data: {allowedRoles: [2,4]}  },
      { path: 'customer', component: CustomerComponent,canActivate:[AuthGuard],data: {allowedRoles: [2,4]}  },
      { path: 'add-customer', component: AddCustomerComponent,canActivate:[AuthGuard],data: {allowedRoles: [2,4]}  },
      { path: 'customer-details', component: CustomerDetailsComponent,canActivate:[AuthGuard],data: {allowedRoles: [2,4]}  },
      { path: 'edit-customer', component: EditCustomerComponent,canActivate:[AuthGuard],data: {allowedRoles: [2,4]}  },
      { path: 'customer-review', component: CustomerReviewComponent,canActivate:[AuthGuard],data: {allowedRoles: [2,4]}  },
      { path: 'payment-status', component: PaymentStatusComponent,canActivate:[AuthGuard],data: {allowedRoles: [2,4]}  },
      { path: 'delivery-report', component: DeliveryReportComponent,canActivate:[AuthGuard],data: {allowedRoles: [2,4]}  },
      { path: 'mileage-report', component: MileageReportComponent,canActivate:[AuthGuard],data: {allowedRoles: [2,4]}  },
      { path: 'gps-history', component: GpsHistoryComponent,canActivate:[AuthGuard],data: {allowedRoles: [2,4]}  },
      { path: 'customer-rating', component: CustomerRatingComponent,canActivate:[AuthGuard],data: {allowedRoles: [2,4]}  },
      { path: 'manager', component: ManagerComponent,canActivate:[AuthGuard],data: {allowedRoles: [2,4]}  },
      { path: 'add-manager', component: AddManagerComponent,canActivate:[AuthGuard],data: {allowedRoles: [2,4]}  },
      { path: 'edit-manager', component: EditManagerComponent,canActivate:[AuthGuard],data: {allowedRoles: [2,4]}  },
      { path: 'manager-details', component: ManagerDetailsComponent,canActivate:[AuthGuard],data: {allowedRoles: [2,4]}  },
      { path: 'add-bulk-order', component: AddBulkOrderComponent,canActivate:[AuthGuard],data: {allowedRoles: [2,4]}  },

      { path: 'manage-users', component: ManageUsersComponent,canActivate:[AuthGuard],data: {allowedRoles: [3]}  },
      { path: 'package-information', component: PackageInformationComponent,canActivate:[AuthGuard],data: {allowedRoles: [3]}  },
      { path: 'viewuserpackage/:user_id', component: ViewUserPackageComponent,canActivate:[AuthGuard],data: {allowedRoles: [3]}  },
      { path: 'payment-history', component: PaymentHistoryComponent,canActivate:[AuthGuard],data: {allowedRoles: [2,3]}  },
      { path: 'payment-details/:payment_id', component: PaymentDetailInfoComponent,canActivate:[AuthGuard],data: {allowedRoles: [2,3]}  },
      { path: 'payment-result/:payment_id', component: PaymentResponseComponent,canActivate:[AuthGuard],data: {allowedRoles: [2,4]}  }
    ]
  },
  { path: 'mobile-login', component: MobileLoginComponent },
  { path: 'login', component: EmailLoginComponent },
  { path: 'signup', component: SignupComponent },
  { path: 'welcome', component: welcomeComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: "**", redirectTo: "" }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }

<!--The content below is only a placeholder and can be replaced.-->
<div class="wrapper default-theme" [ngClass]="getClasses()">
  <app-navbar></app-navbar>
  <main class="main-view">
    <ngx-scrolltop
      [backgroundColor]="'#212121'"
      [symbolColor]="'#fafafa'"
      color="primary"
      [size]="40"
      [mode]="'classic'"
      position="right"
      theme="gray">
    </ngx-scrolltop>
    <app-sidebar></app-sidebar>
    <div class="pages container-fluid" style="padding: 0 !important;" (mouseover)="justHide()" (mouseout)="justHide()">
      <app-notify-me 
        [resource]="resourceData" 
        [isWhy]="'Expired Account'"*ngIf="resourceData">
      </app-notify-me>
      <!-- <hr>
      <app-footer></app-footer> -->
      <router-outlet></router-outlet>
    </div>
    <div class="overlay" (click)="toggleSidebar()"></div>
  </main>
</div>

<div class="container-fluid">
    <div class="container">
      <div class="row" style="margin-top: 15px;">
        <form [formGroup]="EmployeeGroup" class="col-md-12">
          <div class="col text-left">
            <p style="margin-top: 12px;font-size: 16px;"><i class="fa fa-arrow-left fa-theme mr-2 pointer" matTooltip="{{'Go Back' | translate}}" [matTooltipPosition]="'above'" [routerLink]="['/manager']"></i><b>{{'Add Manager' | translate}}</b></p>
          </div>
          <div class="col-md-12 col-lg-6 mb-4 align-items-stretch" style="float: left;">
            <div class="form-group">
              <mat-form-field style="width: 100%;" appearance="outline">
                <label style="position: absolute;top: -5px;">{{'Phone Number' | translate}}</label>
                <ngx-mat-intl-tel-input formControlName="SupPhone"
                  [preferredCountries]="preferredCountries"
                  [enableAutoCountrySelect]="false"
                  [enablePlaceholder]="true"
                  [searchCountryFlag]="true"
                  [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                  [selectFirstCountry]="false"
                  [selectedCountryISO]="CountryISO.India"
                  [maxLength]="15"
                  [tooltipField]="TooltipLabel.Name"
                  [(ngModel)]="SupPhone"
                  (keyup)="EmpPhone()"
                  (keypress)="omit_char($event);"
                  [separateDialCode]="false" [enableSearch]="true"
                  name="sup_phone" #phone [readonly]="!isEditable"
                  >
                </ngx-mat-intl-tel-input>
              </mat-form-field>
            </div>
            <div class="form-group" *ngIf="empbyphone">
              <mat-form-field style="width: 100%;" appearance="outline">
                <mat-label>{{'First  Name' | translate}}</mat-label>
                <input matInput placeholder="{{'Enter First Name' | translate}}" name="first_name" formControlName="FirstName" [(ngModel)]="FirstName" required [readonly]="!isEditable">
              </mat-form-field>
            </div>
            <div class="form-group" *ngIf="empbyphone">
              <mat-form-field style="width: 100%;" appearance="outline">
                <mat-label>{{'Email' | translate}}</mat-label>
                <input matInput placeholder="{{'Enter Email'  | translate}}" name="sup_email" (input)="validateEmail($event)" pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" formControlName="SupEmail" [(ngModel)]="SupEmail" [readonly]="!isEditable">
              </mat-form-field>
            </div>
            <div class="form-group" *ngIf="empbyphone">
              <mat-form-field style="width: 100%;" appearance="outline">
                <mat-label>{{'Permanent Address' | translate}}</mat-label>
                <!-- <input matInput placeholder="Enter Permanent Address" formControlName="SupAddress" name="sup_address" [(ngModel)]="SupAddress" required> -->
                <input type="text" matInput name="sup_address" [(ngModel)]="SupAddress" *ngIf="isEditable" formControlName="SupAddress" [value]="SupAddress" placeholder="{{'Enter Permanent Address' | translate}}" matGoogleMapsAutocomplete
                  (onAutocompleteSelected)="onAutocompleteSelected($event)" #search
                  (onLocationSelected)="onLocationSelected($event)" [readonly]="!isEditable" style="width: 90%; border: none !important;outline: none !important;box-shadow: none !important;background: none !important;overflow: hidden;">
                  <input type="text" matInput name="sup_address" [(ngModel)]="SupAddress" *ngIf="!isEditable" formControlName="SupAddress" [value]="SupAddress" placeholder="Enter Permanent Address"
                   [readonly]="!isEditable" style="width: 90%; border: none !important;outline: none !important;box-shadow: none !important;background: none !important;overflow: hidden;">
                  <img src="./assets/img/location.svg" alt="Profile Edit" style="height: 30px;width: 30px;float: right;margin-top: -10px;overflow: hidden;" class="map-icon" (click)="showmap(1)" *ngIf="agmshowmap == 0 && isEditable == true">
              <img src="./assets/img/location-disabled.svg" alt="Profile Edit" style="height: 30px;width: 30px;float: right;margin-top: -10px;" class="map-icon" (click)="showmap(0)" *ngIf="agmshowmap == 1">
            <agm-map [latitude]="latitude" [longitude]="longitude" [zoom]="zoom" *ngIf="agmshowmap == 1">
              <agm-marker [latitude]="latitude" [longitude]="longitude" [markerDraggable]="true"
                (dragEnd)="markerDragEnd($event)"></agm-marker>
            </agm-map>
              </mat-form-field>
            </div>
            <div class="form-group" *ngIf="empbyphone">
              <label>{{'Profile Picture' | translate}}</label>
              <div class="uploads-strip">
                <input hidden type="file" name="image" #uploader multiple accept="video/*,image/*" (change)="fileupload($event)" />
                <button *ngIf="isEditable" class="upload-btn upload" style="margin-top: 35px;cursor:pointer;" id="btn" (click)="uploader.click()" >
                  <!-- <img src="assets/images/upload.png">  -->
                  {{'Upload Profile Picture' | translate}}
                </button><br><br>
                <button *ngIf="!isEditable" class="upload-btn upload" style="margin-top: 35px;cursor:pointer;" id="btn" >
                  <!-- <img src="assets/images/upload.png">  -->
                  {{'Upload Profile Picture' | translate}}
                </button><br><br>
                <ng-container *ngIf="ProfileImage.length > 0">
                  <img src="{{ProfileImage}}" class="profilepic">
                  <img src="/assets/img/remove.png" class="imgdelete" (click)="DeleteImg()">
                </ng-container>
              </div>
            </div>
          </div>
          <div class="col-md-12 col-lg-6 mb-4 align-items-stretch" style="float: left;">
          <div class="form-group" *ngIf="confphoneshow">
            <mat-form-field style="width: 100%;" appearance="outline">
              <label style="position: absolute;top: -5px;">{{'Confirm Phone Number' | translate}}</label>
              <!-- <input matInput placeholder="Enter Confirm Phone Number" name="sup_confphone" formControlName="SupConfNum" [(ngModel)]="SupConfNum" (keypress)="omit_char($event);" (change)="ConfPhoneCheck($event);" required> -->
              <ngx-mat-intl-tel-input formControlName="SupConfNum"
                [preferredCountries]="preferredCountries"
                [enableAutoCountrySelect]="false"
                [enablePlaceholder]="true"
                [searchCountryFlag]="true"
                [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                [selectFirstCountry]="false"
                [selectedCountryISO]="CountryISO.India"
                [maxLength]="15"
                [tooltipField]="TooltipLabel.Name"
                [(ngModel)]="SupConfNum"
                (keyup)="EmpConfPhone()"
                (keypress)="omit_char($event);"
                [separateDialCode]="false" [enableSearch]="true"
                name="sup_confphone" #sup_confphone
                >
              </ngx-mat-intl-tel-input>
            </mat-form-field>
          </div>
          <div class="form-group" *ngIf="empbyphone">
            <mat-form-field style="width: 100%;" appearance="outline">
              <mat-label>{{'Last Name' | translate}}</mat-label>
              <input matInput placeholder="{{'Enter Last Name' | translate}}" name="last_name" formControlName="LastName" [(ngModel)]="LastName" [readonly]="!isEditable">
            </mat-form-field>
          </div>
          <div class="form-group" *ngIf="empbyphone">
            <mat-form-field style="width: 100%;" appearance="outline">
              <label style="position: absolute;top: -5px;">{{'WhatsApp Number' | translate}}</label>
              <!-- <input matInput placeholder="Enter WhatsApp Number" name="sup_whatsapp" formControlName="SupWhatsapp" [(ngModel)]="SupWhatsapp" (keypress)="omit_char($event);" required> -->
              <ngx-mat-intl-tel-input formControlName="SupWhatsapp"
                [preferredCountries]="preferredCountries"
                [enableAutoCountrySelect]="false"
                [enablePlaceholder]="true"
                [searchCountryFlag]="true"
                [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                [selectFirstCountry]="false"
                [selectedCountryISO]="CountryISO.India"
                [maxLength]="15"
                [tooltipField]="TooltipLabel.Name"
                [(ngModel)]="SupWhatsapp"
                (keypress)="omit_char($event);"
                [separateDialCode]="false" [enableSearch]="true"
                name="sup_whatsapp" #sup_whatsapp [readonly]="!isEditable" [disabled]="!isEditable"
                >
            </ngx-mat-intl-tel-input>
            </mat-form-field>
          </div>
         </div>
       </form>
     </div>
   </div>
   <div class="row">
    <div class="col text-right">
      <button type="button" style="margin: 0px 8px 0 0;width: 70px;" (click)="EmpByPhone();" *ngIf="!empbyphone && !confbyphone" class="btn btn-info rounded-0 mb-2"><b>{{'Next' | translate}}</b></button>
      <button type="button" style="margin: 0px 8px 0 0;width: 70px;" (click)="ConfPhoneCheck();" *ngIf="confbyphone" class="btn btn-info rounded-0 mb-2"><b>{{'Next' | translate}}</b></button>
      <button type="button" (click)="Cancel()" class="btn btn-outline-info rounded-0 mb-2 mr-2"><b>{{'Cancel' | translate}}</b></button>
      <button type="button" (click)="AddSupervisor()" [disable]="adddisable" *ngIf="empbyphone" class="btn btn-info rounded-0 mb-2"><b>{{ !isEditable ? 'Associate Manager':'Add Manager' | translate}}</b></button>
    </div>
   </div>
  </div>
   
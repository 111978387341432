import { Component, OnInit, HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders, HttpErrorResponse } from "@angular/common/http";
import { FormGroup } from '@angular/forms';
import { AppService } from '../services/AppService';
import { AuthService } from '../services/AuthService';
import { ApiService } from '../services/ApiService';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';

import {
	SearchCountryField
} from 'custom-template/ngx-intl-tel-input/src/lib/enums/search-country-field.enum';
import { CountryISO } from 'custom-template/ngx-intl-tel-input/src/lib/enums/country-iso.enum';
import { TooltipLabel } from 'custom-template/ngx-intl-tel-input/src/lib/enums/tooltip-label.enum';


@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css'],
})
export class SignupComponent implements OnInit {
  public _token: any;
  signupForm: FormGroup;
  otpsign = {
    phone: "",
    option: "sms",
  };
  user = {
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
  };
  verify = {
    otppin: "",
  }
  submitted = false;
	SearchCountryField = SearchCountryField;
	TooltipLabel = TooltipLabel;
	CountryISO = CountryISO;
	preferredCountries: CountryISO[] = [
    CountryISO.India,
    CountryISO.UnitedArabEmirates,
  ];
  selectedCountry: any = CountryISO.India;
  isVerify = false;
  public OTP_is: any;
  invalid_email: boolean;
  email_required: boolean;
  phoneshow: boolean;
  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private loader: NgxSpinnerService,
    private toastr: ToastrService,
    public appService: AppService,
    public apiService: ApiService,
  ) { }

  ngOnInit() {
    this.apiService
      .getIpInfo()
      .subscribe((response: any) => {
        if(response.country != '') {
          this.preferredCountries = [
            response.country.toLowerCase()
          ];
          this.selectedCountry = response.country.toLowerCase();
        } else {
          this.preferredCountries = [
            CountryISO.India,
            CountryISO.Singapore,
            CountryISO.UnitedArabEmirates,
            CountryISO.Canada,
          ];
          this.selectedCountry = CountryISO.India;
        }
      });
  }

  validateEmail(email) {
    //var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    var re =/^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

    if(re.test(email.target.value)){
      this.invalid_email = false;
      this.email_required = false;
    }
    else{
      this.invalid_email = true;
      if(email.target.value == ''){
        this.email_required = true
        this.invalid_email = false
      }
    }
  }

  onSubmit() {
    this.phoneshow = true;
    if (this.user) {
      this.loader.show();
      let payload = {
        data: {
          email: this.user.email,
          phone: this.user.phone['e164Number'],
        }
      }
      this.apiService
        .signupOPTGenerator(payload)
        .subscribe(
          response => {
            this.loader.hide();
            if (response['status'] == 'ok') {
              this.isVerify = true;
              this.OTP_is = response['data'].otp;
              this.toastr.success('You will receive the otp via sms');
            } else {
              this.toastr.warning(response['data']);
            }
          },
          (err: HttpErrorResponse) => {
            this.toastr.error('Something went wrong please check.');
            this.loader.hide();
          }
        );
    }
    this.phoneshow = false
  }
  ResendOTP(){
    if (this.user) {
      this.loader.show();
      let payload = {
        data: {
          gateway: this.otpsign.option,
          email: this.user.email,
          phone: this.user.phone['e164Number']
        }
      }
      this.apiService
        .signupOPTGenerator(payload)
        .subscribe((response: any) => {
          this.loader.hide();
          if (response.status == 'ok') {
            this.OTP_is = response['data'].otp;
            if(this.otpsign.option == 'sms'){
              this.toastr.success('You will receive the otp via SMS');
            }else if(this.otpsign.option == 'voice'){
              this.toastr.success('You will receive the otp via Voice Call');
            } 
            this.isVerify = true;
          } else {
            this.toastr.error(response.data);   
          }
        },
          (err: HttpErrorResponse) => {
            this.toastr.error('Something went wrong please try again');
            this.loader.hide();
          }
        );
    }
  }
  verifySubmit() {
    if (this.verify && this.user && this.OTP_is == this.verify.otppin) {
      this.loader.show();
      let payload = {
        data : {
          first_name: this.user.first_name,
          last_name: this.user.last_name,
          email: this.user.email,
          phone: this.user.phone['e164Number'],
          otp: this.verify.otppin,
        }
      }
      this.apiService
        .signup(payload)
        .subscribe((response: any) => {
          this.loader.hide();
          if (response.status == 'ok') {
            if (response.data.length === 0) {
              this.toastr.success('Done successfully Please login again.');
              this.router.navigate(["/welcome"]);
            } else {
              this._token = response.data.token;
              localStorage.setItem(
                "access_token",
                this._token
              );
              localStorage.setItem(
                "client_data",
                this.appService.getClientData()
              );
              delete response.data.token;
              localStorage.removeItem("temp_client_data");
              localStorage.setItem(
                "user_data",
                JSON.stringify(response.data)
              );
               this.router.navigate(["/welcome"]);
              //this.router.navigate(["/dashboard"]);
            }
          } else {
            this.toastr.error(response.data);
          }
        },
        (err: HttpErrorResponse) => {
          this.toastr.error('Something went wrong please check.');
          this.loader.hide();
        });
    } 
    else {
      this.toastr.error('The given OTP is invalid.');
    }
  }
  GoBack(){
    this.isVerify = false;
    this.phoneshow = false;
  }
  gotoLogin(){
    this.router.navigate(["/mobile-login"]);
  }
  password_visit() {
    let x = document.getElementById("login-password") as HTMLInputElement;
    if (x.type == "password") {
      document.getElementById("eye-open").className = "fa fa-lg fa-eye field-icon toggle-password view-hide";
      x.type = "text";
    } else {
      document.getElementById("eye-open").className = "fa fa-lg fa-eye-slash field-icon toggle-password view-hide";
      x.type = "password";
    }
  }
  changePreferredCountries() {
		this.preferredCountries = [
      CountryISO.India,
      CountryISO.Canada,
    ];
  }

  omit_char(event: any) {
    const keyChar = event.key;
    let allowCharacter: boolean;
    if (keyChar === '-' && event.target.selectionStart !== 0) {
      allowCharacter = false;
    } else if (
      keyChar === 'Tab' ||
      keyChar === '+' ||
      keyChar === 'Enter' ||
      keyChar === 'Backspace' ||
      keyChar === 'ArrowLeft' ||
      keyChar === 'ArrowRight' ||
      keyChar === 'Delete'
    ) {
      allowCharacter = true;
    } else {
      allowCharacter = keyChar >= '0' && keyChar <= '9';
    }
    if (!allowCharacter) {
      event.preventDefault();
    }
  }
}

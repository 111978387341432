import { ActivatedRoute, Router } from '@angular/router';
import { Component, HostBinding, OnInit } from '@angular/core';
import { AppService } from './../../services/AppService';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { HttpClient } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/AuthService';
import { ApiService } from 'src/app/services/ApiService';
import { Image_Path } from 'src/app/config/config';
import { GeneralService } from 'src/app/services/GeneralService';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core'

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  @HostBinding('attr.dir') dir = 'ltr';
  isCollapsed = true;
  notifyOpen = false;
  isToggleSidebarPined : Boolean;
  userData: any;
  profileList: any;
  S3BaseUrl = Image_Path.S3_BASE_URL;
  notifyList: any = [];
  page = 1;
  notifyCount: any;
  isOpen = false;
  isTimeZone: any;
  language: any;
  languageSubject: any;
  SelectLang: any;
  constructor(
    private _bottomSheet: MatBottomSheet,
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private loader: NgxSpinnerService,
    private toastr: ToastrService,
    private authService: AuthService,
    public appService: AppService,
    public apiService: ApiService,
    public generalService: GeneralService,
    public translate: TranslateService
  ) {
    translate.addLangs(['English', 'Arabic']);
   }

  ngOnInit() {
    this.SelectLang = localStorage.getItem("language");
    if(this.SelectLang == '' || this.SelectLang == null){
      localStorage.setItem('language','English');
      this.SelectLang = localStorage.getItem('language')
      this.translate.use(this.SelectLang);
    }
    this.translate.use(this.SelectLang);
    if(this.SelectLang != null && this.SelectLang == 'Arabic') {
      this.dir = 'rtl';
    } else {
      this.dir = 'ltr';
    }
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      if(event.lang == 'Arabic') {
        this.dir = 'rtl';
      } else {
        this.dir = 'ltr';
      }
    });
    
    this.userData = JSON.parse(localStorage.getItem("user_data"));
    if(this.userData){
      if(this.userData.profile.profile_image.length > 2){
        var result = this.userData.profile.profile_image.charAt(0);
        if(result == "["){
          var getProfile = JSON.parse(this.userData.profile.profile_image);
          this.userData.profile.profile_image = getProfile[0];
        }
      }
      this.profileList = this.userData.profile;
      this.isTimeZone = this.userData.profile.timezone;
    }
    this.loadNotification();
  }
  convertStringToArray = (object) => {
    return (typeof object === 'string') ? Array(object) : object 
  }
  ngAfterViewInit() {
    this.generalService.profileHeaders.subscribe((type: any) => {
      if(type.profileUrl != undefined){
        this.userData = undefined;
        let localdata = JSON.parse(localStorage.getItem("user_data"));
        localdata.profile.profile_image = type.profileUrl;
        localStorage.setItem(
          "user_data", 
          JSON.stringify(localdata)
        );
        this.userData = JSON.parse(localStorage.getItem("user_data"));
        this.profileList.profile_image = type.profileUrl;
      }
      if(type.timeZone != undefined){
        this.isTimeZone = undefined;
        let localdata = JSON.parse(localStorage.getItem("user_data"));
        localdata.profile.timezone = type.timeZone;
        localStorage.setItem(
          "user_data", 
          JSON.stringify(localdata)
        );
        this.userData = JSON.parse(localStorage.getItem("user_data"));
        this.isTimeZone = type.timeZone;
      }
    });
  }
  switchLang(lang: string) {
    localStorage.setItem("language",lang)
    this.translate.use(lang);
  }
  logout() {
    localStorage.removeItem("access_token");
    localStorage.removeItem("user_data");
    localStorage.removeItem("client_data");
    localStorage.removeItem("driver_details");
    localStorage.removeItem("cust_id");
    localStorage.removeItem("order_detail_id");
    localStorage.removeItem("sup_id");
    localStorage.removeItem("item_id");
    localStorage.removeItem("zoom_control");
    this.router.navigate([ "/mobile-login" ]);
  }
  toggleSidebarPin() {
    this.isToggleSidebarPined = !this.isToggleSidebarPined;
    this.appService.toggleSidebarPin();
  }
  toggleSidebar() {
    this.isCollapsed = true;
    this.appService.toggleSidebar();
  }
  goProfile(){
    this.isCollapsed = true;
  }
  open() { }
  openBottomSheet(): void {
    this._bottomSheet.open(NotificationModal);
  }
  loadNotification(){
    this.apiService
      .notificationList(this.page)
      .subscribe((response: any) => {
        this.notifyCount = response.total;
        // let tempData = [];
        // response.data.forEach(element => {
        //   if(!element.read_at){
        //     tempData.push(element);
        //   }
        // });
        this.notifyList = response.data;
      });
  }
  showMore(){
    this.page = this.page + 1;
    this.apiService
      .notificationList(this.page)
      .subscribe((response: any) => {
        response.data.forEach(element => {
          if(!element.read_at){
            this.notifyList.push(element);
          }
        });
        this.notifyList;
      });
  }
  readNotify(isId: any){
    this.apiService
    .readNotify(isId)
    .subscribe((response: any) => {
      this.loader.hide();
      this.loadNotification();  
    });
  }
  openModel(){
    this.isOpen = !this.isOpen;
  }
}
@Component({
  selector: 'app-notification-modal',
  templateUrl: 'notification-modal.html',
})
export class NotificationModal {
  constructor(
    private _bottomSheetRef: MatBottomSheetRef<NotificationModal>
  ) { }

  openLink(event: MouseEvent): void {
    this._bottomSheetRef.dismiss();
    event.preventDefault();
  }
}

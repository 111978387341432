import { Component, OnInit, ViewChild,Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders, HttpErrorResponse } from "@angular/common/http";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { FormControl, FormGroup } from '@angular/forms';
import { ApiService } from './../../services/ApiService';
import { AppService } from './../../services/AppService';
import { AuthService } from './../../services/AuthService';
import { Config } from '../../config/config';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Angular5Csv } from 'angular5-csv/dist/Angular5-csv';

export interface PeriodicElement {
  name: number;
  email: string;
  contact_no: string;
  // address: string;
}

export interface DialogData {
  animal: string;
  name: string;
}

@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.css'],
})
export class CustomerComponent implements OnInit {
  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl()
  });

  @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumns = ['name', 'email', 'address', 'contact_no', 'Actions'];
  animal: string;
  name: string;
  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private loader: NgxSpinnerService,
    private toastr: ToastrService,
    private authService: AuthService,
    public appService: AppService,
    public apiService: ApiService,
    public dialog: MatDialog
  ) {}

  baseUrl = Config.BASE_URL;
  apiUrl = this.baseUrl + "api/";
  Customerlist: any;
  dataSource: MatTableDataSource<any>;
  isDeleteModel = false;
  deleteItem: any;

  ngOnInit() {
    this.apiService
      .customerList()
      .subscribe((response: any) => {
        this.Customerlist = response.data;
        this.dataSource = new MatTableDataSource(this.Customerlist);
        setTimeout(() => this.dataSource.paginator = this.paginator);
        this.dataSource.sort = this.sort;
      });
  }
  EditCustomer(id:any){
    localStorage.setItem("cust_id",id)
    this.router.navigate(["edit-customer"]);
  }
  // DeleteCustomer(id:any): void {
  //   localStorage.setItem("cust_id",id)
  //   const dialogRef = this.dialog.open(DialogOverviewExampleDialog, {
  //     width: '350px',
  //     data: {name: this.name, animal: this.animal}
  //   });
  //   dialogRef.afterClosed().subscribe(result => {
  //     console.log('The dialog was closed');
  //     this.animal = result;
  //   });
  // }

  DeleteModel(element: any) {
    this.deleteItem = element;
    this.isDeleteModel = true;
  }

  DeleteCustomer(){
    this.loader.show();
    this.apiService
      .customerDelete(this.deleteItem)
      .subscribe((response: any) => {
        if(response.status == "ok"){
          this.loadItems();
          this.toastr.success(response.data);
        }else{
          this.toastr.warning(response.data);
          this.loader.hide();
        }
        this.isDeleteModel = false;
      });
  }

  onClose(){
    this.deleteItem = undefined;
    this.isDeleteModel = false;
  }

  loadItems(){
    this.loader.show();
    this.apiService
      .customerList()
      .subscribe((response: any) => {
        this.Customerlist = response.data;
        this.dataSource = new MatTableDataSource(this.Customerlist);
        setTimeout(() => this.dataSource.paginator = this.paginator);
        this.loader.hide();
      });
  }
  
  ViewCustomer(id:any){
    localStorage.setItem("cust_id",id)
    this.router.navigate(["customer-details"]);
  }

  ApplyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase(); 
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  downloadCustomertable() {
    var ary: any = [];
    var res = this.Customerlist
    for (var i = 0; i < res.length; i++) {
      ary.push({
        // s_no: res[i]['sno'],
        email: res[i]['name'],
        price: res[i]['email'],
        contact_no: res[i]['contact_no'],
      });
    }
    var options = {
      headers: [
        'Customer Name',
        'Email',
        'Contact No',
      ],
      nullToEmptyString: true,
    };
    new Angular5Csv(ary, 'Customer', options);
  }
}


// @Component({
//   selector: 'dialog-overview-example-dialog',
//   templateUrl: 'dialog-overview-example-dialog.html',
// })

// export class DialogOverviewExampleDialog {
//   Cust_ID: any;
//   baseUrl = Config.BASE_URL;
//   apiUrl = this.baseUrl + "api/";
//   Customerlist: any;
//   dataSource: MatTableDataSource<any>;
//   @ViewChild('paginator') paginator: MatPaginator;
//   constructor(
//     public dialogRef: MatDialogRef<DialogOverviewExampleDialog>,
//     @Inject(MAT_DIALOG_DATA) public data: DialogData,
//     private http: HttpClient,
//     private route: ActivatedRoute,
//     private router: Router,
//     private toastr: ToastrService,
//     public dialog: MatDialog,
//     private loader: NgxSpinnerService,
//     public apiService: ApiService,
//   ) {}
    
//   onNoClick(): void {
//     this.dialogRef.close();
//   }
//   ngOnInit() {
//     this.apiService
//       .customerList()
//       .subscribe((response: any) => {
//         this.Customerlist = response.data;
//         this.dataSource = new MatTableDataSource(this.Customerlist);
//         setTimeout(() => this.dataSource.paginator = this.paginator);
//       })
//   }
//   Delete(){
//     this.Cust_ID = localStorage.getItem("cust_id")
//     this.apiService
//       .customerDelete(this.Cust_ID)
//       .subscribe((response: any) => {
//         console.log(response.data)
//         // this.Userlist = response.data;
//         if(response.status == "ok"){
//           this.toastr.success(response.data);
//         // this.loader.hide();
//         this.dialogRef.close();
//         this.dataSource = new MatTableDataSource(this.Customerlist);
//         setTimeout(() => this.dataSource.paginator = this.paginator);
//         // this.router.navigate(["customer"]);
//         }else{
//           this.toastr.warning(response.data);
//         this.loader.hide();
//         }
//       })

//   }

// }
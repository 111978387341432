
    <div class="container">
        <div *ngIf="data.status == 'failure'">
            <div class="header">
              <h3>{{'Failure' | translate}}</h3>
              <div class="closeArrow" mat-dialog-close>&#10539;</div>
            </div>
            <table class="table table-responsive mt-4">
                <thead>
                  <tr>
                    <th scope="col">{{'Row number' | translate}}</th>
                    <th scope="col">{{'Receiver Name' | translate}}</th>
                    <th scope="col">{{'Address' | translate}}</th>
                    <th scope="col">{{'Message' | translate}}</th>
                    <th scope="col">{{'Status' | translate}}</th>
                  </tr>
                </thead>
                <tbody *ngFor="let item of data.data">
                  <tr *ngIf="item.status == 'failure'">
                    <td class="text-center">{{item.row}}</td>
                    <td class="table-Name">{{item.name}}</td>
                    <td class="table-Name">{{item.address}}</td>
                    <td class="table-message">{{item.message}}</td>
                    <td>{{item.status}}</td>
                  </tr>
                </tbody>
              </table> 
        </div>
      </div>
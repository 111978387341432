import { Component, OnInit } from '@angular/core';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { DatePipe } from '@angular/common';
import { AppService } from 'src/app/services/AppService';
import { ApiService } from 'src/app/services/ApiService';
import { SharedFunctionService } from 'src/app/services/SharedFunctionService';


@Component({
  selector: 'app-add-order',
  templateUrl: './add-order.component.html',
  styleUrls: ['./add-order.component.css'],
})
export class AddOrderComponent implements OnInit {
  userData: any;
  isDeliveryLogic:any;
  constructor(
    public appService: AppService,
    public apiService: ApiService,
    public sharedfunction: SharedFunctionService,
    public datepipe: DatePipe
  ) { }

  ngOnInit(){
    this.userData = JSON.parse(localStorage.getItem("user_data"));
    if(this.userData.profile){
      if(this.userData.profile.delivery_logic){
        this.isDeliveryLogic = this.userData.profile.delivery_logic;
      }else{
        this.isDeliveryLogic = 3;
      }
    }
  }
  changeOption(){
    console.log(this.isDeliveryLogic);
  }
  changeBack(obj: any){
    console.log(obj);
  }
}

import { Component, OnInit, NgZone, HostListener, ElementRef, ChangeDetectorRef, ChangeDetectionStrategy, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders, HttpErrorResponse } from "@angular/common/http";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { DatePipe } from '@angular/common';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { AuthService } from 'src/app/services/AuthService';
import { AppService } from 'src/app/services/AppService';
import { ApiService } from 'src/app/services/ApiService';
import { GeneralService } from 'src/app/services/GeneralService';
import { Location, Appearance, GermanAddress, MatValidateAddressDirective } from '@angular-material-extensions/google-maps-autocomplete';
import { SharedFunctionService } from 'src/app/services/SharedFunctionService';
import { Multi_Select_Config } from 'src/app/config/config';
import { SearchCountryField } from 'custom-template/ngx-intl-tel-input/src/lib/enums/search-country-field.enum';
import { TooltipLabel } from 'custom-template/ngx-intl-tel-input/src/lib/enums/tooltip-label.enum';
import { CountryISO } from 'custom-template/ngx-intl-tel-input/src/lib/enums/country-iso.enum';
import { TranslateService } from '@ngx-translate/core';
import PlaceResult = google.maps.places.PlaceResult;
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatStepper } from '@angular/material/stepper';
import { MapsAPILoader } from '@agm/core';
import { ThrowStmt } from '@angular/compiler';
declare var google;

@Component({
  selector: 'order-logic-one',
  templateUrl: './add-order.component.html',
  styleUrls: ['./add-order.component.css'],
  providers: [{
    provide: STEPPER_GLOBAL_OPTIONS, useValue: { showError: true }
  }]
})
export class AddOrderOneComponent implements OnInit {
  @Input() isLogic: any;
  @Output() comingChange = new EventEmitter<any>();
  requiredErrorText = 'The address is required';
  time: Date;
  date: Date;
  formArray: any = [];
  isDateVisible: boolean = true;
  isMeridian: boolean = false;
  dateTime = new Date();
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;
  isPickupShow = true;
  isPickup: string = '';
  pickupFormData: any = [];
  isDeliveryShow = true;
  isDelivery: string = 'add';
  deliveryFormData: any = [];
  receiverhide: boolean;
  public appearance = Appearance;
  public zoom: number;
  public latitude: number;
  public longitude: number;
  public selectedAddress: PlaceResult;
  Config = Multi_Select_Config.config;
  ReceiverOptions: any;
  ItemOptions: any;
  Item: any;
  SearchCountryField = SearchCountryField;
  TooltipLabel = TooltipLabel;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [
    CountryISO.India,
    CountryISO.Canada,
  ];
  pickupAddress: any = "";
  senderAddress: any = "";
  receiverAddress: any = [];
  Receivername: any;
  pickupLocation: any = [];
  deleiveryLocation: any = [];
  senderLocation: any = [];
  userList: any;
  userListShow: any = false;
  isLinear = true;
  multipickupshow: boolean;
  nextshow: boolean = true;
  nextstepshow: boolean;
  Receivernumber: any;
  Pickupnumber: any;
  Receiveremail: any;
  Receiveraddress: any;
  receivershow: any = [];
  phone_number_regex: any = "^[0-9]{6,15}$";
  ProfileData: any;
  Pickuplocation: any;
  minDate: Date;
  inputReadonly: boolean = true;
  private addressValidator: MatValidateAddressDirective = new MatValidateAddressDirective();
  userData: any;
  newitemshow: boolean;
  itemnewshow: boolean = true;
  fieldArray: Array<any> = [
    {
      Deliveryat: '',
      Orderid: '',
      Item: '',
      ItemNew: '',
      Receiver: '',
      Receivername: '',
      Receiveremail: '',
      Receivernumber: '',
      Receiveraddress: '',
      id: '',
    },
  ];
  newAttribute: any = {};

  Location: any;
  address: string;
  private geoCoder;
  company_lat: number;
  company_lng: number;

  agmshowmap = 0;
  agmshowmap1: any = [];
  agmshowmap2 = 0;

  @ViewChild('search')

  public searchElementRef: ElementRef;
  sender_latitude: number;
  sender_longitude: number;
  SelectLang: string;
  PickTimeReq: string;
  PickLocReq: string;
  DelTimeReq: string;
  RecNameReq: string;
  RecNumReq: string;
  InvRecNum: string;
  InvEmail: string;
  RecAddressReq: string;
  ItemReq: string;
  SendMobReq: string;
  PickTime: string;
  PickTimes: string;
  Close: string;
  DelAgeReq: string;
  OrderCreate: string;
  zoneList: any;

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private loader: NgxSpinnerService,
    private toastr: ToastrService,
    private authService: AuthService,
    public appService: AppService,
    public apiService: ApiService,
    private formBuilder: FormBuilder,
    private generalService: GeneralService,
    public sharedfunction: SharedFunctionService,
    private snackBar: MatSnackBar,
    public datepipe: DatePipe,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    public ref: ChangeDetectorRef,
    public translate: TranslateService
  ) { }

  ngOnInit() {
    this.SelectLang = localStorage.getItem("language");
    this.translate.get('Pickup time is Required').subscribe((text: string) => {
      this.PickTimeReq = text
    });
    this.translate.get('Pickup Location is Required').subscribe((text: string) => {
      this.PickLocReq = text
    });
    this.translate.get('Delivery time is Required').subscribe((text: string) => {
      this.DelTimeReq = text
    });
    this.translate.get('Receiver name is Required').subscribe((text: string) => {
      this.RecNameReq = text
    });
    this.translate.get('Receiver number is Required').subscribe((text: string) => {
      this.RecNumReq = text
    });
    this.translate.get('Invalid Receiver number').subscribe((text: string) => {
      this.InvRecNum = text
    });
    this.translate.get('Invalid Email Address').subscribe((text: string) => {
      this.InvEmail = text
    });
    this.translate.get('Receiver address is Required').subscribe((text: string) => {
      this.RecAddressReq = text
    });
    this.translate.get('Item is Required').subscribe((text: string) => {
      this.ItemReq = text
    });
    this.translate.get('Sender mobile number is Required').subscribe((text: string) => {
      this.SendMobReq = text
    });
    this.translate.get('Pickup time should not be before today').subscribe((text: string) => {
      this.PickTime = text
    });
    this.translate.get('Delivery time should be greater than pickup time').subscribe((text: string) => {
      this.PickTimes = text
    });
    this.translate.get('Delivery Agent Required').subscribe((text: string) => {
      this.DelAgeReq = text
    });
    this.translate.get('Order has been successfully created').subscribe((text: string) => {
      this.OrderCreate = text
    });
    this.translate.get('Close').subscribe((text: string) => {
      this.Close = text
    });
    this.translate.use(this.SelectLang);
    this.userData = JSON.parse(localStorage.getItem("user_data"));
    this.loader.show();
    this.apiService
      .userActiveLists()
      .subscribe((response: any) => {
        this.userList = response.data;
        this.loader.hide();
      });
    this.firstFormGroup = this.formBuilder.group({
      Pickuptime: ['', Validators.required],
      Pickuplocation: ['', Validators.required],
      Pickupnumber: [''],
      id: [''],
    });
    let newForm = this.formBuilder.group({
      formArray1: this.formBuilder.array([])
    });
    const arrayControl = <FormArray>newForm.controls['formArray1'];
    var formGroup = this.formBuilder.group({
      Deliveryat: [''],
      Orderid: [''],
      Item: [''],
      ItemNew: [''],
      Receiver: [''],
      Receivername: [''],
      Receiveremail: [''],
      Receivernumber: [''],
      Receiveraddress: [''],
      id: [''],
      formArray2: this.formBuilder.array([
        // item form groups
      ]),
      Orderamount: [''],
      Paymenttype: ['']
    });
    arrayControl.controls.push(formGroup);
    this.secondFormGroup = newForm;
    this.thirdFormGroup = this.formBuilder.group({
      Sendername: [''],
      Sendernumber: ['', Validators.required],
      Schedulestatus: ['Unallocated', Validators.required],
      Senderaddress: [''],
      SenderZone: ['', Validators.required],
      Scheduleuser: [''],
      Geolocationmeters: ['0', Validators.required],
    });
    if (this.userData.profile) {
      if (this.userData.profile.country_code != '') {
        this.preferredCountries = [this.userData.profile.country_code.toLowerCase()];
      } else {
        this.apiService
          .getIpInfo()
          .subscribe((response: any) => {
            if (response.country != '') {
              this.preferredCountries = [
                response.country.toLowerCase()
              ];
            } else {
              this.preferredCountries = [
                CountryISO.India,
                CountryISO.Singapore,
                CountryISO.UnitedArabEmirates,
                CountryISO.Canada,
              ];
            }
          });
      }

      if (this.userData.profile.delivery_logic) {
        this.Pickuplocation = this.userData.profile.pickup_address;
        this.pickupAddress = this.userData.profile.pickup_address;
        // this.firstFormGroup.value.Pickuplocation = this.userData.profile.pickup_address;
        this.firstFormGroup.controls['Pickuplocation'].setValue(this.userData.profile.pickup_address);
      }
    }
    this.firstFormGroup.get('Pickuplocation').valueChanges.subscribe(value => console.log('value changed', value))
    const now = new Date();
    this.minDate = new Date();
    this.minDate.setDate(now.getDate());
    this.callReceivers();
    this.callItems();
    this.getDriverZones();

    this.mapsAPILoader.load().then(() => {
      this.setCurrentLocation();
      this.geoCoder = new google.maps.Geocoder;
      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();
          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          //set latitude, longitude and zoom
          this.latitude = parseFloat(this.userData.profile.company_lat);
          this.longitude = parseFloat(this.userData.profile.company_lng);
          this.sender_latitude = parseFloat(this.userData.profile.company_lat);
          this.sender_longitude = parseFloat(this.userData.profile.company_lng);
          this.zoom = 12;
        });
      });
    });
  }

  getDriverZones() {
    this.apiService
      .drivingZoneList()
      .subscribe((response: any) => {
        this.zoneList = response.data;
      });
  }

  get deliveryForm() { return this.secondFormGroup.controls; }

  switchLang(lang: string) {
    localStorage.setItem("language", lang)
    this.translate.use(lang);
  }

  goForward(stepper: MatStepper) {
    if (this.firstFormGroup.value.Pickuptime == "" || this.firstFormGroup.value.Pickuptime == null) {
      this.snackBar.open(this.PickTimeReq, this.Close, {
        duration: 4000,
        verticalPosition: 'bottom'
      })
    } else if (this.firstFormGroup.value.Pickuplocation == "" || this.firstFormGroup.value.Pickuplocation == null) {
      this.snackBar.open(this.PickLocReq, this.Close, {
        duration: 4000,
        verticalPosition: 'bottom'
      })
    } else if (this.firstFormGroup.value.Pickupnumber == null) {
      this.snackBar.open("Invalid Pickup Number", "Close", {
        duration: 4000,
        verticalPosition: 'bottom'
      });
      return false;
    } else {
      this.firstFormGroup.value.id = this.pickupFormData.length + 1;
      this.pickupFormData.push(this.firstFormGroup.value);
      stepper.next();
    }
  }
  AddNew(index: number) {
    var formobj = <FormArray>this.secondFormGroup.controls.formArray1;
    this.receivershow[index] = false;
    // this.Receivername = [];
    // this.Receivernumber = [];
    // this.Receiveremail = [];
    // this.Receiveraddress = [];
    formobj.controls[index].patchValue({
      Receivername: "",
      Receivernumber: " ",
      Receiveremail: "",
      Receiveraddress: "",
      Receiver: ""
    });
  }
  ItemNew() {
    this.newitemshow = true;
  }
  NewItem() {
    this.itemnewshow = true;
  }
  goBack(stepper: MatStepper) {
    stepper.previous();
  }
  goBackward(stepper: MatStepper) {
    stepper.previous();
  }
  goNext(stepper: MatStepper) {
    var isValid = true;
    if (this.firstFormGroup.value.Pickuptime == "" || this.firstFormGroup.value.Pickuptime == null) {
      this.snackBar.open(this.PickTimeReq, this.Close, {
        duration: 4000,
        verticalPosition: 'bottom'
      });
      isValid = false;
    } else if (this.firstFormGroup.value.Pickuplocation == "" || this.firstFormGroup.value.Pickuplocation == null) {
      this.snackBar.open(this.PickLocReq, this.Close, {
        duration: 4000,
        verticalPosition: 'bottom'
      });
      isValid = false;
    }
    if (isValid) {
      var formobj = <FormArray>this.secondFormGroup.controls.formArray1;
      for (var i = 0; i < formobj.controls.length; i++) {
        if (!this.secondValid(i)) {
          isValid = false;
          break;
        }
        // if(formobj.controls[i].value.Deliveryat == "" || formobj.controls[i].value.Deliveryat == null){
        //   this.snackBar.open("Delivery Time is Required","Close",{
        //     duration: 4000,
        //     verticalPosition:'bottom'
        //   });
        //   isValid = false;
        //   break;
        // }else if(this.firstFormGroup.value.Pickuptime > formobj.controls[i].value.Deliveryat){
        //   this.snackBar.open("Delivery Time Should be Greater than Pickup Time","Close",{
        //     duration: 4000,
        //     verticalPosition:'bottom'
        //   });
        //   isValid = false;
        //   break;
        // }
      }
      if (isValid) {
        this.deliveryFormData = [];
        for (var i = 0; i < formobj.controls.length; i++) {
          this.secondFormGroup.value.id = this.deliveryFormData.length + 1;
          this.deliveryFormData.push(formobj.controls[i].value);
        }
        stepper.next();
      }
    }

  }
  firstValid() {
    if (this.firstFormGroup.value.Pickuptime == "" || this.firstFormGroup.value.Pickuptime == null) {
      this.snackBar.open(this.PickTimeReq, this.Close, {
        duration: 4000,
        verticalPosition: 'bottom'
      });
      return false;
    } else if (this.firstFormGroup.value.Pickuplocation == "" || this.firstFormGroup.value.Pickuplocation == null) {
      this.snackBar.open(this.PickLocReq, this.Close, {
        duration: 4000,
        verticalPosition: 'bottom'
      });
      return false;
    }
    else {
      this.nextshow = true;
      return true;
    }
  }
  secondValid(index: number) {
    var formobj = <FormArray>this.secondFormGroup.controls.formArray1;
    var obj = formobj.controls[index];
    // debugger
    if (obj.value.Receiveremail != '') {
      if (obj.value.Receiveremail != undefined) {
        if (!/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/.test(obj.value.Receiveremail)) {
          this.snackBar.open("Invalid Email", "Close", {
            duration: 4000,
            verticalPosition: 'bottom'
          })
          return 0;
        }
      }
    }
    if (obj.value.Receivernumber == null) {
      this.snackBar.open("Invalid Receiver Number", "Close", {
        duration: 4000,
        verticalPosition: 'bottom'
      });
      return false;
    }
    if (obj.value.Deliveryat == "" || obj.value.Deliveryat == null) {
      this.snackBar.open(this.DelTimeReq, this.Close, {
        duration: 4000,
        verticalPosition: 'bottom'
      });
      return false;
    } else if (obj.value.Receivername == "" || obj.value.Receivername == null) {
      this.snackBar.open(this.RecNameReq, this.Close, {
        duration: 4000,
        verticalPosition: 'bottom'
      });
      return false;
    } else if (obj.value.Receivernumber == "" || obj.value.Receivernumber == null) {
      this.snackBar.open(this.RecNumReq, this.Close, {
        duration: 4000,
        verticalPosition: 'bottom'
      });
      return false;
    } else if (obj.value.Receiveraddress == "" || obj.value.Receiveraddress == null) {
      this.snackBar.open(this.RecAddressReq, this.Close, {
        duration: 4000,
        verticalPosition: 'bottom'
      });
      return false;
    } else if (obj.value.Orderamount == "" || obj.value.Orderamount == null) {
      this.snackBar.open("Order amount is required", this.Close, {
        duration: 4000,
        verticalPosition: 'bottom'
      });
      return false;
    } else if (obj.value.Paymenttype == "" || obj.value.Paymenttype == null) {
      this.snackBar.open("Payment type is required", this.Close, {
        duration: 4000,
        verticalPosition: 'bottom'
      });
      return false;
    } else if ((obj.value.Item == "" || obj.value.Item == null) &&
      (obj.value.ItemNew == "" || obj.value.ItemNew == null)) {
      this.snackBar.open(this.ItemReq, this.Close, {
        duration: 4000,
        verticalPosition: 'bottom'
      });
      return false;
    } else if (this.firstFormGroup.value.Pickuptime > obj.value.Deliveryat) {
      this.snackBar.open(this.PickTimes, this.Close, {
        duration: 4000,
        verticalPosition: 'bottom'
      });
    }
    else {
      // let phoneNumber = obj.value.Receivernumber.number.replace(/\s/g,'');
      // if(!phoneNumber.match(this.phone_number_regex)){
      //   this.snackBar.open("Invalid Phone Number","Close",{
      //     duration: 4000,
      //     verticalPosition:'bottom'
      //   });
      //   return false;
      // } 
      // else {
      //   this.nextstepshow = true;
      //   return true;
      // }
      this.nextstepshow = true;
      return true;
    }
  }
  thirdValid() {
    if (this.thirdFormGroup.value.Sendernumber == "" || this.thirdFormGroup.value.Sendernumber == null) {
      this.snackBar.open(this.SendMobReq, this.Close, {
        duration: 4000,
        verticalPosition: 'bottom'
      });
      return false;
    } else if (this.thirdFormGroup.value.Schedulestatus == "" || this.thirdFormGroup.value.Schedulestatus == null) {
      this.snackBar.open("Schedule Status is Required", "Close", {
        duration: 4000,
        verticalPosition: 'bottom'
      });
      return false;
    } else if (this.thirdFormGroup.value.Geolocationmeters == "" || this.thirdFormGroup.value.Geolocationmeters == null) {
      this.snackBar.open("Geo Location Meters is Required", "Close", {
        duration: 4000,
        verticalPosition: 'bottom'
      });
      return false;
    } else {
      // let phoneNumber = this.thirdFormGroup.value.Sendernumber.number.replace(/\s/g,'');
      // if(!phoneNumber.match(this.phone_number_regex)){
      //   this.snackBar.open("Invalid Sender Mobile Number","Close",{
      //     duration: 4000,
      //     verticalPosition:'bottom'
      //   });
      //   return false;
      // }
      // else
      if (this.thirdFormGroup.value.Schedulestatus == "Allocated") {
        if (this.thirdFormGroup.value.Scheduleuser == "" || this.thirdFormGroup.value.Scheduleuser == null) {
          this.snackBar.open(this.DelAgeReq, this.Close, {
            duration: 4000,
            verticalPosition: 'bottom'
          });
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    }
  }
  submit() {
    if (!this.thirdValid()) {
      return 0;
    }
    let senderFormData = this.thirdFormGroup.value;
    let pickupFormData, deliveryFormData, payload;
    if (this.isLogic == 1) {
      let requestData = [];
      for (var i = 0; i < this.deliveryFormData.length; i++) {
        let Item = [];
        if (this.deliveryFormData[i].Item.length > 0) {
          this.deliveryFormData[i].Item.forEach(obj => {
            Item.push(obj.id);
          });
        }
        requestData.push({
          "schedule": this.datepipe.transform(this.deliveryFormData[i].Deliveryat, 'yyyy-MM-dd H:mm'),
          "order_id": this.deliveryFormData[i].Orderid,
          "delivery_notes1": Item,
          "delivery_notes2": this.deliveryFormData[i].ItemNew ? this.deliveryFormData[i].ItemNew.split(',') : [],
          "delivery_notes_item_quantity": this.deliveryFormData[i].formArray2,
          "cust_name": "",
          "cust_phone": this.deliveryFormData[i].Receivernumber,
          "cust_email": this.deliveryFormData[i].Receiveremail,
          "temp_cust_email": "",
          "cust_address": this.deliveryFormData[i].Receiveraddress,
          "loc_lat": this.deleiveryLocation[i].latitude,
          "loc_lng": this.deleiveryLocation[i].longitude,
          "cust_id": this.deliveryFormData[i].Receiver,
          "receiver_name": this.deliveryFormData[i].Receivername,
          "order_amount": this.deliveryFormData[i].Orderamount,
          "payment_type": this.deliveryFormData[i].Paymenttype,
          "payment_status": this.deliveryFormData[i].Paymenttype == 1 ? 1 : 0,
        });
      }
      pickupFormData = this.pickupFormData[0];
      deliveryFormData = requestData;
    }
    if (this.isLogic == 1) {
      payload = {
        data: {
          "status": senderFormData.Schedulestatus,
          "type": 0,
          "driving_zone_id": senderFormData.SenderZone ? senderFormData.SenderZone : undefined,
          "emp": senderFormData.Scheduleuser ? senderFormData.Scheduleuser : undefined,
          "method": "Pickup",
          "is_multidelivery": true,
          "is_multipickup": false,
          "schedule": new Date(),
          "picktime": new Date(),
          "loc_lat": this.deleiveryLocation[0].latitude,
          "loc_lng": this.deleiveryLocation[0].longitude,
          "pickup_ladd": this.pickupLocation[0].latitude,
          "pickup_long": this.pickupLocation[0].longitude,
          "sent_ladd": this.senderLocation[0]?.latitude ? this.senderLocation[0].latitude : "",
          "sent_long": this.senderLocation[0]?.longitude ? this.senderLocation[0].longitude : "",
          "geo_fence_meter": senderFormData.Geolocationmeters,
          "time_requirement": "",
          "time_to_delivery": "",
          "showpick": false,
          "showdeliv": false,
          "multiple_pickup": [
            {
              "picktime": this.datepipe.transform(pickupFormData.Pickuptime, 'yyyy-MM-dd H:mm'),
              "pick_address": pickupFormData.Pickuplocation,
              "delivery_notes": [],
              "pickup_ladd": this.pickupLocation[0].latitude,
              "pickup_long": this.pickupLocation[0].longitude,
              "pickup_phone": pickupFormData.Pickupnumber,
              "product_weight": "",
              "time_to_delivery": "",
              "time_requirement": "",
              "product_length": "",
              "product_height": "",
              "product_breadth": ""
            }
          ],
          "multiple_delivery": deliveryFormData,
          "delivery_logic": this.isLogic,
          "sender_name": senderFormData.Sendername,
          "sender_number": senderFormData.Sendernumber,
          "sent_address": senderFormData.Senderaddress,
          "is_geo_fence": 1
        },
        "geo_fence_meter": senderFormData.Geolocationmeters,
        "sender_name": senderFormData.Sendername
      }
    }
    this.loader.show();
    this.apiService
      .addNewOrder(payload)
      .subscribe((response: any) => {
        if (response.status == 'ok') {
          this.toastr.success(this.OrderCreate);
          this.router.navigate(["/dashboard"]);
        } else {
          this.toastr.warning(response.data);
        }
        this.loader.hide();
      });
  }

  showmap(value: number) {
    this.agmshowmap = value;
  }

  showmap1(value: number, i: number) {
    this.agmshowmap1[i] = value;
  }

  showmap2(value: number, i: number) {
    this.agmshowmap2 = value;
  }

  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        if ((this.latitude == null || this.latitude == undefined) || (this.longitude == null || this.longitude == undefined)) {
          this.zoom = 8;
          if (this.userData.profile) {
            if (this.userData.profile.delivery_logic) {
              this.Location = this.userData.profile.pickup_address;
              this.Pickuplocation = this.userData.profile.pickup_address;
              this.firstFormGroup.value.Pickuplocation = this.userData.profile.pickup_address;
              this.geoCoder.geocode({ 'address': this.userData.profile.pickup_address }, (results, status) => {
                this.latitude = parseFloat(results[0].geometry.location.lat());
                this.longitude = parseFloat(results[0].geometry.location.lng());
                this.zoom = 12;
                this.pickupLocation.push({
                  latitude: this.latitude, longitude: this.longitude
                })
                this.deleiveryLocation.push({
                  latitude: this.latitude, longitude: this.longitude
                })
                // this.getAddress(this.latitude, this.longitude);
              });
            }
          } else {
            this.latitude = parseFloat(this.userData.profile.company_lat);
            this.longitude = parseFloat(this.userData.profile.company_lng);
            this.getAddress(this.latitude, this.longitude);
          }
        } else {
          this.zoom = 8;
          this.getAddress(this.latitude, this.longitude);
        }

        if ((this.sender_latitude == null || this.sender_latitude == undefined) || (this.sender_longitude == null || this.sender_longitude == undefined)) {
          this.zoom = 8;
          if (this.userData.profile) {
            if (this.userData.profile.delivery_logic) {
              this.Location = this.userData.profile.pickup_address;
              this.Pickuplocation = this.userData.profile.pickup_address;
              this.firstFormGroup.value.Pickuplocation = this.userData.profile.pickup_address;
              this.geoCoder.geocode({ 'address': this.userData.profile.pickup_address }, (results, status) => {
                this.sender_latitude = parseFloat(results[0].geometry.location.lat());
                this.sender_longitude = parseFloat(results[0].geometry.location.lng());
                this.zoom = 12;
                this.pickupLocation.push({
                  latitude: this.sender_latitude, longitude: this.sender_longitude
                })
                this.deleiveryLocation.push({
                  latitude: this.sender_latitude, longitude: this.sender_longitude
                })
                // this.getAddress(this.latitude, this.longitude);
              });
            }
          } else {
            this.sender_latitude = parseFloat(this.userData.profile.company_lat);
            this.sender_longitude = parseFloat(this.userData.profile.company_lng);
            this.getAddress_sender(this.sender_latitude, this.sender_longitude);
          }
        } else {
          this.zoom = 8;
          this.getAddress_sender(this.sender_latitude, this.sender_longitude);
        }
      });
    }
  }

  markerDragEndNew($event: google.maps.MouseEvent) {
    this.latitude = $event.latLng.lat();
    this.longitude = $event.latLng.lng();
    this.getAddress(this.latitude, this.longitude);
    this.pickupLocation[0] = {
      latitude: this.latitude, longitude: this.longitude
    }
    this.ref.detectChanges();
  }

  markerDragEndNew3($event: google.maps.MouseEvent) {
    this.sender_latitude = $event.latLng.lat();
    this.sender_longitude = $event.latLng.lng();
    this.getAddress_sender(this.sender_latitude, this.sender_longitude);
    this.senderLocation[0] = {
      latitude: this.sender_latitude, longitude: this.sender_longitude
    }
    this.ref.detectChanges();
  }

  markerDragEndNew2($event1: google.maps.MouseEvent, index: number) {
    this.latitude = $event1.latLng.lat();
    this.longitude = $event1.latLng.lng();
    this.getAddress_delivery(this.latitude, this.longitude, index);
    this.deleiveryLocation[index] = {
      latitude: this.latitude, longitude: this.longitude
    }
    this.ref.detectChanges();
  }

  getAddress_delivery(latitude, longitude, index) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;
          this.Location = results[0].formatted_address;
          const arrayControl = <FormArray>this.secondFormGroup.controls['formArray1'];
          arrayControl.controls[index].patchValue({
            Receiveraddress: results[0].formatted_address
          });
          this.Receiveraddress = results[0].formatted_address;
        } else {
          console.log('No results found');
        }
      } else {
        console.log('Geocoder failed due to: ' + status);
      }

    });
  }

  getAddress(latitude, longitude) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;
          this.Location = results[0].formatted_address;
          this.Pickuplocation = results[0].formatted_address;
          this.pickupAddress = results[0].formatted_address;
          this.firstFormGroup.patchValue({
            Pickuplocation: results[0].formatted_address
          });
          this.latitude = latitude;
          this.longitude = longitude;
        } else {
          console.log('No results found');
        }
      } else {
        console.log('Geocoder failed due to: ' + status);
      }

    });
  }

  getAddress_sender(latitude, longitude) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;
          this.thirdFormGroup.controls['Senderaddress'].setValue(results[0].formatted_address);
          this.senderAddress = results[0].formatted_address
          this.sender_latitude = latitude;
          this.sender_longitude = longitude;
        } else {
          console.log('No results found');
        }
      } else {
        console.log('Geocoder failed due to: ' + status);
      }

    });
  }

  onLocationSelected(location: Location, isWhere: any, index: number) {
    this.latitude = location.latitude;
    this.longitude = location.longitude;
    if (isWhere == 'pickup') {
      this.pickupLocation[0] = {
        latitude: location.latitude, longitude: location.longitude
      }
    }
    if (isWhere == 'delivery') {
      this.deleiveryLocation[index] = {
        latitude: location.latitude, longitude: location.longitude
      }
    }
    if (isWhere == 'sender') {
      this.senderLocation[0] = {
        latitude: location.latitude, longitude: location.longitude
      }
      this.sender_latitude = location.latitude;
      this.sender_longitude = location.longitude;
    }
  }
  onGermanAddressMapped($event: GermanAddress) {
    console.log('onGermanAddressMapped', $event);
  }
  markerDragEnd(position: any) {
    this.latitude = position.coords.latitude;
    this.longitude = position.coords.longitude;
  }
  pickupAdd() {
    // this.receivershow = false;
    if (this.firstValid()) {
      this.isPickup = '';
      this.isPickupShow = true;


    }
  }
  pickupAddMore() {
    this.isPickup = 'add';
    this.isPickupShow = true;
    this.pickupAddress = [];
    this.firstFormGroup = this.formBuilder.group({
      Pickuptime: [this.datepipe.transform(new Date(), 'yyyy-MM-dd H:mm'), Validators.required],
      Pickuplocation: ['', Validators.required],
      id: [''],
    });
  }
  RemovePickup() {
    this.nextshow = true;
    this.isPickupShow = false;
    this.isPickup = 'addMore';
  }
  pickupEdit(obj: any) {
    this.nextshow = false;
    this.isPickup = 'save';
    this.isPickupShow = true;
    this.pickupAddress = obj.Pickuplocation
    this.Pickuplocation = obj.Pickuplocation
    this.firstFormGroup.patchValue({
      id: obj.id,
      Pickuptime: obj.Pickuptime,
      Pickuplocation: obj.Pickuplocation,
    });
  }
  pickupSave() {
    if (this.firstValid()) {
      this.isPickup = 'addMore';
      this.isPickupShow = false;
      this.Pickuplocation = this.firstFormGroup.value.Pickuplocation
      var foundIndex = this.pickupFormData.findIndex(obj => obj.id == this.firstFormGroup.value.id);
      this.pickupFormData[foundIndex] = { ...this.firstFormGroup.value };
    }
  }
  pickupDelete(obj: any) {
    var foundIndex = this.pickupFormData.findIndex(x => x.id == obj.id);
    this.pickupFormData.splice(foundIndex, 1);
    this.firstFormGroup.patchValue({
      id: "",
      Pickuptime: "",
      Pickuplocation: "",
    });
    this.pickupAddress = "";
    this.Pickuplocation = "";
    this.nextshow = false;
    this.isPickupShow = true;
    this.isPickup = 'add';
  }
  deliveryAdd() {
    var arrayControl = <FormArray>this.secondFormGroup.controls['formArray1'];
    var index = arrayControl.length - 1;
    if (this.secondValid(index)) {
      this.isDelivery = 'addMore';
      this.isDeliveryShow = true;

      this.secondFormGroup.value.formArray1[0] = arrayControl.controls[index].value;
    }
  }
  getControls() {
    return (this.secondFormGroup.controls['formArray1'] as FormArray).controls;
  }
  RemoveDelivery(index, number) {
    this.nextstepshow = true;
    this.isDeliveryShow = false;
    this.isDelivery = 'addMore';
    const arrayControl = <FormArray>this.secondFormGroup.controls['formArray1'];
    arrayControl.removeAt(index);
    this.deliveryFormData.splice(index, 1);
  }
  deliveryAddMore() {
    this.fieldArray.push(this.newAttribute);
    this.newAttribute = {};
    var ind = this.fieldArray.length - 1;
    this.fieldArray[ind]['picktime'] = [];

    this.isDelivery = 'add';
    this.isDeliveryShow = true;
    var arrayControl = <FormArray>this.secondFormGroup.controls['formArray1'];
    var formGroup = this.formBuilder.group({
      Deliveryat: [''],
      Orderid: [''],
      Item: [''],
      ItemNew: [''],
      Receiver: [''],
      Receivername: [''],
      Receiveremail: [''],
      Receivernumber: [''],
      Receiveraddress: [''],
      id: [''],
      formArray2: this.formBuilder.array([
        // item form groups
      ]),
      Orderamount: [''],
      Paymenttype: ['']
    });
    arrayControl.controls[arrayControl.controls.length] = formGroup;
    arrayControl.controls[arrayControl.controls.length - 1].patchValue({
      Receiveraddress: '',
      Receivernumber: '',
      Receiveremail: '',
      Receivername: ''
    });
  }
  deliveryEdit(obj: any) {
    this.secondFormGroup.patchValue({
      id: obj.id,
      Deliveryat: obj.Deliveryat,
      Orderid: obj.Orderid,
      Item: obj.Item,
      ItemNew: obj.ItemNew,
      Receiver: obj.Receiver,
      Receivername: obj.Receivername,
      Receiveremail: obj.Receiveremail,
      Receivernumber: obj.Receivernumber,
      Receiveraddress: obj.Receiveraddress,
    });
    this.receiverAddress = obj.Receiveraddress;
    this.nextstepshow = false;
    this.isDelivery = 'save';
    this.isDeliveryShow = true;
  }
  deliverySave() {
    if (this.secondValid(0)) {
      this.isDelivery = 'addMore';
      this.isDeliveryShow = false;
      var foundIndex = this.deliveryFormData.findIndex(obj => obj.id == this.secondFormGroup.value.id);
      this.deliveryFormData[foundIndex] = { ...this.secondFormGroup.value };
    }
  }
  deliveryDelete(obj: any) {
    this.isDeliveryShow = false;
    this.isDelivery = 'addMore';
    var foundIndex = this.deliveryFormData.findIndex(x => x.id == obj.id);
    this.deliveryFormData.splice(foundIndex, 1);
    this.secondFormGroup.patchValue({
      id: "",
      Deliveryat: "",
      Orderid: "",
      Item: "",
      ItemNew: "",
      Receiver: "",
      Receivername: "",
      Receiveremail: "",
      Receivernumber: "",
      Receiveraddress: "",
      Orderamount: '',
      Paymenttype: ''
    });
    this.Receivername = "";
    this.Receivernumber = "";
    this.Receiveremail = "";
    this.receiverAddress = "";
    this.Receiveraddress = "";
    if (this.deliveryFormData.length == 0) {
      this.deliveryAddMore();
    }
  }
  onChange(result: PlaceResult, arg: any) {
    let val = result['target']['value']
    if (val == '') {
      this.firstFormGroup.patchValue({
        Pickuplocation: ''
      });
    }
  }
  onChangeReceiverAdddress(result: PlaceResult, arg: any, index) {
    let val = result['target']['value']
    if (val == '') {
      var arrayControl = <FormArray>this.secondFormGroup.controls['formArray1'];
      arrayControl.controls[index].patchValue({
        Receiveraddress: ''
      });
    }
  }
  onAutocompleteSelected(result: PlaceResult, arg: any, index: number) {
    if (arg == 'pickup') {
      this.pickupAddress = result.formatted_address;
      this.firstFormGroup.patchValue({
        Pickuplocation: result.formatted_address,
      });
    }
    if (arg == 'delivery') {
      var arrayControl = <FormArray>this.secondFormGroup.controls['formArray1'];
      this.receiverAddress[index] = result.formatted_address;
      arrayControl.controls[index].patchValue({
        Receiveraddress: result.formatted_address,
      });
    }
    if (arg == 'sender') {
      this.senderAddress = result.formatted_address
      this.thirdFormGroup.patchValue({
        Senderaddress: result.formatted_address,
      });
    }
  }
  changeDeliveryOption() {
    this.isPickup = 'add';
    this.isDelivery = 'add';
    this.pickupFormData = [];
    this.deliveryFormData = [];
    this.pickupAddress = [];
    this.isPickupShow = true;
    this.isDeliveryShow = true;
    this.formDataReset();
    this.callReceivers();
    this.callItems();
  }
  formDataReset() {
    this.firstFormGroup = this.formBuilder.group({
      Pickuptime: ['', Validators.required],
      Pickuplocation: ['', Validators.required],
      id: [''],
    });
    this.secondFormGroup = this.formBuilder.group({
      Deliveryat: ['', Validators.required],
      Orderid: [''],
      Item: ['', Validators.required],
      ItemNew: [''],
      Receiver: [''],
      Receivername: ['', Validators.required],
      Receiveremail: [''],
      Receivernumber: [''],
      Receiveraddress: [''],
      id: [''],
    });
    this.thirdFormGroup = this.formBuilder.group({
      Sendername: [''],
      Sendernumber: ['', Validators.required],
      Schedulestatus: ['Unallocated', Validators.required],
      Senderaddress: [''],
      Scheduleuser: [''],
      Geolocationmeters: ['0', Validators.required],
    });
  }
  callReceivers() {
    this.apiService
      .customerList()
      .subscribe((response: any) => {
        let tempRes = [];
        response.data.forEach(element => {
          tempRes.push(element);
        });
        this.ReceiverOptions = tempRes;
      });
  }
  callItems() {
    this.apiService
      .itemList()
      .subscribe((response: any) => {
        let tempRes = [];
        response.data.forEach(element => {
          tempRes.push({ id: element.id, name: element.name });
        });
        this.ItemOptions = tempRes;
      });
  }
  receiverChanged(arg: any, index: number) {
    var arrayControl = <FormArray>this.secondFormGroup.controls['formArray1'];
    if (arg) {
      let filterData = this.ReceiverOptions.find(obj => obj.id == arg);
      arrayControl.controls[index].patchValue({
        Receivername: filterData.name,
        Receiveremail: filterData.email,
        Receivernumber: filterData.contact_no,
        Receiveraddress: filterData.address
      });
      this.receivershow[index] = true
      this.agmshowmap1[index] = 2;
      this.receiverAddress[index] = filterData.address
      this.deleiveryLocation[index] = {
        latitude: parseFloat(filterData.loc_lat), longitude: parseFloat(filterData.loc_lng)
      }
    } else {
      arrayControl.controls[index].patchValue({
        Receivername: "",
        Receiveremail: "",
        Receivernumber: " ",
        Receiveraddress: "",
      });
      this.agmshowmap1[index] = 0;
      this.receivershow[index] = false;
      this.receiverAddress[index] = "";
      this.deleiveryLocation[index] = {
        latitude: null, longitude: null
      }
    }
  }
  itemChanged(data: any, index: number) {
    this.loader.show();
    if (data.length) {
      setTimeout(() => {
        let itemList = this.secondFormGroup.controls['formArray1']['controls'][index]['controls']['formArray2']['value'];
        for (let i = 0; i < itemList.length; i++) {
          if (itemList[i].IsFrom == "oldItem") {
            if (!data.find(obj => obj.name.toLowerCase() == itemList[i].ItemName.toLowerCase())) {
              // condition true
              let arrayControl = <FormArray>this.secondFormGroup.controls['formArray1']['controls'][index]['controls']['formArray2'];
              arrayControl.removeAt(i);
            }
          }
        }
        let newItemList = this.secondFormGroup.controls['formArray1']['controls'][index]['controls']['formArray2']['value'];
        for (let i = 0; i < data.length; i++) {
          let isNew = newItemList.find(obj => obj.ItemName.toLowerCase() == data[i].name.toLowerCase());
          if (!isNew) {
            const control = (<FormArray>this.deliveryForm.formArray1).at(index).get('formArray2') as FormArray;
            control.push(
              this.formBuilder.group({
                ItemName: [data[i].name],
                Quantity: [1],
                IsFrom: ["oldItem"],
                id: [data[i].id],
              }));
          }
        }
        this.loader.hide();
      }, 300);
    } else {
      setTimeout(() => {
        let itemList = this.secondFormGroup.controls['formArray1']['controls'][index]['controls']['formArray2']['value'];
        if (itemList.length) {
          for (let i = 0; i < itemList.length; i++) {
            if (itemList[i].IsFrom == "oldItem") {
              if (!data.find(obj => obj.name.toLowerCase() == itemList[i].ItemName.toLowerCase())) {
                // condition true
                let arrayControl = <FormArray>this.secondFormGroup.controls['formArray1']['controls'][index]['controls']['formArray2'];
                arrayControl.removeAt(i);
              }
            }
          }
        } else {
          let arrayControl = <FormArray>this.secondFormGroup.controls['formArray1']['controls'][index]['controls']['formArray2']['value'];
          arrayControl.removeAt(index);
        }
        this.loader.hide();
      });
    }
  }
  newItemChanged(object: any, index: number) {
    this.loader.show();
    let data = object ? object.split(',') : [];
    if (data.length) {
      setTimeout(() => {
        let itemList = this.secondFormGroup.controls['formArray1']['controls'][index]['controls']['formArray2']['value'];
        for (let i = 0; i < itemList.length; i++) {
          if (itemList[i].IsFrom == "newItem") {
            if (!data.find(obj => obj.toLowerCase() == itemList[i].ItemName.toLowerCase())) {
              let arrayControl = <FormArray>this.secondFormGroup.controls['formArray1']['controls'][index]['controls']['formArray2'];
              var arrayIndex = this.secondFormGroup.controls['formArray1']['controls'][index]['controls']['formArray2']['value'].findIndex(obj => obj.id_fork == itemList[i].id_fork);
              arrayControl.removeAt(arrayIndex);
            }
          }
        }
        let newItemList = this.secondFormGroup.controls['formArray1']['controls'][index]['controls']['formArray2']['value'];
        for (let i = 0; i < data.length; i++) {
          if (!this.secondFormGroup.controls['formArray1']['controls'][index]['controls']['formArray2']['value'].find(obj => obj.ItemName.toLowerCase() == data[i].toLowerCase())) {
            if (data[i] && !this.hasWhiteSpaces(data[i])) {
              const control = (<FormArray>this.deliveryForm.formArray1).at(index).get('formArray2') as FormArray;
              control.push(
                this.formBuilder.group({
                  ItemName: [data[i]],
                  Quantity: [1],
                  IsFrom: ["newItem"],
                  id_fork: [i + data[i] + i],
                  id: [""],
                }));
            }
          }
        }
        this.loader.hide();
      }, 300);
    } else {
      setTimeout(() => {
        let itemList = this.secondFormGroup.controls['formArray1']['controls'][index]['controls']['formArray2']['value'];
        for (let i = 0; i < itemList.length; i++) {
          if (itemList[i].IsFrom == "newItem") {
            let arrayControl = <FormArray>this.secondFormGroup.controls['formArray1']['controls'][index]['controls']['formArray2'];
            var arrayIndex = this.secondFormGroup.controls['formArray1']['controls'][index]['controls']['formArray2']['value'].findIndex(obj => obj.id_fork == itemList[i].id_fork);
            arrayControl.removeAt(arrayIndex);
          }
        }
        this.loader.hide();
      }, 300);
    }
  }
  hasWhiteSpaces(string) {
    let newString = string.replace(/^[ ]+/g, "");
    if (newString != null && newString != undefined && newString != '') {
      return false;
    } else {
      return true;
    }
  }
  changePreferredCountries() {
    this.preferredCountries = [
      CountryISO.India,
      CountryISO.Canada,
    ];
  }

  zoneChange(value) {
    if (value == '') {
      this.userList = [];
    }
    // else if (value == '1') {
    //   this.loader.show();
    //   this.apiService
    //     .userActiveLists()
    //     .subscribe((response: any) => {
    //       this.userList = response.data;
    //       this.loader.hide();
    //     });
    // }
    else {
      this.zoneList.forEach(element => {
        if (element.id == value) {
          this.userList = element.selected_drivers;
        }
      });
    }
  }

  statusChange(obj) {
    // this.thirdFormGroup.controls['Scheduleuser'].setValue('');
    if (obj != 'Unallocated') {
      this.userListShow = true;
      // this.thirdFormGroup.controls['SenderZone'].setValidators(Validators.required);
      this.thirdFormGroup.controls['Scheduleuser'].setValidators(Validators.required);
    } else {
      // this.thirdFormGroup.controls['SenderZone'].setValue('');
      // this.thirdFormGroup.controls['SenderZone'].clearValidators();
      // this.thirdFormGroup.controls['SenderZone'].updateValueAndValidity();

      this.thirdFormGroup.controls['Scheduleuser'].setValue('');
      this.userListShow = false;
      this.thirdFormGroup.controls['Scheduleuser'].clearValidators();
      this.thirdFormGroup.controls['Scheduleuser'].updateValueAndValidity();
    }
  }

  // statusChange(obj) {
  //   if (obj != 'Unallocated') {
  //     this.userListShow = true;
  //     this.thirdFormGroup.controls['Scheduleuser'].setValidators(Validators.required);
  //   } else {
  //     this.userListShow = false;
  //     this.thirdFormGroup.controls['Scheduleuser'].setValue('');
  //     this.thirdFormGroup.controls['Scheduleuser'].clearValidators();
  //     this.thirdFormGroup.controls['Scheduleuser'].updateValueAndValidity();
  //   }
  // }
  omit_char(event: any) {
    const keyChar = event.key;
    let allowCharacter: boolean;
    if (event.target.value.length < 0 || event.target.value.length > 11) {
      event.preventDefault();
    }
    if (keyChar === '-' && event.target.selectionStart !== 0) {
      allowCharacter = false;
    } else if (
      keyChar === 'Tab' ||
      keyChar === 'Enter' ||
      keyChar === 'Backspace' ||
      keyChar === 'ArrowLeft' ||
      keyChar === 'ArrowRight' ||
      keyChar === 'Delete'
    ) {
      allowCharacter = true;
    } else {
      allowCharacter = keyChar >= '0' && keyChar <= '9';
    }
    if (!allowCharacter) {
      event.preventDefault();
    }
  }
}

<!-- <h2 mat-dialog-title>Install Angular</h2> -->
<mat-dialog-content class="mat-typography">
  <div class="form-group">
    <label>Business Name <span class="text-danger">*</span></label>
    <input type="text" class="form-control" placeholder="Enter Business Name">
  </div>
  <div class="form-group">
    <label>Pickup Location <span class="text-danger">*</span></label>
    <input type="text" class="form-control" placeholder="Enter Pickup Location">
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button type="button" class="btn btn-sm btn-outline-info rounded-0 mb-2 mr-2" mat-dialog-close>Cancel</button>
  <button type="button" class="btn btn-sm btn-info rounded-0 mb-2 mr-2" [mat-dialog-close]="true">Add</button>
</mat-dialog-actions>

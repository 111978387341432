<div class="container-fluid">
  <div class="container">
    <div class="row" style="margin-top: 15px;">
      <div class="col text-left">
        <p style="margin-top: 12px;font-size: 16px;"><i class="fa fa-arrow-left fa-theme mr-2 pointer" matTooltip="{{'Go Back' | translate}}" [matTooltipPosition]="'above'" [routerLink]="['/customer']"></i><b>{{'Customer Detail' | translate}}</b></p>
        <!-- <h5 class="mb-4"><b>Customer Detail </b></h5> -->
      </div>
      <div class="col text-right">
        <button type="button" routerLink="/edit-customer" class="btn btn-outline-info rounded-0 mb-2 mr-2" matTooltip="click here"><b><i class="far fa-edit mr-1"></i>{{'Edit' | translate}}</b></button>
      </div>
    </div>
    <div class="row" style="margin-top: 15px;">
      <div class="col-md-12">
        <div class="col-md-12 col-lg-6 mb-4 align-items-stretch" style="float: left;">
          <div class="form-group">
            <mat-form-field style="width: 100%;" appearance="outline">
              <mat-label>{{'Customer ID' | translate}}</mat-label>
              <input matInput value="{{Cust_ID}}" disabled>
            </mat-form-field>
          </div>
        </div>
        <div class="col-md-12 col-lg-6 mb-4 align-items-stretch" style="float: left;">
          <div class="form-group" style="color: black;">
            <mat-form-field style="width: 100%;" appearance="outline">
              <mat-label>{{'Name' | translate}}</mat-label>
              <input matInput value="{{CustName}}" disabled>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="col-md-12 col-lg-6 mb-4 align-items-stretch" style="float: left;">
          <div class="form-group">
            <mat-form-field style="width: 100%;" appearance="outline">
              <mat-label>{{'Contact No' | translate}}</mat-label>
              <input matInput value="{{CustPhone}}" disabled>
            </mat-form-field>
          </div>
        </div>
        <div class="col-md-12 col-lg-6 mb-4 align-items-stretch" style="float: left;">
          <div class="form-group" style="color: black;">
            <mat-form-field style="width: 100%;" appearance="outline">
              <mat-label>{{'Email' | translate}}</mat-label>
              <input matInput value="{{CustEmail}}" disabled>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="col-md-12 col-lg-6 mb-4 align-items-stretch" style="float: left;">
          <div class="form-group">
            <mat-form-field style="width: 100%;" appearance="outline">
              <mat-label>{{'Address' | translate}}</mat-label>
              <input matInput value="{{CustAddress}}" disabled title="{{CustAddress}}">
            </mat-form-field>
          </div>
        </div>
        <div class="col-md-12 col-lg-6 mb-4 align-items-stretch" style="float: left;">
        </div>
      </div>
    </div>
  </div>
</div>
  
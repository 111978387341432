import { ChangePasswordComponent } from './pages/profile/change-password/change-password.component';
import { NgModule, NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule, DatePipe } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgxSpinnerModule } from "ngx-spinner";
import { ToastrModule } from 'ngx-toastr';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { MaterialModule } from './material/material.module';
import { SelectDropDownModule } from 'ngx-select-dropdown';
// import { OwlDateTimeModule, OwlNativeDateTimeModule} from 'ng-pick-datetime';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { NgxPaginationModule } from 'ngx-pagination';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { NgxStarRatingModule } from 'ngx-star-rating';
import { NgxStarsModule } from 'ngx-stars';
import {
  NgxIntlTelInputModule
} from './../../custom-template/ngx-intl-tel-input/src/lib/ngx-intl-tel-input.module';
import { NgxMatIntlTelInputModule } from 'ngx-mat-intl-tel-input';
import { MatGoogleMapsAutocompleteModule } from '@angular-material-extensions/google-maps-autocomplete';
import { AgmCoreModule, GoogleMapsAPIWrapper } from '@agm/core';
import { AgmDirectionModule } from 'agm-direction';
import { Config_MAP_API } from './config/config';
import { NgxMatDatetimePickerModule, NgxMatTimepickerModule, NgxMatNativeDateModule } from '@angular-material-components/datetime-picker';
// import { NgxMatMomentModule } from '@angular-material-components/moment-adapter';
import { TokenInterceptorService } from './services/auth/TokenInterceptorService';
import { AppService } from './services/AppService';
import { AuthGuardService } from './services/AuthGuardService';
import { AuthService } from './services/AuthService';
import { ApiService } from './services/ApiService';
import { FilterPipe } from './services/FilterPipe';
import { GeneralService } from './services/GeneralService';
import { SharedFunctionService } from './services/SharedFunctionService';
import { TimezoneService } from './services/TimezoneService';
import { SocketService } from './services/SocketService';
import { AppComponent } from './app.component';
import { CSNavbarComponent } from './code-segments/navbar/navbar.component';
import { CSFooterComponent } from './code-segments/footer/footer.component';
import { DateTimePickerModule } from 'ngx-datetime-picker';
import { Ng2TelInputModule } from 'ng2-tel-input';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { EmailLoginComponent } from './login/email-login/email-login.component';
import { MobileLoginComponent } from './login/mobile-login/mobile-login.component';
import { SignupComponent } from './signup/signup.component';
import { welcomeComponent } from './welcome/welcome.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { NavbarComponent, NotificationModal } from './components/navbar/navbar.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { FooterComponent } from './components/footer/footer.component';
import { HomeComponent } from './home/home.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { NotifyMeAlertComponent } from './pages/notify-me/notify-me.component';
import { MapPartComponent } from './pages/dashboard/map-part/map-part.component';
import { CalendarComponent } from './pages/calendar/calendar.component';
import { VehicleComponent } from './pages/vehicle/vehicle.component';
import { SupervisorComponent } from './pages/supervisor/supervisor.component';
import { ItemComponent } from './pages/item/item.component';
import { CustomerServiceComponent } from './pages/customer-service/customer-service.component';
import { AgencyComponent } from './pages/agency/agency.component';
import { TrucksComponent } from './pages/trucks/trucks.component';
import { RidersComponent } from './pages/riders/riders.component';
import { VehicleMapComponent } from './pages/vehicle-map/vehicle-map.component';
import { CustomerComponent } from './pages/customer/customer.component';
import { PaymentStatusComponent } from './pages/payment-status/payment-status.component';
import { CustomerReviewComponent } from './pages/customer-review/customer-review.component';
import { DeliveryReportComponent } from './pages/reports/delivery-report/delivery-report.component';
import { MileageReportComponent } from './pages/reports/mileage-report/mileage-report.component';
import { GpsHistoryComponent } from './pages/reports/gps-history/gps-history.component';
import { AddOrderComponent } from './pages/add-order/add-order.component';
import { AddOrderOneComponent } from './pages/add-order/add-order-one/add-order.component';
import { AddOrderTwoComponent } from './pages/add-order/add-order-two/add-order.component';
import { AddOrderThreeComponent } from './pages/add-order/add-order-three/add-order.component';
import { EditOrderComponent } from './pages/edit_order/edit-order.component';
import { EditOrderOneComponent } from './pages/edit_order/edit-order-one/edit-order.component';
import { EditOrderTwoComponent } from './pages/edit_order/edit-order-two/edit-order.component';
import { EditOrderThreeComponent } from './pages/edit_order/edit-order-three/edit-order.component';
import { AddEmployeeComponent } from './pages/add-employee/add-employee.component';
import { AddDrivingZoneComponent } from './pages/add-driving-zone/add-driving-zone.component';
import { DrivingZoneComponent } from './pages/driving-zone/driving-zone.component';
import { AddSupervisorComponent } from './pages/add-supervisor/add-supervisor.component';
import { AddItemComponent } from './pages/add-item/add-item.component';
import { AddVehicleComponent } from './pages/add-vehicle/add-vehicle.component';
import { SupervisorDetailComponent } from './pages/add-supervisor/supervisor-detail/supervisor-detail.component';
import { VehicleDetailComponent } from './pages/add-vehicle/vehicle-detail/vehicle-detail.component';
import { ProfileComponent, DialogProfileDialog } from './pages/profile/profile.component';
import { ProfileEditComponent } from './pages/profile/profile-edit/profile-edit.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { MatPaginatorModule } from '@angular/material/paginator';
import { EditSupervisorComponent } from './pages/edit-supervisor/edit-supervisor.component';
import { EditItemComponent } from './pages/edit-item/edit-item.component';
import { AddCustomerComponent } from './pages/add-customer/add-customer.component';
import { CustomerDetailsComponent } from './pages/customer-details/customer-details.component';
import { EditCustomerComponent } from './pages/edit-customer/edit-customer.component';
import { OrderDetailsComponent } from './pages/order-details/order-details.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { DriverDetailComponent } from './pages/driver-detail/driver-detail.component';
import { AlertModule } from 'ngx-bootstrap/alert';
import { DatepickerModule } from 'ngx-bootstrap/datepicker';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { CustomerRatingComponent } from './pages/customer-rating/customer-rating.component';
import { ManagerComponent } from './pages/manager/manager.component';
import { AddManagerComponent } from './pages/add-manager/add-manager.component';
import { ManagerDetailsComponent } from './pages/manager-details/manager-details.component';
import { EditManagerComponent } from './pages/edit-manager/edit-manager.component';
import { NgxNumberSpinnerModule } from 'ngx-number-spinner';
import { NgImageSliderModule } from 'ng-image-slider';
import { NgMarqueeModule } from 'ng-marquee';
import { PopoverModule } from "ngx-smart-popover";
import { NgxScrollTopModule } from 'ngx-scrolltop';
import { BlockCopyPasteDirective } from './directive/block-copy-paste.directive';
import { NgxCaptchaModule } from 'ngx-captcha';
import { ManageUsersComponent } from './pages/manage-users/manage-users.component';
import { PackageInformationComponent } from './pages/package-information/package-information.component';
import { ViewUserPackageComponent, DialogExtendAction } from './pages/view-user-package/view-user-package.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { PaymentHistoryComponent } from './pages/payment-history/payment-history.component';
import { PaymentDetailInfoComponent } from './pages/payment-detail-info/payment-detail-info.component';
import { PaymentResponseComponent } from './pages/payment-response/payment-response.component';
import { EditDrivingZoneComponent } from './pages/edit-driving-zone/edit-driving-zone.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { AddBulkOrderComponent } from './pages/add-bulk-order/add-bulk-order.component';
import { BulkOrderPopComponent } from './pages/bulk-order-pop/bulk-order-pop.component';
const googleMapsParams = {
  apiKey: Config_MAP_API.GOOGLE_MAPS_API_KEY,
  libraries: ['places', 'drawing', 'geometry'],
  language: 'en',
  region: 'DE'
};

@NgModule({
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    AppRoutingModule,
    CommonModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    MaterialModule,
    SelectDropDownModule,
    NgxSpinnerModule,
    NgxIntlTelInputModule,
    MatGoogleMapsAutocompleteModule,
    NgMultiSelectDropDownModule.forRoot(),
    CollapseModule.forRoot(),
    AgmCoreModule.forRoot(googleMapsParams),
    AgmDirectionModule,
    Ng2TelInputModule,
    MatPaginatorModule,
    MatSnackBarModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatSelectSearchModule,
    NgxPaginationModule,
    NgxStarRatingModule,
    NgxStarsModule,
    NgSelectModule,
    Ng2SearchPipeModule,
    NgxMatSelectSearchModule,
    // OwlDateTimeModule,
    // OwlNativeDateTimeModule,
    NgxMatNativeDateModule,
    // NgxMatMomentModule,
    DateTimePickerModule,
    NgxNumberSpinnerModule,
    NgImageSliderModule,
    NgMarqueeModule,
    PopoverModule,
    NgxCaptchaModule,
    MatSlideToggleModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient]
      }
    }),
    AlertModule.forRoot(),
    DatepickerModule.forRoot(),
    TimepickerModule.forRoot(),
    NgxMatIntlTelInputModule,
    ToastrModule.forRoot({
      timeOut: 8000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
      closeButton: true,
      progressBar: true,
      progressAnimation: 'increasing',
    }),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    MatDatepickerModule
  ],
  declarations: [
    AppComponent,
    HomeComponent,
    EmailLoginComponent,
    MobileLoginComponent,
    SignupComponent,
    welcomeComponent,
    ForgotPasswordComponent,
    NavbarComponent,
    SidebarComponent,
    FooterComponent,
    DashboardComponent,
    NotifyMeAlertComponent,
    MapPartComponent,
    CalendarComponent,
    VehicleComponent,
    SupervisorComponent,
    ItemComponent,
    DriverDetailComponent,
    CustomerServiceComponent,
    AgencyComponent,
    TrucksComponent,
    RidersComponent,
    VehicleMapComponent,
    CustomerComponent,
    CustomerReviewComponent,
    PaymentStatusComponent,
    DeliveryReportComponent,
    MileageReportComponent,
    GpsHistoryComponent,
    AddOrderComponent,
    AddOrderOneComponent,
    AddOrderTwoComponent,
    AddOrderThreeComponent,
    EditOrderComponent,
    EditOrderOneComponent,
    EditOrderTwoComponent,
    EditOrderThreeComponent,
    AddEmployeeComponent,
    AddDrivingZoneComponent,
    DrivingZoneComponent,
    EditDrivingZoneComponent,
    AddSupervisorComponent,
    AddItemComponent,
    AddVehicleComponent,
    SupervisorDetailComponent,
    VehicleDetailComponent,
    ProfileComponent,
    DialogProfileDialog,
    ProfileEditComponent,
    ChangePasswordComponent,
    CSNavbarComponent,
    CSFooterComponent,
    NotificationModal,
    EditSupervisorComponent,
    EditItemComponent,
    AddCustomerComponent,
    CustomerDetailsComponent,
    EditCustomerComponent,
    OrderDetailsComponent,
    FilterPipe,
    CustomerRatingComponent,
    ManagerComponent,
    AddManagerComponent,
    ManagerDetailsComponent,
    EditManagerComponent,
    BlockCopyPasteDirective,
    ManageUsersComponent,
    PackageInformationComponent,
    ViewUserPackageComponent,
    DialogExtendAction,
    PaymentHistoryComponent,
    PaymentDetailInfoComponent,
    PaymentResponseComponent,
    AddBulkOrderComponent,
    BulkOrderPopComponent,
  ],
  schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true
    }, GoogleMapsAPIWrapper, AuthGuardService, AuthService, AppService, ApiService, GeneralService, SharedFunctionService, TimezoneService, SocketService, Title, DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }

// AOT compilation support
export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

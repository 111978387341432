<footer class="footer">
  <div class="container-fluid footer-container">
    <div class="row warp-footer">
      <div class="col-12 col-lg-4 col-md-4 col-sm-4 text-left">
        <!-- <img src="/assets/img/fr-logo.png" class="logo-img">
        <p>Follow us on</p>
        <div>
          <span class="btn btn-sm mr-2">
            <a href="https://www.facebook.com/pg/ManageTeamz-301961173582026/" target="_blank">
              <img style="height:40px;" src="/assets/img/footer/facebook.png">
            </a>
          </span>
          <span class="btn btn-sm mr-2">
            <a href="https://twitter.com/ManageTeamz" target="_blank">
              <img style="height:40px;" src="/assets/img/footer/twitter.png">
            </a>
          </span>
          <span class="btn btn-sm">
            <a href="https://www.linkedin.com/organization/13316264/" target="_blank">
              <img style="height:40px;" src="/assets/img/footer/linkedin.png">
            </a>
          </span>
        </div> -->
        <h4 style="text-align: center;margin-bottom: 20;"><b><i class="fa fa-thumbs-up footercontent" aria-hidden="true"></i>{{'Useful Links' | translate}}</b></h4>
        <ul class="list-unstyled mt-3">
          <li><a href="https://www.manageteamz.com" class="text-black" target="_blank" style="cursor: pointer;"><i class="fa fa-angle-double-right footercontent" aria-hidden="true"></i>{{'Home' | translate}}</a></li>
          <div class="mb-2"></div>
          <li><a href="https://www.manageteamz.com/delivery-management" class="text-black" target="_blank" style="cursor: pointer;"><i class="fa fa-angle-double-right footercontent" aria-hidden="true"></i>{{'Delivery Management' | translate}}</a></li>
          <div class="mb-2"></div>
          <li><a href="https://www.manageteamz.com/logistics-management-software" class="text-black" target="_blank" style="cursor: pointer;"><i class="fa fa-angle-double-right footercontent" aria-hidden="true"></i>{{'Logistics Management' | translate}}</a></li>
          <div class="mb-2"></div>
          <li><a href="https://www.manageteamz.com/food-delivery-management-software" class="text-black" target="_blank" style="cursor: pointer;"><i class="fa fa-angle-double-right footercontent" aria-hidden="true"></i>{{'Food Delivery Management' | translate}}</a></li>
          <div class="mb-2"></div>
          <li><a href="https://www.manageteamz.com/naira-restaurant-food-delivery-system-case-study" class="text-black" target="_blank" style="cursor: pointer;"><i class="fa fa-angle-double-right footercontent" aria-hidden="true"></i>{{'Case Study' | translate}}</a></li>
          <div class="mb-2"></div>
          <li><a href="https://delivery.manageteamz.com/signup" class="text-black" target="_blank" style="cursor: pointer;"><i class="fa fa-angle-double-right footercontent" aria-hidden="true"></i>{{'Sign Up' | translate}}</a></li>
          <div class="mb-2"></div>
          <li><a href="https://delivery.manageteamz.com/mobile-login" class="text-black" target="_blank" style="cursor: pointer;"><i class="fa fa-angle-double-right footercontent" aria-hidden="true"></i>{{'Login' | translate}}</a></li>
          <div class="mb-2"></div>
          <li><a href="https://www.manageteamz.com/blog" class="text-black" target="_blank" style="cursor: pointer;"><i class="fa fa-angle-double-right footercontent" aria-hidden="true"></i>{{'Blog' | translate}}</a></li>
          <div class="mb-2"></div>
          <li><a href="https://www.manageteamz.com/faq" class="text-black" target="_blank" style="cursor: pointer;"><i class="fa fa-angle-double-right footercontent" aria-hidden="true"></i>{{'FAQ' | translate}}</a></li>
          <div class="mb-2"></div>
          <li><a href="https://www.manageteamz.com/pricing" class="text-black" target="_blank" style="cursor: pointer;"><i class="fa fa-angle-double-right footercontent" aria-hidden="true"></i>{{'Pricing' | translate}}</a></li>
          <div class="mb-2"></div>
          <li><a href="https://www.manageteamz.com/contact" class="text-black" target="_blank" style="cursor: pointer;"><i class="fa fa-angle-double-right footercontent" aria-hidden="true"></i>{{'Contact' | translate}}</a></li>
        </ul>
      </div>
      <div class="col-12 col-lg-4 col-md-4 col-sm-4 text-left">
        <div class="row"></div>
        <h4 style="text-align:center;margin-bottom: 20px;"><b><i class="fa fa-volume-up footercontent" aria-hidden="true"></i>{{'Recent News' | translate}}</b></h4>
          <div class="row">
            <div class="col-md-4 blogsec">
              <a href="https://www.manageteamz.com/blog/become-a-successful-delivery-startup-by-using-delivery-management-software/"><img src="https://www.manageteamz.com/images/delivery-management-software-2.png"></a>
            </div>
            <div class="col-md-8 blogheading">
              <div class="blog-content">
                <a href="https://www.manageteamz.com/blog/become-a-successful-delivery-startup-by-using-delivery-management-software/"><h5 class="text-black">{{'How to become a successful delivery startup b...' | translate}}</h5></a>
                <p>{{'Jan 08, 2021' | translate}}</p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4 blogsec">
              <a href="https://www.manageteamz.com/blog/impacts-of-automation-in-logistics-management/"><img src="https://www.manageteamz.com/images/impacts-of-automation-in-logistic-management.png"></a>
            </div>
            <div class="col-md-8 blogheading">
              <div class="blog-content">
                <a href="https://www.manageteamz.com/blog/impacts-of-automation-in-logistics-management/"><h5 class="text-black">{{'Impacts of Automation in Logistics Management...' | translate}}</h5></a>
                <p>{{'Dec 17, 2020' | translate}}</p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4 blogsec">
              <a href="https://www.manageteamz.com/blog/meet-mt-at-gitex-and-grow-your-delivery-business/"><img src="https://www.manageteamz.com/images/grow-your-delivery-business-with-manageteamz.png"></a>
            </div>
            <div class="col-md-8 blogheading">
              <div class="blog-content">
                <a href="https://www.manageteamz.com/blog/meet-mt-at-gitex-and-grow-your-delivery-business/"><h5 class="text-black">{{'Discover a new way to grow your delivery busi...' | translate}}</h5></a>
                <p>{{'Dec 03, 2020' | translate}}</p>
              </div>
            </div>
            <ul class="list-unstyled mt-3 col-md-12">
              <li><h5><b>{{'Available on' | translate}}</b></h5></li>
              <div class="mb-2"></div>
              <li class="col-md-6" style="float: left;">
                <a href="https://itunes.apple.com/in/app/manageteamz-delivery/id1241923128?mt=8" target="_blank" class="btn btn-sm mobile-btn">
                  <img src="/assets/img/footer/app-store.png" style="width: 100%;height: 40px;">
                </a>
              </li>
              <div class="mb-2"></div>
              <li class="col-md-6" style="float: left;"><a href="https://play.google.com/store/apps/details?id=com.w2s.android.sfa" target="_blank" class="btn btn-sm mobile-btn"><img src="/assets/img/footer/google-play.png" style="width: 100%;height: 40px"></a></li>
            </ul>
          </div>
      </div>
      <!-- <div class="col-12 col-lg-3 col-md-3 col-sm-3 text-left">
        <h4><b><i class="fa fa-envelope footercontent" aria-hidden="true"></i>{{'Get in Touch' | translate}}</b></h4>
        <p><b>{{'Our team is happy to answer your questions.' | translate}}</b></p>
        <form>
          <div class="row">
            <div class="col-md-12 col-sm-12">
              <div class="form-group mtbottom">
                <input type="text" name="name" class="form__input" placeholder="{{'Name' | translate}}">
              </div>
            </div>
            <div class="col-md-12 col-sm-12">
              <div class="form-group mtbottom">
                <input type="email" name="email" class="form__input" placeholder="{{'Email' | translate}}">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 col-sm-12">
              <div class="form-group mtbottom">
                <ngx-intl-tel-input limitsclass="custom-intl-tel-input"
                  [preferredCountries]="preferredCountries"
                  [enableAutoCountrySelect]="false"
                  [enablePlaceholder]="false"
                  [searchCountryFlag]="true"
                  [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                  [selectFirstCountry]="false"
                  [selectedCountryISO]="selectedCountry"
                  [maxLength]="15"
                  [minLength]="6"
                  [tooltipField]="TooltipLabel.Name"
                  [separateDialCode]="true"
                  placeholder="Mobile"
                  name="phone"
                  required>
                </ngx-intl-tel-input>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <textarea name="message_quote" class="form-control form__input textare__input mtbottom" placeholder="{{'Message' | translate}}" rows="3"></textarea>
              </div>
            </div>
          </div>
          <div class="row send_cont">
            <div class="col-md-8">
                <div class="linkedin-container">
                <input id="num3" class="sum" type="text" name="num3" value="3" readonly="readonly"> +
                <input id="num4" class="sum" type="text" name="num4" value="5" readonly="readonly"> =
                <input id="linkedin1" class="linkedin" type="text" name="linkedin1" maxlength="2">
                </div>
            </div>
            <div class="col-md-4">
              <div class="form-grpup">
                <input type="submit" name="submit" value="{{'Send' | translate}}" class="submit__btn">
                <input id="pageurl" type="text" name="pageurl" readonly="readonly">
              </div>
            </div>
          </div>
        </form>
      </div> -->
      <div class="col-12 col-lg-4 col-md-4 col-sm-4 text-left">
        <div class="footercontent1">
          <h4 style="text-align:center;margin-bottom: 20px;"><b><i class="fa fa-user footercontent" aria-hidden="true"></i>{{'About Us' | translate}}</b></h4>
          <p>{{'ManageTeamz is a delivery management platform crafted by Way2Smile Solutions that helps to connect your drivers, customers & your delivery business into a single dashboard with live tracking, auto allocation, route optimization, communication with audio & video calls, and collecting proof of delivery.' | translate}}</p>
          <a href="tel:+917338773388" class="mtcontact"><i class="fa fa-phone" aria-hidden="true"></i> +917338773388</a><br>
          <a style="color:#000;" href="#">bd@manageteamz.com</a>
          <ul class="list-inline social-icons">
            <li><a href="https://www.facebook.com/ManageTeamz/"><img src="/assets/img/footer/facebookk.png" style="height: 30px;"></a>
            </li>
            <li><a href="https://www.linkedin.com/showcase/manageteamz/"><img src="/assets/img/footer/linkedinn.png" style="height: 30px;"></a>
            </li>
            <li><a href="https://twitter.com/ManageTeamz"><img src="/assets/img/footer/twitterr.png" style="height: 30px;"></a>
            </li>
            <li><a href="https://in.pinterest.com/Manageteamz/"><img src="/assets/img/footer/pinterest.png" style="height: 30px;"></a>
            </li>
            <li><a href="https://www.instagram.com/manageteamz/"><img src="/assets/img/footer/instagram.png" style="height: 30px;"></a>
            </li>
          </ul>
        </div>
        
      </div>
    </div>
  </div>
  <hr>
  <div class="container-fluid sub-footer-container">
    <div class="row warp-footer">
      <div class="col-md-6">
        <p class="text-left mobile-center mb-4">{{'©2020 ManageTeamz' | translate}}</p>
      </div>
      <div class="col-md-6">
        <p class="text-right mobile-center mb-4">{{'Designed & Developed by W2S Solutions' | translate}}</p>
      </div>
    </div>
  </div>
</footer>

import { Component, OnInit, HostBinding } from '@angular/core';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  @HostBinding('attr.dir') dir = 'ltr';
  @HostBinding('attr.class') class = 'ltr';
  title = 'manageteamz';

  constructor(public translate: TranslateService){
    translate.addLangs(['English', 'Arabic']);
  }
  ngOnInit() {
    let SelectLang = localStorage.getItem("language");
    if (SelectLang != null && SelectLang == 'Arabic') {
      this.dir = 'rtl';
      this.class = 'rtl';
    } else {
      this.dir = 'ltr';
      this.class = 'ltr';
    }
    this.translate.use(SelectLang);
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      if(event.lang == 'Arabic') {
        this.dir = 'rtl';
        this.class = 'rtl';
      } else {
        this.dir = 'ltr';
        this.class = 'ltr';
      }
    });
  }
}
<div class="row">
  <div class="col text-left">
    <p><i class="fa fa-arrow-left fa-theme mr-2 pointer" matTooltip="Go Back to profile" [matTooltipPosition]="'below'" routerLink="/supervisor"></i>Vehicle Detail</p>
  </div>
  <div class="col text-right">
    <button type="button" routerLink="/edit-supervisor" class="btn btn-sm btn-outline-info rounded-0 mb-2 mr-2" matTooltip="click here"><i class="far fa-edit mr-1"></i>Edit</button>
  </div>
</div>
<div class="card">
  <div class="container">
    <br>
    <div class="row">
      <div class="col-md-10 col-lg-10 mb-4 align-items-stretch">
        <p>Vehicle Id : 1</p>
        <p>Vehicle Name : Vehicle</p>
        <p>Vehicle Type : All Wheel Drive</p>
        <p>Vehicle Model : 2020</p>
        <p>Licence Plate : TN 01 AB 001</p>
        <p>Status : Active</p>
        <p>Address : Alli Raised Road , All QUTTA Dubai ,UAE</p>
      </div>
      <div class="col-md-2 col-lg-2 mb-4 align-items-stretch">
        <button type="button" class="btn btn-sm btn-success rounded-0 mb-2 mr-2 float-right" matTooltip="click here">Active</button>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 col-lg-4 mb-4 align-items-stretch">
        <div class="container">
          <p>Log Book</p>
          <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcRlQ1cyRlcsvwQujyLLSr6F-a-orHosGbkD4w&usqp=CAU" class="img-thumbnail" alt="Cinque Terre" width="304" height="236">
        </div>
      </div>
      <div class="col-md-12 col-lg-4 mb-4 align-items-stretch">
        <div class="container">
          <p>insurance Certificate</p>
          <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcRlQ1cyRlcsvwQujyLLSr6F-a-orHosGbkD4w&usqp=CAU" class="img-thumbnail" alt="Cinque Terre" width="304" height="236">
        </div>
      </div>
      <div class="col-md-12 col-lg-4 mb-4 align-items-stretch">
        <div class="container">
          <p>inspection Certificate</p>
          <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcRlQ1cyRlcsvwQujyLLSr6F-a-orHosGbkD4w&usqp=CAU" class="img-thumbnail" alt="Cinque Terre" width="304" height="236">
        </div>
      </div>
      <div class="col-md-12 col-lg-4 mb-4 align-items-stretch">
        <div class="container">
          <p>Tax Pin</p>
          <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcRlQ1cyRlcsvwQujyLLSr6F-a-orHosGbkD4w&usqp=CAU" class="img-thumbnail" alt="Cinque Terre" width="304" height="236">
        </div>
      </div>
      <div class="col-md-12 col-lg-4 mb-4 align-items-stretch">
        <div class="container">
          <p>Vehicle Photo</p>
          <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcRlQ1cyRlcsvwQujyLLSr6F-a-orHosGbkD4w&usqp=CAU" class="img-thumbnail" alt="Cinque Terre" width="304" height="236">
        </div>
      </div>
  </div>
  </div>
</div>


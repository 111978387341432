import { Component, OnInit, NgZone, ElementRef, ChangeDetectionStrategy, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders, HttpErrorResponse } from "@angular/common/http";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { DatePipe } from '@angular/common';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { AuthService } from 'src/app/services/AuthService';
import { AppService } from 'src/app/services/AppService';
import { ApiService } from 'src/app/services/ApiService';
import { GeneralService } from 'src/app/services/GeneralService';
import { Location, Appearance, GermanAddress, MatValidateAddressDirective } from '@angular-material-extensions/google-maps-autocomplete';
import { SharedFunctionService } from 'src/app/services/SharedFunctionService';
import { Multi_Select_Config } from 'src/app/config/config';
import { SearchCountryField } from 'custom-template/ngx-intl-tel-input/src/lib/enums/search-country-field.enum';
import { TooltipLabel } from 'custom-template/ngx-intl-tel-input/src/lib/enums/tooltip-label.enum';
import { CountryISO } from 'custom-template/ngx-intl-tel-input/src/lib/enums/country-iso.enum';
import { TranslateService } from '@ngx-translate/core';
import PlaceResult = google.maps.places.PlaceResult;
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatStepper } from '@angular/material/stepper';
import { MapsAPILoader } from '@agm/core';
import { element } from 'protractor';
import { TimezoneService } from 'src/app/services/TimezoneService';
declare var google: any;
declare var $: any;

@Component({
  selector: 'order-logic-three-edit',
  templateUrl: './edit-order.component.html',
  styleUrls: ['./edit-order.component.css'],
  providers: [{
    provide: STEPPER_GLOBAL_OPTIONS, useValue: { showError: true }
  }]
})
export class EditOrderThreeComponent implements OnInit {
  @Input() isLogic: any;
  @Input() orderId: any;
  @Output() comingChange = new EventEmitter<any>();
  requiredErrorText = 'The address is required';
  time: Date;
  date: Date;
  isDateVisible: boolean = true;
  isMeridian: boolean = false;
  dateTime = new Date();
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;
  isPickupShow = true;
  isPickup: string = '';
  pickupFormData: any = [];
  isDeliveryShow = true;
  isDelivery: string = '';
  deliveryFormData: any = [];
  receiverhide: boolean;
  public appearance = Appearance;
  public zoom: number;
  public latitude: number;
  public longitude: number;
  public delivery_latitude: number;
  public delivery_longitude: number;
  public selectedAddress: PlaceResult;
  Config = Multi_Select_Config.config;
  ReceiverOptions: any = [];
  ItemOptions: any;
  Item: any;
  SearchCountryField = SearchCountryField;
  TooltipLabel = TooltipLabel;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [
    CountryISO.India,
    CountryISO.Canada,
  ];
  pickupAddress: any = "";
  receiverAddress: any = "";
  Receivername: any;
  pickupLocation: any = [];
  deleiveryLocation: any = [];
  senderLocation: any = [];
  userList: any;
  userListShow: boolean = false;
  isLinear = true;
  multipickupshow: boolean;
  nextshow: boolean = true;
  nextstepshow: boolean = true;
  Receivernumber: any;
  Receiveremail: any;
  Receiveraddress: any;
  receivershow: boolean;
  phone_number_regex: any = "^[0-9]{6,15}$";
  ProfileData: any;
  // Pickuplocation: any;
  minDate: Date;
  inputReadonly: boolean = true;
  private addressValidator: MatValidateAddressDirective = new MatValidateAddressDirective();
  userData: any;
  newitemshow: boolean;
  itemnewshow: boolean = true;
  senderAddress: any = "";

  Location: any;
  address: string;
  private geoCoder;
  company_lat: number;
  company_lng: number;

  agmshowmap = 0;
  agmshowmap1 = 0;
  agmshowmap2 = 0;

  @ViewChild('search')
  public searchElementRef: ElementRef;
  sender_latitude: number;
  sender_longitude: number;
  pickup_phone: any;
  Receiver: any;
  SelectLang: string;
  PickTimeReq: string;
  PickLocReq: string;
  DelTimeReq: string;
  RecNameReq: string;
  RecNumReq: string;
  InvRecNum: string;
  InvEmail: string;
  RecAddressReq: string;
  ItemReq: string;
  SendMobReq: string;
  PickTime: string;
  PickTimes: string;
  DelAgeReq: string;
  OrderCreate: string;
  Close: string;
  zoneList: any;

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private loader: NgxSpinnerService,
    private toastr: ToastrService,
    private authService: AuthService,
    public appService: AppService,
    public apiService: ApiService,
    private formBuilder: FormBuilder,
    private generalService: GeneralService,
    public sharedfunction: SharedFunctionService,
    private snackBar: MatSnackBar,
    public datepipe: DatePipe,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    public timezone: TimezoneService,
    public translate: TranslateService
  ) { }

  ngOnInit() {
    this.SelectLang = localStorage.getItem("language");
    this.translate.get('Pickup time is Required').subscribe((text: string) => {
      this.PickTimeReq = text
    });
    this.translate.get('Pickup Location is Required').subscribe((text: string) => {
      this.PickLocReq = text
    });
    this.translate.get('Delivery time is Required').subscribe((text: string) => {
      this.DelTimeReq = text
    });
    this.translate.get('Receiver name is Required').subscribe((text: string) => {
      this.RecNameReq = text
    });
    this.translate.get('Receiver number is Required').subscribe((text: string) => {
      this.RecNumReq = text
    });
    this.translate.get('Invalid Receiver number').subscribe((text: string) => {
      this.InvRecNum = text
    });
    this.translate.get('Invalid Email Address').subscribe((text: string) => {
      this.InvEmail = text
    });
    this.translate.get('Receiver address is Required').subscribe((text: string) => {
      this.RecAddressReq = text
    });
    this.translate.get('Item is Required').subscribe((text: string) => {
      this.ItemReq = text
    });
    this.translate.get('Sender mobile number is Required').subscribe((text: string) => {
      this.SendMobReq = text
    });
    this.translate.get('Pickup time should not be before today').subscribe((text: string) => {
      this.PickTime = text
    });
    this.translate.get('Delivery time should be greater than pickup time').subscribe((text: string) => {
      this.PickTimes = text
    });
    this.translate.get('Delivery Agent Required').subscribe((text: string) => {
      this.DelAgeReq = text
    });
    this.translate.get('Order has been successfully created').subscribe((text: string) => {
      this.OrderCreate = text
    });
    this.translate.get('Close').subscribe((text: string) => {
      this.Close = text
    });
    this.translate.use(this.SelectLang);
    this.userData = JSON.parse(localStorage.getItem("user_data"));
    this.firstFormGroup = this.formBuilder.group({
      Pickuptime: ['', Validators.required],
      Pickuplocation: ['', Validators.required],
      pickup_phone: [''],
      id: [''],
    });

    this.loader.show();
    this.apiService
      .userActiveLists()
      .subscribe((response: any) => {
        this.userList = response.data;
        this.loader.hide();
      });

    let newForm = this.formBuilder.group({
      formArray1: this.formBuilder.array([])
    });
    const arrayControl = <FormArray>newForm.controls['formArray1'];
    var formGroup = this.formBuilder.group({
      Deliveryat: [''],
      Orderid: [''],
      Item: [''],
      ItemNew: [''],
      Receiver: [''],
      Receivername: [''],
      Receiveremail: [''],
      Receivernumber: [''],
      Receiveraddress: [''],
      id: [''],
      formArray2: this.formBuilder.array([
        // item form qroups
      ]),
      Orderamount: [''],
      Paymenttype: ['']
    });
    arrayControl.controls.push(formGroup);
    this.secondFormGroup = newForm;
    this.thirdFormGroup = this.formBuilder.group({
      Sendername: [''],
      Sendernumber: ['', Validators.required],
      Schedulestatus: ['Unallocated', Validators.required],
      Senderaddress: [''],
      SenderZone: ['', Validators.required],
      Scheduleuser: [''],
      Geolocationmeters: ['200', Validators.required],
    });

    this.firstFormGroup.get('Pickuplocation').valueChanges.subscribe(value => console.log('value changed', value))
    const now = new Date();
    this.minDate = new Date();
    this.minDate.setDate(now.getDate());
    this.callReceivers();
    this.callItems();
    this.getDriverZones();

    if (this.firstFormGroup.value.pickup_phone == "" || this.firstFormGroup.value.pickup_phone == null) {
      if (this.userData.profile.country_code != '') {
        this.preferredCountries = [this.userData.profile.country_code.toLowerCase()];
      } else {
        this.preferredCountries = [
          CountryISO.India,
          CountryISO.Singapore,
          CountryISO.UnitedArabEmirates,
          CountryISO.Canada,
        ];
      }
    }

    /// map location

    this.mapsAPILoader.load().then(() => {
      // this.setCurrentLocation();

      this.geoCoder = new google.maps.Geocoder;
      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();

          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          //set latitude, longitude and zoom
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.zoom = 12;
        });
      });
    });
    this.receivershow = true;
  }

  ngAfterViewInit() {
    this.firstFormGroup.controls.pickup_phone.setValue('');
    this.apiService
      .orderDetails(this.orderId)
      .subscribe((response: any) => {
        var obj = response.data.multiple_pickup[0];
        this.pickupAddress = obj.pick_address;
        this.pickup_phone = response.data.pickup_phone;
        if (obj.pickup_phone == null) {
          obj.pickup_phone = "";
        }

        this.firstFormGroup.patchValue({
          id: obj.id,
          Pickuptime: new Date(Date.parse(new Date(obj.picktime.replace(/ /g, 'T')).toISOString())),
          Pickuplocation: obj.pick_address,
          pickup_phone: obj.pickup_phone,
        });
        this.pickupLocation.push({
          latitude: obj.pickup_ladd, longitude: obj.pickup_long
        });
        this.latitude = parseFloat(obj.pickup_ladd);
        this.longitude = parseFloat(obj.pickup_long);
        this.zoom = 12;
        var delivery_obj = response.data.multiple_delivery[0];
        // let ReceiverData = this.ReceiverOptions.find(element => element.name == delivery_obj.receiver_name);
        var arrayControl = <FormArray>this.secondFormGroup.controls['formArray1'];
        var i = 0;
        response.data.multiple_delivery.forEach(element => {
          let newGroup;
          if (i > 0) {
            newGroup = this.formBuilder.group({
              Deliveryat: [''],
              Orderid: [''],
              Item: [''],
              ItemNew: [''],
              Receiver: [''],
              Receivername: [''],
              Receiveremail: [''],
              Receivernumber: [''],
              Receiveraddress: [''],
              id: [''],
              formArray2: this.formBuilder.array([
                // item form qroups
              ]),
              Orderamount: [''],
              Paymenttype: ['']
            });
            arrayControl.controls.push(newGroup);
          }
          let tempRes = [];
          let itemTemp = [];
          for (var j = 0; j < delivery_obj.delivery_notes1.length; j++) {
            // controls
            arrayControl.controls[0]['controls']['formArray2'].controls.push(
              this.formBuilder.group({
                ItemName: [''],
                Quantity: [''],
                IsFrom: [''],
                id: [''],
              }));
          }
          for (var j = 0; j < delivery_obj.delivery_notes1.length; j++) {
            tempRes.push({ id: delivery_obj.delivery_notes1[j].items.id, name: delivery_obj.delivery_notes1[j].items.name });
            itemTemp.push({ id: delivery_obj.delivery_notes1[j].items.id, Quantity: delivery_obj.delivery_notes1[j].quantity, IsFrom: 'oldItem', ItemName: delivery_obj.delivery_notes1[j].items.name });
            // controls
            arrayControl.controls[0]['controls']['formArray2'].controls[j].setValue({
              ItemName: delivery_obj.delivery_notes1[j].items.name,
              Quantity: delivery_obj.delivery_notes1[j].quantity,
              IsFrom: 'oldItem',
              id: delivery_obj.delivery_notes1[j].items.id,
            });
          }
          var ItemOptions = tempRes;
          var ItemsQuantity = itemTemp;
          this.receiverAddress = delivery_obj.cust_address;
          arrayControl.controls[0].setValue({
            id: delivery_obj.id,
            Deliveryat: new Date(Date.parse(new Date(delivery_obj.schedule.replace(/ /g, 'T')).toISOString())),
            Orderid: delivery_obj.order_id,
            Receiver: delivery_obj.cust_id,
            Receivername: delivery_obj.receiver_name,
            Receiveremail: delivery_obj.cust_email,
            Receivernumber: delivery_obj.cust_phone,
            Receiveraddress: delivery_obj.cust_address,
            Item: ItemOptions,
            ItemNew: '',
            formArray2: ItemsQuantity,
            Orderamount: delivery_obj.order_amount,
            Paymenttype: delivery_obj.payment_type
          });
          this.Receiver = delivery_obj.cust_id;
          this.deleiveryLocation.push({
            latitude: delivery_obj.loc_lat, longitude: delivery_obj.loc_lng
          });
          this.delivery_latitude = parseFloat(delivery_obj.loc_lat);
          this.delivery_longitude = parseFloat(delivery_obj.loc_lng);

          i = i + 1;
        });
        this.thirdFormGroup.patchValue({
          Sendername: response.data.sender_name,
          Sendernumber: response.data.sender_number,
          Sender: response.data.sender_name,
          Schedulestatus: response.data.status,
          Senderaddress: response.data.sent_address,
          SenderZone: response.data.driving_zone_id,
          Scheduleuser: response.data.emp,
          Geolocationmeters: response.data.geo_fence_meter,
        });
        this.sender_latitude = parseFloat(response.data.sent_ladd)
        this.sender_longitude = parseFloat(response.data.sent_long)
        this.senderAddress = response.data.sent_address;
        if (response.data.status != 'Unallocated') {
          this.zoneChange(response.data.driving_zone_id);
          this.statusChange(response.data.status, response.data.emp);
        }
      });
  }

  get deliveryForm() { return this.secondFormGroup.controls; }

  switchLang(lang: string) {
    localStorage.setItem("language", lang)
    this.translate.use(lang);
  }

  goForward(stepper: MatStepper) {
    var currentdate = new Date();
    if (this.firstFormGroup.value.Pickuptime == "" || this.firstFormGroup.value.Pickuptime == null) {
      this.snackBar.open(this.PickTimeReq, this.Close, {
        duration: 4000,
        verticalPosition: 'bottom'
      })
    }
    else if (this.firstFormGroup.value.Pickuptime < currentdate) {
      this.snackBar.open("Pickup Time should be greater than current time", "Close", {
        duration: 4000,
        verticalPosition: 'bottom'
      })
    }
    else if (this.firstFormGroup.value.Pickuplocation == "" || this.firstFormGroup.value.Pickuplocation == null) {
      this.snackBar.open(this.PickLocReq, this.Close, {
        duration: 4000,
        verticalPosition: 'bottom'
      })
    }
    else if (this.firstFormGroup.value.pickup_phone == null) {
      this.snackBar.open("Invalid Pickup Number", "Close", {
        duration: 4000,
        verticalPosition: 'bottom'
      });
      return false;
    }
    else {
      this.pickupFormData = [];
      // this.firstFormGroup.value.id = this.pickupFormData.length + 1;
      this.pickupFormData.push(this.firstFormGroup.value);
      stepper.next();
    }
  }
  AddNew() {
    this.receivershow = false;
    this.agmshowmap1 = 0
    this.Receivername = [];
    this.Receivernumber = " ";
    this.Receiveremail = '';
    this.Receiveraddress = null;
    this.receiverAddress = '';
    this.secondFormGroup.patchValue({
      Receiver: "",
      // Receivernumber:"",
      Receiveraddress: ""
    });
  }
  ItemNew() {
    this.newitemshow = true;
  }
  NewItem() {
    this.itemnewshow = true;
  }
  goBack(stepper: MatStepper) {
    stepper.previous();
  }
  goBackward(stepper: MatStepper) {
    stepper.previous();
  }
  goNext(stepper: MatStepper) {
    if (this.firstFormGroup.value.Pickuptime == "" || this.firstFormGroup.value.Pickuptime == null) {
      this.snackBar.open(this.PickTimeReq, this.Close, {
        duration: 4000,
        verticalPosition: 'bottom'
      })
    } else if (this.firstFormGroup.value.Pickuplocation == "" || this.firstFormGroup.value.Pickuplocation == null) {
      this.snackBar.open(this.PickLocReq, this.Close, {
        duration: 4000,
        verticalPosition: 'bottom'
      })
    }
    // else if(this.firstFormGroup.value.pickup_phone == "" || this.firstFormGroup.value.pickup_phone == null){
    //   this.snackBar.open("Pickup Phone is Required","Close",{
    //     duration: 4000,
    //     verticalPosition:'bottom'
    //   })
    // }
    else {
      if (this.secondValid()) {
        this.pickupFormData = [];
        //this.firstFormGroup.value.id = this.pickupFormData.length + 1;
        this.pickupFormData.push(this.firstFormGroup.value);
        this.deliveryFormData = [];
        // this.secondFormGroup.value.id = this.deliveryFormData.length + 1;
        var formobj = <FormArray>this.secondFormGroup.controls['formArray1'];
        this.secondFormGroup.value.formArray1[0] = formobj.controls[0].value;
        this.deliveryFormData.push(formobj.controls[0].value);
        stepper.next();
      }
    }
  }
  firstValid() {
    if (this.firstFormGroup.value.Pickuptime == "" || this.firstFormGroup.value.Pickuptime == null) {
      this.snackBar.open(this.PickTimeReq, this.Close, {
        duration: 4000,
        verticalPosition: 'bottom'
      });
      return false;
    } else if (this.firstFormGroup.value.Pickuplocation == "" || this.firstFormGroup.value.Pickuplocation == null) {
      this.snackBar.open(this.PickLocReq, this.Close, {
        duration: 4000,
        verticalPosition: 'bottom'
      });
      return false;
    }
    // else if(this.firstFormGroup.value.pickup_phone == "" || this.firstFormGroup.value.pickup_phone == null){
    //   this.snackBar.open("Pickup Phone is Required","Close",{
    //     duration: 4000,
    //     verticalPosition:'bottom'
    //   });
    //   return false;
    // }
    else {
      this.nextshow = true;
      return true;
    }
  }
  secondValid() {
    var formobj = <FormArray>this.secondFormGroup.controls.formArray1;
    var obj = formobj.controls[0];
    if (obj.value.Receiveremail != '') {
      if (obj.value.Receiveremail != undefined) {
        if (!/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/.test(obj.value.Receiveremail)) {
          this.snackBar.open("Invalid Email", "Close", {
            duration: 4000,
            verticalPosition: 'bottom'
          })
          return 0;
        }
      }
    }
    if (obj.value.Deliveryat == "" || obj.value.Deliveryat == null) {
      this.snackBar.open(this.DelTimeReq, this.Close, {
        duration: 4000,
        verticalPosition: 'bottom'
      });
      return false;
    } else if (obj.value.Receivername == "" || obj.value.Receivername == null) {
      this.snackBar.open(this.RecNameReq, this.Close, {
        duration: 4000,
        verticalPosition: 'bottom'
      });
      return false;
    } else if (obj.value.Receivernumber == "" || obj.value.Receivernumber == null) {
      this.snackBar.open(this.RecNumReq, this.Close, {
        duration: 4000,
        verticalPosition: 'bottom'
      });
      return false;
    } else if (obj.value.Receiveraddress == "" || obj.value.Receiveraddress == null) {
      this.snackBar.open(this.RecAddressReq, this.Close, {
        duration: 4000,
        verticalPosition: 'bottom'
      });
      return false;
    } else if (obj.value.Orderamount == "" || obj.value.Orderamount == null) {
      this.snackBar.open("Order amount is required", this.Close, {
        duration: 4000,
        verticalPosition: 'bottom'
      });
      return false;
    } else if (obj.value.Paymenttype == "" || obj.value.Paymenttype == null) {
      this.snackBar.open("Payment type is required", this.Close, {
        duration: 4000,
        verticalPosition: 'bottom'
      });
      return false;
    } else if ((obj.value.Item == "" || obj.value.Item == null) &&
      (obj.value.ItemNew == "" || obj.value.ItemNew == null)) {
      this.snackBar.open("Item is Required", "Close", {
        duration: 4000,
        verticalPosition: 'bottom'
      });
      return false;
    } else if (this.firstFormGroup.value.Pickuptime > obj.value.Deliveryat) {
      this.snackBar.open(this.PickTimes, this.Close, {
        duration: 4000,
        verticalPosition: 'bottom'
      });
      return false;
    }
    return true;
  }

  thirdValid() {
    if (this.thirdFormGroup.value.Sendernumber == "" || this.thirdFormGroup.value.Sendernumber == null) {
      this.snackBar.open(this.SendMobReq, this.Close, {
        duration: 4000,
        verticalPosition: 'bottom'
      });
      return false;
    } else if (this.thirdFormGroup.value.Schedulestatus == "" || this.thirdFormGroup.value.Schedulestatus == null) {
      this.snackBar.open("Schedule Status is Required", "Close", {
        duration: 4000,
        verticalPosition: 'bottom'
      });
      return false;
    } else if (this.thirdFormGroup.value.Geolocationmeters.toString() == "") {
      this.snackBar.open("Geo Location Meters is Required", "Close", {
        duration: 4000,
        verticalPosition: 'bottom'
      });
      return false;
    } else {
      if (this.thirdFormGroup.value.Schedulestatus == "Allocated") {
        if (this.thirdFormGroup.value.SenderZone == "" || this.thirdFormGroup.value.SenderZone == null) {
          this.snackBar.open("Delivery Zone is Required", "Close", {
            duration: 4000,
            verticalPosition: 'bottom'
          });
          return false;
        }
        else if (this.thirdFormGroup.value.Scheduleuser == "" || this.thirdFormGroup.value.Scheduleuser == null) {
          this.snackBar.open(this.DelAgeReq, this.Close, {
            duration: 4000,
            verticalPosition: 'bottom'
          });
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    }
  }
  submit() {
    if (!this.thirdValid()) {
      return 0;
    }
    let senderFormData = this.thirdFormGroup.value;
    let pickupFormData, deliveryFormData, payload;
    let Item = [];
    if (this.isLogic == 3) {
      pickupFormData = this.pickupFormData[0];
      deliveryFormData = this.deliveryFormData[0];
      deliveryFormData.Item.forEach(element => {
        Item.push(element.id);
      });
      // deliveryFormData.Item = Item;
    }
    if (this.isLogic == 3) {
      payload = {
        data: {
          "status": senderFormData.Schedulestatus,
          "type": 0,
          "driving_zone_id": senderFormData.SenderZone ? senderFormData.SenderZone : undefined,
          "emp": senderFormData.Scheduleuser ? senderFormData.Scheduleuser : undefined,
          "method": "Pickup",
          "is_multidelivery": false,
          "is_multipickup": false,
          "schedule": new Date(),
          "picktime": new Date(),
          "loc_lat": this.deleiveryLocation[0].latitude,
          "loc_lng": this.deleiveryLocation[0].longitude,
          "pickup_ladd": this.pickupLocation[0].latitude,
          "pickup_long": this.pickupLocation[0].longitude,
          "sent_ladd": this.sender_latitude ? this.sender_latitude : "",
          "sent_long": this.sender_longitude ? this.sender_longitude : "",
          "geo_fence_meter": senderFormData.Geolocationmeters,
          "time_requirement": "",
          "time_to_delivery": "",
          "showpick": false,
          "showdeliv": false,
          "multiple_pickup": [{
            "id": pickupFormData.id,
            "picktime": this.datepipe.transform(pickupFormData.Pickuptime, 'yyyy-MM-dd H:mm'),
            "pick_address": pickupFormData.Pickuplocation,
            "pickup_phone": pickupFormData.pickup_phone,
            "delivery_notes": [],
            "pickup_ladd": this.pickupLocation[0].latitude,
            "pickup_long": this.pickupLocation[0].longitude,
            "product_weight": "",
            "time_to_delivery": "",
            "time_requirement": "",
            "product_length": "",
            "product_height": "",
            "product_breadth": ""
          }],
          "multiple_delivery": [{
            "id": deliveryFormData.id,
            "schedule": this.datepipe.transform(deliveryFormData.Deliveryat, 'yyyy-MM-dd H:mm'),
            "order_id": deliveryFormData.Orderid,
            "delivery_notes1": Item,
            "delivery_notes2": deliveryFormData.ItemNew ? deliveryFormData.ItemNew.split(',') : [],
            "delivery_notes_item_quantity": this.deliveryFormData[0].formArray2,
            "cust_name": "",
            "cust_phone": deliveryFormData.Receivernumber,
            "cust_email": "",
            "temp_cust_email": deliveryFormData.Receiveremail,
            "cust_address": deliveryFormData.Receiveraddress,
            "loc_lat": this.deleiveryLocation[0].latitude,
            "loc_lng": this.deleiveryLocation[0].longitude,
            "cust_id": deliveryFormData.Receiver,
            "receiver_name": deliveryFormData.Receivername,
            "order_amount": deliveryFormData.Orderamount,
            "payment_type": deliveryFormData.Paymenttype,
            "payment_status": deliveryFormData.Paymenttype == 1 ? 1 : 0,
          }],
          "delivery_logic": this.isLogic,
          "sender_name": senderFormData.Sendername,
          "sender_number": senderFormData.Sendernumber,
          "sent_address": senderFormData.Senderaddress,
          "is_geo_fence": 1
        },
        "geo_fence_meter": senderFormData.Geolocationmeters,
        "sender_name": senderFormData.Sendername
      };
    }
    this.loader.show();
    this.apiService
      .editOrder(this.orderId, payload)
      .subscribe((response: any) => {
        if (response.status == 'ok') {
          this.toastr.success(response.data);
          this.router.navigate(["/dashboard"]);
        } else {
          this.toastr.warning(response.data);
        }
        this.loader.hide();
      });
  }
  onLocationSelected(location: Location, isWhere: any) {
    this.latitude = location.latitude;
    this.longitude = location.longitude;
    if (isWhere == 'pickup') {
      this.pickupLocation = [];
      this.pickupLocation.push({
        latitude: location.latitude, longitude: location.longitude
      })
    }
    if (isWhere == 'delivery') {
      this.deleiveryLocation = [];
      this.deleiveryLocation.push({
        latitude: location.latitude, longitude: location.longitude
      })
    }
    if (isWhere == 'sender') {
      this.senderLocation = [];
      this.senderLocation.push({
        latitude: location.latitude, longitude: location.longitude
      })
    }
  }
  onGermanAddressMapped($event: GermanAddress) {
    console.log('onGermanAddressMapped', $event);
  }

  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        if ((this.latitude == null || this.latitude == undefined) || (this.longitude == null || this.longitude == undefined)) {
          this.zoom = 8;
          if (this.userData.profile) {
            if (this.userData.profile.delivery_logic) {
              // this.Location = this.userData.profile.pickup_address;
              // this.Pickuplocation = this.userData.profile.pickup_address;
              // this.firstFormGroup.value.Pickuplocation = this.userData.profile.pickup_address;
              this.geoCoder.geocode({ 'address': this.userData.profile.pickup_address }, (results, status) => {
                this.latitude = parseFloat(results[0].geometry.location.lat());
                this.longitude = parseFloat(results[0].geometry.location.lng());
                this.zoom = 12;

                // this.getAddress(this.latitude, this.longitude);
              });
            }
          } else {
            this.latitude = parseFloat(this.userData.profile.company_lat);
            this.longitude = parseFloat(this.userData.profile.company_lng);
            this.getAddress(this.latitude, this.longitude);
          }

        } else {
          this.zoom = 8;
          this.getAddress(this.latitude, this.longitude);
        }
      });
    }
  }

  markerDragEnd(position: any) {
    this.latitude = position.coords.latitude;
    this.longitude = position.coords.longitude;
    // this.getAddress(this.latitude, this.longitude);
  }

  markerDragEndNew($event: google.maps.MouseEvent) {
    this.latitude = $event.latLng.lat();
    this.longitude = $event.latLng.lng();
    this.getAddress(this.latitude, this.longitude);
    this.pickupLocation[0] = {
      latitude: this.latitude, longitude: this.longitude
    };
  }

  markerDragEndNew2($event1: google.maps.MouseEvent) {
    this.delivery_latitude = $event1.latLng.lat();
    this.delivery_longitude = $event1.latLng.lng();
    this.getAddress_delivery(this.delivery_latitude, this.delivery_longitude);
    this.deleiveryLocation[0] = {
      latitude: this.delivery_latitude, longitude: this.delivery_longitude
    };
  }

  markerDragEndNew3($event2: google.maps.MouseEvent) {
    this.loader.show()
    this.sender_latitude = $event2.latLng.lat();
    this.sender_longitude = $event2.latLng.lng();
    this.getAddress_sender(this.sender_latitude, this.sender_longitude);
    this.senderLocation[0] = {
      latitude: this.sender_latitude, longitude: this.sender_longitude
    };
    setTimeout(() => {
      this.loader.hide()
    }, 1000)
  }

  getAddress(latitude, longitude) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;
          // this.Location = results[0].formatted_address;
          this.firstFormGroup.patchValue({
            Pickuplocation: results[0].formatted_address
          });
          this.pickupAddress = results[0].formatted_address;
        } else {
          console.log('No results found');
        }
      } else {
        console.log('Geocoder failed due to: ' + status);
      }

    });
  }

  getAddress_sender(latitude, longitude) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;
          // this.Location = results[0].formatted_address;
          this.thirdFormGroup.controls['Senderaddress'].setValue(results[0].formatted_address);
          this.senderAddress = results[0].formatted_address;
        } else {
          console.log('No results found');
        }
      } else {
        console.log('Geocoder failed due to: ' + status);
      }

    });
  }

  getAddress_delivery(latitude, longitude) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;
          this.Location = results[0].formatted_address;
          this.receiverAddress = results[0].formatted_address;
          const arrayControl = <FormArray>this.secondFormGroup.controls['formArray1'];
          arrayControl.controls[0].patchValue({
            Receiveraddress: results[0].formatted_address
          });
        } else {
          console.log('No results found');
        }
      } else {
        console.log('Geocoder failed due to: ' + status);
      }

    });
  }

  pickupAdd() {
    this.receivershow = false;
    if (this.firstValid()) {
      this.isPickup = '';
      this.isPickupShow = true;
      this.firstFormGroup.value.id = this.pickupFormData.length + 1;
      this.pickupFormData.push(this.firstFormGroup.value);
    }
  }
  RemovePickup() {
    this.nextshow = true;
    this.isPickupShow = false;
    this.isPickup = 'addMore'
    this.firstFormGroup.patchValue({
      id: "",
      Pickuptime: "",
      Pickuplocation: "",
      PickupItem: ""
    });
    this.pickupAddress = "";
    // this.Pickuplocation = "";
  }
  pickupEdit(obj: any) {
    this.firstFormGroup.patchValue({
      id: obj.id,
      Pickuptime: obj.Pickuptime,
      Pickuplocation: obj.Pickuplocation,
    });
    this.nextshow = false;
    this.isPickup = 'save';
    this.isPickupShow = true;
    this.pickupAddress = obj.Pickuplocation
    // this.Pickuplocation = obj.Pickuplocation
  }
  pickupSave() {
    if (this.firstValid()) {
      this.isPickup = 'addMore';
      this.isPickupShow = false;
      // this.Pickuplocation = this.firstFormGroup.value.Pickuplocation
      var foundIndex = this.pickupFormData.findIndex(obj => obj.id == this.firstFormGroup.value.id);
      this.pickupFormData[foundIndex] = { ...this.firstFormGroup.value };
    }
  }
  pickupDelete(obj: any) {
    var foundIndex = this.pickupFormData.findIndex(x => x.id == obj.id);
    this.pickupFormData.splice(foundIndex, 1);
    this.firstFormGroup.patchValue({
      id: "",
      Pickuptime: "",
      Pickuplocation: "",
      PickupItem: ""
    });
    this.pickupAddress = "";
    // this.Pickuplocation = "";
    this.nextshow = false;
    this.isPickupShow = true;
    this.isPickup = 'add';
  }
  deliveryAdd() {
    if (this.secondValid()) {
      this.isDelivery = '';
      this.isDeliveryShow = true;
      // this.secondFormGroup.value.id = this.deliveryFormData.length + 1;
      // this.deliveryFormData.push(this.secondFormGroup.value);
    }
  }
  RemoveDelivery() {
    this.nextstepshow = true;
    this.isDeliveryShow = false;
    this.isDelivery = 'addMore'
  }
  deliveryEdit(obj: any) {
    this.secondFormGroup.patchValue({
      id: obj.id,
      Deliveryat: obj.Deliveryat,
      Orderid: obj.Orderid,
      Item: obj.Item,
      ItemNew: obj.ItemNew,
      Receiver: obj.Receiver,
      Receivername: obj.Receivername,
      Receiveremail: obj.Receiveremail,
      Receivernumber: obj.Receivernumber,
      Receiveraddress: obj.Receiveraddress,
    });
    this.receiverAddress = obj.Receiveraddress;
    this.nextstepshow = false;
    this.isDelivery = 'save';
    this.isDeliveryShow = true;
  }
  deliverySave() {
    if (this.secondValid()) {
      this.isDeliveryShow = false;
      this.isDelivery = 'addMore';
      var foundIndex = this.deliveryFormData.findIndex(obj => obj.id == this.secondFormGroup.value.id);
      this.deliveryFormData[foundIndex] = { ...this.secondFormGroup.value };
    }
  }
  deliveryDelete(obj: any) {
    this.isDeliveryShow = true;
    this.isDelivery = 'add'
    var foundIndex = this.deliveryFormData.findIndex(x => x.id == obj.id);
    this.deliveryFormData.splice(foundIndex, 1);
    this.secondFormGroup.patchValue({
      id: "",
      Deliveryat: "",
      Orderid: "",
      Item: "",
      ItemNew: "",
      Receiver: "",
      Receivername: "",
      Receiveremail: "",
      Receivernumber: "",
      Receiveraddress: "",
    });
    this.Receivername = "";
    this.Receivernumber = "";
    this.Receiveremail = "";
    this.receiverAddress = "";
    this.Receiveraddress = "";
  }
  onChange(result: PlaceResult, arg: any) {
    let val = result['target']['value']
    if (val == '') {
      this.firstFormGroup.patchValue({
        Pickuplocation: ''
      });
    }
  }
  onChangeReceiverAdddress(result: PlaceResult, arg: any) {
    let val = result['target']['value']
    if (val == '') {
      this.secondFormGroup.patchValue({
        Receiveraddress: ''
      });
    }
  }
  onAutocompleteSelected(result: PlaceResult, arg: any) {
    if (arg == 'pickup') {
      this.pickupAddress = result.formatted_address;
      this.firstFormGroup.patchValue({
        Pickuplocation: result.formatted_address,
      });
    }
    if (arg == 'delivery') {
      this.receiverAddress = result.formatted_address;
      this.secondFormGroup.patchValue({
        Receiveraddress: result.formatted_address,
      });
      const arrayControl = <FormArray>this.secondFormGroup.controls['formArray1'];
      arrayControl.controls[0].patchValue({
        Receiveraddress: result.formatted_address
      });
    }
    if (arg == 'sender') {
      this.thirdFormGroup.patchValue({
        Senderaddress: result.formatted_address,
      });
    }
  }
  changeDeliveryOption() {
    this.isPickup = 'add';
    this.isDelivery = 'add';
    this.pickupFormData = [];
    this.deliveryFormData = [];
    this.pickupAddress = [];
    this.isPickupShow = true;
    this.isDeliveryShow = true;
    this.formDataReset();
    this.callReceivers();
    this.callItems();
  }
  formDataReset() {
    this.firstFormGroup = this.formBuilder.group({
      Pickuptime: ['', Validators.required],
      Pickuplocation: ['', Validators.required],
      pickup_phone: [''],
      id: [''],
    });
    this.secondFormGroup = this.formBuilder.group({
      Deliveryat: ['', Validators.required],
      Orderid: [''],
      Item: ['', Validators.required],
      ItemNew: [''],
      Receiver: [''],
      Receivername: ['', Validators.required],
      Receiveremail: [''],
      Receivernumber: [''],
      Receiveraddress: [''],
      id: [''],
    });
    this.thirdFormGroup = this.formBuilder.group({
      Sendername: [''],
      Sendernumber: ['', Validators.required],
      Schedulestatus: ['Unallocated', Validators.required],
      Senderaddress: [''],
      SenderZone: ['', Validators.required],
      Scheduleuser: [''],
      Geolocationmeters: ['200', Validators.required],
    });
  }

  getDriverZones() {
    this.apiService
      .drivingZoneList()
      .subscribe((response: any) => {
        this.zoneList = response.data;
      });
  }

  callReceivers() {
    this.apiService
      .customerList()
      .subscribe((response: any) => {
        let tempRes = [];
        response.data.forEach(element => {
          tempRes.push(element);
        });
        this.ReceiverOptions = tempRes;
      });
  }
  callItems() {
    this.apiService
      .itemList()
      .subscribe((response: any) => {
        let tempRes = [];
        response.data.forEach(element => {
          tempRes.push({ id: element.id, name: element.name });
        });
        this.ItemOptions = tempRes;
      });
  }
  receiverChanged(arg: any, index: number) {
    var arrayControl = <FormArray>this.secondFormGroup.controls['formArray1'];
    if (arg) {
      let filterData = this.ReceiverOptions.find(obj => obj.id == arg);
      arrayControl.controls[index].patchValue({
        Receivername: filterData.name,
        Receiveremail: filterData.email,
        Receivernumber: filterData.contact_no,
        Receiveraddress: filterData.address
      });
      this.agmshowmap1 = 2;
      this.receivershow = true
      this.receiverAddress = filterData.address;
      this.deleiveryLocation[index] = {
        latitude: parseFloat(filterData.loc_lat), longitude: parseFloat(filterData.loc_lng)
      }
    } else {
      arrayControl.controls[index].patchValue({
        Receivername: "",
        Receiveremail: "",
        Receivernumber: " ",
        Receiveraddress: "",
      });
      this.agmshowmap1 = 0;
      this.receivershow = false;
      this.receiverAddress = "";
      this.deleiveryLocation[index] = {
        latitude: null, longitude: null
      }
    }
  }
  changeQuantity(data: any, iX: number, iY: number) {
    var formData = <FormArray>this.secondFormGroup.controls['formArray1'];
    formData.controls[iX].value.formArray2[iY] = {
      IsFrom: data.IsFrom,
      ItemName: data.ItemName,
      Quantity: data.Quantity,
      id: data.id,
    };
  }
  itemChanged(data: any, index: number) {
    this.loader.show();
    if (data.length) {
      setTimeout(() => {
        let itemList = this.secondFormGroup.controls['formArray1']['controls'][index]['controls']['formArray2']['value'];
        for (let i = 0; i < itemList.length; i++) {
          if (itemList[i].IsFrom == "oldItem") {
            if (!data.find(obj => obj.name.toLowerCase() == itemList[i].ItemName.toLowerCase())) {
              // condition true
              let arrayControl = <FormArray>this.secondFormGroup.controls['formArray1']['controls'][index]['controls']['formArray2'];
              arrayControl.removeAt(i);
            }
          }
        }
        let newItemList = this.secondFormGroup.controls['formArray1']['controls'][index]['controls']['formArray2']['value'];
        for (let i = 0; i < data.length; i++) {
          let isNew = newItemList.find(obj => obj.ItemName.toLowerCase() == data[i].name.toLowerCase());
          if (!isNew) {
            const control = (<FormArray>this.deliveryForm.formArray1).at(index).get('formArray2') as FormArray;
            control.push(
              this.formBuilder.group({
                ItemName: [data[i].name],
                Quantity: [1],
                IsFrom: ["oldItem"],
                id: [data[i].id],
              }));
          }
        }
        this.loader.hide();
      }, 300);
    } else {
      setTimeout(() => {
        let itemList = this.secondFormGroup.controls['formArray1']['controls'][index]['controls']['formArray2']['value'];
        if (itemList.length) {
          for (let i = 0; i < itemList.length; i++) {
            if (itemList[i].IsFrom == "oldItem") {
              if (!data.find(obj => obj.name.toLowerCase() == itemList[i].ItemName.toLowerCase())) {
                // condition true
                let arrayControl = <FormArray>this.secondFormGroup.controls['formArray1']['controls'][index]['controls']['formArray2'];
                arrayControl.removeAt(i);
              }
            }
          }
        } else {
          let arrayControl = <FormArray>this.secondFormGroup.controls['formArray1']['controls'][index]['controls']['formArray2']['value'];
          arrayControl.removeAt(index);
        }
        this.loader.hide();
      });
    }
  }
  newItemChanged(object: any, index: number) {
    this.loader.show();
    let data = object ? object.split(',') : [];
    if (data.length) {
      setTimeout(() => {
        let itemList = this.secondFormGroup.controls['formArray1']['controls'][index]['controls']['formArray2']['value'];
        for (let i = 0; i < itemList.length; i++) {
          if (itemList[i].IsFrom == "newItem") {
            if (!data.find(obj => obj.toLowerCase() == itemList[i].ItemName.toLowerCase())) {
              let arrayControl = <FormArray>this.secondFormGroup.controls['formArray1']['controls'][index]['controls']['formArray2'];
              var arrayIndex = this.secondFormGroup.controls['formArray1']['controls'][index]['controls']['formArray2']['value'].findIndex(obj => obj.id_fork == itemList[i].id_fork);
              arrayControl.removeAt(arrayIndex);
            }
          }
        }
        let newItemList = this.secondFormGroup.controls['formArray1']['controls'][index]['controls']['formArray2']['value'];
        for (let i = 0; i < data.length; i++) {
          if (!this.secondFormGroup.controls['formArray1']['controls'][index]['controls']['formArray2']['value'].find(obj => obj.ItemName.toLowerCase() == data[i].toLowerCase())) {
            if (data[i] && !this.hasWhiteSpaces(data[i])) {
              const control = (<FormArray>this.deliveryForm.formArray1).at(index).get('formArray2') as FormArray;
              control.push(
                this.formBuilder.group({
                  ItemName: [data[i]],
                  Quantity: [1],
                  IsFrom: ["newItem"],
                  id_fork: [i + data[i] + i],
                  id: [""],
                }));
            }
          }
        }
        this.loader.hide();
      }, 300);
    } else {
      setTimeout(() => {
        let itemList = this.secondFormGroup.controls['formArray1']['controls'][index]['controls']['formArray2']['value'];
        for (let i = 0; i < itemList.length; i++) {
          if (itemList[i].IsFrom == "newItem") {
            let arrayControl = <FormArray>this.secondFormGroup.controls['formArray1']['controls'][index]['controls']['formArray2'];
            var arrayIndex = this.secondFormGroup.controls['formArray1']['controls'][index]['controls']['formArray2']['value'].findIndex(obj => obj.id_fork == itemList[i].id_fork);
            arrayControl.removeAt(arrayIndex);
          }
        }
        this.loader.hide();
      }, 300);
    }
  }
  hasWhiteSpaces(string) {
    let newString = string.replace(/^[ ]+/g, "");
    if (newString != null && newString != undefined && newString != '') {
      return false;
    } else {
      return true;
    }
  }
  changePreferredCountries() {
    this.preferredCountries = [
      CountryISO.India,
      CountryISO.Canada,
    ];
  }

  showmap(value: number) {
    this.agmshowmap = value;
  }
  showmap1(value: number) {
    this.agmshowmap1 = value;
  }
  showmap2(value: number) {
    this.agmshowmap2 = value;
  }

  zoneChange(value) {
    if (value == '') {
      this.userList = [];
    }
    // else if (value == '1') {
    //   this.loader.show();
    //   this.apiService
    //     .userActiveLists()
    //     .subscribe((response: any) => {
    //       this.userList = response.data;
    //       this.loader.hide();
    //     });
    // }
    else {
      this.zoneList.forEach(element => {
        if (element.id == value) {
          this.userList = element.selected_drivers;
        }
      });
    }
  }

  statusChange(obj, emp_id) {
    // this.thirdFormGroup.controls['Scheduleuser'].setValue('');
    if (obj != 'Unallocated') {
      this.userListShow = true;
      // this.thirdFormGroup.controls['SenderZone'].setValidators(Validators.required);
      this.thirdFormGroup.controls['Scheduleuser'].setValidators(Validators.required);
      if (emp_id != '') {
        this.thirdFormGroup.controls['Scheduleuser'].setValue(emp_id);
      }
    } else {
      // this.thirdFormGroup.controls['SenderZone'].setValue('');
      // this.thirdFormGroup.controls['SenderZone'].clearValidators();
      // this.thirdFormGroup.controls['SenderZone'].updateValueAndValidity();

      this.thirdFormGroup.controls['Scheduleuser'].setValue('');
      this.userListShow = false;
      this.thirdFormGroup.controls['Scheduleuser'].clearValidators();
      this.thirdFormGroup.controls['Scheduleuser'].updateValueAndValidity();
    }
  }

  // statusChange(obj, emp_id){
  //   if(obj !='Unallocated'){
  //     this.userListShow = true;
  //     this.thirdFormGroup.controls['Scheduleuser'].setValidators(Validators.required);
  //     if (emp_id != '') {
  //       this.thirdFormGroup.controls['Scheduleuser'].setValue(emp_id);
  //     }
  //   } else {
  //     this.thirdFormGroup.controls['Scheduleuser'].setValue('');
  //     this.userListShow = false;
  //     this.thirdFormGroup.controls['Scheduleuser'].clearValidators();
  //     this.thirdFormGroup.controls['Scheduleuser'].updateValueAndValidity(); 
  //   }
  // }
  omit_char(event: any) {
    const keyChar = event.key;
    let allowCharacter: boolean;
    if (event.target.value.length < 0 || event.target.value.length > 11) {
      event.preventDefault();
    }
    if (keyChar === '-' && event.target.selectionStart !== 0) {
      allowCharacter = false;
    } else if (
      keyChar === 'Tab' ||
      keyChar === 'Enter' ||
      keyChar === 'Backspace' ||
      keyChar === 'ArrowLeft' ||
      keyChar === 'ArrowRight' ||
      keyChar === 'Delete'
    ) {
      allowCharacter = true;
    } else {
      allowCharacter = keyChar >= '0' && keyChar <= '9';
    }
    if (!allowCharacter) {
      event.preventDefault();
    }
  }
}

import { Component, OnInit, ViewChild, QueryList, ViewChildren, ElementRef, Inject, ChangeDetectorRef, AfterViewInit } from "@angular/core";
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders, HttpErrorResponse } from "@angular/common/http";
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import $ from "jquery";
import { AuthService } from 'src/app/services/AuthService';
import { AppService } from 'src/app/services/AppService';
import { ApiService } from 'src/app/services/ApiService';
import { Image_Path } from 'src/app/config/config';
import { GeneralService } from 'src/app/services/GeneralService';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ThemePalette } from '@angular/material/core';
import * as moment from 'moment';

export interface UpdateDialogData {
  user_id: any;
  update_data: any;
  param: any;
}
@Component({
  selector: 'app-payment-response',
  templateUrl: './payment-response.component.html',
  styleUrls: ['./payment-response.component.css']
})
export class PaymentResponseComponent implements OnInit, AfterViewInit {
  loaderOne = false;
  isOpen = false;
  modelTitle: any;
  ProfileData: any;
  Firstname: any;
  Lastname: any;
  Phoneno: any;
  Email: any;
  PickupAddress: any;
  Role: string;
  UserData: any;
  PackageDetails: any;
  PackageName: any;
  PackActivateDate: any;
  PackEndDate: any;
  TotalAllocatedEmployees: any;
  Remainingloyees: any;
  SelectedTask: string;
  auto_checked:boolean = false;
  autoallocshow: boolean;
  Timezone: any;
  Location: any;
  ReqExpires: any;
  MaxRadius: any;
  alloclogic: any;
  autochecked: number;
  S3BaseUrl = Image_Path.S3_BASE_URL;
  headers: HttpHeaders;
  ProfileImage: any;
  color: any;
  disabled: any;
  fileToUpload: File;
  profileImage: any;
  userData: any;
  packDataInfo: any;
  total_emp: number;
  remaining_emp: number;
  total_mgr: any;
  remaining_mgr: any;
  managershow: boolean;
  UserID: string;
  paymentDetails: any;
  popupForm: FormGroup;
  popupFormExpiry: FormGroup;
  popupFormCust: FormGroup;
  popupFormTask: FormGroup;
  popupFormEmp: FormGroup;
  payment_id: string;
  paymentResponse: string;
  secondCounter: number;

  constructor(
    public dialog: MatDialog,
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private loader: NgxSpinnerService,
    private toastr: ToastrService,
    private authService: AuthService,
    public appService: AppService,
    public apiService: ApiService,
    public generalService: GeneralService,
    private snackBar:MatSnackBar,
    public ref: ChangeDetectorRef,
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit() {
    console.log("payment response", this.route.snapshot.paramMap.get('payment_id'));
    this.userData = JSON.parse(localStorage.getItem("user_data"));
    this.payment_id = this.route.snapshot.paramMap.get('payment_id');
    if(this.payment_id){
      this.getPaymentDetailsById()
    }
    this.popupFormExpiry = this.formBuilder.group({
      date: ['', Validators.required]
    })
    this.popupFormCust = this.formBuilder.group({
      no_of_cust: ['', Validators.required]
    })
    this.popupFormTask = this.formBuilder.group({
      total_task: ['', Validators.required]
    })
    this.popupFormEmp = this.formBuilder.group({
      total_emp: ['', Validators.required]
    })
    this.secondCounter = 10;
  }

  ngAfterViewInit() {
    var intervalId = setInterval(() => {
      this.secondCounter--;
      if(this.secondCounter == 0) {
        this.redirectToProfile(intervalId);
      }
    }, 1000)
  }

  redirectToProfile(intervalId) {
    clearInterval(intervalId);
    this.router.navigate(["/profile"]);
  }

  getPaymentDetailsById(){
    this.apiService.getPaymentDetailsById(this.payment_id).subscribe((response)=>{
      this.paymentDetails = response['data'];
      if(this.paymentDetails.status == 'Success') {
        this.paymentResponse = "Thank you for making the payment. We received your payment and your account has been renewed";
      } else {
        this.paymentResponse = "Sorry! Your last payment was not received successfully. Reason: " + this.paymentDetails.failure_message;
      }
    })
  }

  
}


import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders, HttpErrorResponse } from "@angular/common/http";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { FormGroup } from '@angular/forms';
import { ApiService } from '../../services/ApiService';
import { AppService } from '../../services/AppService';
import { AuthService } from '../../services/AuthService';
import { Multi_Select_Config } from 'src/app/config/config';
import { FormBuilder } from '@angular/forms';
import { Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-add-driving-zone',
  templateUrl: './add-driving-zone.component.html',
  styleUrls: ['./add-driving-zone.component.css'],
})
export class AddDrivingZoneComponent implements OnInit {

  pointList: { lat: number; lng: number }[] = [];
  selectedShape: any;
  selectedArea = 0;
  availableDrivers: any = [];
  Config = Multi_Select_Config.config;
  ZoneName: any;
  DriverList: any;
  drivingZoneGroup: FormGroup;
  dataModel: any;
  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};
  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private loader: NgxSpinnerService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private authService: AuthService,
    public appService: AppService,
    public apiService: ApiService,
  ) { }

  ngOnInit() {
    this.drivingZoneGroup = this.formBuilder.group({
      zoneName: ['', Validators.required],
      driverList: ['', Validators.required],
    });

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };

    this.getDriverList()

  }
  //getDriverDetails(0)
  getDriverList() {
    this.apiService
      .getDriverDetails(0)
      .subscribe((response: any) => {
        let tempRes = [];
        response.data.forEach(element => {
          tempRes.push({ id: element.user_id, name: element.first_name });
        });
        this.availableDrivers = tempRes
      });
  }
  Cancel() {
    this.router.navigate(["driving-zone"]);
  }
  onItemSelect(item: any) {
  }

  onSelectAll(items: any) {
    this.dataModel = items
    console.log(items);
  }

  Submit() {
    if (this.drivingZoneGroup.value.zoneName == "") {
      this.snackBar.open("Zone Name is required", "Close", {
        duration: 4000,
        verticalPosition: 'bottom'
      });
      return false;
    }

    if (this.drivingZoneGroup.value.driverList == null) {
      this.snackBar.open("Driver List should not be empty", "Close", {
        duration: 4000,
        verticalPosition: 'bottom'
      });
      return false;
    }

    let driverIds = this.dataModel.map(function (x) {
      return x.id;
    });

    let payload = {
      "data": {
        "name": this.drivingZoneGroup.value.zoneName,
        "selected_area": this.pointList,
        "selected_drivers": driverIds
      }
    }
    this.submitApiCall(payload)

  }

  submitApiCall(payload) {
    this.apiService
      .createDrivingZone(payload)
      .subscribe((response: any) => {
        if (response.status == 'ok') {
          this.toastr.success("Zone created successfully");
          this.router.navigate(["/driving-zone"]);
        } else {
          this.toastr.warning(response.data);
        }
      });
  }

  reback(obj: any) {
    this.pointList = [];
    const len = obj.getLength();
    for (let i = 0; i < len; i++) {
      this.pointList.push(
        obj.getAt(i).toJSON()
      );
    }
    this.selectedArea = google.maps.geometry.spherical.computeArea(
      obj
    );
  }
}

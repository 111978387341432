import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders, HttpErrorResponse } from "@angular/common/http";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { FormControl, FormGroup } from '@angular/forms';
import { ApiService } from './../../services/ApiService';
import { AppService } from './../../services/AppService';
import { AuthService } from './../../services/AuthService';
import { startOfDay,endOfDay,subDays,addDays,endOfMonth,isSameDay,isSameMonth,addHours } from 'date-fns';
import { Subject } from 'rxjs';
// import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CalendarEvent, CalendarEventAction, CalendarEventTimesChangedEvent, CalendarView } from 'angular-calendar';
import { TimezoneService } from 'src/app/services/TimezoneService';

const colors: any = {
  red: {
    primary: '#dc3545',
    secondary: '#FAE3E3',
  },
  blue: {
    primary: '#3f51b5',
    secondary: '#D1E8FF',
  },
  green: {
    primary: '#28a745',
    secondary: '#d8f7df',
  },
  yellow: {
    primary: '#f7bf08',
    secondary: '#ffffb3',
  },
  violet: {
    primary: '#993366',
    secondary: '#ecc6d9',
  },
};

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.css'],
})
export class CalendarComponent implements OnInit {
  // @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;
  view: CalendarView = CalendarView.Month;
  CalendarView = CalendarView;
  viewDate: Date = new Date();
  modalData: {
    action: string;
    event: CalendarEvent;
  };
  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl()
  });
  actions: CalendarEventAction[] = [
    {
      label: '<i class="fas fa-info-circle text-info"></i>',
      a11yLabel: 'Edit',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.handleEvent('Edited', event);
      },
    },
    // {
    //   label: '<i class="fas fa-fw fa-trash-alt text-info"></i>',
    //   a11yLabel: 'Delete',
    //   onClick: ({ event }: { event: CalendarEvent }): void => {
    //     this.events = this.events.filter((iEvent) => iEvent !== event);
    //     this.handleEvent('Deleted', event);
    //   },
    // },
  ];
  refresh: Subject<any> = new Subject();
  events: CalendarEvent[] = [];
  activeDayIsOpen: boolean = false;
  isOpenModal = false;
  userData: any;
  showME = true;
  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private loader: NgxSpinnerService,
    private toastr: ToastrService,
    private authService: AuthService,
    public appService: AppService,
    public apiService: ApiService,
    public timezone: TimezoneService
  ) { }

  ngOnInit(){
    this.userData = JSON.parse(localStorage.getItem("user_data"));
    let params = this.getParams();
    this.loadEvents(params);
    setTimeout(()=>{  
      this.showME = false;
    },60000);
  }
  loadEvents(payload : any) {
    if(payload.date){
      this.loader.show();
      this.apiService
        .calendarList(payload.date)
        .subscribe((response: any) => {
          let tempEvents = [];
          if(response.error) {
            this.events = [];
            this.loader.hide();
          }else {
            let tempData = Object.values(response.data)
            let isResult = response.data[payload.date];
            // check if there is today's event
             if(isResult){
              if(isResult['Accepted'].length ||
              isResult['Allocated'].length ||
              isResult['Delivered'].length ||
              isResult['In-Progress'].length ||
              isResult['Unallocated'].length){
                this.activeDayIsOpen = true;
              }
            }
            tempData.forEach(element => {
              let temparray;
              if(element['Accepted'].length){
                element['Accepted'].forEach(element => {
                  temparray = {
                    start: new Date(new Date(element.order_start_time.replace(/\s/, 'T')).toISOString()),
                    end: new Date(new Date(element.order_end_time.replace(/\s/, 'T')).toISOString()),
                    title: `Accepted Task From Order ID : ${element.id}`,
                    data: element,
                    color: colors.violet,
                    actions: this.actions,
                    allDay: false,
                    resizable: {
                      beforeStart: false,
                      afterEnd: false,
                    },
                    draggable: false,
                  }
                  tempEvents.push(temparray);
                });
              }
              if(element['Allocated'].length) {
                element['Allocated'].forEach(element => {
                  temparray = {
                    start: new Date(new Date(element.order_start_time.replace(/\s/, 'T')).toISOString()),
                    end: new Date(new Date(element.order_end_time.replace(/\s/, 'T')).toISOString()),
                    title: `Allocated Task From Order ID : ${element.id}`,
                    data: element,
                    color: colors.blue,
                    actions: this.actions,
                    allDay: false,
                    resizable: {
                      beforeStart: false,
                      afterEnd: false,
                    },
                    draggable: false,
                  }
                  tempEvents.push(temparray);
                });
              }
              if(element['Delivered'].length){
                element['Delivered'].forEach(element => {
                  temparray = {
                    start: new Date(new Date(element.order_start_time.replace(/\s/, 'T')).toISOString()),
                    end: new Date(new Date(element.order_end_time.replace(/\s/, 'T')).toISOString()),
                    title: `Delivered Task From Order ID : ${element.id}`,
                    data: element,
                    color: colors.green,
                    actions: this.actions,
                    allDay: false,
                    resizable: {
                      beforeStart: false,
                      afterEnd: false,
                    },
                    draggable: false,
                  }
                  tempEvents.push(temparray);
                });
              }
              if(element['In-Progress'].length){
                element['In-Progress'].forEach(element => {
                  temparray = {
                    start: new Date(new Date(element.order_start_time.replace(/\s/, 'T')).toISOString()),
                    end: new Date(new Date(element.order_end_time.replace(/\s/, 'T')).toISOString()),
                    title: `In-Progress Task From Order ID : ${element.id}`,
                    data: element,
                    color: colors.yellow,
                    actions: this.actions,
                    allDay: false,
                    resizable: {
                      beforeStart: false,
                      afterEnd: false,
                    },
                    draggable: false,
                  }
                  tempEvents.push(temparray);
                });
              }
              if(element['Unallocated'].length){
                element['Unallocated'].forEach(element => {
                  temparray = {
                    start: new Date(new Date(element.order_start_time.replace(/\s/, 'T')).toISOString()),
                    end: new Date(new Date(element.order_end_time.replace(/\s/, 'T')).toISOString()),
                    title: `Unallocated Task From Order ID : ${element.id}`,
                    data: element,
                    color: colors.red,
                    actions: this.actions,
                    allDay: false,
                    resizable: {
                      beforeStart: false,
                      afterEnd: false,
                    },
                    draggable: false,
                  };
                  tempEvents.push(temparray);
                });
              }
              this.events = tempEvents;
              this.loader.hide();
            });
          }
        });
    }else{
      console.log('something wrong');
    }
  }
  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }
  eventTimesChanged({
    event,
    newStart,
    newEnd,
  }: CalendarEventTimesChangedEvent): void {
    this.events = this.events.map((iEvent) => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd,
        };
      }
      return iEvent;
    });
    // this.handleEvent('Dropped or resized', event);
  }
  handleEvent(action: string, event: CalendarEvent): void {
    this.modalData = { event, action };
    this.isOpenModal = true;
  }
  orderDetails(orderID){
    this.router.navigate(['/order-details']);
    localStorage.setItem(
      "order_detail_id",
      orderID
    );
  }
  deleteEvent(eventToDelete: CalendarEvent) {
    this.events = this.events.filter((event) => event !== eventToDelete);
  }
  setView(view: CalendarView) {
    this.view = view;
  }
  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
    let params = this.getParams();
    this.loadEvents(params);
  }
  onCloseModal() {
    this.modalData = null;
    this.isOpenModal = false;
  }
  closeME(value: any){
    this.showME = value;
  }
  getParams(){
    let givenDate = this.timezone.calendarDateFormat(this.viewDate);
    const request = {
      date: givenDate,
    }
    return request
  }
}

<!-- <h2 mat-dialog-title>Install Angular</h2> -->
<mat-dialog-content class="mat-typography">
    <div class="form-group" *ngIf="data.param =='expiry_date'">
      <label>{{"Expiry Date" | translate}} <span class="text-danger">*</span></label>
      <!-- <input type="text" [min]="minDate" [readonly]="inputReadonly" [(ngModel)]="date"  [ngxMatDatetimePicker]="picker" class="form-control" placeholder="{{'Expiry Date' | translate}}">
      <mat-datepicker-toggle matSuffix [for]="picker" class="picktime"></mat-datepicker-toggle>
      <ngx-mat-datetime-picker #picker [format]="24" [showSpinners]="true" [showSeconds]="false"
        [stepHour]="1" [stepMinute]="1" [min]="minDate" [stepSecond]="stepSecond" [touchUi]="false"
        [color]="color">
      </ngx-mat-datetime-picker> -->
      <input matInput [matDatepicker]="picker" [min]="minDate" [readonly]="inputReadonly" [(ngModel)]="date">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </div>
    <div class="form-group" *ngIf="data.param =='no_of_cust'">
      <label>{{"No of Customers" | translate}} <span class="text-danger">*</span></label>
      <input type="number" class="form-control" placeholder="{{'No of Customers' | translate}}" [attr.disabled]="no_of_cust_checked ==true ? 'disabled':null" [(ngModel)]="no_of_cust"><br/><br/>
      <label><input type="checkbox" (change)="checkboxClick()" [(ngModel)]="no_of_cust_checked">&nbsp;&nbsp;{{"Unlimited" | translate}} </label>
    </div>
    <div class="form-group" *ngIf="data.param =='total_task'">
      <input type="text" class="form-control" placeholder="{{'No of Tasks' | translate}}" [attr.disabled]="total_task_checked ==true ? 'disabled':null" [(ngModel)]="total_task">
      <br/><br/>
      <label><input type="checkbox" (change)="checkboxClick()" [(ngModel)]="total_task_checked">&nbsp;&nbsp;{{"Unlimited" | translate}} </label>
    </div>
    <div class="form-group" *ngIf="data.param =='total_emp'">
      <input type="text" class="form-control" placeholder="{{'No of Employees' | translate}}" [(ngModel)]="total_emp" [attr.disabled]="total_emp_checked ==true ? 'disabled':null"><br/><br/>
      <label><input type="checkbox" (change)="checkboxClick()" [(ngModel)]="total_emp_checked">&nbsp;&nbsp;{{"Unlimited" | translate}} </label>
    </div>  
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button type="button" class="btn btn-sm btn-outline-info rounded-0 mb-2 mr-2" mat-dialog-close>Cancel</button>
  <button type="button" class="btn btn-sm btn-info rounded-0 mb-2 mr-2" (click)="updateData()">Change</button>
</mat-dialog-actions>

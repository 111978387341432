  import { DatePipe } from '@angular/common';
  import { HttpClient } from '@angular/common/http';
  import { Component, OnInit, NgZone, ChangeDetectorRef } from '@angular/core';
  import { ActivatedRoute, Router } from '@angular/router';
  import { NgxSpinnerService } from 'ngx-spinner';
  import { ToastrService } from 'ngx-toastr';
  import { Image_Path } from 'src/app/config/config';
  import { ApiService } from 'src/app/services/ApiService';
  import { AuthService } from 'src/app/services/AuthService';
  import { GeneralService } from 'src/app/services/GeneralService';
  import { SharedFunctionService } from 'src/app/services/SharedFunctionService';
  import { SocketService } from 'src/app/services/SocketService';
  import { TranslateService } from '@ngx-translate/core';

  @Component({
    selector: 'app-order-details',
    templateUrl: './order-details.component.html',
    styleUrls: ['./order-details.component.css']
  })
  export class OrderDetailsComponent implements OnInit {

    isRightArrow = true;
    isGroupObj: any = 'pickup';
    orederId: any;
    listOrders: any;
    userList: any;
    TrackingCust : any = [];
    RouteOrigin : any = [];
    RouteDestination: any = [];
    FinalResource : any = [];
    WayPoints : any = [];
    GpsData : any = [];
    DriverInfo : any;
    S3BaseUrl = Image_Path.S3_BASE_URL;
    Status: any;
    editshow: boolean;
    emp_id: number;
    employeeList: any;
    AgentID: any;
    OrderDetails: any;
    isUnallocated: boolean;
    isAllocated: boolean;
    TaskStatus:any;
    allocateshow: boolean;
    isExpired: any;
    archiveshow: boolean;
    isDeleteModel = false;
    deleteItem: any;
    DeliveryLogic: any;
    SelectLang: string;
    StatusUpdate: string;
    estimatedtime: any;
    
    constructor(
      private http: HttpClient,
      private route: ActivatedRoute,
      private router: Router,
      private loader: NgxSpinnerService,
      private toastr: ToastrService,
      private datePipe: DatePipe,
      public ngZone: NgZone,
      public authService: AuthService,
      public apiService: ApiService,
      public socketService: SocketService,
      public sharedfunction: SharedFunctionService,
      public changeDetector: ChangeDetectorRef,
      public generalService: GeneralService,
      public translate: TranslateService
    ) { }

    ngOnInit() {
      this.SelectLang = localStorage.getItem("language");
      this.translate.get('Status has been updated successfully').subscribe((text: string) => {
        this.StatusUpdate= text
      });
      this.translate.use(this.SelectLang);
      this.apiService
      .userActiveLists()
      .subscribe((response: any) => {
        this.employeeList = response.data;
      });
      this.isUnallocated = false;
      this.isAllocated = false;
      this.loader.show();
      this.orederId = JSON.parse(localStorage.getItem("order_detail_id"));
      this.isExpired = JSON.parse(localStorage.getItem("is_expired"));
      this.ngZone.run(() =>{
      this.apiService
        .orderDetailView(this.orederId)
        .subscribe((response: any) => {
          this.emp_id = response.data.emp_id;
          if(response.data){
            if(response.data.status == "Unallocated"){
              this.isUnallocated = true;
              this.emp_id = response.data.emp_id;
            }
            if(response.data.status != "Unallocated"){
              this.isAllocated = true;
            }
            if(response.data.status == "Unallocated" || response.data.status == "Allocated"){
              this.archiveshow = true;
            }else{
              this.archiveshow = false;
            }
            this.Status = response.data.status;
            if(this.Status == "Delivered"){
              this.editshow = false;
              this.isAllocated = false;
            }else{
              this.editshow = true;
            }
            this.OrderDetails = response.data.order;
            let tempData,gpsData = [];
            if(response.data.delivery_logic){
              tempData = this.sharedfunction.processOrderDetailsData(response.data);
              if(tempData.otherDetails.status == "Delivered" ||
                tempData.otherDetails.status == "Completed" ||
                tempData.otherDetails.status == "Delivered back" ||
                tempData.otherDetails.status == "Incomplete"){
                this.apiService
                  .orderSummary(this.orederId)
                  .subscribe((response: any) => {
                    if(response.data){
                      response.data.gpsData.forEach(element => {
                        gpsData.push({
                          lat: parseFloat(element.lat),
                          lng: parseFloat(element.lng),
                          path: element.path
                        })
                      });
                      tempData.historyDetails = response.data;
                      tempData.gpsDetails = gpsData;
                    }
              });
              }
            }
            this.TaskStatus = this.OrderDetails[0].status;
            if(this.TaskStatus == 'Allocated'){
              this.allocateshow = true;
            }else{
              this.allocateshow = false;
            }
            this.listOrders = tempData;
            this.listOrders.orderStatusDropdown = false;
            if(response.data.status == "Allocated"){
              this.isAllocated = true;
              this.listOrders.orderStatusDropdown = true;
            }
            this.loader.hide();
          }
        });
      });
      this.estimatedTime();
    }
    ngAfterViewInit() {
      this.ngZone.run(() =>{
        this.socketService.userOrderTrackSocket.subscribe((data: any) => {
          if(data.data != undefined && (data.data.id == this.orederId)){
          let response = data;
            var tempResponseData = data.data;
            tempResponseData['order'] = response.data.order_data;
            if(tempResponseData.order != undefined){
              // this.listOrders = undefined;
              let tempData,gpsData = [];
              if(tempResponseData.delivery_logic){
                tempData = this.sharedfunction.processOrderDetailsData(tempResponseData);
                if(tempData.otherDetails.status == "Delivered" ||
                  tempData.otherDetails.status == "Completed" ||
                  tempData.otherDetails.status == "Delivered back" ||
                  tempData.otherDetails.status == "Incomplete"){
                  this.apiService
                    .orderSummary(this.orederId)
                    .subscribe((response: any) => {
                      if(response.data){
                        response.data.gpsData.forEach(element => {
                          gpsData.push({
                            lat: parseFloat(element.lat),
                            lng: parseFloat(element.lng),
                            path: element.path
                          })
                        });
                        tempData.historyDetails = response.data;
                        tempData.gpsDetails = gpsData;
                      }
                  });
                }
              }
              if(response.data.emp_id == this.emp_id){
                this.listOrders = tempData;
                this.generalService.gpsDetailsLatLng(tempData);
                this.changeDetector.detectChanges();
              }
            }
          }
        });
        this.socketService.locationUpdateOrderDetailSocket.subscribe((data: any) => {
          if(data.data != undefined && (data.data.id == this.orederId)){
            this.changeDetector.detectChanges();
            let response = data;
            this.changeDetector.detectChanges();
            if(this.listOrders.otherDetails){
              if(this.listOrders.otherDetails.emp_id == this.emp_id){
                this.generalService.orderDriverLatLng({
                  lat: response.data.lat,
                  lng: response.data.lng,
                  status: true
                });
                this.changeDetector.detectChanges();
              }
            }            
          }
        });
      });
    }

    switchLang(lang: string) {
      localStorage.setItem("language",lang)
      this.translate.use(lang);
    }

    estimatedTime(){
      let payloadd= {data:{order_id: this.orederId}};
      this.apiService
       .getEstimatedTime(payloadd)
       .subscribe((response: any)=>{
          this.estimatedtime= response.data;
       })
    }

    AllocateAgent(OrderID){
      var payload = {
        "data" : {
          "emp" : this.AgentID,
          "status" : "Allocated",
          "taskdata": this.OrderDetails
        }
      }
      this.loader.show();
      this.apiService
      .AllocateAgent(payload,OrderID)
      .subscribe((response: any) => {
        if(response.status == "ok"){
          this.toastr.success(this.StatusUpdate);
          this.changeDetector.detectChanges();
          this.ngOnInit();
        this.loader.hide();
        }else{
          this.toastr.warning(response.data);
        this.loader.hide();
        }
      });
    }
    DeleteModel(element: any) {
      this.deleteItem = element;
      this.isDeleteModel = true;
    }
    ArchiveOrder(){
      var payload = {
        "data" : {
          // "emp" : this.OrderDetails[0].emp_info.user_id,
          "status" : "Archive",
          "order_id": this.deleteItem
        }
      }
      this.loader.show();
      this.apiService
      .UpdateTaskStatus(payload)
      .subscribe((response: any) => {
        if(response.status == "ok"){
          this.toastr.success(this.StatusUpdate);
          this.changeDetector.detectChanges();
          this.router.navigate(['/dashboard']);
          this.ngOnInit();
        this.loader.hide();
        }else{
          this.toastr.warning(response.data);
        this.loader.hide();
        }
      });
    }

    UpdateStatus(Order_ID){
      var payload = {
        "data" : {
          "order_id" : Order_ID,
          "status" : this.TaskStatus,
        }
      }
      this.loader.show();
      this.apiService
      .UpdateTaskStatus(payload)
      .subscribe((response: any) => {
        if(response.status == "ok"){
          this.toastr.success(this.StatusUpdate);
          this.changeDetector.detectChanges();
          this.ngOnInit();
        this.loader.hide();
        }else{
          this.toastr.warning(response.data);
        this.loader.hide();
        }
      });
    }
    
    rightArrow(){
      this.isRightArrow = !this.isRightArrow;
    }
    isGroup(obj :any) {
      this.isGroupObj = obj;
    }
    driverList(){
      this.loader.show();
      this.apiService
        .userLists()
        .subscribe((response: any) => {
          var element = response.data.find(obj =>obj.user_id == this.listOrders.emp);
          if(element.profile_image.length > 2){
            var result = element.profile_image.charAt(0);
            if(result == "["){
              var getProfile = JSON.parse(element.profile_image);
              element.profile_image = getProfile[0];
            }
          }
          this.userList = element;
          this.loader.hide();
        });
    }
    PickupTaskStatusChange(status,orderlist){
      this.DeliveryLogic = this.listOrders.otherDetails.delivery_logic;
      var pickuplat = orderlist.pickup_ladd;
      var pickuplan = orderlist.pickup_long;
      var task_id = orderlist.id;
      var time = new Date();
      console.log(this.DeliveryLogic)
      var timestamp = this.datePipe.transform(time, 'yyyy-MM-dd H:mm:ss');
      if(this.DeliveryLogic == 1){
        var payload = {
          "data" : {
            "order_id" : orderlist.mt_order_id,
            "status" : status,
          }
        }
        this.loader.show();
        this.apiService
        .UpdateTaskStatus(payload)
        .subscribe((response: any) => {
          if(response.status == "ok"){
            this.toastr.success(this.StatusUpdate);
            this.changeDetector.detectChanges();
            this.ngOnInit();
          this.loader.hide();
          }else{
            this.toastr.warning(response.data);
          this.loader.hide();
          }
        });
      }else{
        var payloads = {
          "data" : {
            "status": status,
            "lat": pickuplat,
            "lng": pickuplan,
            "timestamps": timestamp,
            "network_status": ""
          }
        }
        this.loader.show();
        this.apiService
        .UpdateTaskViseStatus(payloads,task_id)
        .subscribe((response: any) => {
          if(response.status == "ok"){
            this.toastr.success(this.StatusUpdate);
            this.changeDetector.detectChanges();
            this.ngOnInit();
          this.loader.hide();
          }else{
            this.toastr.warning(response.data);
          this.loader.hide();
          }
        });
      }
      
    }
    DeliveryTaskStatusChange(status,orderlist){
      this.DeliveryLogic = this.listOrders.otherDetails.delivery_logic;
      var deliverylat = orderlist.loc_lat;
      var deliverylan = orderlist.loc_lat;
      var task_id = orderlist.id;
      var order_id = orderlist.mt_order_id
      var time = new Date();
      var timestamp = this.datePipe.transform(time, 'yyyy-MM-dd H:mm:ss');
      if(this.DeliveryLogic != 2){
        var payload = {
          "data": {
              "status": status,
              "lat": deliverylat,
              "lng": deliverylan,
              "timestamps": timestamp,
              "delivery_to": "",
              "delivery_phone": "",
              "is_cust_delivery": orderlist.is_cust_delivery,
              "remarks": "",
              "signature": "",
              "idproof": "",
              "reason_id": "",
              "network_status": "online",
              "mt_order_id": orderlist.mt_order_id,
              "delivery_logic": this.DeliveryLogic
          }
      }
        this.loader.show();
        this.apiService
        .UpdateTaskViseDeliverStatus(payload,task_id)
        .subscribe((response: any) => {
          if(response.status == "ok"){
            this.toastr.success(this.StatusUpdate);
            this.changeDetector.detectChanges();
            this.ngOnInit();
          this.loader.hide();
          }else{
            this.toastr.warning(response.data);
          this.loader.hide();
          }
        });
      } else {
          var payload = {
            "data": {
                "status": status,
                "lat": deliverylat,
                "lng": deliverylan,
                "timestamps": timestamp,
                "delivery_to": "",
                "delivery_phone": "",
                "is_cust_delivery": orderlist.is_cust_delivery,
                "remarks": "",
                "signature": "",
                "idproof": "",
                "reason_id": "",
                "network_status": "online",
                "mt_order_id": orderlist.mt_order_id,
                "delivery_logic": this.DeliveryLogic
            }
        }
          this.loader.show();
          this.apiService
          .UpdateTaskViseUnDeliverStatus(payload,order_id)
          .subscribe((response: any) => {
            if(response.status == "ok"){
              this.toastr.success(this.StatusUpdate);
              this.changeDetector.detectChanges();
              this.ngOnInit();
            this.loader.hide();
            }else{
              this.toastr.warning(response.data);
            this.loader.hide();
            }
          });
      }
      
    }
    editOrder(orderID){
      this.router.navigate(['/order-edit'],{queryParams: {id: orderID}});
      localStorage.setItem(
        "order_detail_id",
        orderID
      );
      // this.generalService.EditOrderModule(obj.resource.id);
      // this.router.navigate(['/order-edit'],{queryParams: {id: obj['resource'].id}});
    } 
    onClose(){
      this.deleteItem = undefined;
      this.isDeleteModel = false;
    }
  }
import { Component, OnInit } from '@angular/core';
import { AppService } from '../services/AppService';
import { AuthService } from '../services/AuthService';
import { Router } from '@angular/router';
import { ApiService } from '../services/ApiService';
import * as moment from 'moment-timezone';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {
  isExpired: boolean;
  isExpiredTwodays: boolean;
  checkUserExpires: any;
  resourceData: { isExpired: boolean; isExpiredTwodays: boolean; };
  isLanguage:any;
  language: any;
  languageSubject: any;
  constructor(
    private appService: AppService,
    private authService: AuthService,
    private router: Router,
    public apiService: ApiService,
  ) {}

  ngOnInit() {
    var role = localStorage.getItem("user_data");
    var userData = JSON.parse(role);
    var role_id = userData.profile.role_id
    if(role_id == 3){
      this.router.navigate(['manage-users']);
    } else {
      this.checkUserExpired();
    }
  }
  getClasses() {
    const classes = {
      'pinned-sidebar': this.appService.getSidebarStat().isSidebarPinned,
      'toggeled-sidebar': this.appService.getSidebarStat().isSidebarToggeled
    }
    return classes;
  }
  toggleSidebar() {
    this.appService.toggleSidebar();
  }
  justHide() {
    if(!this.appService.getSidebarStat().isSidebarPinned){
      this.appService.toggleSidebarPin();
    }
  }
  checkUserExpired(){
    this.apiService
      .checkUsers()
      .subscribe((response: any) => {
        this.checkUserExpires = response[0];
        var _date = new Date();
        var current_date = moment(_date).format("YYYY-MM-DD");
        var end_date = moment(this.checkUserExpires.end_date);
        var end_date1 = end_date.format("YYYY-MM-DD");
        var two_days_b4 = end_date.subtract(2, "days").format("YYYY-MM-DD");
        var one_days_b4 = end_date.add(1, "days").format("YYYY-MM-DD");
        var checkBetween = moment(current_date).isBetween(two_days_b4, end_date1);
        var checkIsSame = moment(end_date1).isSame(current_date);
        var checkIsBefore = moment(end_date1).isBefore(current_date);
        if(checkBetween || checkIsSame){
          this.resourceData = {
            isExpired: false,
            isExpiredTwodays: true,
          }
        }else{
          if(checkIsBefore){
            this.resourceData = {
              isExpired: true,
              isExpiredTwodays: false,
            }
          }
        }
      });
  }
}

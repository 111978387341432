<div class="container-fluid" style="margin-top: 10px;">
  <div class="col text-left">
    <h5 class="mb-4 text-muted"><b>{{'Driving Zone' | translate}} </b></h5>
  </div>
  <div class="col text-right">
    <button mat-raised-button color="primary" routerLink="/add-driving-zone" matTooltip="click here"><b>{{'Add Driving Zone' | translate}}</b></button>
  </div>
</div>
<div class="container-fluid">
  <div class="card-body">
    <div class="row px-3 py-0">
      <div class="col-12 bg-light py-3">
        <div class="row justify-content-between">
          <div class="col-md">
            <div class="row">
            </div>
          </div>
          <div class="col-auto">
            <div class="row">
              <div class="col-auto">
                <div class="input-group mb-3">
                  <input type="text" class="form-control" (keyup)="ApplyFilter($event)" placeholder="{{'Search' | translate}}">
                  <div class="input-group-append">
                    <span class="fa fa-search input-group-text pointer"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="card-body">
          <div class="table-responsive">
            <!-- <table class="table">
              <thead class="text-primary pointer">
                <th>
                  <a href="#" class="sort">
                    <i class="fas fa-sort-amount-down"></i>
                  </a>
                  Name
                </th>
                <th class="w-15">
                  <a href="#" class="sort">
                    <i class="fas fa-sort-amount-down"></i>
                  </a>
                  Email
                </th>
                <th class="w-15">
                  <a href="#" class="sort">
                    <i class="fas fa-sort-amount-down"></i>
                  </a>
                  Contact No
                </th>
                <th class="w-15">
                  <a href="#" class="sort">
                    <i class="fas fa-sort-amount-down"></i>
                  </a>
                  Address
                </th>
                <th class="w-5">
                  <span>Actions</span>
                </th>
              </thead>
              <tbody>
                <tr *ngFor="let sample of samples">
                  <td>{{sample.name}}</td>
                  <td>{{sample.email}}</td>
                  <td>{{sample.conatct_number}}</td>
                  <td>{{sample.address}}</td>
                  <td>
                    <a class="btn btn-sm icon-btn rounded-0 mr-2 sort" [matMenuTriggerFor]="menu" matTooltip="More">
                      <i class="fa fa-ellipsis-v"></i>
                    </a>
                    <mat-menu #menu="matMenu">
                      <button mat-menu-item (click)="Edit(sample)">Edit</button>
                      <button mat-menu-item (click)="View(sample)">View</button>
                      <button mat-menu-item (click)="Delete(sample)">Delete</button>
                    </mat-menu>
                  </td>
                </tr>
              </tbody>
            </table> -->
            <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8" style="width: 100%;border: 1px solid #ccc;">

              <!--- Note that these columns can be defined in any order.
                    The actual rendered columns are set as a property on the row definition" -->
            
              <!-- Position Column -->
              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="tablehead" style="background-color: #1e9e95;color: #fff;font-size: 15px;"> {{'Name' | translate}} </th>
                <td mat-cell *matCellDef="let element"> {{element.name}} </td>
              </ng-container>
            
              <ng-container matColumnDef="Actions">
                <th mat-header-cell *matHeaderCellDef class="tablehead" style="background-color: #1e9e95;color: #fff;font-size: 15px;text-align: center;"> {{'Actions' | translate}} </th>
                <td mat-cell *matCellDef="let element" style="width: 130px;text-align: center;">
                     <!-- <mat-icon title="Reset Password" class="action-icons" style="cursor: pointer;color: green;" (click)="password_change('Password Change')">lock</mat-icon> -->
                     <mat-icon title="{{'Edit Zone' | translate}}" class="action-icons" style="cursor: pointer;color: green;" (click)="EditZone(element.id)">edit</mat-icon>
                      <mat-icon title="{{'Delete Customer'| translate}}" class="action-icons" style="cursor: pointer;color: green;" (click)="DeleteZone(element.id)">delete</mat-icon> 
                     <!-- <mat-icon title="{{'View Zone' | translate}}" class="action-icons" style="cursor: pointer;color: green;" (click)="ViewCustomer(element.id)">visibility</mat-icon> -->
                </td>
              </ng-container>
            
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <div style="padding-top: 10px;" *ngIf="dataSource?.filteredData.length === 0"> 
              <p class="text-center">{{'No Records Found!' | translate}}</p><hr>
            </div>
            <mat-paginator #paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
          </div>
        </div>
      </div>
    </div>
    <!-- <mat-paginator [length]="100"
      [pageSize]="10"
      [pageSizeOptions]="[5, 10, 25, 100]">
    </mat-paginator> -->
  </div>
</div>

<div class="modal full-size" tabindex="-1" role="dialog"
[ngClass]="{ fade: !isDeleteZone, 'd-flex': isDeleteZone }">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title w-100">Confirmation</h5>
        <a class="btn btn-sm btn-white dialog-close" (click)="onClose()"><span aria-hidden="true">&times;</span></a>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12" style="padding-bottom: 1px;">
            <b>Are you sure to delete this Zone?</b>
          </div>
        </div>
        <div class="col-md-12 text-right" style="padding-bottom: 1px; padding-top: 5px;">
          <button type="button" class="btn btn-sm btn-info rounded-0 mb-2 mr-2" (click)="onClose()">No</button>
          <button type="button" class="btn btn-sm btn-outline-info rounded-0 mb-2 mr-2" (click)="onDelete()">Yes</button>
        </div>
      </div>
    </div>
  </div>
</div>
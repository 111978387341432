import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders, HttpErrorResponse } from "@angular/common/http";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { FormGroup } from '@angular/forms';
import { ApiService } from './../../services/ApiService';
import { AppService } from './../../services/AppService';
import { AuthService } from './../../services/AuthService';

@Component({
  selector: 'app-payment-status',
  templateUrl: './payment-status.component.html',
  styleUrls: ['./payment-status.component.css'],
})
export class PaymentStatusComponent implements OnInit {

  samples = [
    {
      id: 1,
      vehicle_name: "MaRDUTHI",
      vehicle_model: "2010",
    },
    {
      id: 2,
      vehicle_name: "MaRasI",
      vehicle_model: "1212",
    },
    {
      id: 3,
      vehicle_name: "MARF",
      vehicle_model: "1212",
    },
    {
      id: 4,
      vehicle_name: "AASAS",
      vehicle_model: "3232",
    },
    {
      id: 5,
      vehicle_name: "DASSA",
      vehicle_model: "4232",
    },
    {
      id: 6,
      vehicle_name: "ASD",
      vehicle_model: "3233",
    },
    {
      id: 7,
      vehicle_name: "ASC",
      vehicle_model: "4322",
    }
  ];
  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private loader: NgxSpinnerService,
    private toastr: ToastrService,
    private authService: AuthService,
    public appService: AppService,
    public apiService: ApiService
  ) {}

  ngOnInit() {
  }
  Edit(obj:any){
    console.log(obj);
  }
  Delete(obj:any){
    console.log(obj);
  }
  View(obj:any){
    console.log(obj);
  }
}

import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders, HttpErrorResponse } from "@angular/common/http";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { FormGroup } from '@angular/forms';
import { ApiService } from './../../services/ApiService';
import { AppService } from './../../services/AppService';
import { AuthService } from './../../services/AuthService';
import { Config } from '../../config/config';


@Component({
  selector: 'app-manager-details',
  templateUrl: './manager-details.component.html',
  styleUrls: ['./manager-details.component.css']
})
export class ManagerDetailsComponent implements OnInit {

  Supervisordetails: any;
  User_id: any;
  FirstName: any;
  LastName: any;
  Phone: any;
  Status: any;
  GPSTracking: any;
  Address: any;
  VehicleModel: any;
  LicensePlate: any;
  ProfileImage: any = [];
  Active: number;
  role_id: number;
  Email: any;
  WhatsApp: any;
  userData: any;
  sup_id: string;
  VehicleType: any;

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private loader: NgxSpinnerService,
    private toastr: ToastrService,
    private authService: AuthService,
    public appService: AppService,
    public apiService: ApiService
  ) { }

  ngOnInit(): void {
    this.userData = JSON.parse(localStorage.getItem("user_data"));
    this.sup_id =  localStorage.getItem("sup_id");
    this.apiService
      .ManagerDetails(this.sup_id)
      .subscribe((response: any) => {
      this.Supervisordetails = response.data; 
      this.User_id  = this.Supervisordetails.user_id ? this.Supervisordetails.user_id : " ";
      this.FirstName = this.Supervisordetails.first_name ? this.Supervisordetails.first_name : " ";
      this.LastName = this.Supervisordetails.last_name ? this.Supervisordetails.last_name : " ";
      this.Phone = this.Supervisordetails.phone ? this.Supervisordetails.phone : " ";
      this.WhatsApp = this.Supervisordetails.whatsapp ? this.Supervisordetails.whatsapp : " ";
      this.Email = this.Supervisordetails.email ? this.Supervisordetails.email : " ";
      if(this.Supervisordetails.is_delete == 1){
        this.Status = "Delete";
      } else if(this.Supervisordetails.is_delete == 0){
        this.Status = "Active";
      }
      if(this.Supervisordetails.zipcode == "1"){
        this.GPSTracking = "ON";
      } else if(this.Supervisordetails.zipcode == "0"){
        this.GPSTracking = "OFF";
      }
      this.Active = this.Supervisordetails.is_active;
      this.Address = this.Supervisordetails.street ? this.Supervisordetails.street : " ";
      this.VehicleModel = this.Supervisordetails.vehicle_model ? this.Supervisordetails.vehicle_model : " ";
      this.VehicleType = this.Supervisordetails.vehicle_type ? this.Supervisordetails.vehicle_type : " ";
      this.LicensePlate = this.Supervisordetails.license_plate ? this.Supervisordetails.license_plate : " ";
      if(this.Supervisordetails.profile_image.length > 2){
        var result = this.Supervisordetails.profile_image.charAt(0);
        if(result == "["){
          var getProfile = JSON.parse(this.Supervisordetails.profile_image);
          this.Supervisordetails.profile_image = getProfile[0];
          this.ProfileImage = this.Supervisordetails.profile_image;
        }
      }else{
        this.ProfileImage = this.Supervisordetails.profile_image;
      }
   })
  }

  ActiveAgent(activeval: any){
    this.loader.show();
    if(activeval == 0){
      var valactive = 1;
      var valdelete = 0;
    }else{
      var valactive = 0;
      var valdelete = 1;
    }
    var data = {
      data : {
        "is_active" : valactive,
        "is_delete" : valdelete,
      }
    }
    this.apiService
      .StatusUpdateSupervisor(this.sup_id, data)
      .subscribe((response: any) => {
        if(response.status == "ok"){
          this.Active = valactive;
          this.toastr.success(response.data);
          this.loader.hide();
        }else{
          this.toastr.warning(response.data);
          this.loader.hide();
        }
      });
  }

}

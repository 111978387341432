import { Component, OnInit , ChangeDetectionStrategy, Output, Input, EventEmitter} from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from './../../services/ApiService';
import { AppService } from './../../services/AppService';
import { AuthService } from './../../services/AuthService';

@Component({
  selector: 'app-notify-me',
  templateUrl: './notify-me.component.html',
  styleUrls: ['./notify-me.component.css'],
})
export class NotifyMeAlertComponent implements OnInit {
  @Input() resource: any;
  @Input() isWhy: any;
  isExpired: any = false;
  isExpiredTwodays: any = false;

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private loader: NgxSpinnerService,
    private toastr: ToastrService,
    private authService: AuthService,
    public appService: AppService,
    public apiService: ApiService
  ) {}

  ngOnInit() {
    if(this.isWhy == 'Expired Account'){
      this.isExpired = this.resource.isExpired;
      this.isExpiredTwodays = this.resource.isExpiredTwodays;
    }
  }

  send_mail() {
    this.loader.show();
    this.apiService
      .sentMail()
      .subscribe((response: any) => {
        console.log(response);
        if(response.status == 'ok'){
          this.toastr.success("Thanks for contacting us,we will revert back shortly");
        } else {
          this.toastr.warning(response.data);
        }
        this.loader.hide();
      });
  }
}

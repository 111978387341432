<ng-container *ngIf="fromWhere == 'dashboard'">
  <ng-container fxFlex="1 1 100%" #map *ngIf="dashboardMap">
    <agm-map [style.height.px]="map.offsetHeight" id="map" [latitude]="companyLatitide" (mapReady)="onMapReady($event)" [disableDefaultUI]="false" [mapTypeId]="satstyles" [streetViewControl]="pegmanview" [longitude]="companyLongitude" [zoom]="zoomControl">
      <agm-marker *ngFor="let list of dashboardMap; let i=index"
        [latitude]="list.lat"
        [longitude]="list.lng"
        [iconUrl]="list.markerOptions.origin.icon">
        <agm-info-window>
          <div [innerHtml]="list.markerOptions.origin.infoWindow"></div>
        </agm-info-window>
      </agm-marker>
    </agm-map>
    <div tabindex="1">
      <mat-icon (click)="onshowmap_settings()" class="setting-icon">settings</mat-icon>
      <div class="map-setting" *ngIf="showmap_settings">
        <div style="border-bottom: 1px solid #eee;padding:10px;">
          <span style="color:#3b9e95">{{'Map Settings' | translate}}</span><span style="color: #ccc;float: right;cursor: pointer;" (click)="onshowmap_settings()">X</span>
        </div>
        <div style="padding:10px;">
          {{'Satellite View' | translate}} <mat-slide-toggle color="primary" (change)="changemapview($event)" [(ngModel)]="mapview" style="float: right;"></mat-slide-toggle><br/><br/>
          {{'Street View' | translate}} <mat-slide-toggle color="primary" (change)="changepegmanview($event)" [(ngModel)]="pegmanview" style="float: right;"></mat-slide-toggle><br/><br/>
          {{'Traffic Layer View' | translate}} <mat-slide-toggle color="primary" (change)="changetrafficview($event)" [(ngModel)]="trafficview" style="float: right;"></mat-slide-toggle><br/><br/>
          {{'Custom Zoom Size' | translate}}: {{zoomControl}} <br/>
          <input style="width:100%;" type="range" id="cowbell" name="maprange" (change)="changemaprange($event)" [(ngModel)]="zoomControl" 
          min="4" max="20" value="12" step="2">
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>

<ng-container *ngIf="fromWhere == 'orderDetails'">
  <ng-container *ngIf="driverAllocated == 'Unallocated'">
    <ng-container fxFlex="1 1 100%" #map *ngIf="resource.otherDetails.delivery_logic == 3">
      <agm-map [style.height.px]="map.offsetHeight" [latitude]="companyLatitide" (mapReady)="onMapReady($event)" [disableDefaultUI]="false" [mapTypeId]="satstyles" [streetViewControl]="pegmanview" [longitude]="companyLongitude" [zoom]="zoomControl">
        <agm-direction
          [origin]="logicThirdPickup" 
          [destination]="logicThirdDelivery" 
          [waypoints]="waypoints" 
          [renderOptions]="renderOptions" 
          [markerOptions]="markerOptions"
          (onChange)="change($event)">
        </agm-direction>
      </agm-map>
      <div tabindex="1">
        <mat-icon (click)="onshowmap_settings()" class="setting-icon">settings</mat-icon>
        <div class="map-setting" *ngIf="showmap_settings">
          <div style="border-bottom: 1px solid #eee;padding:10px;">
            <span style="color:#3b9e95">{{'Map Settings' | translate}}</span><span style="color: #ccc;float: right;cursor: pointer;" (click)="onshowmap_settings()">X</span>
          </div>
          <div style="padding:10px;">
            {{'Satilite' | translate}} <mat-slide-toggle color="primary" (change)="changemapview($event)" [(ngModel)]="mapview" style="float: right;"></mat-slide-toggle><br/><br/>
            {{'Street View' | translate}} <mat-slide-toggle color="primary" (change)="changepegmanview($event)" [(ngModel)]="pegmanview" style="float: right;"></mat-slide-toggle><br/><br/>
            {{'Traffic Layer View' | translate}} <mat-slide-toggle color="primary" (change)="changetrafficview($event)" [(ngModel)]="trafficview" style="float: right;"></mat-slide-toggle><br/><br/>
            {{'Custom Zoom Size' | translate}}: {{zoomControl}} <br/>
            <input style="width:100%;" type="range" id="cowbell" name="maprange" (change)="changemaprange($event)" [(ngModel)]="zoomControl" 
            min="4" max="20" value="12" step="2">
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container fxFlex="1 1 100%" #map *ngIf="resource.otherDetails.delivery_logic == 2">
      <agm-map [style.height.px]="map.offsetHeight" [latitude]="companyLatitide" (mapReady)="onMapReady($event)" [disableDefaultUI]="false" [mapTypeId]="satstyles" [streetViewControl]="pegmanview" [longitude]="companyLongitude" [zoom]="zoomControl">
        <ng-container *ngFor="let delivery of logicSecondDelivery">
          <ng-conatiner *ngFor="let pickup of logicSecondPickup">
            <agm-direction 
              [origin]="pickup" 
              [destination]="delivery" 
              [waypoints]="waypoints" 
              [renderOptions]="renderOptions" 
              [markerOptions]="markerOptions"
              (onChange)="change($event)">
            </agm-direction>
          </ng-conatiner>
        </ng-container>
      </agm-map>
      <mat-icon (click)="onshowmap_settings()" class="setting-icon">settings</mat-icon>
      <div class="map-setting" *ngIf="showmap_settings">
        <div style="border-bottom: 1px solid #eee;padding:10px;">
          <span style="color:#3b9e95">{{'Map Settings' | translate}}</span><span style="color: #ccc;float: right;cursor: pointer;" (click)="onshowmap_settings()">X</span>
        </div>
        <div style="padding:10px;">
          {{'Satilite' | translate}} <mat-slide-toggle color="primary" (change)="changemapview($event)" [(ngModel)]="mapview" style="float: right;"></mat-slide-toggle><br/><br/>
          {{'Street View' | translate}} <mat-slide-toggle color="primary" (change)="changepegmanview($event)" [(ngModel)]="pegmanview" style="float: right;"></mat-slide-toggle><br/><br/>
          {{"Traffic Layer View" | translate}} <mat-slide-toggle color="primary" (change)="changetrafficview($event)" [(ngModel)]="trafficview" style="float: right;"></mat-slide-toggle><br/><br/>
          {{'Custom Zoom Size' | translate}}: {{zoomControl}} <br/>
          <input style="width:100%;" type="range" id="cowbell" name="maprange" (change)="changemaprange($event)" [(ngModel)]="zoomControl" 
          min="4" max="20" value="12" step="2">
        </div>
      </div>
    </ng-container>
    <ng-container fxFlex="1 1 100%" #map *ngIf="resource.otherDetails.delivery_logic == 1">
      <agm-map [style.height.px]="map.offsetHeight" [latitude]="companyLatitide" (mapReady)="onMapReady($event)" [disableDefaultUI]="false" [mapTypeId]="satstyles" [streetViewControl]="pegmanview" [longitude]="companyLongitude" [zoom]="zoomControl">
        <ng-container *ngFor="let pickup of logicFirstPickup">
          <ng-conatiner *ngFor="let delivery of logicFirstDelivery">
            <agm-direction 
              [origin]="pickup" 
              [destination]="delivery" 
              [waypoints]="waypoints" 
              [renderOptions]="renderOptions" 
              [markerOptions]="markerOptions"
              (onChange)="change($event)">
            </agm-direction>
          </ng-conatiner>
        </ng-container>
      </agm-map>
      <mat-icon (click)="onshowmap_settings()" class="setting-icon">settings</mat-icon>
      <div class="map-setting" *ngIf="showmap_settings">
        <div style="border-bottom: 1px solid #eee;padding:10px;">
          <span style="color:#3b9e95">{{'Map Settings' | translate}}</span><span style="color: #ccc;float: right;cursor: pointer;" (click)="onshowmap_settings()">X</span>
        </div>
        <div style="padding:10px;">
          {{'Satilite' | translate}} <mat-slide-toggle color="primary" (change)="changemapview($event)" [(ngModel)]="mapview" style="float: right;"></mat-slide-toggle><br/><br/>
          {{'Street View' | translate}} <mat-slide-toggle color="primary" (change)="changepegmanview($event)" [(ngModel)]="pegmanview" style="float: right;"></mat-slide-toggle><br/><br/>
          {{'Traffic Layer View' | translate}} <mat-slide-toggle color="primary" (change)="changetrafficview($event)" [(ngModel)]="trafficview" style="float: right;"></mat-slide-toggle><br/><br/>
          {{'Custom Zoom Size' | translate}}: {{zoomControl}} <br/>
          <input style="width:100%;" type="range" id="cowbell" name="maprange" (change)="changemaprange($event)" [(ngModel)]="zoomControl" 
          min="4" max="20" value="12" step="2">
        </div>
      </div>
    </ng-container>
  </ng-container>
  <ng-container fxFlex="1 1 100%" #map *ngIf="driverAllocated == 'Allocated'">
    <ng-container *ngIf="resource.otherDetails.delivery_logic == 3">
      <agm-map [style.height.px]="map.offsetHeight" [latitude]="driverOrigin.lat" (mapReady)="onMapReady($event)" [disableDefaultUI]="false" [mapTypeId]="satstyles" [streetViewControl]="pegmanview" [longitude]="driverOrigin.lng" [disableDefaultUI]="false" 
      [zoomControl]="false" [fitBounds]="true" [zoom]="4">
        <agm-direction 
          [origin]="logicThirdPickup" 
          [destination]="logicThirdDelivery" 
          [waypoints]="waypoints" 
          [renderOptions]="renderOptions" 
          [markerOptions]="markerOptions"
          (onChange)="change($event)">
        </agm-direction>
        <agm-direction *ngIf="resource.otherDetails.status == 'Allocated' 
          || resource.otherDetails.status == 'Accepted'"
          [origin]="driverOrigin" 
          [destination]="logicThirdPickup" 
          [waypoints]="waypoints" 
          [renderOptions]="renderOptions" 
          [markerOptions]="driverMarkerOptions"
          (onChange)="change($event)">
        </agm-direction>
        <agm-marker *ngIf="resource.otherDetails.status != 'Allocated' 
          && resource.otherDetails.status != 'Accepted'"
          [latitude]="driverOrigin.lat"
          [longitude]="driverOrigin.lng"
          [iconUrl]="StartedRideIcon">
        </agm-marker>
      </agm-map>
      <div class="est-time">
        <span class="badge badge-light">ETA {{estTime}} Min</span>
      </div>
      <mat-icon (click)="onshowmap_settings()" class="setting-icon">settings</mat-icon>
      <div class="map-setting" *ngIf="showmap_settings">
        <div style="border-bottom: 1px solid #eee;padding:10px;">
          <span style="color:#3b9e95">{{'Map Settings' | translate}}</span><span style="color: #ccc;float: right;cursor: pointer;" (click)="onshowmap_settings()">X</span>
        </div>
        <div style="padding:10px;">
          {{'Satellite View' | translate}} <mat-slide-toggle color="primary" (change)="changemapview($event)" [(ngModel)]="mapview" style="float: right;"></mat-slide-toggle><br/><br/>
          {{'Street View' | translate}} <mat-slide-toggle color="primary" (change)="changepegmanview($event)" [(ngModel)]="pegmanview" style="float: right;"></mat-slide-toggle><br/><br/>
          {{'Traffic Layer View' | translate}} <mat-slide-toggle color="primary" (change)="changetrafficview($event)" [(ngModel)]="trafficview" style="float: right;"></mat-slide-toggle><br/><br/>
          {{'Custom Zoom Size' | translate}}: {{zoomControl}} <br/>
          <input style="width:100%;" type="range" id="cowbell" name="maprange" (change)="changemaprange($event)" [(ngModel)]="zoomControl" 
          min="4" max="20" value="12" step="2">
        </div>
      </div>
    </ng-container>
    <ng-container fxFlex="1 1 100%" #map *ngIf="resource.otherDetails.delivery_logic == 2">
      <agm-map [style.height.px]="map.offsetHeight" [latitude]="driverOrigin.lat" (mapReady)="onMapReady($event)" [disableDefaultUI]="false" [mapTypeId]="satstyles" [streetViewControl]="pegmanview" [longitude]="driverOrigin.lng" [disableDefaultUI]="false" 
      [zoomControl]="false" [fitBounds]="true" [zoom]="4">
        <ng-container *ngFor="let delivery of logicSecondDelivery">
          <ng-conatiner *ngFor="let pickup of logicSecondPickup">
            <agm-direction 
              [origin]="pickup" 
              [destination]="delivery" 
              [waypoints]="waypoints" 
              [renderOptions]="renderOptions" 
              [markerOptions]="markerOptions"
              (onChange)="change($event)">
            </agm-direction>
          </ng-conatiner>
        </ng-container>
        <agm-direction *ngIf="resource.otherDetails.status == 'Allocated' 
        || resource.otherDetails.status == 'Accepted'"
          [origin]="driverOrigin" 
          [destination]="logicSecondPickup[0]" 
          [waypoints]="waypoints" 
          [renderOptions]="renderOptions" 
          [markerOptions]="driverMarkerOptions"
          (onChange)="change($event)">
        </agm-direction>
        <agm-marker *ngIf="resource.otherDetails.status != 'Allocated' 
        && resource.otherDetails.status != 'Accepted'"
          [latitude]="driverOrigin.lat"
          [longitude]="driverOrigin.lng"
          [iconUrl]="StartedRideIcon">
        </agm-marker>
      </agm-map>
      <div class="est-time">
        <span class="badge badge-light">ETA {{estTime}} Min</span>
      </div>
      <mat-icon (click)="onshowmap_settings()" class="setting-icon">settings</mat-icon>
      <div class="map-setting" *ngIf="showmap_settings">
        <div style="border-bottom: 1px solid #eee;padding:10px;">
          <span style="color:#3b9e95">{{'Map Settings' | translate}}</span><span style="color: #ccc;float: right;cursor: pointer;" (click)="onshowmap_settings()">X</span>
        </div>
        <div style="padding:10px;">
          {{'Satellite View' | translate}} <mat-slide-toggle color="primary" (change)="changemapview($event)" [(ngModel)]="mapview" style="float: right;"></mat-slide-toggle><br/><br/>
          {{'Street View' | translate}} <mat-slide-toggle color="primary" (change)="changepegmanview($event)" [(ngModel)]="pegmanview" style="float: right;"></mat-slide-toggle><br/><br/>
          {{'Traffic Layer View' | translate}} <mat-slide-toggle color="primary" (change)="changetrafficview($event)" [(ngModel)]="trafficview" style="float: right;"></mat-slide-toggle><br/><br/>
          {{'Custom Zoom Size' | translate}}: {{zoomControl}} <br/>
          <input style="width:100%;" type="range" id="cowbell" name="maprange" (change)="changemaprange($event)" [(ngModel)]="zoomControl" 
          min="4" max="20" value="12" step="2">
        </div>
      </div>
    </ng-container>
    <ng-container fxFlex="1 1 100%" #map *ngIf="resource.otherDetails.delivery_logic == 1">
      <agm-map [style.height.px]="map.offsetHeight" [latitude]="driverOrigin.lat" (mapReady)="onMapReady($event)" [disableDefaultUI]="false" [mapTypeId]="satstyles" [streetViewControl]="pegmanview" [longitude]="driverOrigin.lng" [disableDefaultUI]="false" 
      [zoomControl]="false" [fitBounds]="true" [zoom]="4">
        <ng-container *ngFor="let pickup of logicFirstPickup">
          <ng-conatiner *ngFor="let delivery of logicFirstDelivery">
            <agm-direction 
              [origin]="pickup" 
              [destination]="delivery" 
              [waypoints]="waypoints" 
              [renderOptions]="renderOptions" 
              [markerOptions]="markerOptions"
              (onChange)="change($event)">
            </agm-direction>
            <agm-direction *ngIf="resource.otherDetails.status == 'Allocated' 
            || resource.otherDetails.status == 'Accepted'"
              [origin]="driverOrigin" 
              [destination]="logicFirstPickup[0]" 
              [waypoints]="waypoints" 
              [renderOptions]="renderOptions" 
              [markerOptions]="driverMarkerOptions"
              (onChange)="change($event)">
            </agm-direction>
          </ng-conatiner>
        </ng-container>
        <agm-marker *ngIf="resource.otherDetails.status != 'Allocated' 
        && resource.otherDetails.status != 'Accepted'"
          [latitude]="driverOrigin.lat"
          [longitude]="driverOrigin.lng"
          [iconUrl]="StartedRideIcon">
        </agm-marker>
      </agm-map>
      <div class="est-time">
        <span class="badge badge-light">ETA {{estTime}} Min</span>
      </div>
      <mat-icon (click)="onshowmap_settings()" class="setting-icon">settings</mat-icon>
      <div class="map-setting" *ngIf="showmap_settings">
        <div style="border-bottom: 1px solid #eee;padding:10px;">
          <span style="color:#3b9e95">{{'Map Settings' | translate}}</span><span style="color: #ccc;float: right;cursor: pointer;" (click)="onshowmap_settings()">X</span>
        </div>
        <div style="padding:10px;">
          {{'Satellite View' | translate}} <mat-slide-toggle color="primary" (change)="changemapview($event)" [(ngModel)]="mapview" style="float: right;"></mat-slide-toggle><br/><br/>
          {{'Street View' | translate}} <mat-slide-toggle color="primary" (change)="changepegmanview($event)" [(ngModel)]="pegmanview" style="float: right;"></mat-slide-toggle><br/><br/>
          {{'Traffic Layer View' | translate}} <mat-slide-toggle color="primary" (change)="changetrafficview($event)" [(ngModel)]="trafficview" style="float: right;"></mat-slide-toggle><br/><br/>
          {{'Custom Zoom Size' | translate}}: {{zoomControl}} <br/>
          <input style="width:100%;" type="range" id="cowbell" name="maprange" (change)="changemaprange($event)" [(ngModel)]="zoomControl" 
          min="4" max="20" value="12" step="2">
        </div>
      </div>
    </ng-container>
  </ng-container>
  <ng-container fxFlex="1 1 100%" #map *ngIf="driverAllocated == 'Delivered'">
    <agm-map [style.height.px]="map.offsetHeight" [latitude]="deliveredPickupLat" (mapReady)="onMapReady($event)" [disableDefaultUI]="false" [mapTypeId]="satstyles" [streetViewControl]="pegmanview" [longitude]="deliveredPickupLong" [zoom]="13">
      <ng-container *ngIf="resource.otherDetails.delivery_logic == 3">
        <agm-marker
          [latitude]="logicThirdPickup.lat"
          [longitude]="logicThirdPickup.lng"
          [iconUrl]="markerOptions.origin.icon">
        </agm-marker>
        <agm-marker
          [latitude]="logicThirdDelivery.lat"
          [longitude]="logicThirdDelivery.lng"
          [iconUrl]="AllocatedIcon">
        </agm-marker>
      </ng-container>
      <ng-container *ngIf="resource.otherDetails.delivery_logic == 2">
        <ng-conatiner *ngFor="let pickup of logicSecondPickup">
          <agm-marker
            [latitude]="pickup.lat"
            [longitude]="pickup.lng"
            [iconUrl]="markerOptions.origin.icon">
          </agm-marker>
        </ng-conatiner>
        <ng-container *ngFor="let delivery of logicSecondDelivery">
          <agm-marker
            [latitude]="delivery.lat"
            [longitude]="delivery.lng"
            [iconUrl]="AllocatedIcon">
          </agm-marker>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="resource.otherDetails.delivery_logic == 1">
        <ng-conatiner *ngFor="let pickup of logicFirstPickup">
          <agm-marker
            [latitude]="pickup.lat"
            [longitude]="pickup.lng" 
            [iconUrl]="markerOptions.origin.icon">
          </agm-marker>
        </ng-conatiner>
        <ng-container *ngFor="let delivery of logicFirstDelivery">
          <agm-marker
            [latitude]="delivery.lat"
            [longitude]="delivery.lng"
            [iconUrl]="AllocatedIcon">
          </agm-marker>
        </ng-container>
      </ng-container>
      <agm-polyline [visible]="true" [strokeWeight]="5" [strokeColor]="'#1e9e95'">
        <ng-container *ngFor="let point of gpsDataLines; let i=index">
          <agm-polyline-point
            [latitude]="point.lat"
            [longitude]="point.lng">
          </agm-polyline-point>
        </ng-container>
      </agm-polyline>
    </agm-map>
    <mat-icon (click)="onshowmap_settings()" class="setting-icon">settings</mat-icon>
    <div class="map-setting" *ngIf="showmap_settings">
      <div style="border-bottom: 1px solid #eee;padding:10px;">
        <span style="color:#3b9e95">{{'Map Settings' | translate}}</span><span style="color: #ccc;float: right;cursor: pointer;" (click)="onshowmap_settings()">X</span>
      </div>
      <div style="padding:10px;">
        {{'Satellite View' | translate}} <mat-slide-toggle color="primary" (change)="changemapview($event)" [(ngModel)]="mapview" style="float: right;"></mat-slide-toggle><br/><br/>
        {{'Street View' | translate}} <mat-slide-toggle color="primary" (change)="changepegmanview($event)" [(ngModel)]="pegmanview" style="float: right;"></mat-slide-toggle><br/><br/>
        {{'Traffic Layer View' | translate}} <mat-slide-toggle color="primary" (change)="changetrafficview($event)" [(ngModel)]="trafficview" style="float: right;"></mat-slide-toggle><br/><br/>
        {{'Custom Zoom Size' | translate}}: {{zoomControl}} <br/>
        <input style="width:100%;" type="range" id="cowbell" name="maprange" (change)="changemaprange($event)" [(ngModel)]="zoomControl" 
         min="4" max="20" value="12" step="2">
      </div>
    </div>
  </ng-container>  
</ng-container>

<ng-container fxFlex="1 1 100%" #map *ngIf="fromWhere == 'driverDetails'">
  <agm-map [style.height.px]="map.offsetHeight" [latitude]="companyLatitide" (mapReady)="onMapReady($event)" [disableDefaultUI]="false" [mapTypeId]="satstyles" [streetViewControl]="pegmanview" [longitude]="companyLongitude" [zoom]="zoomControl">
    <agm-marker *ngFor="let list of dashboardMap; let i=index"
      [latitude]="list[0].lat"
      [longitude]="list[0].lng"
      [iconUrl]="list[1].icon">
    </agm-marker>
  </agm-map>
  <mat-icon (click)="onshowmap_settings()" class="setting-icon">settings</mat-icon>
  <div class="map-setting" *ngIf="showmap_settings">
    <div style="border-bottom: 1px solid #eee;padding:10px;">
      <span style="color:#3b9e95">{{'Map Settings' | translate}}</span><span style="color: #ccc;float: right;cursor: pointer;" (click)="onshowmap_settings()">X</span>
    </div>
    <div style="padding:10px;">
      {{'Satellite View' | translate}} <mat-slide-toggle color="primary" (change)="changemapview($event)" [(ngModel)]="mapview" style="float: right;"></mat-slide-toggle><br/><br/>
      {{'Street View' | translate}} <mat-slide-toggle color="primary" (change)="changepegmanview($event)" [(ngModel)]="pegmanview" style="float: right;"></mat-slide-toggle><br/><br/>
      {{'Traffic Layer View' | translate}} <mat-slide-toggle color="primary" (change)="changetrafficview($event)" [(ngModel)]="trafficview" style="float: right;"></mat-slide-toggle><br/><br/>
      {{'Custom Zoom Size' | translate}}: {{zoomControl}} <br/>
      <input style="width:100%;" type="range" id="cowbell" name="maprange" (change)="changemaprange($event)" [(ngModel)]="zoomControl" 
        min="4" max="20" value="12" step="2">
    </div>
  </div>
</ng-container>
<ng-container fxFlex="1 1 100%" #map *ngIf="fromWhere == 'gpsHistory'">
  <ng-container *ngIf="dashboardMap">
    <agm-map [latitude]="companyLatitide" [longitude]="companyLongitude" (mapReady)="onMapReady($event)" [disableDefaultUI]="false" [mapTypeId]="satstyles" [streetViewControl]="pegmanview" [zoom]="zoomControl">
      <agm-marker *ngFor="let list of gpsHistoryStartMap; let i=index"
        [latitude]="list[0].lat"
        [longitude]="list[0].lng"
        [iconUrl]="list[1].icon">
      </agm-marker>
      <agm-marker *ngFor="let list of gpsHistoryStopMap; let i=index"
        [latitude]="list[0].lat"
        [longitude]="list[0].lng"
        [iconUrl]="list[1].icon">
      </agm-marker>
      <agm-polyline [visible]="true" [strokeWeight]="5" [strokeColor]="'#1e9e95'">
        <ng-container *ngFor="let list of gpsHistorySegementsMap; let i=index">
          <agm-polyline-point *ngFor="let point of list; let i=index"
            [latitude]="point.lat"
            [longitude]="point.lng">
          </agm-polyline-point>
        </ng-container>
      </agm-polyline>
    </agm-map>
    <mat-icon (click)="onshowmap_settings()" class="setting-icon">settings</mat-icon>
    <div class="map-setting" *ngIf="showmap_settings">
      <div style="border-bottom: 1px solid #eee;padding:10px;">
        <span style="color:#3b9e95">{{'Map Settings' | translate}}</span><span style="color: #ccc;float: right;cursor: pointer;" (click)="onshowmap_settings()">X</span>
      </div>
      <div style="padding:10px;">
        {{'Satellite View' | translate}} <mat-slide-toggle color="primary" (change)="changemapview($event)" [(ngModel)]="mapview" style="float: right;"></mat-slide-toggle><br/><br/>
        {{'Street View' | translate}} <mat-slide-toggle color="primary" (change)="changepegmanview($event)" [(ngModel)]="pegmanview" style="float: right;"></mat-slide-toggle><br/><br/>
        {{'Traffic Layer View' | translate}} <mat-slide-toggle color="primary" (change)="changetrafficview($event)" [(ngModel)]="trafficview" style="float: right;"></mat-slide-toggle><br/><br/>
        {{'Custom Zoom Size' | translate}}: {{zoomControl}} <br/>
        <input style="width:100%;" type="range" id="cowbell" name="maprange" (change)="changemaprange($event)" [(ngModel)]="zoomControl" 
         min="4" max="20" value="12" step="2">
      </div>
    </div>
  </ng-container>
</ng-container>
<ng-container fxFlex="1 1 100%" #map *ngIf="fromWhere == 'drivingZone'">
  <ng-container *ngIf="dashboardMap">
    <agm-map [zoom]="zoomControl" [latitude]="companyLatitide" [longitude]="companyLongitude" (mapReady)="onMapReady($event)"></agm-map>
    <div class="map-setting" *ngIf="showmap_settings">
      <div style="border-bottom: 1px solid #eee;padding:10px;">
        <span style="color:#3b9e95">{{'Map Settings' | translate}}</span><span style="color: #ccc;float: right;cursor: pointer;" (click)="onshowmap_settings()">X</span>
      </div>
      <div style="padding:10px;">
        {{'Satellite View' | translate}} <mat-slide-toggle color="primary" (change)="changemapview($event)" [(ngModel)]="mapview" style="float: right;"></mat-slide-toggle><br/><br/>
        {{'Street View' | translate}} <mat-slide-toggle color="primary" (change)="changepegmanview($event)" [(ngModel)]="pegmanview" style="float: right;"></mat-slide-toggle><br/><br/>
        {{'Traffic Layer View' | translate}} <mat-slide-toggle color="primary" (change)="changetrafficview($event)" [(ngModel)]="trafficview" style="float: right;"></mat-slide-toggle><br/><br/>
        {{'Custom Zoom Size' | translate}}: {{zoomControl}} <br/>
        <input style="width:100%;" type="range" id="cowbell" name="maprange" (change)="changemaprange($event)" [(ngModel)]="zoomControl" 
          min="4" max="20" value="12" step="2">
      </div>
    </div>
  </ng-container>
</ng-container>

<ng-container fxFlex="1 1 100%" #map *ngIf="fromWhere == 'editDrivingZone'">
  <ng-container *ngIf="dashboardMap">
    <agm-map [zoom]="zoomControl" [latitude]="poly_lat" [longitude]="poly_lng" (mapReady)="onMapReady($event)"></agm-map>
    <div class="map-setting" *ngIf="showmap_settings">
      <div style="border-bottom: 1px solid #eee;padding:10px;">
        <span style="color:#3b9e95">{{'Map Settings' | translate}}</span><span style="color: #ccc;float: right;cursor: pointer;" (click)="onshowmap_settings()">X</span>
      </div>
      <div style="padding:10px;">
        {{'Satellite View' | translate}} <mat-slide-toggle color="primary" (change)="changemapview($event)" [(ngModel)]="mapview" style="float: right;"></mat-slide-toggle><br/><br/>
        {{'Street View' | translate}} <mat-slide-toggle color="primary" (change)="changepegmanview($event)" [(ngModel)]="pegmanview" style="float: right;"></mat-slide-toggle><br/><br/>
        {{'Traffic Layer View' | translate}} <mat-slide-toggle color="primary" (change)="changetrafficview($event)" [(ngModel)]="trafficview" style="float: right;"></mat-slide-toggle><br/><br/>
        {{'Custom Zoom Size' | translate}}: {{zoomControl}} <br/>
        <input style="width:100%;" type="range" id="cowbell" name="maprange" (change)="changemaprange($event)" [(ngModel)]="zoomControl" 
          min="4" max="20" value="12" step="2">
      </div>
    </div>
  </ng-container>
</ng-container>

<!-- <ng-container *ngIf="fromWhere == 'newOrderDetails'">
  <agm-map [latitude]="companyLatitide" [longitude]="companyLongitude" [zoom]="zoomControl">
      <ng-conatiner *ngFor="let list of resource; let i=index;">
        <agm-direction 
          [origin]="list.origin[0]" 
          [destination]="list.destination[0]"
          [waypoints]="waypoints" 
          [renderOptions]="renderOptions" 
          [markerOptions]="markerOptions"
          (onChange)="change($event)">
        </agm-direction>
      </ng-conatiner>
      <agm-polyline [visible]="true" [strokeWeight]="5" [strokeColor]="'#1e9e95'" *ngIf="resource">
        <agm-polyline-point *ngFor="let point of resource; let i=index;"
          [latitude]="point.gpsdatas.lat"
          [longitude]="point.gpsdatas.lng">
        </agm-polyline-point>
      </agm-polyline>
    </agm-map>
</ng-container> -->

<!-- <agm-map [latitude]="lat" [longitude]="lng" [zoom]="zoom">
  <agm-direction 
    [origin]="origin1" 
    [destination]="destination1" 
    [waypoints]="waypoints" 
    [renderOptions]="renderOptions" 
    [markerOptions]="markerOptions"
    (onChange)="change($event)">
  </agm-direction>
</agm-map> -->
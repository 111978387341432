import { Injectable } from '@angular/core';
import { AuthService } from './AuthService';
import { Config_SOCKET } from '../config/config';
import { Observable, BehaviorSubject } from 'rxjs';
import { List } from 'immutable';
import Echo from "laravel-echo";

window.io = require('socket.io-client');
declare global {
  interface Window { io: any; }
  interface Window { Echo: any; }
}
window.io = window.io || require('socket.io-client');
window.Echo = window.Echo || {};
window.Echo = new Echo(Config_SOCKET.socket_config);

@Injectable({
  providedIn: 'root'
})
export class SocketService {
  userBroadcosts: any;
  userData: any;
  private _locationUpdateSocket: BehaviorSubject<List<any>> = new BehaviorSubject(List([]));
  public locationUpdateSocket: Observable<List<any>> = this._locationUpdateSocket.asObservable();

  private _locationUpdateOrderDetailSocket: BehaviorSubject<List<any>> = new BehaviorSubject(List([]));
  public locationUpdateOrderDetailSocket: Observable<List<any>> = this._locationUpdateOrderDetailSocket.asObservable();

  private _userAvailabilitySocket: BehaviorSubject<List<any>> = new BehaviorSubject(List([]));
  public userAvailabilitySocket: Observable<List<any>> = this._userAvailabilitySocket.asObservable();

  private _userOrderTrackSocket: BehaviorSubject<List<any>> = new BehaviorSubject(List([]));
  public userOrderTrackSocket: Observable<List<any>> = this._userOrderTrackSocket.asObservable();

  constructor(
    public auth: AuthService,
    ) { 
      // debugger
      this.userData = JSON.parse(localStorage.getItem("user_data"));
      if(this.auth.getToken()){
        console.log('socket conected');
        this.locatioUpdateSOCKET();
        this.userAvailabilitySOCKET();
        this.userOrderTrackSOCKET();
        this.locatioUpdateOrderDetailsSOCKET();
      }
    }
  
  // Sockets Channel and its Event Listening ...
  locatioUpdateSOCKET(){
    window.Echo
    .private(`testdelivery-user-${this.userData.profile.user_id}`)
    .listen('LocationUpdate', (data) => {
      this._locationUpdateSocket.next(data);
    });
  }
  locatioUpdateOrderDetailsSOCKET(){
    window.Echo
    .private(`testdelivery-user-${this.userData.profile.user_id}`)
    .listen('LocationUpdate', (data) => {
      this._locationUpdateOrderDetailSocket.next(data);
    });
  }
  userAvailabilitySOCKET(){
    window.Echo
    .private(`testdelivery-user-${this.userData.profile.user_id}`)
    .listen('TaskUpdateEvent', (data) => {
      this._userAvailabilitySocket.next(data);
    });
  }
  userOrderTrackSOCKET(){
    // window.Echo
    // .private(`testdelivery-user-${this.userData.profile.user_id}`)
    // .listen('LocationUpdate', (data) => {
    //   this._userOrderTrackSocket.next(data);
    // });
    window.Echo
    .private(`testdelivery-user-${this.userData.profile.user_id}`)
    .listen('TaskUpdateEvent', (data) => {
      this._userOrderTrackSocket.next(data);
    });
  }
}

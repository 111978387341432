import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { Config } from '../../config/config';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Location, Appearance, GermanAddress } from '@angular-material-extensions/google-maps-autocomplete';
import PlaceResult = google.maps.places.PlaceResult;
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiService } from 'src/app/services/ApiService';
import { SearchCountryField } from 'custom-template/ngx-intl-tel-input/src/lib/enums/search-country-field.enum';
import { TooltipLabel } from 'custom-template/ngx-intl-tel-input/src/lib/enums/tooltip-label.enum';
import { CountryISO } from 'custom-template/ngx-intl-tel-input/src/lib/enums/country-iso.enum';

interface Active {
  value: string;
  name: string;
}

interface Stat {
  value: string;
  name: string;
}

interface GPS {
  value: string;
  name: string;
}

@Component({
  selector: 'app-edit-supervisor',
  templateUrl: './edit-supervisor.component.html',
  styleUrls: ['./edit-supervisor.component.css']
})
export class EditSupervisorComponent implements OnInit {

  foods: Active[] = [
    {value: '0', name: 'Active'},
    {value: '1', name: 'Delete'},
  ];

  GPSTrack: GPS[] = [
    {value: '1', name: 'On'},
    {value: '0', name: 'Off'},
  ];

  Stats: Stat[] = [
    {value: '1', name: 'Active'},
    {value: '0', name: 'De Active'},
  ];

  Supervisordetails: any;
  public latitude: number;
  public longitude: number;
  FirstName: any;
  LastName: any;
  SupPhone: any;
  SupEmail: any;
  SupWhatsapp: any;
  SupStatus: any;
  SupActive: any;
  SupGpsTrack: any;
  SupAddress: any;
  role_id: number;
  User_id: any;
  VehicleModel: any;
  LicensePlate: any;
  Vehicles: string[] = ['Truck', 'Car', 'Bike', 'Bicycle'];
  VehicleType:any;
  files: any = [];
  filename: any = [];
  type: any;
  file: any;
  imgURL: any = [];
  imageo: any = [];
  img_exeed_count: any;
  exeed_count: any;
  current_exceed: number;
  Actives: Active[];
  Status: Stat[];
  GPSTracking: GPS[];
  ProfileImage: any = [];
  public imagePath;
  imgURLs: any;
  image: any;
  Profile_Image: any;
  SearchCountryField = SearchCountryField;
	TooltipLabel = TooltipLabel;
	CountryISO = CountryISO;
	preferredCountries: CountryISO[] = [
    CountryISO.India,
    CountryISO.Canada,
  ];
  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private loader: NgxSpinnerService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private apiService: ApiService,
  ) { }

  baseUrl = Config.BASE_URL;
  apiUrl = this.baseUrl + "api/";
  fileToUpload: File = null;
  Filename: string;
  EmployeeGroup: FormGroup;
  empForm: any;

  ngOnInit(): void {
    this.EmployeeGroup = this.formBuilder.group({
      FirstName: ['', Validators.required],
      LastName: [''],
      SupPhone: ['', Validators.required],
      SupEmail: [''],
      SupWhatsapp: ['', Validators.required],
      SupGpsTrack: ['', Validators.required],
      SupAddress: ['', Validators.required],
      VehicleType: [''],
      VehicleModel: [''],
      LicensePlate: [''],
      image: [''],
    });
    this.Actives = this.foods;
    this.Status = this.Stats;
    this.GPSTracking = this.GPSTrack;
    var role = localStorage.getItem("user_data");
    var roleid = JSON.parse(role);
    if(roleid.role == 'manager'){
      this.role_id = 2;
    } else if(roleid.role == 'driver'){
      this.role_id = 1
    }
    var sup_id = localStorage.getItem("sup_id");
    this.apiService
      .supervisorListById(sup_id)
      .subscribe((response: any) => {
        this.Supervisordetails = response.data;
        this.User_id  = this.Supervisordetails.user_id;
        this.FirstName = this.Supervisordetails.first_name;
        this.LastName = this.Supervisordetails.last_name;
        this.SupPhone = this.Supervisordetails.phone;
        this.SupEmail = this.Supervisordetails.email;
        this.SupWhatsapp = this.Supervisordetails.whatsapp;
        this.SupStatus = this.Supervisordetails.is_delete;
        this.SupActive = this.Supervisordetails.is_active;
        this.SupGpsTrack = this.Supervisordetails.zipcode;
        this.SupAddress = this.Supervisordetails.street;
        this.VehicleModel = this.Supervisordetails.vehicle_model;
        this.LicensePlate = this.Supervisordetails.license_plate;
        this.ProfileImage = JSON.parse(this.Supervisordetails.profile_image);
      })
  }

  onAutocompleteSelected(result: PlaceResult) {
    this.SupAddress = result.formatted_address
  }
  onLocationSelected(location: Location) {
    this.latitude = location.latitude;
    this.longitude = location.longitude;
  }

  changePreferredCountries() {
		this.preferredCountries = [
      CountryISO.India,
      CountryISO.Canada,
    ];
  }
//   fileupload(files: FileList) {
//     this.fileToUpload = files.item(0);
//     this.Filename = this.fileToUpload.name
// } 
fileupload(event:any){
  var reader = new FileReader();
    this.imagePath = event.target.files;
    reader.readAsDataURL(event.target.files[0]); 
    reader.onload = (_event) => { 
      this.imgURLs = reader.result; 
    }
    for(var i=0;i<event.target.files.length;i++){
      var name = event.target.files[i];
      // this.name1 = event.target.files[i].name;
      if(event.target.files[i].type.indexOf('image')> -1){
        this.type = 'image';
      } else if(event.target.files[i].type.indexOf('application')> -1){
        this.type = 'application';
      } else if(event.target.files[i].type.indexOf('text')> -1){
        this.type = 'text';
      }

      this.filename.push(name);
      const formValue = new FormData();
    for (var file of this.filename) {
      formValue.append('files[]', file) // file.name is optional
    }
    // formValue.append('image', file);
    this.apiService
      .supervisorFileUpload(formValue)
      .subscribe((response: any) => {
        this.ProfileImage = response.data
        // this.Userlist = response.data;
        // if(response.status == "ok"){
        // }
      
      })
    }

  }

removeLocImage(i,img,count, type){
  this.imgURL.splice(i,1)
  this.files.splice(i,1)
  this.filename.splice(img,1)
  if(type == 'image' && count > 0){
    this.img_exeed_count = this.img_exeed_count -1;
  }
  if(type == 'video' && count > 0){
    this.exeed_count = this.exeed_count -1;
  }

  const contentType = this.files.type;
  
}

  UpdateEmployee(){

    this.empForm = this.EmployeeGroup.value

    if(this.empForm.FirstName == undefined || this.empForm.FirstName == ''){
      this.snackBar.open("First Name is Required","Close",{
        duration: 4000,
        verticalPosition:'bottom'
      })
      return 0;
    }

    // if(this.empForm.LastName == undefined || this.empForm.LastName == ''){
    //   this.snackBar.open("Last Name is Required","Close",{
    //     duration: 4000,
    //     verticalPosition:'bottom'
    //   })
    //   return 0;
    // }

    if(this.empForm.SupPhone == undefined || this.empForm.SupPhone == ''){
      this.snackBar.open("Phone Number is Required","Close",{
        duration: 4000,
        verticalPosition:'bottom'
      })
      return 0;
    }

    if(this.empForm.SupWhatsapp == undefined || this.empForm.SupWhatsapp == ''){
      this.snackBar.open("WhatsApp Number is Required","Close",{
        duration: 4000,
        verticalPosition:'bottom'
      })
      return 0;
    }

    if(this.empForm.SupAddress == undefined || this.empForm.SupAddress == ''){
      this.snackBar.open("Address  is Required","Close",{
        duration: 4000,
        verticalPosition:'bottom'
      })
      return 0;
    }

    if(this.ProfileImage == undefined){
      var data = {
        data : {
          "user_id" : this.User_id,
          "role_id" : this.role_id,
          "first_name" : this.FirstName,
          "last_name" : this.LastName,
          "phone" : this.SupPhone,
          "email" : this.SupEmail,
          "whatsapp" : this.SupWhatsapp,
          "is_delete" : this.SupActive,
          "is_active" : this.SupStatus,
          "zipcode" : this.SupGpsTrack,
          "street" : this.SupAddress,
          "vehicle_model" : this.VehicleModel,
          "vehicle_type" : this.VehicleType,
          "license_plate" : this.LicensePlate,
          "employee_lat" : this.latitude,
          "employee_lng" : this.longitude
        },
        "whatsapp" : this.SupWhatsapp,
      }
      this.apiService
        .editSupervisor(this.User_id, data)
        .subscribe((response: any) => {
          // this.Userlist = response.data;
          if(response.status == "ok"){
            this.toastr.success(response.data);
          this.loader.hide();
          this.router.navigate(["delivery-agent"]);
          }else{
            this.toastr.warning(response.data);
          this.loader.hide();
          }
        })
    }else{
      var data1 = {
        data : {
          "user_id" : this.User_id,
          "role_id" : this.role_id,
          "first_name" : this.FirstName,
          "last_name" : this.LastName,
          "phone" : this.SupPhone,
          "email" : this.SupEmail,
          "whatsapp" : this.SupWhatsapp,
          "is_delete" : this.SupActive,
          "is_active" : this.SupStatus,
          "zipcode" : this.SupGpsTrack,
          "street" : this.SupAddress,
          "vehicle_model" : this.VehicleModel,
          "vehicle_type" : this.VehicleType,
          "license_plate" : this.LicensePlate,
          "profile_image":this.ProfileImage,
          "employee_lat" : this.latitude,
          "employee_lng" : this.longitude
        },
        "whatsapp" : this.SupWhatsapp,
    }
    this.apiService
      .editSupervisor(this.User_id, data1)
      .subscribe((response: any) => {
        // this.Userlist = response.data;
        if(response.status == "ok"){
          this.toastr.success(response.data);
        this.loader.hide();
        this.router.navigate(["delivery-agent"]);
        }else{
          this.toastr.warning(response.data);
        this.loader.hide();
        }
      })
    
    }
    
  }

  Cancel(){
    this.router.navigate(["delivery-agent"]);
  }

  omit_char(event: any) {
    const keyChar = event.key;
    let allowCharacter: boolean;
    if (keyChar === '-' && event.target.selectionStart !== 0) {
      allowCharacter = false;
    } else if (
      keyChar === 'Tab' ||
      keyChar === '+' ||
      keyChar === 'Enter' ||
      keyChar === 'Backspace' ||
      keyChar === 'ArrowLeft' ||
      keyChar === 'ArrowRight' ||
      keyChar === 'Delete'
    ) {
      allowCharacter = true;
    } else {
      allowCharacter = keyChar >= '0' && keyChar <= '9';
    }
    if (!allowCharacter) {
      event.preventDefault();
    }
  }

}
import { Component, OnInit , ChangeDetectionStrategy, Output, Input, EventEmitter, NgZone, ChangeDetectorRef} from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import { GeneralService } from 'src/app/services/GeneralService';
import { Location, Appearance, GermanAddress } from '@angular-material-extensions/google-maps-autocomplete';
import { FormControl } from '@angular/forms';
// import { InfoWindow } from '@agm/core/services/google-maps-types';
import { Image_Path } from 'src/app/config/config';
// import {} from '@types/googlemaps';
import PlaceResult = google.maps.places.PlaceResult;
import { MatDialog } from '@angular/material/dialog';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { ApiService } from './../../../services/ApiService';
import { AppService } from './../../../services/AppService';
import { AuthService } from './../../../services/AuthService';
import * as $ from 'jquery';
import { da } from 'date-fns/locale';
// declare const google: any;


@Component({
  selector: 'app-map-part',
  templateUrl: './map-part.component.html',
  styleUrls: ['./map-part.component.css'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapPartComponent implements OnInit {
  @Input() resource: any;
  @Input() fromWhere: any;
  @Input() estTime : any;
  @Output() changeResource = new EventEmitter<any>();
  public appearance = Appearance;
  public zoom: number = 2;
  public selectedAddress: PlaceResult;

  public logicFirstPickup: any = [];
  public logicFirstDelivery: any = [];

  public logicSecondPickup: any = [];
  public logicSecondDelivery: any = [];

  public logicThirdPickup: { lat: number; lng: number; };
  public logicThirdDelivery: { lat: number; lng: number; };

  public driverOrigin: { lat: number; lng: number; };
  public driverDestination: { lat: number; lng: number; };
  
  public dashboardMap: any = [];

  public origin1: Object = { lat: 13.0826802, lng: 80.2707184 };
  public destination1: Object = { lat: 12.977370, lng: 80.209070 };
  
  public origin2: Object = { lat: 12.888520, lng: 80.227500 };
  public destination2: Object = { lat: 12.969710, lng: 80.184780 };

  public renderOptions = {
    suppressMarkers: true,
    // draggable: true,
  }
  public markerOptions = {
    origin: {
      icon: `${Image_Path.BASE_URL}collections/pick-up.gif`,
      // infoWindow: `<h4>4356<h4>`
    },
    destination: {
      icon: `${Image_Path.BASE_URL}collections/Unallocated.png`,
      // infoWindow: `<h4>3356<h4>`
    },
  };
  public driverMarkerOptions = {
    origin: {
      icon: `${Image_Path.BASE_URL}collections/gif-green.gif`,
    },
    destination: {
      icon: `${Image_Path.BASE_URL}collections/Noimage.png`,
    },
  };

  AllocatedIcon = `${Image_Path.BASE_URL}collections/Allocated.png`;
  StartedRideIcon = `${Image_Path.BASE_URL}collections/gif-green.gif`;
  public gpsOptions = {
    origin: {
      icon: `${Image_Path.BASE_URL}collections/Start.png`,
      // infoWindow: `<h4>4356<h4>`
    },
    destination: {
      icon: `${Image_Path.BASE_URL}collections/Stop.png`,
      // infoWindow: `<h4>3356<h4>`
    },
  };

  public markerOptionsDash = {
    origin: {
      icon: `${Image_Path.BASE_URL}collections/gif-green.gif`,
      infoWindow: `<span><b> Emp ID :></b> 1011</span><br><span><b>Name :<.b> Balaji</span><br><span><b>Active :</b> 2 mins ago </span>`
    },
    destination: {
      icon: `${Image_Path.BASE_URL}collections/gif-green.gif`,
      infoWindow: `<span><b> Emp ID :</b> 1011</span><br><span><b>Name :</b> Balaji</span><br><span><b>Active :</b> 2 mins ago </span>`
    },
  };

  // public infoWindow: InfoWindow = undefined;
  // public obtainInfowindow(window: InfoWindow) {
  //   this.infoWindow = window
  // }
  public waypoints: any = []
  latitude: number;
  longitude: number;
  gpsHistoryMap: any = [];
  gpsHistoryStartMap: any = [];
  gpsHistoryStopMap: any = [];
  gpsHistorySegementsMap: any = [];

  public companyLatitide : number;
  public companyLongitude : number;
  public zoomControl : number;
  userData: any;
  driverAllocated: string;
  orderLatLang: any;
  gpsDataLines: any = [];
  deliveredPickupLat: number;
  deliveredPickupLong: number;
  mapview: any;
  maprange: any = 12;
  showmap_settings:boolean = false;
  satstyles: any = 'roadmap';
  pegmanview: boolean = true;
  trafficview = false;
  map: any;
  trafficLayer: any;
  poly_lat: any;
  poly_lng: any;
  pointList: { lat: number; lng: number }[] = [];
  drawingManager: any;
  selectedShape: any;
  selectedArea = 0;
  estimatedTime: number;

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private loader: NgxSpinnerService,
    private toastr: ToastrService,
    private authService: AuthService,
    public appService: AppService,
    public apiService: ApiService,
    public generalService: GeneralService,
    public ngZone: NgZone,
    public changeDetector: ChangeDetectorRef,
  ) { }

  ngOnInit() {
    
    setTimeout(()=>{
      this.trafficLayer = new google.maps.TrafficLayer();
    },1000);
    
    this.userData = JSON.parse(localStorage.getItem("user_data"));
    this.companyLatitide = parseFloat(this.userData.profile.company_lat);
    this.companyLongitude = parseFloat(this.userData.profile.company_lng);
    this.zoomControl = Number(localStorage.getItem("zoom_control"));
    this.loadMapData();
    this.setCurrentPosition();
    this.changeDetector.detectChanges();
    // const map = new google.maps.Map(
    //   document.getElementById("map") as HTMLElement,
    //   {
    //     zoom: 13,
    //     center: { lat: 34.04924594193164, lng: -118.24104309082031 },
    //   }
    // );
    // const trafficLayer = new google.maps.TrafficLayer();
    // trafficLayer.setMap(map);
  }
  changemapview(event:any){
    if(this.satstyles == 'roadmap'){
      this.satstyles = 'hybrid'
    }
    else{
      this.satstyles = 'roadmap'
    }
  }
  changetrafficview(event:any){
    // this.trafficLayer = new google.maps.TrafficLayer();
    if(this.trafficview){
      this.trafficLayer.setMap(this.map);
      // map.setOptions({
      //     zoomControl: 'true',
      //     zoomControlOptions: {
      //         position: google.maps.ControlPosition.RIGHT_CENTER,
      //         style: google.maps.ZoomControlStyle.DEFAULT
      //     },
      //     zoom:this.maprange,
      //     streetViewControl: this.pegmanview
      // });
    }
    else{
      this.trafficLayer.setMap(null);
      this.changeDetector.detectChanges();
    }
  }
  changemaprange(event:any){
    var payload = {"data":this.zoomControl}
    this.apiService
          .UpdateZoom(payload)
          .subscribe((response: any) => {
          });
    localStorage.setItem('zoom_control', JSON.stringify(this.zoomControl));
  }
  changepegmanview(event:any){
    if(event.checked){
      $(".gm-svpc").show()
    }
    else{
      $(".gm-svpc").hide()
    }
  }
  onshowmap_settings(){
    if(this.showmap_settings == true){
      this.showmap_settings = false
    }
    else{
      this.showmap_settings = true
    }
  }
  loadMapData(){
    this.ngZone.run(() => {
      if(this.resource && this.fromWhere == 'dashboard'){
        this.resource.forEach(element => {
          if(element.lat && element.lng){
            let profile;
            if(element.user.profile_image.length == 2){
              profile = `<div class="avatar-circle">
              <span class="initials">${element.user.first_name[0]}</span>
              </div>`;
            }
            if(element.user.profile_image.length > 2){
              var result = element.user.profile_image.charAt(0);
              if(result == "["){
                var getProfile = JSON.parse(element.user.profile_image);
                element.user.profile_image = getProfile[0];
              }
              else{
                element.user.profile_image = JSON.parse(element.user.profile_image)
              }

              profile = `<img class="avatar-circle-img" style=" 
              background-size: cover,background-position: center" 
              src="${element.user.profile_image}">`;
            }
            this.dashboardMap.push({
              userId: element.user.user_id,
              lat: parseFloat(element.lat),
              lng: parseFloat(element.lng),
              markerOptions:{
                origin: {
                  icon: `${Image_Path.BASE_URL}collections/gif-green.gif`,
                  infoWindow: `
                  <div class="row">
                    <div class="col-4">
                      ${profile}
                    </div>
                    <div class="col-8">
                      <span><b> Emp ID :</b> ${element.user.user_id}</span><br><span><b>Name :</b> ${element.user.first_name} ${element.user.last_name}</span><br><span><b>Active :</b> ${element.user.activated_on != null ? element.user.activated_on : ''} </span>
                    </div>
                  </div>`
                }
              }
            });
          }
        });
      }
      if(this.resource && this.fromWhere =='driverDetails'){
        let element = this.resource;
        if(element.employee_lat && element.employee_lng){
          this.dashboardMap.push([{ 
            lat: parseFloat(element.employee_lat), lng: parseFloat(element.employee_lng) 
          },{
            icon: `${Image_Path.BASE_URL}collections/gif-green.gif`,
          }]);
        }
      }
      if(this.resource && this.fromWhere == 'orderDetails'){
        // debugger
        if(this.resource.pickupDetails &&  this.resource.deliveryDetails && this.resource.otherDetails){
          if(this.resource.otherDetails.status == 'Allocated'
          || this.resource.otherDetails.status == 'Accepted'
          || this.resource.otherDetails.status == 'In-Progress' 
          || this.resource.otherDetails.status == 'Products Picked up'
          || this.resource.otherDetails.status == 'Started Ride'
          || this.resource.otherDetails.status == 'In Supplier Place'){
            this.driverAllocated = 'Allocated';
            if(this.resource.otherDetails.delivery_logic == 3){
              this.resource.pickupDetails.forEach(element => {
                this.logicThirdPickup = { 
                  lat: parseFloat(element.pickup_ladd),
                  lng: parseFloat(element.pickup_long), 
                };
              });
              this.resource.deliveryDetails.forEach(element => {
                this.logicThirdDelivery = { 
                  lat: parseFloat(element.loc_lat),
                  lng: parseFloat(element.loc_lng),
                };
              });
              if(this.resource.otherDetails.driver_employee_lat && this.resource.otherDetails.driver_employee_lng){
                this.driverOrigin = { 
                  lat: parseFloat(this.resource.otherDetails.driver_employee_lat),
                  lng: parseFloat(this.resource.otherDetails.driver_employee_lng),
                };
              }
            }else if(this.resource.otherDetails.delivery_logic == 2){
              this.resource.pickupDetails.forEach(element => {
                this.logicSecondPickup.push({ 
                  lat: parseFloat(element.pickup_ladd),
                  lng: parseFloat(element.pickup_long),
                });
              });
              this.resource.deliveryDetails.forEach(element => {
                this.logicSecondDelivery.push({ 
                  lat: parseFloat(element.loc_lat),
                  lng: parseFloat(element.loc_lng),
                });
              });
              if(this.resource.otherDetails.driver_employee_lat && this.resource.otherDetails.driver_employee_lng){
                this.driverOrigin = { 
                  lat: parseFloat(this.resource.otherDetails.driver_employee_lat),
                  lng: parseFloat(this.resource.otherDetails.driver_employee_lng),
                };
              }
            }else if(this.resource.otherDetails.delivery_logic == 1){
              this.resource.pickupDetails.forEach(element => {
                this.logicFirstPickup.push({ 
                  lat: parseFloat(element.pickup_ladd),
                  lng: parseFloat(element.pickup_long),
                });
              });
              this.resource.deliveryDetails.forEach(element => {
                this.logicFirstDelivery.push({ 
                  lat: parseFloat(element.loc_lat),
                  lng: parseFloat(element.loc_lng),
                });
              });
              if(this.resource.otherDetails.driver_employee_lat && this.resource.otherDetails.driver_employee_lng){
                this.driverOrigin = { 
                  lat: parseFloat(this.resource.otherDetails.driver_employee_lat),
                  lng: parseFloat(this.resource.otherDetails.driver_employee_lng),
                };
              }
            }
          }else if(this.resource.otherDetails.status == 'Delivered'){
            this.driverAllocated = 'Delivered';
            if(this.resource.otherDetails.delivery_logic == 3){
              this.resource.pickupDetails.forEach(element => {
                this.logicThirdPickup = { 
                  lat: parseFloat(element.pickup_ladd),
                  lng: parseFloat(element.pickup_long),
                };
              });
              this.resource.deliveryDetails.forEach(element => {
                this.logicThirdDelivery = { 
                  lat: parseFloat(element.loc_lat),
                  lng: parseFloat(element.loc_lng),
                };
              });
              this.deliveredPickupLat = this.logicThirdDelivery.lat;
              this.deliveredPickupLong =this.logicThirdDelivery.lng;
            }else if(this.resource.otherDetails.delivery_logic == 2){
              this.resource.pickupDetails.forEach(element => {
                this.logicSecondPickup.push({ 
                  lat: parseFloat(element.pickup_ladd),
                  lng: parseFloat(element.pickup_long),
                });
              });
              this.resource.deliveryDetails.forEach(element => {
                this.logicSecondDelivery.push({ 
                  lat: parseFloat(element.loc_lat),
                  lng: parseFloat(element.loc_lng),
                });
              });
              this.deliveredPickupLat = this.logicSecondDelivery[0].lat;
              this.deliveredPickupLong = this.logicSecondDelivery[0].lng;
            }else if(this.resource.otherDetails.delivery_logic == 1){
              this.resource.pickupDetails.forEach(element => {
                this.logicFirstPickup.push({ 
                  lat: parseFloat(element.pickup_ladd),
                  lng: parseFloat(element.pickup_long),
                });
              });
              this.resource.deliveryDetails.forEach(element => {
                this.logicFirstDelivery.push({ 
                  lat: parseFloat(element.loc_lat),
                  lng: parseFloat(element.loc_lng),
                });
              });
              this.deliveredPickupLat = this.logicFirstDelivery[0].lat;
              this.deliveredPickupLong = this.logicFirstDelivery[0].lng;
            }
            setTimeout(()=>{
              this.resource.gpsDetails.forEach(element => {
                this.gpsDataLines.push({
                  lat: parseFloat(element.lat),
                  lng: parseFloat(element.lng),
                });
                this.changeDetector.detectChanges();
              });
            },2000)
          }else{
            this.driverAllocated = 'Unallocated';
            if(this.resource.otherDetails.delivery_logic == 3){
              this.resource.pickupDetails.forEach(element => {
                this.logicThirdPickup = { 
                  lat: parseFloat(element.pickup_ladd),
                  lng: parseFloat(element.pickup_long),
                };
              });
              this.resource.deliveryDetails.forEach(element => {
                this.logicThirdDelivery = { 
                  lat: parseFloat(element.loc_lat),
                  lng: parseFloat(element.loc_lng),
                };
              });
            }else if(this.resource.otherDetails.delivery_logic == 2){
              this.resource.pickupDetails.forEach(element => {
                this.logicSecondPickup.push({ 
                  lat: parseFloat(element.pickup_ladd),
                  lng: parseFloat(element.pickup_long),
                });
              });
              this.resource.deliveryDetails.forEach(element => {
                this.logicSecondDelivery.push({ 
                  lat: parseFloat(element.loc_lat),
                  lng: parseFloat(element.loc_lng),
                });
              });
            }else if(this.resource.otherDetails.delivery_logic == 1){
              this.resource.pickupDetails.forEach(element => {
                this.logicFirstPickup.push({ 
                  lat: parseFloat(element.pickup_ladd),
                  lng: parseFloat(element.pickup_long),
                });
              });
              this.resource.deliveryDetails.forEach(element => {
                this.logicFirstDelivery.push({ 
                  lat: parseFloat(element.loc_lat),
                  lng: parseFloat(element.loc_lng),
                });
              });
            }
          }
        }
      }
      if(this.resource && this.fromWhere == 'gpsHistory'){
        let element = this.resource.geoData;
        let startData = [];
        let stopData = [];
        let geoSegmentsData = [];
        let geoSegments = [];
        if(element){
          if(element.length > 0){
            for(var i = 0 ; i <= element.length - 1; i++){
              if(element[i]['activity'] == 'Start'){
                startData.push([{ 
                  lat: parseFloat(element[i]['lat']), lng: parseFloat(element[i]['lng']) 
                },{
                  icon: `${Image_Path.BASE_URL}collections/Start.png`,
                }]);
              }
              if(element[i]['activity'] == 'Stop'){
                stopData.push([{ 
                  lat: parseFloat(element[i]['lat']), lng: parseFloat(element[i]['lng']) 
                },{
                  icon: `${Image_Path.BASE_URL}collections/Stop.png`,
                }]);
              }
              if(element[i]['activity'] == 'Start'){
                geoSegments.push(geoSegmentsData);
                geoSegmentsData = [];
                geoSegmentsData.push({
                  lat: parseFloat(element[i]['lat']),lng: parseFloat(element[i]['lng'])
                });
              }else{
                geoSegmentsData.push({
                  lat: parseFloat(element[i]['lat']),lng: parseFloat(element[i]['lng'])
                });
              }
            }
            if(element.length > -1){
              geoSegments.push(geoSegmentsData);
              geoSegmentsData = [];
            }
          }
        }
        this.gpsHistoryStartMap = startData;
        this.gpsHistoryStopMap = stopData;
        this.gpsHistorySegementsMap = geoSegments;
      }
    });
  }
  onMapReady(map) {
    this.map = map;
    this.initDrawingManager(map);
    var trafficLayer = new google.maps.TrafficLayer();
    trafficLayer.setMap(null);

    if(this.fromWhere == "editDrivingZone") {
      let data = this.resource;
      let coordinates: google.maps.LatLng[] = [];
      for(var i = 0 ; i <= data.length - 1; i++) {
        coordinates.push(new google.maps.LatLng(parseFloat(data[i].lat), parseFloat(data[i].lng)));
      }
      var myPolygon = new google.maps.Polygon({
        map: map,
        paths: coordinates, // one time registration reqd only
        editable: true,
        draggable: true,
        clickable: true
      });
      myPolygon.setMap(map);

      google.maps.event.addListener(myPolygon, 'drag', () => {
        this.updatePointList(myPolygon.getPath());
      });

      google.maps.event.addListener(myPolygon.getPath(), 'insert_at', () => {
        this.updatePointList(myPolygon.getPath());
      });

      google.maps.event.addListener(myPolygon.getPath(), 'remove_at', () => {
        this.updatePointList(myPolygon.getPath());
      });

      google.maps.event.addListener(myPolygon.getPath(), 'set_at', () => {
        this.updatePointList(myPolygon.getPath());
      });
    }
    
    map.setOptions({
        zoomControl: 'true',
        zoomControlOptions: {
            position: google.maps.ControlPosition.RIGHT_CENTER,
            style: google.maps.ZoomControlStyle.DEFAULT
        },
        zoom:this.zoomControl,
        streetViewControl: this.pegmanview
    });
    
  }

  initDrawingManager = (map: any) => {
    const self = this;
    var options = {
        drawingControl: this.fromWhere == "editDrivingZone" ? false : true,
        drawingControlOptions: {
          drawingModes: [google.maps.drawing.OverlayType.POLYGON],
        },
        polygonOptions: {
          draggable: true,
          editable: true
        },
        drawingMode: this.fromWhere == "editDrivingZone" ? null : google.maps.drawing.OverlayType.POLYGON,
      };
    
    this.drawingManager = new google.maps.drawing.DrawingManager(options);
    this.drawingManager.setMap(map);
    google.maps.event.addListener(
      this.drawingManager,
      'overlaycomplete',
      (event) => {
        google.maps.event.addListener(event.overlay, 'click', function(evt) {
          self.updatePointList(event.overlay.getPath());
        });
        google.maps.event.addListener(event.overlay, 'drag', function(evt) {
          self.updatePointList(event.overlay.getPath());
        });
        if (event.type === google.maps.drawing.OverlayType.POLYGON) {
          const paths = event.overlay.getPaths();
          for (let p = 0; p < paths.getLength(); p++) {
            google.maps.event.addListener(
              paths.getAt(p),
              'set_at',
              () => {
                if (!event.overlay.drag) {
                  self.updatePointList(event.overlay.getPath());
                }
              }
            );
            google.maps.event.addListener(
              paths.getAt(p),
              'insert_at',
              () => {
                self.updatePointList(event.overlay.getPath());
              }
            );
            google.maps.event.addListener(
              paths.getAt(p),
              'remove_at',
              () => {
                self.updatePointList(event.overlay.getPath());
              }
            );
          }
          self.updatePointList(event.overlay.getPath());
          this.selectedShape = event.overlay;
          this.selectedShape.type = event.type;
        }
        if (event.type !== google.maps.drawing.OverlayType.MARKER) {
          // Switch back to non-drawing mode after drawing a shape.
          self.drawingManager.setDrawingMode(null);
          // To hide:
          self.drawingManager.setOptions({
            drawingControl: false,
          });
        }
      }
    );
  }

  setCurrentPosition() {
    if(this.fromWhere == "editDrivingZone") {
      this.poly_lat = parseFloat(this.resource[0].lat);
      this.poly_lng = parseFloat(this.resource[0].lng);
    } else {
      if ('geolocation' in navigator) {
        navigator.geolocation.getCurrentPosition((position) => {
          this.poly_lat = position.coords.latitude;
          this.poly_lng = position.coords.longitude;
        });
      }
    }
  }

  deleteSelectedShape() {
    if (this.selectedShape) {
      this.selectedShape.setMap(null);
      this.selectedArea = 0;
      this.pointList = [];
      // To show:
      this.drawingManager.setOptions({
        drawingControl: true,
      });
    }
  }

  updatePointList(path) {
    this.changeResource.emit(path);
    // this.pointList = [];
    // const len = path.getLength();
    // for (let i = 0; i < len; i++) {
    //   this.pointList.push(
    //     path.getAt(i).toJSON()
    //   );
    // }
    // this.selectedArea = google.maps.geometry.spherical.computeArea(
    //   path
    // );
  }

  ngAfterViewInit() {
    this.generalService.dashboardLocationData.subscribe((data: any) => {
      if(data){
        this.ngZone.run(() =>{
          if(this.resource && this.fromWhere == 'dashboard'){
            //debugger
            let profile;
            let element = this.resource.filter(obj=>obj.user.user_id == data.user.user_id);
            element = element[0];
            if(element){
              if(element.user.user_id == data.user.user_id){
                if(data.lat && data.lng){
                  if(data.user.profile_image.length == 2){
                    profile = `<div class="avatar-circle">
                    <span class="initials">${data.user.first_name[0]}</span>
                    </div>`;
                  }
                  if(data.user.profile_image.length > 2){
                    var result = data.user.profile_image.charAt(0);
                    if(result == "["){
                      var getProfile = JSON.parse(data.user.profile_image);
                      data.user.profile_image = getProfile[0];
                    }
                    profile = `<img class="avatar-circle-img" style=" 
                    background-size: cover,background-position: center" 
                    src="${JSON.parse(data.user.profile_image)}">`;
                  }
                }
              }
            }
            var foundIndex = this.dashboardMap.findIndex(x => x.userId == element.user.user_id);
            this.dashboardMap[foundIndex] = {
              userId: data.user.user_id,
              lat: parseFloat(data.lat),
              lng: parseFloat(data.lng),
              markerOptions:{
                origin: {
                  icon: `${Image_Path.BASE_URL}collections/gif-green.gif`,
                  infoWindow: `
                  <div class="row">
                    <div class="col-4">
                      ${profile}
                    </div>
                    <div class="col-8">
                      <span><b> Emp ID :</b> ${data.user.user_id}</span><br><span><b>Name :</b> ${data.user.first_name} ${data.user.last_name}</span><br><span><b>Active :</b> ${data.user.activated_on != null ? data.user.activated_on : ''} </span>
                    </div>
                  </div>`
                }
              }
            };
            this.changeDetector.detectChanges();
          }
        });
      }
    }); 
    this.generalService.driverLocationLATLNG.subscribe((latlng: any) => {
      if(latlng)
      this.ngZone.run(() =>{
        let element = latlng;
        if(element.lat && element.lng){
          this.dashboardMap[0] = [{ 
            lat: parseFloat(element.lat), lng: parseFloat(element.lng) 
          },{
            icon: `${Image_Path.BASE_URL}collections/gif-green.gif`,
          }];
        }
        this.changeDetector.detectChanges();
      });
    });
    this.generalService.orderDriverLATLNG.subscribe((latlng: any) => {
      if(latlng)
      this.ngZone.run(() =>{
        this.driverOrigin = { 
          lat: parseFloat(latlng.lat),
          lng: parseFloat(latlng.lng),
        };
        this.changeDetector.detectChanges();
      });
    });
    this.generalService.gpsDetailsLATLNG.subscribe((data: any) => {
      this.ngZone.run(() => {
        if(this.resource && this.fromWhere == 'orderDetails' && data.otherDetails.status == 'Delivered') {
            this.driverAllocated = 'Delivered';
            if(data.otherDetails.delivery_logic == 3) {
              data.pickupDetails.forEach(element => {
                this.logicThirdPickup = { 
                  lat: parseFloat(element.pickup_ladd),
                  lng: parseFloat(element.pickup_long),
                };
              });
              data.deliveryDetails.forEach(element => {
                this.logicThirdDelivery = { 
                  lat: parseFloat(element.loc_lat),
                  lng: parseFloat(element.loc_lng),
                };
              });
              this.deliveredPickupLat = this.logicThirdDelivery.lat;
              this.deliveredPickupLong =this.logicThirdDelivery.lng;
            } else if(data.otherDetails.delivery_logic == 2) {
              data.pickupDetails.forEach(element => {
                this.logicSecondPickup.push({ 
                  lat: parseFloat(element.pickup_ladd),
                  lng: parseFloat(element.pickup_long),
                });
              });
              data.deliveryDetails.forEach(element => {
                this.logicSecondDelivery.push({ 
                  lat: parseFloat(element.loc_lat),
                  lng: parseFloat(element.loc_lng),
                });
              });
              this.deliveredPickupLat = this.logicSecondDelivery[0].lat;
              this.deliveredPickupLong = this.logicSecondDelivery[0].lng;
            } else if(data.otherDetails.delivery_logic == 1) {
              data.pickupDetails.forEach(element => {
                this.logicFirstPickup.push({ 
                  lat: parseFloat(element.pickup_ladd),
                  lng: parseFloat(element.pickup_long),
                });
              });
              data.deliveryDetails.forEach(element => {
                this.logicFirstDelivery.push({ 
                  lat: parseFloat(element.loc_lat),
                  lng: parseFloat(element.loc_lng),
                });
              });
              this.deliveredPickupLat = this.logicFirstDelivery[0].lat;
              this.deliveredPickupLong = this.logicFirstDelivery[0].lng;
            }
            setTimeout(()=>{
              data.gpsDetails.forEach(element => {
                this.gpsDataLines.push({
                  lat: parseFloat(element.lat),
                  lng: parseFloat(element.lng),
                });
                this.changeDetector.detectChanges();
              });
            },1000);
        }
      });
    });
  }
  public change(event: any) {
    this.waypoints = event.request.waypoints;
  }
  onAutocompleteSelected(result: PlaceResult) {
    console.log('onAutocompleteSelected: ', result);
  }
  onLocationSelected(location: Location) {
    this.latitude = location.latitude;
    this.longitude = location.longitude;
  }
  onGermanAddressMapped($event: GermanAddress) {
    console.log('onGermanAddressMapped', $event);
  }
}

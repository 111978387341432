<div class="row">
  <div class="col-lg-12">
    <mat-horizontal-stepper [linear]="isLinear" #stepper>
      <mat-step [stepControl]="firstFormGroup">
        <form [formGroup]="firstFormGroup" style="padding: 0 0 330px 0;">
          <ng-template matStepLabel>{{'Pickup Details' | translate}}</ng-template>
          <div formArrayName="formArray1">
            <mat-card class="pb-4 mt-b-1" *ngFor="let Xcontrol of pickupForm.formArray1['controls']; let i=index">
              <mat-card-header class="mat-card-header">
                <mat-icon *ngIf="Xcontrol.value.id == ''" style="cursor: pointer;" (click)="RemovePickup(i)">close
                </mat-icon>
                <mat-icon *ngIf="i > 0 && Xcontrol.value.id != ''" style="cursor: pointer;"
                  (click)="DeleteModel(Xcontrol.value.id)">close</mat-icon>
              </mat-card-header>
              <div class="row" [formGroup]="Xcontrol">
                <div class="col-lg-12 col-md-12">
                  <p class="text-info" style="font-size: medium;font-weight: 500;">{{'Pickup Details' | translate}}:
                    {{i+1}}</p>
                </div>
                <div class="col-lg-4 col-md-12">
                  <div class="form-group">
                    <label>{{'Pickup Time' | translate}}<span style="color: red;"> *</span></label>
                    <input type="text" [min]="minDate" [readonly]="inputReadonly" [ngxMatDatetimePicker]="picker"
                      class="form-control" placeholder="{{'Select Pickup Time' | translate}}"
                      formControlName="Pickuptime">
                    <mat-datepicker-toggle matSuffix [for]="picker" class="picktime"></mat-datepicker-toggle>
                    <ngx-mat-datetime-picker #picker [showSpinners]="true" [showSeconds]="false" [stepHour]="1"
                      [stepMinute]="1" [min]="minDate" [stepSecond]="stepSecond" [touchUi]="false" [color]="color">
                    </ngx-mat-datetime-picker>
                  </div>
                </div>
                <div class="col-lg-4 col-md-12">
                  <div class="form-group">
                    <label>{{'Pickup Location' | translate}}<span style="color: red;"> *</span></label>
                    <input type="text" class="form-control marker_p" formControlName="Pickuplocation" Pickuplocation
                      value={{Xcontrol.value.Pickuplocation}} placeholder="{{'Enter Location' | translate}}"
                      matGoogleMapsAutocomplete (onAutocompleteSelected)="onAutocompleteSelected($event,'pickup', i)"
                      (onLocationSelected)="onLocationSelected($event, 'pickup', i)"
                      (input)="onChange($event,'pickup',i)">
                    <img src="./assets/img/location.svg" alt="Profile Edit"
                      style="height: 30px;width: 30px;float: right;margin-top: -36px;overflow: hidden;" class="map-icon"
                      (click)="showmap(1,i)" *ngIf="agmshowmap[i] != 1">
                    <img src="./assets/img/location-disabled.svg" alt="Profile Edit"
                      style="height: 30px;width: 30px;float: right;margin-top: -36px;" class="map-icon"
                      (click)="showmap(0,i)" *ngIf="agmshowmap[i] == 1">
                    <div class="agm-map" *ngIf="agmshowmap[i] == 1">
                      <agm-map [latitude]="latitude" [longitude]="longitude" [zoom]="zoom">
                        <agm-marker [latitude]="latitude" [longitude]="longitude" [markerDraggable]="true"
                          (dragEnd)="markerDragEndNew($event, i)"></agm-marker>
                      </agm-map>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-12">
                  <div class="form-group">
                    <label>{{'Pickup Phone Number' | translate}}</label>
                    <ngx-mat-intl-tel-input class="custom-intl-tel-input" [preferredCountries]="preferredCountries"
                      [enableAutoCountrySelect]="false" [enablePlaceholder]="true" [searchCountryFlag]="true"
                      [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                      [selectFirstCountry]="false" [selectedCountryISO]="CountryISO.India" [maxLength]="15"
                      value="value={{Xcontrol.value.pickup_phone}}" [tooltipField]="TooltipLabel.Name"
                      placeholder="Enter Phone Number" (keypress)="omit_char($event);" formControlName="pickup_phone"
                      [separateDialCode]="false" [enableSearch]="true" name="pickup_phone" #phone>
                    </ngx-mat-intl-tel-input>
                  </div>
                </div>
                <div class="col-lg-4 col-md-12">
                  <div class="form-group">
                    <label>{{'Items' | translate}}<span style="color: red;"> *</span></label>
                    <ngx-select-dropdown tabindex="0" [multiple]="true" [config]="Config" [options]="ItemOptions"
                      formControlName="PickupItem" (change)="itemChanged($event.value, i)" style="width:100%;">
                    </ngx-select-dropdown>
                  </div>
                  <!-- <button mat-raised-button color="primary" class="mr-2" (click)="ItemNew()" style="width: 23%;position: absolute;right: 0;bottom: 25%;">New</button> -->
                </div>
                <div class="col-lg-4 col-md-12" *ngIf="newitemshow">
                  <div class="form-group">
                    <label>{{'New Items' | translate}}</label><span style="margin-left: 10px;font-size: 12px;">{{'Ex:
                      Mobile,Laptop' | translate}}</span>
                    <input type="text" class="form-control" placeholder="{{'Enter New Item' | translate}}"
                      formControlName="ItemNew" (focusout)="newItemChanged($event.target.value, i)">
                  </div>
                </div>
                <div class="col-lg-12 col-md-12">
                  <div class="row" formArrayName="formArray2">
                    <div class="col-lg-6 col-md-12 mv-compact" style="padding-right: 15px;padding-left: 15px;"
                      *ngFor="let Ycontrol of Xcontrol['controls'].formArray2['controls']; let iy=index">
                      <table class="table table-hover shopping-cart-wrap" style="margin-bottom: 0px !important;"
                        formGroupName="{{iy}}">
                        <tbody>
                          <tr style="background-color: #ffffff!important;">
                            <td>
                              <div class="form-group">
                                <input type="text" class="form-control" readonly
                                  placeholder="{{'Enter New Item' | translate}}" formControlName="ItemName"
                                  matTooltip="{{Ycontrol.value.ItemName}}" [matTooltipPosition]="'above'"
                                  style="border: none!important;">
                              </div>
                            </td>
                            <td class="hide-mv" style="width: 30%;text-align: right;">
                              <div class="badge badge-secondary" style="margin-top: 12px;">
                                {{'Quantity' | translate}}
                              </div>
                            </td>
                            <td style="width: 30%;">
                              <div class="form-group">
                                <ngx-number-spinner matTooltip="Add Quantity" [matTooltipPosition]="'above'" [min]="1"
                                  formControlName="Quantity" (change)="changeQuantity(Ycontrol.value,i,iy)">
                                </ngx-number-spinner>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div class="modal full-size" tabindex="-1" role="dialog"
                  [ngClass]="{ fade: !isDeleteModel, 'd-flex': isDeleteModel }">
                  <div class="modal-dialog" role="document">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h5 class="modal-title w-100">{{'Confirmation' | translate}}</h5>
                        <a class="btn btn-sm btn-white dialog-close" (click)="onClose()"><span
                            aria-hidden="true">&times;</span></a>
                      </div>
                      <div class="modal-body">
                        <div class="row">
                          <div class="col-md-12" style="padding-bottom: 1px;">
                            <b>{{'Are you sure want to remove this?' | translate}}</b><br>
                            <b>{{'Once deleted, you will not be able to recover this!' | translate}}</b>
                          </div>
                        </div>
                        <div class="col-md-12 text-right" style="padding-bottom: 1px; padding-top: 5px;">
                          <button type="button" class="btn btn-sm btn-outline-info rounded-0 mb-2 mr-2"
                            (click)="onClose()">{{'No' | translate}}</button>
                          <button class="btn btn-sm btn-outline-info rounded-0 mb-2 mr-2"
                            (click)="DeleteTask(Xcontrol.value.id)">{{'Yes' | translate}}</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </mat-card>
          </div>
          <div class="row pb-4">
            <div class="col-lg-12 col-md-12 text-right">
              <button mat-raised-button color="primary" class="mr-2" (click)="pickupAdd()"
                *ngIf="isPickup == 'add'">{{'Add' | translate}}</button>
              <button mat-raised-button color="primary" class="mr-2 sec" style="float: left;" id="button2"
                (click)="pickupAddMore()" *ngIf="isPickup == 'addMore'"><i class="fas fa-plus mr-1"></i>{{'Add More' |
                translate}}</button>
              <button mat-raised-button color="primary" *ngIf="nextshow" style="float: right;"
                [stepControl]="firstFormGroup" (click)="goForward(stepper)">{{'Next' | translate}}</button>
              <button mat-raised-button color="primary" class="mr-2" (click)="pickupSave()"
                *ngIf="isPickup == 'save'">{{'Save' | translate
                }}</button>
            </div>
          </div>
        </form>
      </mat-step>
      <mat-step [stepControl]="secondFormGroup">
        <form [formGroup]="secondFormGroup">
          <ng-template matStepLabel>{{'Delivery Details' | translate}}</ng-template>
          <mat-card class="pb-4 mt-b-1" *ngIf="isDeliveryShow">
            <mat-card-header class="mat-card-header">

            </mat-card-header>
            <div class="row">
              <div class="col-lg-12 col-md-12">
                <p class="text-info" style="font-size: smaller;">{{'Delivery Details' | translate}}</p>
              </div>
              <div class="col-lg-3 col-md-12">
                <div class="form-group">
                  <label>{{'Delivery At' | translate}}<span style="color: red;"> *</span></label>
                  <input type="text" [ngxMatDatetimePicker]="picker" [readonly]="inputReadonly" class="form-control"
                    [min]="minDate" formControlName="Deliveryat" placeholder="{{'Select Delivery Time' | translate}}">
                  <mat-datepicker-toggle matSuffix [for]="picker" class="picktime"></mat-datepicker-toggle>
                  <ngx-mat-datetime-picker #picker [showSpinners]="true" [showSeconds]="false" [stepHour]="1"
                    [stepMinute]="1" [stepSecond]="stepSecond" [touchUi]="false" [color]="color">
                  </ngx-mat-datetime-picker>
                </div>
              </div>
              <div class="col-lg-3 col-md-12">
                <div class="form-group">
                  <label>{{'Order ID' | translate}}</label>
                  <input type="text" class="form-control" placeholder="{{'Enter Order ID' | translate}}"
                    formControlName="Orderid">
                </div>
              </div>
              <div class="col-lg-3 col-md-12" style="padding:0px;">
                <div class="form-group" style="width: 60%;float: left;">
                  <label>{{'Select Receiver' | translate}}</label>
                  <select class="form-control" formControlName="Receiver"
                    style="position: absolute;width: 60%;z-index: 1111111;"
                    (change)="receiverChanged($event.target.value)" onfocus='this.size=5;' onblur='this.size=1;'
                    onchange='this.size=1; this.blur();'>
                    <option value="">-- Select --</option>
                    <option *ngFor="let list of ReceiverOptions" value={{list.id}}>{{list.name}}</option>
                  </select>
                </div>
                <button mat-raised-button color="primary" class="mr-2" value="yes" (click)="AddNew()"
                  style="width: 23%;float: right;margin: 34px 0 0 0;">{{'New' | translate}}</button>
              </div>
              <div class="col-lg-3 col-md-12">
                <div class="form-group">
                  <label>{{'Receiver Name' | translate}}<span style="color: red;"> *</span></label>
                  <input type="text" class="form-control" placeholder="Enter Receiver Name" [readonly]="receivershow"
                    [(ngModel)]="Receivername" formControlName="Receivername" id="valdisable">
                </div>
              </div>
              <div class="col-lg-3 col-md-12">
                <div class="form-group">
                  <label>{{'Receiver Phone Number' | translate}}<span style="color: red;"> *</span></label>
                  <!-- <ngx-intl-tel-input class="custom-intl-tel-input"
                    [preferredCountries]="preferredCountries"
                    [enableAutoCountrySelect]="false"
                    [enablePlaceholder]="false"
                    [searchCountryFlag]="true"
                    [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                    [selectFirstCountry]="false"
                    [selectedCountryISO]="CountryISO.India"
                    [maxLength]="15"
                    [tooltipField]="TooltipLabel.Name"
                    [separateDialCode]="true"
                    [disabled]="receivershow"
                    placeholder="Enter Phone Number"
                    [(ngModel)]="Receivernumber"
                    (keypress)="omit_char($event);"
                    formControlName="Receivernumber">
                  </ngx-intl-tel-input> -->
                  <ngx-mat-intl-tel-input class="custom-intl-tel-input" [preferredCountries]="preferredCountries"
                    [enableAutoCountrySelect]="false" [enablePlaceholder]="true" [searchCountryFlag]="true"
                    [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                    [selectFirstCountry]="false" [selectedCountryISO]="CountryISO.India" [maxLength]="15"
                    [tooltipField]="TooltipLabel.Name" [disabled]="receivershow" [readonly]="receivershow"
                    placeholder="Enter Phone Number" [(ngModel)]="Receivernumber" (keypress)="omit_char($event);"
                    formControlName="Receivernumber" [separateDialCode]="false" [enableSearch]="true" name="recv_phone"
                    #phone>
                  </ngx-mat-intl-tel-input>
                </div>
              </div>
              <div class="col-lg-3 col-md-12">
                <div class="form-group">
                  <label>{{'Receiver Email' | translate}}</label>
                  <input type="text" class="form-control" placeholder="{{'Enter Receiver Email' | translate}}"
                    [readonly]="receivershow" [(ngModel)]="Receiveremail" formControlName="Receiveremail">
                </div>
              </div>
              <div class="col-lg-3 col-md-12" style="padding:0px;">
                <div class="form-group">
                  <label>{{'Receiver Address' | translate}}<span style="color: red;"> *</span></label>
                  <input type="text" class="form-control marker_p" formControlName="Receiveraddress"
                    value="{{Receiveraddress}}" id="valdisable" [disabled]="receivershow" [readonly]="receivershow"
                    placeholder="{{'Enter Location' | translate}}" matGoogleMapsAutocomplete
                    (input)="onChangeReceiverAdddress($event,'pickup')"
                    (onAutocompleteSelected)="onAutocompleteSelected($event,'delivery', 0)"
                    (onLocationSelected)="onLocationSelected($event,'delivery', 0)" #search>
                  <img src="./assets/img/location.svg" alt="Profile Edit"
                    style="height: 30px;width: 30px;float: right;margin-top: -36px;overflow: hidden;" class="map-icon"
                    (click)="showmap1(1)" *ngIf="agmshowmap1 == 0">
                  <img src="./assets/img/location-disabled.svg" alt="Profile Edit"
                    style="height: 30px;width: 30px;float: right;margin-top: -36px;" class="map-icon"
                    (click)="showmap1(0)" *ngIf="agmshowmap1 == 1">
                  <div class="agm-map" *ngIf="agmshowmap1 == 1">
                    <agm-map [latitude]="latitude" [longitude]="longitude" [zoom]="zoom">
                      <agm-marker [latitude]="latitude" [longitude]="longitude" [markerDraggable]="true"
                        (dragEnd)="markerDragEndNew2($event, i)"></agm-marker>
                    </agm-map>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-12">
                <div class="form-group">
                  <label>{{'Order Amount' | translate}}<span style="color: red;"> *</span></label>
                  <input type="text" class="form-control" placeholder="{{'Enter Order Amount' | translate}}"
                    formControlName="Orderamount">
                </div>
              </div>
              <div class="col-lg-3 col-md-12">
                <div class="form-group">
                  <label>{{'Payment Type' | translate}}<span style="color: red;"> *</span></label>
                  <select class="form-control" formControlName="Paymenttype">
                    <option value="">-- {{'Select' | translate}} --</option>
                    <option value="1">Online</option>
                    <option value="2">Cash On Delivery</option>
                  </select>
                </div>
              </div>
            </div>
          </mat-card>
          <div class="row pb-4">
            <div class="col-lg-12 col-md-12 text-right">
              <button mat-raised-button color="primary" class="mr-2" (click)="deliveryAdd()"
                *ngIf="isDelivery == 'add'">{{'Add' | translate}}</button>
              <button mat-raised-button color="primary" class="mr-2" (click)="deliverySave()"
                *ngIf="isDelivery == 'save'">{{'Save' | translate}}</button>
            </div>
          </div>
          <div>
            <button mat-raised-button class="sec" color="primary" matStepperPrevious>{{'Previous' | translate}}</button>
            <button mat-raised-button color="primary" *ngIf="nextstepshow" [stepControl]="secondFormGroup"
              style="margin-left: 15px;float: right;" (click)="goNext(stepper)">{{'Next' | translate}}</button>
          </div>
        </form>
      </mat-step>
      <mat-step [stepControl]="thirdFormGroup">
        <form [formGroup]="thirdFormGroup"
          style="border: 1px solid #ccc;border-radius: 5px;margin-bottom: 10px;padding:10px;">
          <ng-template matStepLabel style="font-size: medium;font-weight: 500;">{{'Other Details' | translate}}
          </ng-template>
          <div class="row">
            <div class="col-lg-12 col-md-12">
              <p class="text-info" style="font-size: smaller;">{{'Other Details' | translate}}</p>
            </div>
            <div class="col-lg-3 col-md-12">
              <div class="form-group">
                <label>{{'Sender Name' | translate}}</label>
                <input type="text" class="form-control" formControlName="Sendername">
              </div>
            </div>
            <div class="col-lg-3 col-md-12">
              <div class="form-group">
                <label>{{'Sender Mobile Number' | translate}}<span style="color: red;"> *</span></label>
                <!-- <ngx-intl-tel-input class="custom-intl-tel-input"
                [preferredCountries]="preferredCountries"
                [enableAutoCountrySelect]="false"
                [enablePlaceholder]="false"
                [searchCountryFlag]="true"
                [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                [selectFirstCountry]="false"
                [selectedCountryISO]="CountryISO.India"
                [maxLength]="15"
                [tooltipField]="TooltipLabel.Name"
                [separateDialCode]="true"
                (keypress)="omit_char($event);"
                formControlName="Sendernumber">
                </ngx-intl-tel-input> -->
                <ngx-mat-intl-tel-input class="custom-intl-tel-input" [preferredCountries]="preferredCountries"
                  [enableAutoCountrySelect]="false" [enablePlaceholder]="true" [searchCountryFlag]="true"
                  [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]" [selectFirstCountry]="false"
                  [selectedCountryISO]="CountryISO.India" [maxLength]="15" [tooltipField]="TooltipLabel.Name"
                  placeholder="Enter Phone Number" (keypress)="omit_char($event);" required
                  formControlName="Sendernumber" [separateDialCode]="false" [enableSearch]="true" name="send_phone"
                  #phone1>
                </ngx-mat-intl-tel-input>
              </div>
            </div>
            <div class="col-lg-3 col-md-12">
              <div class="form-group">
                <label>{{'Sender Address' | translate}}</label>
                <input type="text" class="form-control marker_p" formControlName="Senderaddress" value={{Senderaddress}}
                  placeholder="{{'Enter Location' | translate}}" matGoogleMapsAutocomplete
                  (onAutocompleteSelected)="onAutocompleteSelected($event,'sender', 0)"
                  (onLocationSelected)="onLocationSelected($event,'sender', 0)">
                <img src="./assets/img/location.svg" alt="Profile Edit"
                  style="height: 30px;width: 30px;float: right;margin-top: -36px;overflow: hidden;" class="map-icon"
                  (click)="showmap2(1)" *ngIf="agmshowmap2 == 0">
                <img src="./assets/img/location-disabled.svg" alt="Profile Edit"
                  style="height: 30px;width: 30px;float: right;margin-top: -36px;" class="map-icon"
                  (click)="showmap2(0)" *ngIf="agmshowmap2 == 1">
                <div class="agm-map" *ngIf="agmshowmap2 == 1">
                  <agm-map [latitude]="latitude" [longitude]="longitude" [zoom]="zoom">
                    <agm-marker [latitude]="latitude" [longitude]="longitude" [markerDraggable]="true"
                      (dragEnd)="markerDragEndNew3($event, i)"></agm-marker>
                  </agm-map>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-12">
              <div class="form-group">
                <label>{{'Schedule Status' | translate}}</label>
                <select class="form-control" formControlName="Schedulestatus"
                  (change)="statusChange($event.target.value)">
                  <option value="Unallocated">{{'Unallocated' | translate}}</option>
                  <option value="Declined">{{'Declined' | translate}}</option>
                  <option value="Allocated">{{'Allocated' | translate}}</option>
                  <option value="Started Ride">{{'Started Ride' | translate}}</option>
                  <option value="In Supplier Place">{{'In Supplier Place' | translate}}</option>
                  <option value="Products Picked up">{{'Products Picked up' | translate}}</option>
                  <option value="Delivered">{{'Delivered' | translate}}</option>
                  <option value="Delivered Back">{{'Delivered Back' | translate}}</option>
                </select>
              </div>
            </div>
            <div class="col-lg-3 col-md-12" >
              <div class="form-group">
                <label>{{'Driving Zone' | translate}}<span style="color: red;"> *</span></label>
                <select class="form-control" formControlName="SenderZone" (change)="zoneChange($event.target.value)">
                  <option value=''>-- {{'Please Select' | translate}} --</option>
                  <!-- <option value='0'>-- {{'ALL' | translate}} --</option> -->
                  <option *ngFor="let list of zoneList" [value]="list.id">{{list.name}}</option>
                </select>
              </div>
            </div>
            <div class="col-lg-3 col-md-12" *ngIf="userListShow">
              <div class="form-group">
                <label>{{'To Delivery Agent' | translate}}<span style="color: red;"> *</span></label>
                <select class="form-control" formControlName="Scheduleuser">
                  <option value=''>-- {{'Please Select' | translate}} --</option>
                  <option *ngFor="let list of userList" [value]="list.driver_id">{{list.driver_detail}}</option>
                </select>
              </div>
            </div>
            <!-- <div class="col-lg-3 col-md-12" *ngIf="userListShow">
              <div class="form-group">
                <label>{{'To Delivery Agent' | translate}}<span style="color: red;"> *</span></label>
                <select class="form-control" formControlName="Scheduleuser">
                  <option value=''>-- {{'Please Select' | translate}} --</option>
                  <option *ngFor="let list of userList" [value]="list.user_id">{{list.first_name}} {{list.last_name}}</option>
                </select>
              </div>
            </div>  -->
            <div class="col-lg-3 col-md-12">
              <div class="form-group">
                <label>{{'Geo Location Meters' | translate}}<span style="color: red;"> *</span></label>
                <input type="text" class="form-control" (keypress)="omit_char($event);"
                  formControlName="Geolocationmeters">
              </div>
            </div>
          </div>

        </form>
        <br>
        <div>
          <button mat-button color="primary" (click)="goBackward(stepper)" class="mr-2 sec">{{'Previous' |
            translate}}</button>
          <button mat-raised-button color="primary" (click)="submit()" class="mr-2" style="float: right;">{{'Update
            Order' | translate}}</button>
        </div>
      </mat-step>
    </mat-horizontal-stepper>
    <br>
  </div>
</div>
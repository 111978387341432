import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders, HttpErrorResponse } from "@angular/common/http";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { FormControl, FormGroup } from '@angular/forms';
import { ApiService } from './../../../services/ApiService';
import { AppService } from './../../../services/AppService';
import { AuthService } from './../../../services/AuthService';
import { Config } from '../../../config/config';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as moment from 'moment-timezone';
import { Angular5Csv } from 'angular5-csv/dist/Angular5-csv';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-delivery-report',
  templateUrl: './delivery-report.component.html',
  styleUrls: ['./delivery-report.component.css'],
})

export class DeliveryReportComponent implements OnInit {
  @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns = ['employee','status','customer','order id','pickup time','delivery time','delivery location'];
  dataSource: MatTableDataSource<any>;
  isDeleteModel = false;
  deleteItem: any;
  employeeList: any;
  range = new FormGroup({
    start: new FormControl(new Date()),
    end: new FormControl(new Date())
  });
  employeeID: any = "all";
  locationEmployeeList: any;
  SelectLang: string;
  EmpReq: string;
  DateReq: string;
  Close: string;
  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private loader: NgxSpinnerService,
    private toastr: ToastrService,
    private authService: AuthService,
    public appService: AppService,
    public apiService: ApiService,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    public translate: TranslateService
  ) { }
  
  ngOnInit() {
    this.SelectLang = localStorage.getItem("language");
    this.translate.get('Please select employee from list').subscribe((text: string) => {
      this.EmpReq= text
    });
    this.translate.get('Please select an appropriate date range').subscribe((text: string) => {
      this.DateReq = text
    });
    this.translate.get('Close').subscribe((text: string) => {
      this.Close = text
    });
    this.translate.use(this.SelectLang);
    this.apiService
      .userActiveLists()
      .subscribe((response: any) => {
        this.employeeList = response.data;
      });
    let payload = {
      data: {
        start_date: this.range.value.start,
        end_date: this.range.value.end,
        emp_id: this.employeeID,
      }
    }
    this.apiService
      .locationEmployee(payload, this.employeeID)
      .subscribe((response: any) => {
        this.locationEmployeeList = response.data.visit_list;
        this.dataSource = new MatTableDataSource(this.locationEmployeeList);
        setTimeout(() => this.dataSource.paginator = this.paginator);
        this.dataSource.sort = this.sort;
      });
  }
  switchLang(lang: string) {
    localStorage.setItem("language",lang)
    this.translate.use(lang);
  }
  onClose(){
    this.deleteItem = undefined;
    this.isDeleteModel = false;
  }

  filterData(){
    let startDate = moment(this.range.value.start).format("YYYY-MM-DD HH:mm:ss");
    let endDate = moment(this.range.value.end).format("YYYY-MM-DD HH:mm:ss");
    if(this.employeeID == undefined || this.employeeID == ''){
      this.snackBar.open(this.EmpReq,this.Close,{
        duration: 4000,
        verticalPosition:'bottom'
      })
      return 0;
    }
    if(this.range.value.start == undefined || this.range.value.start == '' ){
      this.snackBar.open(this.DateReq,this.Close,{
        duration: 4000,
        verticalPosition:'bottom'
      })
      return 0;
    }
    if(this.range.value.end == undefined || this.range.value.end == '' ){
      this.snackBar.open(this.DateReq,this.Close,{
        duration: 4000,
        verticalPosition:'bottom'
      })
      return 0;
    }
    let payload = {
      data: {
        start_date: startDate,
        end_date: endDate,
        emp_id: this.employeeID,
      }
    }
    this.loader.show();
    this.apiService
      .locationEmployee(payload, this.employeeID)
      .subscribe((response: any) => {
        this.locationEmployeeList = response.data.visit_list;
        this.dataSource = new MatTableDataSource(this.locationEmployeeList);
        setTimeout(() => this.dataSource.paginator = this.paginator);
        this.loader.hide();
      });
  }
  downloadCustomertable() {
    var ary: any = [];
    for (var i = 0; i < this.locationEmployeeList.length; i++) {
      ary.push({
        employee: this.locationEmployeeList[i].task['allocated_emp'],
        customer: this.locationEmployeeList[i].task['receiver_name'],
        order_id: this.locationEmployeeList[i].task['order_id'],
        pickup_time: this.locationEmployeeList[i].task['picktime'],
        delivery_time: this.locationEmployeeList[i].task['schedule_date_time'],
        delivery_location: this.locationEmployeeList[i].task['cust_address'],
        status: this.locationEmployeeList[i].task['status'],
      });
    }
    var options = {
      showLabels: true, 
      showTitle: true,
      title: 'Customer Delivery Report',
      headers: [
        'Employee Name',
        'Customer',
        'Order ID',
        'Pickup Time',
        'Delivery Time',
        'Delivery Location',
        'Status',
      ],
      nullToEmptyString: true,
    };
    new Angular5Csv(ary, `DeliveryReport ${new Date()}`, options);
  }
  ApplyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}

<div class="container-fluid" style="margin-top: 10px;">
  <div class="col text-left">
    <h5 class="mb-4 text-muted"><b>{{'Mileage Report' | translate}} </b></h5>
  </div>
</div>
<div class="container-fluid">
  <div class="card-body">
    <div class="row px-3 py-0">
      <div class="col-12 bg-light py-3">
        <div class="row justify-content-between">
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-12 col-lg-auto" style="margin-bottom: 10px;">
                <mat-form-field style="width:100%;">
                  <mat-label>{{'Select Delivery Agent' | translate}}</mat-label>
                  <mat-select [(ngModel)]="employeeID">
                    <mat-option value="all">{{'All Delivery Agents' | translate}}</mat-option>
                    <mat-option *ngFor="let list of employeeList" [value]="list.emp_id">{{list.first_name}} {{list.last_name}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-md-12 col-lg-auto" style="margin-bottom: 10px;">
                <mat-form-field style="width:100%;">
                  <mat-label>{{'Select Date Range' | translate}}</mat-label>
                  <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
                    <input matStartDate formControlName="start" placeholder="Start date">
                    <input matEndDate formControlName="end" placeholder="End date">
                  </mat-date-range-input>
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-date-range-picker #picker></mat-date-range-picker>
                  <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">{{'Invalid start date' | translate}}</mat-error>
                  <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">{{'Invalid end date' | translate}}</mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-12 col-lg-auto" style="margin-bottom: 10px;">
                <input type="text" class="form-control" style="margin-top: 10px;" placeholder="{{'Order ID' | translate}}"  [(ngModel)]="orderID">
              </div>
              <div class="col-md-12 col-lg-auto" style="margin-bottom: 10px;">
                <button mat-raised-button style="margin-top: 10px;" color="primary" class="mr-2" (click)="filterData()"><b>{{'Filter' | translate}}</b></button>
                <button mat-raised-button style="margin-top: 10px;" color="primary" matTooltip="Export as CSV" (click)="downloadCustomertable()"><b>{{'Export' | translate}}</b></button>
              </div>
            </div> 
          </div>
          <div style="width:100%;">
            <div class="input-group mb-3" style="width: 30%;float: right;">
              <input type="text" class="form-control" (keyup)="ApplyFilter($event)" placeholder="{{'Search' | translate}}" aria-controls="example">
              <div class="input-group-append">
                <span class="fa fa-search input-group-text pointer"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="table-responsive">
      <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8" style="width: 100%;border: 1px solid #ccc;">
        <!-- Position Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef  mat-sort-header style="background-color:#1e9e95; color: white; font-size: 15px;"> {{'Delivery Agent' | translate}} </th>
          <td mat-cell *matCellDef="let element; let i = index"> {{element.first_name}} {{element.last_name}} </td>
        </ng-container>

        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef mat-sort-header style="background-color:#1e9e95; color: white; font-size: 15px;"> {{'Order ID' | translate}} </th>
          <td mat-cell *matCellDef="let element; let i = index"> {{element.order_id}} </td>
        </ng-container>
        <!-- Name Column -->
        <ng-container matColumnDef="start date">
          <th mat-header-cell *matHeaderCellDef mat-sort-header style="background-color:#1e9e95; color: white; font-size: 15px;"> {{'Start Date' | translate}} </th>
          <td mat-cell *matCellDef="let element"> {{element.picktime | date :'short'}} </td>
        </ng-container>
        <!-- Date Column -->
        <ng-container matColumnDef="end date">
          <th mat-header-cell *matHeaderCellDef mat-sort-header style="background-color:#1e9e95; color: white; font-size: 15px;"> {{'End Date' | translate}} </th>
          <td mat-cell *matCellDef="let element"> {{element.schedule_date_time | date :'short'}} </td>
        </ng-container>
        <!-- Date Column -->
        <ng-container matColumnDef="distance">
          <th mat-header-cell *matHeaderCellDef mat-sort-header style="background-color:#1e9e95; color: white; font-size: 15px;"> {{'Distance Covered' | translate}} </th>
          <td mat-cell *matCellDef="let element"> {{element.distance}} </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      <div style="padding-top: 10px;" *ngIf="dataSource?.filteredData.length === 0"> 
        <p class="text-center">{{'No Records Found!' | translate}}</p><hr>
      </div>
      <mat-paginator #paginator [pageSizeOptions]="[10, 25, 50, 100]"></mat-paginator>
    </div>
  </div>
</div>


import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders, HttpErrorResponse } from "@angular/common/http";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { FormGroup } from '@angular/forms';
import { ApiService } from './../../../services/ApiService';
import { AppService } from './../../../services/AppService';
import { AuthService } from './../../../services/AuthService';
import { Config } from '../../../config/config';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css'],
})
export class ChangePasswordComponent implements OnInit {
  OldPassword: any;
  NewPassword: any;
  ConfPassword: any;

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private loader: NgxSpinnerService,
    private toastr: ToastrService,
    private authService: AuthService,
    public appService: AppService,
    public apiService: ApiService,
    private snackBar:MatSnackBar,
  ) {}

  baseUrl = Config.BASE_URL;
  apiUrl = this.baseUrl + "api/";

  ngOnInit() {
  }

  ChangePassword(){

    if(this.OldPassword == undefined || this.OldPassword == ''){
      this.snackBar.open("Old Password is Required","Close",{
        duration: 4000,
        verticalPosition:'bottom'
      })
      return 0;
    }

    if(this.NewPassword == undefined || this.NewPassword == ''){
      this.snackBar.open("New Password is Required","Close",{
        duration: 4000,
        verticalPosition:'bottom'
      })
      return 0;
    }

    if(this.ConfPassword == undefined || this.ConfPassword == ''){
      this.snackBar.open("Confirm Password is Required","Close",{
        duration: 4000,
        verticalPosition:'bottom'
      })
      return 0;
    }

    
    if(this.NewPassword != this.ConfPassword){
      this.snackBar.open("New Password and Confirm Password does not Match","Close",{
        duration: 4000,
        verticalPosition:'bottom'
      })
      return 0;
    }


    var data = {
      "data":{
        "old_password":this.OldPassword,
        "new_password":this.NewPassword,
        "confirm_password":this.ConfPassword
      }
    }
    this.http.put(this.apiUrl + "change_password_emp",data).subscribe((response: any) => {
      // this.Userlist = response.data;
      if(response.status == "ok"){
        this.toastr.success(response.data);
      this.loader.hide();
      this.router.navigate(["profile"]);
      }else{
        this.toastr.warning(response.data);
      this.loader.hide();
      }
    })
  }
  old_password() {
    let x = document.getElementById("old-password") as HTMLInputElement;
    if (x.type == "password") {
      document.getElementById("old-eye-open").className = "fa fa-sm fa-eye field-icon toggle-password old-view-hide";
      x.type = "text";
    } else {
      document.getElementById("old-eye-open").className = "fa fa-sm fa-eye-slash field-icon toggle-password old-view-hide";
      x.type = "password";
    }
  }
  new_password() {
    let x = document.getElementById("new-password") as HTMLInputElement;
    if (x.type == "password") {
      document.getElementById("new-eye-open").className = "fa fa-sm fa-eye field-icon toggle-password new-view-hide";
      x.type = "text";
    } else {
      document.getElementById("new-eye-open").className = "fa fa-sm fa-eye-slash field-icon toggle-password new-view-hide";
      x.type = "password";
    }
  }
  confirm_password() {
    let x = document.getElementById("confirm-password") as HTMLInputElement;
    if (x.type == "password") {
      document.getElementById("confirm-eye-open").className = "fa fa-sm fa-eye field-icon toggle-password same-view-hide";
      x.type = "text";
    } else {
      document.getElementById("confirm-eye-open").className = "fa fa-sm fa-eye-slash field-icon toggle-password same-view-hide";
      x.type = "password";
    }
  }
  
  Cancel(){
    this.router.navigate(["profile-edit"]);
  }
}

import { Component, Inject } from '@angular/core';
import { ApiService } from 'src/app/services/ApiService';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BulkOrderPopComponent } from '../bulk-order-pop/bulk-order-pop.component';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
import { Router, ActivatedRoute } from '@angular/router';
import * as FileSaver from 'file-saver';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core'


@Component({
  selector: 'app-add-bulk-order',
  templateUrl: './add-bulk-order.component.html',
  styleUrls: ['./add-bulk-order.component.css']
})
export class AddBulkOrderComponent {

  fileName: string = '';
  ResData: any;
  Status: any = '';
  xlsxFile: any = '../../../assets/bulkupload.xlsx';

  constructor(
    public apiService: ApiService,
    public dialog: MatDialog,
    private loader: NgxSpinnerService,
    private toastr: ToastrService,
    private router: Router,
    public translate: TranslateService

  ) { }

  onFileChange(evt: any) {
    /* wire up file reader */
    if (evt.target.files[0]?.type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      this.fileName = evt.target.files[0]?.name
      console.log('type', evt.target.files[0])
      const formData = new FormData();
      formData.append('formFile', evt.target.files[0]);
      this.FileUpload(formData)
    } else {
      this.toastr.error('Invalid file format (Accept format only xlsx)');
    }
  }

  FileUpload(data: any) {
    this.loader.show();
    this.apiService
      .AddBulkUpload(data)
      .subscribe((response: any) => {
        this.loader.hide();
        console.log('response',response)
        if (response?.status == 'error') {
          this.toastr.error(response?.data);
          document.getElementById('FileId').innerHTML = '';
          this.fileName = '';
        } else {
          this.toastr.success(response?.data?.message);
          this.router.navigate(["/dashboard"]);
        }
      }, ((err) => {
        this.loader.hide();
        console.log('error',err.error)
        if (this.ResData = err.error.data.length !== 0) {
          this.Status = 'failure';
          this.ResData = err.error.data;
          this.ViewPop();
          this.fileName = '';
        } else {
          this.toastr.error('No Data found');
          this.fileName = '';
        }
      }));
  }

  ViewPop() {
    const dialogRef = this.dialog.open(BulkOrderPopComponent, {
      data: {
        data: this.ResData,
        status: this.Status
      }
    });
    dialogRef.afterClosed().subscribe(result => {
    })
  }

  downloadFile() {
    FileSaver.saveAs(this.xlsxFile, "mt-sampleUploadFile");
  }

}

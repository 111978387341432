import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  isSidebarPinned = true;
  isSidebarToggeled = false;

  constructor() { }
  toggleSidebar() {
    this.isSidebarToggeled = ! this.isSidebarToggeled;
  }
  toggleSidebarPin() {
    this.isSidebarPinned = ! this.isSidebarPinned;
  }
  getSidebarStat() {
    return {
      isSidebarPinned: this.isSidebarPinned,
      isSidebarToggeled: this.isSidebarToggeled
    }
  }
  // getRandomString() {
  //   let myString = `w2s-mt-(${Math.random().toString(36).slice(2)})`;
  //   return myString;
  // }
  getRandomString(length){
    var genString = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for( var i = 0; i < length; i++ ){
      genString += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return genString;
  }
  getClientData() {
    let myString = localStorage.getItem("temp_client_data");
    return myString;
  }
}

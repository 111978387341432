<div class="row">
  <div class="col-lg-4 col-md-12" *ngIf="!isLeftArrow" style="padding: 0;">
    <span class="form-inline nav-link" style="background-color: #2e6371;">
      <label
        style="float: left;margin: 0px 12px 0px 0px;font-weight: bold;color: #fff;margin: 5px 10px 0 89px; ">{{'Select Language' | translate}}</label>
      <select class="form-control" #selectedLang style="width: 30%;" [(ngModel)]="SelectLang"
        (change)="switchLang(selectedLang.value)">
        <option *ngFor="let language of translate.getLangs()" [value]="language"
          [selected]="language === translate.currentLang">
          {{ language }}
        </option>
      </select>
    </span>
    <div style="background-color:#2e6371">
      <div class="row" style="padding: 15px 0 0 5px;">
        <div class="col-3 right_align">
          <h4 class="mb-4 text-muted" style="color: #fff !important;font-size: 20px;margin-top: 6px;">
            <b>{{'Orders' | translate}}</b> </h4>
        </div>
        <div class="col-9 left_align">
          <div class="dasboard_Order_Btn">
          <button type="button" *ngIf="!isExpired" class="btn btn-sm btn-info rounded-0 mb-2 ml-3 mr-3" [routerLink]="['/add-order']"
            routerLinkActive="router-link-active"
            style="background-color: #4ea21f !important;border-radius: 3px !important;border: none !important;"
            (click)="emitDashboard()"><b>{{'Add Single Order' | translate}}</b></button>
            <button type="button" *ngIf="!isExpired" class="btn btn-sm btn-info rounded-0 mb-2" [routerLink]="['/add-bulk-order']"
            style="background-color: #005af0 !important;border-radius: 3px !important;border: none !important;"
            (click)="emitDashboard()" ><b>{{'Add Bulk Order' | translate}}</b></button>
          </div>
          <span class="badge badge-danger text-right"
            *ngIf="userData?.profile?.is_auto_allocation_enable == 1">{{'Auto allocation is enabled' | translate}}</span>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="card-body" style="height:32px;padding: 0 0 0 5px;">
            <mat-form-field color="primary" style="width: 100%;margin: -13px 0 0 0;">
              <input matInput [matDatepicker]="picker2" (dateChange)="changeDate($event)" readonly
                [(ngModel)]="isTaskDate">
              <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
              <mat-datepicker #picker2 color="primary" style="height: 40px;"></mat-datepicker>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
    <mat-tab-group mat-stretch-tabs style="height: 450px;">
      <mat-tab>
        <ng-template mat-tab-label>
          <span class="tab-number tab-red" style="color:#fff"><b
              style="font-size: 20px;">{{unallocated.length}}</b></span>
          <span class="tab-text tab-red" style="color: #fff;margin-bottom: 10px;"><b
              style="font-size: 15px;">{{'Unallocated' | translate}}</b></span>
        </ng-template>
        <div class="row" style="margin-top: 0.5rem;">
          <mat-accordion multi style="width: 100%;">
            <mat-expansion-panel (opened)="panelOpenState = true" style="margin: 2px 10px 7px 9px;border-radius: 3px;"
              (closed)="panelOpenState = false" *ngFor="let unallocate of unallocated;let i=index;">
              <mat-expansion-panel-header style="height:103px;padding: 15px 12px 60px 12px;">
                <mat-panel-title style="margin-right:0px;">
                  <span (click)="OrderDetails(unallocate.resource.id)">
                    <b style="color: #3b9e95;font-size: 18px;">{{'Order ID' | translate}} :
                      {{unallocate.resource.id}}</b>
                  </span><br><br>
                  <div class="task__info">
                    <b style="position: absolute;top: 43px;color: #707070;font-size: 14px;left:12px;">
                      {{'Customer Name' | translate}} : {{unallocate.order[0].receiver_name}}
                      <b class="overMe" style="color: #3b9e95;font-size: 13px;"
                        popover="{{callForStillMore(unallocate.order,'popOver')}}" popoverPlacement="left"
                        [popoverOnHover]="true" [popoverCloseOnMouseOutside]="true"
                        *ngIf="unallocate.resource.delivery_logic == 1">{{callForStillMore(unallocate.order,'isStill')}}</b>
                      <!-- <ng-container *ngFor="let list of unallocate.order;let j=index;">
                            <ng-container *ngIf="j>0">
                              {{list.receiver_name}}<ng-container *ngIf="unallocate.order.length !== j+1">,</ng-container>
                            </ng-container>
                          </ng-container> -->
                    </b>
                    <b style="position: absolute;top: 72px;font-size: 15px;left:12px;">
                      {{'Number of Tasks' | translate}} : {{unallocate.order.length - 1}}
                    </b>
                  </div>
                </mat-panel-title>
                <mat-panel-description class="header_icon">
                  <div class="allocated">
                    <span class="mr-2" matTooltip="Edit Order" [matTooltipPosition]="'above'"
                      (click)="editOrder(unallocate)" *ngIf="!isExpired">
                      <!-- <i class="fas fa-pencil-alt text-danger"></i> -->
                      <img src="./assets/img/action-edit.png" alt="Task Edit"
                        style="height: 20px;width: 20px;margin-right:10px;">
                    </span>
                    <b style="color: #000;font-size: 14px;">{{unallocate.resource.status}}</b>
                  </div>
                  <span class="track_img">
                    <img *ngIf="unallocate.resource.delivery_logic == 1"
                      src="./assets/img/single-pickup-to-multidelivery.png" alt="Task Edit" class="dellogic">
                    <img *ngIf="unallocate.resource.delivery_logic == 2"
                      src="./assets/img/multipickup-to-single-delivery.png" alt="Task Edit" class="dellogic">
                    <img *ngIf="unallocate.resource.delivery_logic == 3"
                      src="./assets/img/single-pickup-to-single-delivery.png" alt="Task Edit" class="dellogic">
                  </span>
                </mat-panel-description>
              </mat-expansion-panel-header>
              <ul class="timeline timeline-grey" style="margin-left: 15px;">
                <li [ngClass]="{'dot-green': obj.isFor == 'Delivery','dot-red': obj.isFor == 'Pickup'}"
                  *ngFor="let obj of unallocate.order;let j=index;">
                  <p class="id_per"
                    *ngIf="unallocate.resource.delivery_logic != 1 && unallocate.resource.delivery_logic != 3 && obj.isFor != 'Delivery'"
                    style="position: absolute;
                    margin: 0px 0px 0px -12%;font-size: 13px;font-weight: bold;">{{obj.id}}</p>
                  <p class="id_per"
                    *ngIf="unallocate.resource.delivery_logic != 2 && unallocate.resource.delivery_logic != 3 && obj.isFor != 'Pickup'"
                    style="position: absolute;
                    margin: 0px 0px 0px -12%;font-size: 13px;font-weight: bold;">{{obj.id}}</p>
                  <p class="id_per"
                    *ngIf="unallocate.resource.delivery_logic != 1 && unallocate.resource.delivery_logic != 2 && obj.isFor != 'Pickup'"
                    style="position: absolute;
                    margin: 0px 0px 0px -12%;font-size: 13px;font-weight: bold;">{{obj.id}}</p>
                  <p style="margin-top: 0;
                    margin-bottom: 1rem;
                    padding-left: 33px;font-size: 13px;font-weight: bold;color: #707070;">
                    {{obj.isFor == 'Delivery' ? obj.cust_address: obj.pick_address}}</p>
                </li>
              </ul>
            </mat-expansion-panel>
            <div class="row" *ngIf="unallocated.length == 0">
              <img class="notask" src="./assets/img/collections/add-task.png" alt="no task"><br>
            </div>
            <span *ngIf="!isExpired">
              <button type="button" *ngIf="unallocated.length == 0" class="btn btn-sm btn-info rounded-0 mb-2 mr-2"
                [routerLink]="['/add-order']" routerLinkActive="router-link-active"
                style="background-color: #4ea21f !important;border-radius: 3px !important;border: none !important;    margin: 11% 0 0 36%;"
                (click)="emitDashboard()"><b>{{'Add New Order' | translate}}</b></button>
            </span>
          </mat-accordion>
        </div>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <span class="tab-number tab-blue" style="color: #fff;"><b
              style="font-size: 20px;">{{allocated.length}}</b></span>
          <span class="tab-text tab-blue" style="color: #fff;margin-bottom: 10px;"><b
              style="font-size: 15px;">{{'Allocated' | translate}}</b></span>
        </ng-template>
        <div class="row" style="margin-top: 0.5rem;">
          <mat-accordion multi style="width: 100%;">
            <mat-expansion-panel (opened)="panelOpenState = true" style="margin: 2px 10px 7px 9px;border-radius: 3px;"
              (closed)="panelOpenState = false" *ngFor="let allocate of allocated;let i=index;">
              <mat-expansion-panel-header style="height:103px;padding: 15px 12px 60px 12px;">
                <mat-panel-title>
                  <span (click)="OrderDetails(allocate.resource.id)">
                    <b style="color: #3b9e95;font-size: 18px;">{{'Order ID' | translate}} : {{allocate.resource.id}}</b>
                  </span><br><br>
                  <div class="task__info">
                    <b style="position: absolute;top: 43px;color: #707070;font-size: 14px;left:12px;">
                      {{'Customer Name' | translate}} : {{allocate.order[0].receiver_name}}
                      <b class="overMe" style="color: #3b9e95;font-size: 13px;"
                        popover="{{callForStillMore(allocate.order,'popOver')}}" popoverPlacement="left"
                        [popoverOnHover]="true" [popoverCloseOnMouseOutside]="true"
                        *ngIf="allocate.resource.delivery_logic == 1">{{callForStillMore(allocate.order,'isStill')}}</b>
                    </b>
                    <b style="position: absolute;top: 72px;font-size: 15px;left:12px;">
                      {{'Number of Tasks' | translate}} : {{allocate.order.length - 1}}
                    </b>

                  </div>

                </mat-panel-title>
                <mat-panel-description>
                  <div class="allocated">
                    <span class="mr-2" matTooltip="Edit Order" [matTooltipPosition]="'above'"
                      (click)="editOrder(allocate)" *ngIf="!isExpired">
                      <!-- <i class="fas fa-pencil-alt text-danger"></i> -->
                      <img src="./assets/img/action-edit.png" alt="Task Edit" style="height: 20px;width: 20px;">
                    </span>
                    <b style="color: #000;font-size: 14px;">{{allocate.resource.status}}</b>

                  </div>

                  <span>
                    <img *ngIf="allocate.resource.delivery_logic == 1"
                      src="./assets/img/single-pickup-to-multidelivery.png" alt="Task Edit" class="dellogic">
                    <img *ngIf="allocate.resource.delivery_logic == 2"
                      src="./assets/img/multipickup-to-single-delivery.png" alt="Task Edit" class="dellogic">
                    <img *ngIf="allocate.resource.delivery_logic == 3"
                      src="./assets/img/single-pickup-to-single-delivery.png" alt="Task Edit" class="dellogic">
                  </span>
                </mat-panel-description>
              </mat-expansion-panel-header>
              <ul class="timeline timeline-red" style="margin-left: 15px;">
                <li [ngClass]="{'dot-green': obj.isFor == 'Delivery','dot-red': obj.isFor == 'Pickup'}"
                  *ngFor="let obj of allocate.order;let j=index;">
                  <p class="id_per"
                    *ngIf="allocate.resource.delivery_logic != 1 && allocate.resource.delivery_logic != 3 && obj.isFor != 'Delivery'"
                    style="position: absolute;
                    margin: 0px 0px 0px -12%;font-size: 13px;font-weight: bold;">{{obj.id}}</p>
                  <p class="id_per"
                    *ngIf="allocate.resource.delivery_logic != 2 && allocate.resource.delivery_logic != 3 && obj.isFor != 'Pickup'"
                    style="position: absolute;
                    margin: 0px 0px 0px -12%;font-size: 13px;font-weight: bold;">{{obj.id}}</p>
                  <p class="id_per"
                    *ngIf="allocate.resource.delivery_logic != 1 && allocate.resource.delivery_logic != 2 && obj.isFor != 'Pickup'"
                    style="position: absolute;
                    margin: 0px 0px 0px -12%;font-size: 13px;font-weight: bold;">{{obj.id}}</p>
                  <p style="margin-top: 0;
                    margin-bottom: 1rem;
                    padding-left: 33px;font-size: 13px;font-weight: bold;color: #707070;">
                    {{obj.isFor == 'Delivery' ? obj.cust_address: obj.pick_address}}</p>
                </li>
              </ul>
            </mat-expansion-panel>
            <div class="row" *ngIf="allocated.length == 0">
              <img class="notask" src="./assets/img/collections/add-task.png" alt="no task"><br>
            </div>
            <span *ngIf="!isExpired">
              <button type="button" *ngIf="allocated.length == 0" class="btn btn-sm btn-info rounded-0 mb-2 mr-2"
                [routerLink]="['/add-order']" routerLinkActive="router-link-active"
                style="background-color: #4ea21f !important;border-radius: 3px !important;border: none !important;    margin: 11% 0 0 36%;"
                (click)="emitDashboard()"><b>{{'Add New Order' | translate}}</b></button>
            </span>
          </mat-accordion>
        </div>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <span class="tab-number tab-green" style="color: #fff;"><b
              style="font-size: 20px;">{{completed.length}}</b></span>
          <span class="tab-text tab-green" style="color: #fff;margin-bottom: 10px;"><b
              style="font-size: 15px;">{{'Completed' | translate}}</b></span>
        </ng-template>
        <div class="row" style="margin-top: 0.5rem;">
          <mat-accordion multi style="width: 100%;">
            <mat-expansion-panel (opened)="panelOpenState = true" style="margin: 2px 10px 7px 9px;border-radius: 3px;"
              (closed)="panelOpenState = false" *ngFor="let complete of completed;let i=index;">
              <mat-expansion-panel-header style="height:103px;padding: 15px 12px 60px 12px;">
                <mat-panel-title>
                  <span (click)="OrderDetails(complete.resource.id)">
                    <b style="color: #3b9e95;font-size: 18px;">{{'Order ID' | translate}} : {{complete.resource.id}}</b>
                  </span><br><br>
                  <div class="task__info">
                    <b style="position: absolute;top:43px;color: #707070;font-size: 14px;left:12px;">
                      {{'Customer Name' | translate}} : {{complete.order[0].receiver_name}}
                      <b class="overMe" style="color: #3b9e95;font-size: 13px;"
                        popover="{{callForStillMore(complete.order,'popOver')}}" popoverPlacement="left"
                        [popoverOnHover]="true" [popoverCloseOnMouseOutside]="true"
                        *ngIf="complete.resource.delivery_logic == 1">{{callForStillMore(complete.order,'isStill')}}</b>
                    </b>
                    <b style="position: absolute;top: 72px;font-size: 15px;left:12px;">
                      {{'Number of Tasks' | translate}} : {{complete.order.length - 1}}
                    </b>

                  </div>


                </mat-panel-title>
                <mat-panel-description>
                  <b style="color: #000;font-size: 14px;">{{complete.resource.status}}</b>
                  <!-- <span class="mr-2" matTooltip="Edit Order" [matTooltipPosition]="'above'" (click)="editOrder(complete)">
                      <img src="./assets/img/action-edit.png" alt="Task Edit" style="height: 20px;width: 20px;">
                    </span> -->
                  <span>
                    <img *ngIf="complete.resource.delivery_logic == 1"
                      src="./assets/img/single-pickup-to-multidelivery.png" alt="Task Edit" class="dellogic">
                    <img *ngIf="complete.resource.delivery_logic == 2"
                      src="./assets/img/multipickup-to-single-delivery.png" alt="Task Edit" class="dellogic">
                    <img *ngIf="complete.resource.delivery_logic == 3"
                      src="./assets/img/single-pickup-to-single-delivery.png" alt="Task Edit" class="dellogic">
                  </span>
                </mat-panel-description>
              </mat-expansion-panel-header>
              <ul class="timeline timeline-grey" style="margin-left: 15px">
                <li [ngClass]="{'dot-green': obj.isFor == 'Delivery','dot-red': obj.isFor == 'Pickup'}"
                  *ngFor="let obj of complete.order;let j=index;">
                  <p class="id_per"
                    *ngIf="complete.resource.delivery_logic != 1 && complete.resource.delivery_logic != 3 && obj.isFor != 'Delivery'"
                    style="position: absolute;
                    margin: 0px 0px 0px -12%;font-size: 13px;font-weight: bold;">{{obj.id}}</p>
                  <p class="id_per"
                    *ngIf="complete.resource.delivery_logic != 2 && complete.resource.delivery_logic != 3 && obj.isFor != 'Pickup'"
                    style="position: absolute;
                    margin: 0px 0px 0px -12%;font-size: 13px;font-weight: bold;">{{obj.id}}</p>
                  <p class="id_per"
                    *ngIf="complete.resource.delivery_logic != 1 && complete.resource.delivery_logic != 2 && obj.isFor != 'Pickup'"
                    style="position: absolute;
                    margin: 0px 0px 0px -12%;font-size: 13px;font-weight: bold;">{{obj.id}}</p>
                  <p style="margin-top: 0;
                    margin-bottom: 1rem;
                    padding-left: 33px;font-size: 13px;font-weight: bold;color: #707070;">
                    {{obj.isFor == 'Delivery' ? obj.cust_address: obj.pick_address}}</p>
                </li>
              </ul>
            </mat-expansion-panel>
            <div class="row" *ngIf="completed.length == 0">
              <img class="notask" src="./assets/img/collections/add-task.png" alt="no task"><br>
            </div>
            <span *ngIf="!isExpired">
              <button type="button" *ngIf="completed.length == 0" class="btn btn-sm btn-info rounded-0 mb-2 mr-2"
                [routerLink]="['/add-order']" routerLinkActive="router-link-active"
                style="background-color: #4ea21f !important;border-radius: 3px !important;border: none !important;    margin: 11% 0 0 36%;"
                (click)="emitDashboard()"><b>{{'Add New Order' | translate}}</b></button>
            </span>
          </mat-accordion>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
  <div class="col-lg-8 col-md-12" style="padding: 0px !important;height: 300px;"
    [ngClass]="{'col-lg-8 col-md-12': isLeftArrow || isRightArrow,'col-lg-12 col-md-12': isLeftArrow && isRightArrow}">
    <div class="map-marker" style="height: 400px !important;">
      <div class="overlay-arrow-left">
        <span class="btn btn-sm btn-dark novisible" (click)="leftArrow()"><b *ngIf="isLeftArrow">Orders </b><i
            class="fas"
            [ngClass]="{'fa-angle-double-left': !isLeftArrow,'fa-angle-double-right': isLeftArrow}"></i></span>
      </div>
      <!-- <div class="overlay-arrow-right">
          <span class="btn btn-sm btn-dark" (click)="rightArrow()"><i class="fas" [ngClass]="{'fa-angle-double-right': !isRightArrow,'fa-angle-double-left': isRightArrow}"></i></span>
        </div> -->
      <!-- <agm-map [latitude]="latitude" [longitude]="longitude" [scrollwheel]="true" [zoom]="zoom">
          <agm-direction [origin]="origin" [destination]="destination">
          </agm-direction>
          <agm-marker [latitude]="latitude" [longitude]="longitude"></agm-marker>
        </agm-map> -->
      <app-map-part [resource]="onlineList" [fromWhere]="'dashboard'" (changeResource)="reback($event)"
        *ngIf="onlineList"></app-map-part>
      <div class="bg-white"><br>
        <div class="row">
          <div class="col-lg-3 col-md-12" style="padding: 0px 0px 0px 15px;max-width: 20%;">
            <h5 style="font-size: 17px;font-weight: bold;margin: 8px 0 0 0;">{{'Delivery Agents' | translate}}</h5>
          </div>
          <div class="col-lg-6 col-md-12" style="padding:0px;">
            <div class="row">
              <div class="col-lg-6 col-md-12 mv-groupbtn" style="padding-left: 0px;">
                <div class="btn-group busyfree-mv" role="group" aria-label="Basic example">
                  <button type="button" class="btn btn-sm"
                    [ngClass]="{'btn-info': isAvailability == 'busy','btn-outline-info': isAvailability == 'free' || isAvailability == 'all'}"
                    (click)="availability('busy')"
                    style="border: 1.5px solid #3b9e95;font-weight: bold;">{{(userlist | filter : 'isUserFor' : 'busy' : 'equal').length}}
                    {{'Busy' | translate}}</button>
                  <button type="button" class="btn btn-sm"
                    [ngClass]="{'btn-info': isAvailability == 'free','btn-outline-info': isAvailability == 'busy' || isAvailability == 'all'}"
                    (click)="availability('free')"
                    style="border: 1.5px solid #3b9e95;font-weight: bold;">{{(userlist | filter : 'isUserFor' : 'free' : 'equal').length ? (userlist | filter : 'isUserFor' : 'free' : 'equal').length : (userlist | filter : 'isUserFor' : 'all' : 'equal').length}}
                    {{'Free' | translate}}</button>
                </div>
              </div>
              <div class="col-lg-6 col-md-12" style="padding:0px;">
                <div class="form-group mv-check" style="padding: 5px 0px;display: flex;">
                  <mat-checkbox style="display: inline-block;width: 10%;margin-right: unset !important;" #check
                    color='primary' [(ngModel)]='isViewAll' (change)="availability('all')" class="mr-2"></mat-checkbox>
                  <label style="display: inline-block;width: 90%;"
                    for="check">{{'Check to View All Agents' | translate}}</label>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-12 mv-addbtn" *ngIf="!isExpired"
            style="padding: 0px 10px 10px 10px;max-width: 24%;">
            <button type="button" class="btn btn-sm btn-info addempbtn-mv" [routerLink]="['/delivery-agent']"
              routerLinkActive="router-link-active" style="font-weight:bold;float:right;"
              *ngIf="ButtonShow == true">{{'Add Delivery Agent' | translate}}</button>
          </div>
        </div>
        <div class="row">
          <div class="col-12 scrollmenu">
            <div class="scrollmenu">
              <ng-container *ngIf="userlist && userlist.length >0">
                <ul class="nav nav-tabs border-bottom">
                  <li style="display: inline-flex;padding-bottom: 8px;">
                    <ng-container *ngFor="let list of userlist; let i=index;">
                      <ng-container *ngIf="isAvailability == list.isUserFor || isViewAll">
                        <div class="custom-card">
                          <div class="card-body" style="cursor: pointer;"
                            (click)="driverDetails(list.id,list.emp_id,list.isUserFor)">
                            <div class="row">
                              <div class="col-3" style="padding-right: 15px;">
                                <!-- <img src="https://sfaprojectmanageteamz.s3.ap-south-1.amazonaws.com/uploads/qa_multi_delivery/iPlQuXIfiay1On5YcJpxHdiByQauQl1kXmSp6IAq.png" class="profile-img" alt="Avatar"> -->
                                <div class="avatar-circle" *ngIf="list.profile_image.length === 2">
                                  <span class="initials">{{list?.first_name[0] | uppercase }}</span>
                                </div>
                                <div class="avatar-circle-img" *ngIf="list.profile_image.length > 2" [ngStyle]="{'background-image': 'url(' + list.profile_image + ')', 
                                    'background-size': 'cover', 'background-position': 'center'}">
                                </div>
                              </div>
                              <div class="col-6" style="padding-right: 15px;">
                                <h6 class="pointer" style="color: #000;cursor: pointer;font-weight: bold;"
                                  matTooltip="{{list.first_name}} {{list.last_name}}" [matTooltipPosition]="'above'">
                                  {{(nameConcat(list.first_name,list.last_name) ? nameConcat(list.first_name,list.last_name) : '') | slice:0:15}}
                                  <ng-container *ngIf="nameConcat(list.first_name,list.last_name)">
                                    {{nameConcat(list.first_name,list.last_name).length > 15 ? '...' : ''}}
                                  </ng-container>
                                </h6>
                                <h6 style="color: #707070;font-weight: bold;">{{list.phone}}</h6>
                              </div>
                              <div class="col-3 bg-light" style="border-radius: 8px;padding-right: 15px;">
                                <h6 class="text-center pt-1" style="margin-top: 4px;"><b>{{list.isTaskCount}}</b></h6>
                                <h6 class="text-center" style="color: #666666;font-weight: bold;">
                                  {{'Tasks' | translate}}</h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </ng-container>
                    </ng-container>
                  </li>
                </ul>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<br>

<!-- Model Starts Popups -->
<div class="modal full-size" tabindex="-1" role="dialog" [ngClass]="{ fade: !timeZoneModel, 'd-flex': timeZoneModel }">
  <div class="modal-dialog modal-dialog-scrollable" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title w-100">{{'Update Basic Info' | translate}}</h5>
        <!-- <a class="btn btn-sm btn-white dialog-close" (click)="onClose()"><span aria-hidden="true">&times;</span></a> -->
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-6 col-md-12">
            <div class="form-group">
              <label>{{'Countries' | translate}}<span class="text-danger">*</span></label>
              <!-- <mat-select class="form-control" 
                  [(ngModel)]="userTimezone.country_code" 
                  [ngModelOptions]="{standalone: true}"
                  required (change)="countryChanged()">
                  <mat-option><ngx-mat-select-search></ngx-mat-select-search></mat-option>
                  <mat-option [value]="list.country_code" *ngFor="let list of countries; let i=index;">{{list.country_name}}</mat-option>
                </mat-select> -->
              <select class="form-control" [(ngModel)]="userTimezone.country_code" [ngModelOptions]="{standalone: true}"
                required (change)="countryChanged()">
                <option value="">-- {{'Select Option' | translate}} --</option>
                <option [value]="list.country_code" *ngFor="let list of countries; let i=index;">{{list.country_name}}
                </option>
              </select>
            </div>
          </div>
          <div class="col-lg-6 col-md-12">
            <div class="form-group">
              <label>{{'Timezone' | translate}}<span class="text-danger">*</span></label>
              <select class="form-control" [(ngModel)]="userTimezone.timezone" [ngModelOptions]="{standalone: true}"
                required>
                <option value="">-- {{'Select Option' | translate}} --</option>
                <option [value]="list.desc" *ngFor="let list of countryBasedTimezones; let i=index;">{{list.desc}}
                </option>
              </select>
            </div>
          </div>
          <div class="col-lg-6 col-md-12">
            <div class="form-group">
              <label>{{'Business Type' | translate}}<span class="text-danger">*</span></label>
              <select class="form-control" [(ngModel)]="userTimezone.businestype" [ngModelOptions]="{standalone: true}"
                required>
                <option value="">-- {{'Select Option' | translate}} --</option>
                <option value="FMCGBusiness">{{'FMCG ( Food and Beverages ) Business' | translate}}</option>
                <option value="LogisticsBusiness">{{'Logistics Business' | translate}}</option>
                <option value="CourierBusiness">{{'Courier Business' | translate}}</option>
                <!-- <option value="other">Other</option> -->
              </select>
            </div>
          </div>
          <div class="col-lg-6 col-md-12">
            <div class="form-group">
              <label>{{'Business Name' | translate}}<span class="text-danger">*</span></label>
              <input type="text" class="form-control" [(ngModel)]="userTimezone.businessname"
                [ngModelOptions]="{standalone: true}" required>
            </div>
          </div>
          <div class="col-lg-12 col-md-12">
            <div class="form-group">
              <label>{{'Address' | translate}} <span class="text-danger">*</span></label>
              <input type="text" class="form-control" placeholder="Enter Address" [value]="userTimezone.address"
                [(ngModel)]="userTimezone.address" [ngModelOptions]="{standalone: true}" required
                matGoogleMapsAutocomplete (onAutocompleteSelected)="onAutocompleteSelected($event)"
                (onLocationSelected)="onLocationSelected($event)" (input)="onChange($event)"
                style="width: 95%;overflow: hidden;" #search>
              <!-- <input matInput type="text" class="form-control" (keydown.enter)="$event.preventDefault()" placeholder="Enter Address" 
                  autocorrect="off" 
                  autocapitalize="off"
                  spellcheck="off"
                  type="text" #search [value]="" 
                  [(ngModel)]="userTimezone.address" required> -->
              <img src="./assets/img/location.svg" alt="Profile Edit"
                style="height: 30px;width: 30px;float: right;margin-top: -36px;overflow: hidden;"
                class="map-icon mapicon" (click)="showmap(1)" *ngIf="agmshowmap == 0">
              <img src="./assets/img/location-disabled.svg" alt="Profile Edit"
                style="height: 30px;width: 30px;float: right;margin-top: -36px;" class="map-icon" (click)="showmap(0)"
                *ngIf="agmshowmap == 1">
              <agm-map [latitude]="latitude" [longitude]="longitude" (mapReady)="onMapReady($event)" [zoom]="zoom"
                *ngIf="agmshowmap == 1">
                <agm-marker [latitude]="latitude" [longitude]="longitude" [markerDraggable]="true"
                  (dragEnd)="markerDragEnd($event)"></agm-marker>
              </agm-map>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <div class="row">
          <div class="col-md-12" style="padding-bottom: 1px;">
            <!-- <button type="button" class="btn btn-sm btn-outline-info rounded-0 mb-2 mr-2" (click)="onClose()">Cancel</button> -->
            <button type="button" class="btn btn-sm btn-info rounded-0 mb-2 mr-2"
              (click)="onSubmit()">{{'Update' | translate}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
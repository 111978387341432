import { Injectable } from '@angular/core';
import * as moment from 'moment-timezone';

@Injectable()
export class TimezoneService {

  constructor() { }

  convertUtcToLocal(date){
    var Timezone;
    var userData = JSON.parse(localStorage.getItem("user_data"));
    var utcTimezone = moment.tz(date, "UTC");
    Timezone = utcTimezone.clone().tz(userData.profile.timezone);
    //Timezone = utcTimezone.clone().tz("Australia/Sydney");
    return Timezone.format("YYYY-MM-DD HH:mm:ss");
  }
  calendarDateFormat(date){
    var Timezone;
    Timezone = moment(date);
    return Timezone.format("YYYY-MM-DD");
  }
}

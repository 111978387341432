<agm-map [latitude]="lat" [longitude]="lng">
  <agm-direction 
    [origin]="origin1" 
    [destination]="destination1" 
    [waypoints]="waypoints" 
    [renderOptions]="renderOptions" 
    [markerOptions]="markerOptions" 
    (onChange)="change($event)"
  >
  </agm-direction>
  <agm-direction 
    [origin]="origin2" 
    [destination]="destination2" 
    [waypoints]="waypoints" 
    [renderOptions]="renderOptions" 
    [markerOptions]="markerOptions" 
    (onChange)="change($event)"
  >
  </agm-direction>
</agm-map>
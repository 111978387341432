import { Component, OnInit, HostBinding } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders, HttpErrorResponse } from "@angular/common/http";
import { FormGroup } from '@angular/forms';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/AuthService';
import { AppService } from 'src/app/services/AppService';
import { ApiService } from 'src/app/services/ApiService';
import { ReCaptchaV3Service } from 'ngx-captcha';
import { Config_ReCaptcha } from 'src/app/config/config';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

@Component({
  selector: 'app-email-login',
  templateUrl: './email-login.component.html',
  styleUrls: ['./email-login.component.css'],
  providers: [AuthService]
})
export class EmailLoginComponent implements OnInit {
  public _token: any;
  @HostBinding('attr.dir') dir = 'ltr';
  loginForm: FormGroup;
  submitted = false;
  user = {
    email: "",
    password: "",
    // recaptcha: "",
  };
  isDriverAsAdmin: boolean;
  is_remember: any;
  user_email: string;
  user_password: string;
  invalid_email: boolean = false;
  email_required: boolean;
  language: any;
  languageSubject: any;
  siteKey = Config_ReCaptcha.SITE_KEY;
  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private loader: NgxSpinnerService,
    private toastr: ToastrService,
    private authService: AuthService,
    public appService: AppService,
    public apiService: ApiService,
    public translate: TranslateService
  ) {}

  ngOnInit() {
    if (this.authService.isAuthenticated()) {
      this.router.navigate(["/dashboard"]);
    }
    else{
      if(localStorage.getItem('is_remember') == 'true'){
        this.is_remember = true;
        this.user.email = localStorage.getItem('user_name');
        this.user.password = localStorage.getItem('password');
      }
      else{
        this.is_remember = false;
        this.user.email = '';
        this.user.password = '';
      }
    }
    let SelectLang = localStorage.getItem("language");
    if(SelectLang != null && SelectLang == 'Arabic') {
      this.dir = 'rtl';
    } else {
      this.dir = 'ltr';
    }
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      if(event.lang == 'Arabic') {
        this.dir = 'rtl';
      } else {
        this.dir = 'ltr';
      }
    });
  }
  handleSuccess(e) {
    console.log("ReCaptcha", e);
  }
  Isremeber(event:any){
    if(this.is_remember == true){
      localStorage.setItem('is_remember', event.checked);
    }
    else{
      localStorage.setItem('is_remember', event.checked);
    }
  }
  validateEmail(email) {
    //var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    var re =/^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    if(re.test(email.target.value)){
      this.invalid_email = false;
      this.email_required = false;
    }
    else{
      this.invalid_email = true;
      this.email_required = false;
      if(email.target.value == ''){
        this.email_required = true
        this.invalid_email = false
      }
    }
  }
  onSubmit() {
    if (this.user) {
      // if(!this.user.recaptcha){
      //   return;
      // }
      // delete this.user.recaptcha;
      this.loader.show();
      let payload = {
        data: this.user
      }
      this.apiService
        .emailLogin(payload)
        .subscribe((response: any) => {
          if(response.status == 'ok'){

            var check:any;
            ///// remember me start ///////
            if(this.is_remember == true){
                 check = 'true'
            }
            else{
                 check = 'false'
            }
          
            localStorage.setItem('user_name', this.user.email);
            localStorage.setItem('password', this.user.password);

            ///// remember me end /////////
            this._token = response.data.token;
            localStorage.setItem(
              "access_token",
              this._token
            );
            localStorage.setItem(
              "client_data",
              this.appService.getClientData()
            );
            delete response.data.token;
            localStorage.setItem(
              "user_data",
              JSON.stringify(response.data)
            );
            localStorage.setItem(
              "zoom_control",
              JSON.stringify(response.data.profile.zoom_control)
            );
            localStorage.removeItem("temp_client_data");
            if(response['data']['profile']['role_id'] ==3){
              this.router.navigate(["/manage-users"]);
            }else{
              this.router.navigate(["/dashboard"]);
            }
            this.toastr.success('Login Successfully');            
          } else {
            if(response.data == 'You have a driver account with this email id.  Driver account does not have a web acccess. Would you like to signup as admin ?'){
              this.isDriverAsAdmin = true;
            }else{
              this.toastr.error(response.data);
            }
            this.loader.hide();
          }
        },
        (err: HttpErrorResponse) => {
          this.toastr.error('Something went wrong please try again');
          this.loader.hide();
        }
      );
    }
  }
  onPromotion(){
    this.isDriverAsAdmin = false;
    if (this.user) {
      this.loader.show();
      let payload = {
        data: {
          email: this.user.email
        }
      }
      this.apiService
        .promoteToAdmin(payload)
        .subscribe((response: any) => {
          if(response.status == 'ok'){
            this._token = response.data.token;
            localStorage.setItem(
              "access_token",
              this._token
            );
            localStorage.setItem(
              "client_data",
              this.appService.getClientData()
            );
            delete response.data.token;
            localStorage.setItem(
              "user_data",
              JSON.stringify(response.data)
            );
            localStorage.setItem(
              "zoom_control",
              JSON.stringify(response.data.profile.zoom_control)
            );
            localStorage.removeItem("temp_client_data");
            this.toastr.success('Login Successfully');
            this.router.navigate(["/dashboard"]);
          } else {
            this.toastr.error(response.data);
            this.loader.hide();
          }
        },
        (err: HttpErrorResponse) => {
          this.toastr.error('Something went wrong please try again');
          this.loader.hide();
        }
      );
    }
  }
  promotionCancel(){
    this.isDriverAsAdmin = false;
    this.user = {
      email: "",
      password: "",
      // recaptcha: "",
    };
    this.router.navigate(["/login"]);
  }
  password_visit() {
    let x = document.getElementById("login-password") as HTMLInputElement;
    if (x.type == "password") {
      document.getElementById("eye-open").className = "fa fa-lg fa-eye field-icon toggle-password view-hide changeview";
      x.type = "text";
    } else {
      document.getElementById("eye-open").className = "fa fa-lg fa-eye-slash field-icon toggle-password view-hide changeview";
      x.type = "password";
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders, HttpErrorResponse } from "@angular/common/http";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { FormGroup } from '@angular/forms';
import { ApiService } from './../../services/ApiService';
import { AppService } from './../../services/AppService';
import { AuthService } from './../../services/AuthService';

@Component({
  selector: 'app-trucks',
  templateUrl: './trucks.component.html',
  styleUrls: ['./trucks.component.css'],
})
export class TrucksComponent implements OnInit {
  isOpen: boolean = false;
  modelTitle: any;
  samples = [
    {
      id: 1,
      supervisor_fname: "ASD",
      supervisor_lname: "S",
      supervisor_email: "asds@gmail.com",
      supervisor_contact: "9823438745",
    },
    {
      id: 2,
      supervisor_fname: "ASD",
      supervisor_lname: "S",
      supervisor_email: "asds@gmail.com",
      supervisor_contact: "9823438745",
    },
    {
      id: 3,
      supervisor_fname: "ASD",
      supervisor_lname: "S",
      supervisor_email: "asds@gmail.com",
      supervisor_contact: "9823438745",
    },
    {
      id: 4,
      supervisor_fname: "ASD",
      supervisor_lname: "S",
      supervisor_email: "asds@gmail.com",
      supervisor_contact: "9823438745",
    },
    {
      id: 5,
      supervisor_fname: "ASD",
      supervisor_lname: "S",
      supervisor_email: "asds@gmail.com",
      supervisor_contact: "9823438745",
    },
    {
      id: 6,
      supervisor_fname: "ASD",
      supervisor_lname: "S",
      supervisor_email: "asds@gmail.com",
      supervisor_contact: "9823438745",
    },
    {
      id: 7,
      supervisor_fname: "ASD",
      supervisor_lname: "S",
      supervisor_email: "asds@gmail.com",
      supervisor_contact: "9823438745",
    }
  ];
  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private loader: NgxSpinnerService,
    private toastr: ToastrService,
    private authService: AuthService,
    public appService: AppService,
    public apiService: ApiService
  ) {}

  ngOnInit() {
  }
  password_change(obj: any){
    this.modelTitle = obj;
    this.isOpen = true;
  }
  Edit(obj:any){
    console.log(obj);
  }
  Delete(obj:any){
    console.log(obj);
  }
  View(obj:any){
    console.log(obj);
  }
  onClose(){
    this.modelTitle = undefined;
    this.isOpen = false;
  }
  new_password() {
    let x = document.getElementById("new-password") as HTMLInputElement;
    if (x.type == "password") {
      document.getElementById("new-eye-open").className = "fa fa-sm fa-eye field-icon toggle-password new-view-hide";
      x.type = "text";
    } else {
      document.getElementById("new-eye-open").className = "fa fa-sm fa-eye-slash field-icon toggle-password new-view-hide";
      x.type = "password";
    }
  }
  confirm_password() {
    let x = document.getElementById("confirm-password") as HTMLInputElement;
    if (x.type == "password") {
      document.getElementById("confirm-eye-open").className = "fa fa-sm fa-eye field-icon toggle-password same-view-hide";
      x.type = "text";
    } else {
      document.getElementById("confirm-eye-open").className = "fa fa-sm fa-eye-slash field-icon toggle-password same-view-hide";
      x.type = "password";
    }
  }
}

let port = window.location.port;
let uibaseUrl = ('https:' == document.location.protocol ? 'https://' : 'http://') + location.hostname;
if (port) {
  uibaseUrl += ':' + port + '/';
} else {
  uibaseUrl += '/';
}
console.log(uibaseUrl);
export class Config {
  //static BASE_URL = "http://65.0.179.186/";
  static BASE_URL = "https://testdelivery.manageteamz.com/";
}
export class Config_ReCaptcha {
  static SITE_KEY = "6LeWqI8aAAAAAPnyoWIe8yx-sTjuHRuF-6OXm8ir";
}
export class Config_SOCKET {
  static socket_config = {
    broadcaster: 'socket.io',
    host: 'https://testdelivery.manageteamz.com:6001/',
    encrypted: true,
  }
}
export class Config_MAP_API {
 // static GOOGLE_MAPS_API_KEY = "AIzaSyAy6TF0j10UTSGVh5bDtaDZAxKzSAf_Zz0";
  static GOOGLE_MAPS_API_KEY = "AIzaSyCD_3F73jSjd4SvZG2NHXyTWW1uJeIJo5c";
}
export class Image_Path {
  // Local Image Path 
  static BASE_URL = "https://testdelivery.manageteamz.com/assets/img/";
  // Live Image Path 
  // static BASE_URL = "https://silas.manageteamz.com/assets/img/";
  static S3_BASE_URL = "https:\/\/sfaprojectmanageteamz.s3.ap-south-1.amazonaws.com\/uploads\/qa_multi_delivery\/";
}
export class Multi_Select_Config {
  static config = {
    displayKey: 'name', //if objects array passed which key to be displayed defaults to description
    search: true, //true/false for the search functionlity defaults to false,
    height: 'auto', //height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
    placeholder:'Select', // text to be displayed when no item is selected defaults to Select,
    customComparator: ()=>{}, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
    limitTo: 0, // number thats limits the no of options displayed in the UI (if zero, options will not be limited)
    moreText: 'more', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
    noResultsFound: 'No results found!', // text to be displayed when no items are found while searching
    searchPlaceholder:'Search', // label thats displayed in search input,
    searchOnKey: 'name', // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
    clearOnSelection: false, // clears search criteria when an option is selected if set to true, default is false
    inputDirection: 'ltr', // the direction of the search input can be rtl or ltr(default)
  }
}
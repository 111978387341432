<div class="container-fluid" style="margin-top: 10px;">
  <div class="col text-left">
    <h5 class="mb-4 text-muted"><b>{{'GPS History' | translate}} </b></h5>
  </div>
</div>

<div class="container-fluid">
  <div class="card-body">
    <div class="row px-3 py-0">
      <div class="col-12 bg-light py-3">
        <div class="row justify-content-between">
          <div class="col-md">
            <div class="row">
              <div class="col-md-12 col-lg-auto">
                <mat-form-field>
                  <mat-label>{{'Select Delivery Agent' | translate}}</mat-label>
                  <mat-select [(ngModel)]="employeeID">
                    <mat-option *ngFor="let list of employeeList" [value]="list.emp_id">{{list.first_name}} {{list.last_name}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-md-12 col-lg-auto">
                <mat-form-field>
                  <mat-label>{{'Select Date Range' | translate}}</mat-label>
                  <mat-date-range-input [formGroup]="range" [rangePicker]="picker" [maxDays]='maxDays'>
                    <input matStartDate formControlName="start" placeholder="Start date">
                    <input matEndDate formControlName="end" placeholder="End date">
                  </mat-date-range-input>
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-date-range-picker #picker></mat-date-range-picker>
                  <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">{{'Invalid start date' | translate}}</mat-error>
                  <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">{{'Invalid end date' | translate}}</mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-12 col-lg-auto">
                <button mat-raised-button color="primary" class="mr-2"
                (click)="filterData()"><b>{{'Filter' | translate}}</b></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="col-lg-12 col-md-12">
  <div class="map-marker">
    <app-map-part [resource]="locationList" [fromWhere]="'gpsHistory'" (changeResource)="reback($event)" *ngIf="locationList"></app-map-part>
  </div>
</div>
<div class="container-fluid">
  <div class="row">
    <div class="col-3">
      <p>{{'Allocated' | translate}} : {{travelList.Allocated}}</p>
    </div>
    <div class="col-3">
      <p>{{'Accepted' | translate}} : {{travelList.Accepted}}</p>
    </div>
    <div class="col-3">
      <p>{{'In-Progress' | translate}} : {{travelList.In_Progress}}</p>
    </div>
    <div class="col-3">
      <p>{{'Delivered' | translate}} : {{travelList.Delivered}}</p>
    </div>
    <div class="col-3">
      <p>{{'Duration' | translate}} : {{locationList?.time_taken}}</p>
    </div>
    <div class="col-3">
      <p>{{'Distance' | translate}} : {{locationList?.distance}}</p>
    </div>
  </div>
</div>

import { Component, OnInit } from '@angular/core';
import { TooltipPosition } from '@angular/material/tooltip';
import { AppService } from 'src/app/services/AppService';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

  profile = 'https://ca.slack-edge.com/T04P6EUTA-U019B83GEBA-3cd52f6c9967-32';
  positionOptions: TooltipPosition[] = ['after', 'before', 'above', 'below', 'left', 'right'];
  isSidebarStat: { 'pinned-sidebar': boolean; 'toggeled-sidebar': boolean; };
  managershow: boolean;
  superadmin: boolean=false;
  constructor(
    private appService: AppService,
    private titleService: Title
  ) { }

  ngOnInit() {
    var role = localStorage.getItem("user_data");
    var userData = JSON.parse(role);
    if(userData.profile.role_id == 4){
      this.managershow = false;
    } else if(userData.profile.role_id ==3){
      this.managershow = false;
      this.superadmin = true;
    }
    else{
      this.managershow = true;
    }
  }
  getStat() {
    const classes = {
      'pinned_sidebar': this.appService.getSidebarStat().isSidebarPinned,
      'toggeled_sidebar': this.appService.getSidebarStat().isSidebarToggeled
    }
    if(classes.pinned_sidebar){
      return true;
    } else {
      return false;
    }
  }
  setDocTitle(title: string) {
    let DocTitleString = `ManageTeamz | ${title}`;
    this.titleService.setTitle(DocTitleString);
  }
}

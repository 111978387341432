<div class="row">
 <p><i class="fa fa-arrow-left fa-theme mr-2 pointer" matTooltip="Go Back to profile" [matTooltipPosition]="'below'" routerLink="/vehicle"></i>Add Vehicle</p>
</div>
<div class="container">
  <div class="row">
    <div class="col-md-12 col-lg-4 mb-4 align-items-stretch">
      <div class="form-group">
        <label>Vehicle Name <span class="text-danger">*</span></label>
        <input type="text" class="form-control" placeholder="Enter Vehicle Name">
      </div>
      <div class="form-group">
        <label>Vehicle Model <span class="text-danger">*</span></label>
        <input type="text" class="form-control" placeholder="Enter Phone Number">
      </div>
      <div class="form-group">
        <label>Status <span class="text-danger">*</span></label>
        <input type="text" class="form-control" placeholder="Enter Status">
      </div>
      <div class="form-group">
        <label>Permanent Address <span class="text-danger">*</span></label>
        <input type="text" class="form-control" placeholder="Enter Password">
      </div>
      <div class="form-group">
        <label>Log Book </label>
        <div class="custom-file">
          <input type="file" class="custom-file-input" id="customFile">
          <label class="custom-file-label rounded-0" for="customFile">Choose file</label>
        </div>
      </div>
      <div class="form-group">
        <label>Insurance Certificate </label>
        <div class="custom-file">
          <input type="file" class="custom-file-input" id="customFile">
          <label class="custom-file-label rounded-0" for="customFile">Choose file</label>
        </div>
      </div>
    </div>
    <div class="col-md-12 col-lg-4 mb-4 align-items-stretch">
      <div class="form-group">
        <label>Vehicle Type <span class="text-danger">*</span></label>
        <input type="text" class="form-control" placeholder="Last Vehicle Type">
      </div>
      <div class="form-group">
        <label>Licence Plate <span class="text-danger">*</span></label>
        <input type="text" class="form-control" placeholder="Enter WhatsApp Number">
      </div>
      <div class="form-group">
        <label>Active <span class="text-danger">*</span></label>
        <select class="form-control">
          <option>Active</option>
          <option>In Active</option>
        </select>
      </div>
      <div class="form-group">
        <label>Inspection Certificate </label>
        <div class="custom-file">
          <input type="file" class="custom-file-input" id="customFile">
          <label class="custom-file-label rounded-0" for="customFile">Choose file</label>
        </div>
      </div>
      <div class="form-group">
        <label>Tax Pin for Owner </label>
        <div class="custom-file">
          <input type="file" class="custom-file-input" id="customFile">
          <label class="custom-file-label rounded-0" for="customFile">Choose file</label>
        </div>
      </div>
      <div class="form-group">
        <label>Vehicle Photo </label>
        <div class="custom-file">
          <input type="file" class="custom-file-input" id="customFile">
          <label class="custom-file-label rounded-0" for="customFile">Choose file</label>
        </div>
      </div>
    </div>
    <div class="col-md-12 col-lg-4 mb-4 align-items-stretch">
      <ul class="small text-muted pl-4 mb-0">
        <li>
          Base Rate: 0
        </li>
        <li>
          Distance Cost Per KM: 0
        </li>
        <li>
          Time Cost Per Min: 0
        </li>
        <li>
          Time Overchange Cost Per Min: 0
        </li>
        <li>
          Weight Cost Per KG: 0
        </li>
        <li>
          Volume Cost Per Meter: 0
        </li>
      </ul>
    </div>
  </div>
</div>
<div class="row">
  <div class="col text-right">
    <button type="button" class="btn btn-sm btn-outline-info rounded-0 mb-2 mr-2">Cancel</button>
    <button type="button" class="btn btn-sm btn-info rounded-0 mb-2">Add Vehicle</button>
  </div>
 </div>

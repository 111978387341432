import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders, HttpErrorResponse } from "@angular/common/http";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { FormGroup } from '@angular/forms';
import { FormControl } from '@angular/forms';
import { ApiService } from './../../../services/ApiService';
import { AppService } from './../../../services/AppService';
import { AuthService } from './../../../services/AuthService';
import * as moment from 'moment-timezone';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-gps-history',
  templateUrl: './gps-history.component.html',
  styleUrls: ['./gps-history.component.css'],
})
export class GpsHistoryComponent implements OnInit {
  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });
  employeeList: any = [];
  travelList: any = [];
  locationList: any = [];
  employeeID: any;
  public maxDays: Number = 6;
  GPSData: any;
  SelectLang: string;
  EmpReq: string;
  DateReq: string;
  Close: string;
  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private loader: NgxSpinnerService,
    private toastr: ToastrService,
    private authService: AuthService,
    public appService: AppService,
    public apiService: ApiService,    
    private snackBar: MatSnackBar,
    public translate: TranslateService
  ) {}

  ngOnInit() {
    this.SelectLang = localStorage.getItem("language");
    this.translate.get('Please select employee from list').subscribe((text: string) => {
      this.EmpReq= text
    });
    this.translate.get('Please select an appropriate date range').subscribe((text: string) => {
      this.DateReq = text
    });
    this.translate.get('Close').subscribe((text: string) => {
      this.Close = text
    });
    this.translate.use(this.SelectLang);
    this.apiService
    .userActiveLists()
    .subscribe((response: any) => {
      this.employeeList = response.data;
    });
  }

  switchLang(lang: string) {
    localStorage.setItem("language",lang)
    this.translate.use(lang);
  }
  
  filterData(){
    let startDate = moment(this.range.value.start).format("YYYY-MM-DD HH:mm:ss");
    let endDate = moment(this.range.value.end).format("YYYY-MM-DD HH:mm:ss");
    if(this.employeeID == undefined || this.employeeID == ''){
      this.snackBar.open(this.EmpReq,this.Close,{
        duration: 4000,
        verticalPosition:'bottom'
      })
      return 0;
    }
    if(this.range.value.start == undefined || this.range.value.start == '' ){
      this.snackBar.open(this.DateReq,this.Close,{
        duration: 4000,
        verticalPosition:'bottom'
      })
      return 0;
    }
    if(this.range.value.end == undefined || this.range.value.end == '' ){
      this.snackBar.open(this.DateReq,this.Close,{
        duration: 4000,
        verticalPosition:'bottom'
      })
      return 0;
    }
    this.locationList = undefined;
    this.loader.show();
    this.apiService
    .filterTravelMap(startDate, endDate, this.employeeID)
    .subscribe((response: any) => {
      let tempData = [];
      this.GPSData = response.data;
      // this.GPSData.forEach(element => {
      //   if(element.allocated_emp_id == this.employeeID){
      //     tempData.push(element);
      //   }
      // });
      Object.keys(response.data).forEach(function(key) {
        tempData.push(response.data[key]);
      }); 
      this.travelList = tempData[0];
      this.loader.hide();
    });    
    this.apiService
    .getMyLocationsweb(startDate, endDate, this.employeeID)
    .subscribe((response: any) => {
      this.locationList = response.data;
      this.loader.hide();
    });
  }
  reback(obj: any){
  }
}

<app-cs-navbar></app-cs-navbar>
<div class="container-fluid form-container">
  <div class="row">
    <div class="col-md-6 content-center img-mobile-fit">
      <div class="custom-center">
        <h3 class="text-white text-center text-size mb-4">
          <img src="/assets/img/collections/phone.png" class="form-img mr-2">
          <strong>+917338773388</strong>
        </h3>
        <h3 class="text-white text-center text-size mb-4">
          <img src="/assets/img/collections/email.png" class="form-img mr-2">
          <strong>bd@manageteamz.com</strong>
        </h3>
        <!-- <h3 class="text-white text-center text-size mb-4">
          <a href="#" class="btn btn-outline-success">
            <img src="/assets/img/collections/live-chat.png" class="form-img mr-2">
            <strong>Live Chat</strong>
          </a>
        </h3> -->
      </div>
    </div>
    <div class="col-md-6 content-center">
      <div class="card rounded-card">
        <div class="card-body custom-size">
          <h4 class="text-center mb-4">
            <strong>{{'Delivery Account Login' | translate}}</strong>
          </h4>
          <form data-bitwarden-watching="1" (ngSubmit)="onSubmit()" #loginForm="ngForm">
            <div class="form-group">
              <label>{{'Email Address' | translate}}</label>
              <input type="text" class="form-control" (input)="validateEmail($event)" [(ngModel)]="user.email"
                name="email" required [ngModelOptions]="{standalone: true}">
              <div>
                <p class="error-box margin-top-0 text-danger" *ngIf="invalid_email">
                  {{'Email must be a valid email address' | translate}}
                </p>
                <p class="error-box margin-top-0 text-danger" *ngIf="email_required">
                  {{'Email is Required' | translate}}
                </p>
              </div>
            </div>
            <div class="form-group">
              <label>{{'Password' | translate}}</label>
              <div class="float-label-control">
                <input type="password" class="form-control" id="login-password" data-toggle="passwd"
                  [(ngModel)]="user.password" #password="ngModel" name="password" required minlength="3"
                  [ngModelOptions]="{standalone: true}">
                <div *ngIf="password.invalid && (password.dirty)">
                  <p class="error-box margin-top-0 text-danger" *ngIf="password.hasError('required')">
                    {{'Password is Required' | translate}}
                  </p>
                  <!-- <p class="error-box margin-top-0 text-danger" *ngIf="password.hasError('minlength')">
                    Minimum 6 Characters are Required
                  </p> -->
                </div>
                <span class="fa fa-lg fa-eye-slash field-icon toggle-password view-hide changeview" id="eye-open"
                  toggle="#passwd" (click)="password_visit()">
                </span>
              </div>
            </div>
            <!-- <div class="form-group">
              <ngx-recaptcha2 #captchaElem
                [siteKey]="siteKey"
                (success)="handleSuccess($event)"
                [useGlobalDomain]="false"
                [(ngModel)]="user.recaptcha"
                #recaptcha="ngModel"
                name="recaptcha"
                required [ngModelOptions]="{standalone: true}">
              </ngx-recaptcha2>
            </div> -->
            <div class="row">
              <div class="col-6 form-text-size">
                <mat-checkbox class="remember-me" (change)="Isremeber($event)" [(ngModel)]="is_remember"
                  [checked]="is_remember" aria-label="Remember Me" [ngModelOptions]="{standalone: true}">
                  {{'Remember Me' | translate}}
                </mat-checkbox>
              </div>
              <div class="col-6 form-text-size">
                <a href="#" routerLink="/forgot-password"
                  class="text-info float-right">{{'Forgot Password?' | translate}}</a>
              </div>
            </div>
            <div class="form-group">
              <button type="submit" [disabled]="!loginForm.valid || email_required || invalid_email"
                class="btn btn-info btn-lg btn-block">{{'Login' | translate}}</button>
            </div>
            <div class="form-group forget-password">
              <div class="row">
                <div class="col-6 form-text-size">
                  <a href="#" routerLink="/mobile-login"
                    class="text-info">{{'Login with Mobile Number?' | translate}}</a>
                </div>
                <div class="col-6 form-text-size">
                  <a href="#" routerLink="/signup"
                    class="text-info float-right">{{'Don`t have an Account?' | translate}}</a>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- <div class="container-fluid area-container">
  <div class="row">
    <div class="col-md-4 area-left">
      <div class="row">
        <div class="wrap">
          <div class="ico-wrap">
            <span class="mbr-iconfont">
              <img src="/assets/img/collections/location.png" class="area-img mr-2">
            </span>
          </div>
          <div class="vcenter">
            <p class="text-white text-uppercase">
              No.1, 1ST FLOOR, mrk arcade, 200 feet radial road, thoraipakkam, chennai, tamil nadu, india 600097.
            </p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="wrap">
          <div class="ico-wrap">
            <span class="mbr-iconfont">
              <img src="/assets/img/collections/phone.png" class="area-img-phn mr-2">
            </span>
          </div>
          <div class="vcenter">
            <p class="text-white text-uppercase">
              +917338773388
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-4 area-left">
      <div class="row">
        <div class="wrap">
          <div class="ico-wrap">
            <span class="mbr-iconfont">
              <img src="/assets/img/collections/location.png" class="area-img mr-2">
            </span>
          </div>
          <div class="vcenter">
            <p class="text-white text-uppercase">
              9820 ivalenes hope dr, austin, texass tx 78717.
            </p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="wrap">
          <div class="ico-wrap">
            <span class="mbr-iconfont">
              <img src="/assets/img/collections/phone.png" class="area-img-phn mr-2">
            </span>
          </div>
          <div class="vcenter">
            <p class="text-white text-uppercase">
              +16302997737
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-4 area-left">
      <div class="row">
        <div class="wrap">
          <div class="ico-wrap">
            <span class="mbr-iconfont">
              <img src="/assets/img/collections/location.png" class="area-img mr-2">
            </span>
          </div>
          <div class="vcenter">
            <p class="text-white text-uppercase">
              5838 burbank road se, calgary, alberta, canada t2h1z3.
            </p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="wrap">
          <div class="ico-wrap">
            <span class="mbr-iconfont">
              <img src="/assets/img/collections/phone.png" class="area-img-phn mr-2">
            </span>
          </div>
          <div class="vcenter">
            <p class="text-white text-uppercase">
              +14039263660
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->
<app-cs-footer></app-cs-footer>

<div class="modal full-size" tabindex="-1" role="dialog"
  [ngClass]="{ fade: !isDriverAsAdmin, 'd-flex': isDriverAsAdmin }">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title w-100">Confirmation</h5>
        <!-- <a class="btn btn-sm btn-white dialog-close" (click)="onClose()"><span aria-hidden="true">&times;</span></a> -->
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12" style="padding-bottom: 1px;">
            <b>You have a driver account with this email id. Driver account does not have a web acccess. Would you like
              to signup as admin ?</b>
          </div>
        </div>
        <div class="col-md-12 text-right" style="padding-bottom: 1px; padding-top: 5px;">
          <button type="button" class="btn btn-sm btn-info rounded-0 mb-2 mr-2" (click)="onPromotion()">Ok</button>
          <button type="button" class="btn btn-sm btn-outline-info rounded-0 mb-2 mr-2"
            (click)="promotionCancel()">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</div>
import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders, HttpErrorResponse } from "@angular/common/http";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { FormGroup } from '@angular/forms';
import { ApiService } from './../../services/ApiService';
import { AppService } from './../../services/AppService';
import { AuthService } from './../../services/AuthService';
import { Config } from '../../config/config';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as moment from 'moment-timezone';

export interface PeriodicElement {
  sip_id: number;
  first_name: string;
  last_name: string;
  email: string;
  contact_no: string
}

@Component({
  selector: 'app-manage-users',
  templateUrl: './manage-users.component.html',
  styleUrls: ['./manage-users.component.css']
})
export class ManageUsersComponent implements OnInit {

  @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
 
  isOpen: boolean = false;
  modelTitle: any;
  Supervisordata: any = [];
  displayedColumns = ['user_id','first_name','phone','expiry_date', 'package', 'activation_date','account_status','Action'];  
  // page: number = 1;
  // limit: number = 10;
  Supervisorlist: any;
  dataSource: MatTableDataSource<any>;
  NewPassword: any;
  ConfPassword: any;
  Emp_ID: string;
  ButtonShow = true;
  checkUserExpires: any;
  isExpiredTwodays = false;
  isExpired = false;
  userData: any;
  adminUsersList: any=[];
  adminUsersCount: any=0;
  adminUserListSource: MatTableDataSource<any>;


  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private loader: NgxSpinnerService,
    private toastr: ToastrService,
    private authService: AuthService,
    public appService: AppService,
    public apiService: ApiService,
    public dialog: MatDialog,
    private snackBar:MatSnackBar,
  ) { }

  baseUrl = Config.BASE_URL;
  apiUrl = this.baseUrl + "api/";
  animal: string;
  name: string;
  isDeleteModel = false;
  deleteItem: any;
  Search:any = "";
  offset:number=0;
  limit:number=10;

  ngOnInit(): void {
    let param = {
      offset:0,
      limit:10
    }
    this.getAdminUsers(param)
    
    this.userData = JSON.parse(localStorage.getItem("user_data"));
    if(this.userData.profile.role_id == 4){
      this.apiService.ManagerLimitCheckforMgr(this.userData.profile.role_id).subscribe((response: any) => {
        if(response.status == "ok"){
          this.ButtonShow = true;
        }else{
          this.ButtonShow = false;
        }
      });
      // this.managershow = false;
    }else{
      this.apiService.ManagerLimitCheck().subscribe((response: any) => {
        if(response.status == "ok"){
          this.ButtonShow = true;
        }else{
          this.ButtonShow = false;
        }
      });
      // this.managershow = true;
    }
  }
  
  getAdminUsers(param){
    this.loader.show();
    this.apiService.getAdminUsersList(param).subscribe((response)=>{
      this.adminUsersList = response['data']
      this.adminUsersCount = response['count']
      this.loader.hide();
      this.adminUserListSource = new MatTableDataSource(this.adminUsersList);
      setTimeout(() => this.adminUserListSource.paginator = this.paginator);
      this.adminUserListSource.sort = this.sort;
    })
  }

  ViewUserPackage(id){
    this.router.navigate(['viewuserpackage',id])
  }
  
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.adminUserListSource.filter = filterValue.trim().toLowerCase();

    if (this.adminUserListSource.paginator) {
      this.adminUserListSource.paginator.firstPage();
    }
  }

}

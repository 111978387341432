<div class="row" style="background-color: #2e6371">
    <div class="col text-left right_alignt">
        <p style="color: rgb(226, 223, 223);margin-top: 12px"><i class="fa fa-arrow-left fa-theme mr-2 pointer"
                matTooltip="{{'Go Back' | translate}}" [matTooltipPosition]="'above'"
                [routerLink]="['/dasboard']"></i><b>{{'Order ID' | translate}} : {{orederId}}</b></p>
    </div>
    <div class="col text-right left_align" style="margin-top: 12px" *ngIf="editshow">
        <span class="mr-2 pointer left_align" matTooltip="{{'Archive Order' | translate}}" *ngIf="archiveshow"
            [matTooltipPosition]="'above'" (click)="DeleteModel(orederId)" style="cursor: pointer;">
            <img src="./assets/img/open.png" alt="Task Edit" style="height: 22px;width: 22px;margin: 0px 10px 9px 0px;">
        </span>
        <span class="mr-2 pointer left_align" matTooltip="{{'Edit Order' | translate}}" [matTooltipPosition]="'above'"
            (click)="editOrder(orederId)" *ngIf="!isExpired" style="cursor: pointer;">
            <img src="./assets/img/action-edit.png" alt="Task Edit" style="height: 20px;width: 20px;">
        </span>
    </div>
</div>
<div class="row" *ngIf="listOrders">
    <div class="col-lg-12 col-md-12" style="padding: 0px;"
        [ngClass]="{'col-lg-8 col-md-12': isRightArrow,'col-lg-12 col-md-12': !isRightArrow}">
        <div class="map-marker">
            <!-- <div class="overlay-arrow-left">
                <span class="btn btn-sm btn-dark" (click)="leftArrow()"><i class="fas" [ngClass]="{'fa-angle-double-left': !isLeftArrow,'fa-angle-double-right': isLeftArrow}"></i></span>
            </div> -->
            <div class="overlay-arrow-right mv-hide">
                <span class="btn btn-sm btn-dark" (click)="rightArrow()"><i class="fas"
                        [ngClass]="{'fa-angle-double-left': !isRightArrow,'fa-angle-double-right': isRightArrow}"></i>
                    {{ isRightArrow ? '' : 'Order Details'}}</span>
            </div>
            <!-- <app-map-part [resource]="FinalResource" [fromWhere]="'newOrderDetails'" (changeResource)="reback($event)"></app-map-part> -->
            <app-map-part [resource]="listOrders" [fromWhere]="'orderDetails'" [estTime]="estimatedtime" (changeResource)="reback($event)">
            </app-map-part>
        </div>
    </div>
    <div class="col-lg-4 col-md-6" style="padding-right: 10px;background-color: #fff;overflow-y: auto;height: 86vh;"
        *ngIf="isRightArrow">
        <div class="row">
            <div class="btn-group" role="group" aria-label="Basic example" style="margin-top: 12px;">
                <button type="button" class="btn btn-outline-info" [ngClass]="{'active':isGroupObj === 'pickup'}"
                    (click)="isGroup('pickup')" style="font-size: 16px;font-weight: bold;">
                    {{'Pickup Details' | translate}}
                </button>
                <button type="button" class="btn btn-outline-info" [ngClass]="{'active':isGroupObj ==='delivery'}"
                    (click)="isGroup('delivery')" style="font-size: 16px;font-weight: bold;">
                    {{'Delivery Details' | translate}}
                </button>
                <button type="button" class="btn btn-outline-info" [ngClass]="{'active':isGroupObj === 'driver'}"
                    (click)="isGroup('driver')" *ngIf="listOrders?.otherDetails?.status != 'Unallocated'"
                    style="font-size: 16px;font-weight: bold;">
                    {{'Driver' | translate}}
                </button>
                <button type="button" class="btn btn-outline-info" [ngClass]="{'active':isGroupObj === 'history'}"
                    (click)="isGroup('history')"
                    *ngIf="listOrders?.otherDetails?.status == 'Completed' || listOrders?.otherDetails?.status == 'Delivered'"
                    style="font-size: 16px;font-weight: bold;">
                    {{'History' | translate}}
                </button>
            </div>
        </div>
        <br>
        <div [ngSwitch]="isGroupObj">
            <ng-template [ngSwitchCase]="'pickup'">
                <div class="row">
                    <ul class="pickup-timeline" style="width: 100%;">
                        <li [ngClass]="{'dot-red': i==0,'dot-green': i !==0}"
                            *ngFor="let list of listOrders?.pickupDetails;let i=index;">
                            <a href="javascript:void(0)"
                                style="color: #000000;text-decoration: none!important;cursor: unset;" class="mr-2"><b
                                    class="statu" style="font-size: 16px;">{{'Task ID' | translate}} :
                                    {{list.id}}</b></a>
                            <p class="float-right" *ngIf="list.pickupDropdown == false">
                                <span class="badge"
                                    [ngClass]="{'badge-orange': list.status !='Unallocated','badge-secondary': list.status =='Unallocated'}"
                                    style="font-size: 14px;">
                                    {{ list.status }}
                                </span><br>
                            </p>
                            <mat-form-field id="wrap" *ngIf="list.pickupDropdown;else hide"
                                style="float: right;height: 30px;width: 50%;">
                                <mat-select [(ngModel)]="list.status"
                                    (selectionChange)="PickupTaskStatusChange($event.value,list)">
                                    <mat-option value="Started Ride" disabled="{{list.hidePickupOption == true}">{{'Started Ride' | translate}}</mat-option>
                                  <mat-option value="In Supplier Place">{{'In Supplier Place' | translate}}
                                    </mat-option>
                                    <mat-option value="Products Picked up">{{'Products Picked up' | translate}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <p *ngIf="list.pickup_phone != '' && list.pickup_phone != null"
                                style="margin-top: 15px;margin-bottom: 1rem;font-size: 14px;color: #7a7a7a;font-weight: bold;">
                                <i class="fas fa-phone-alt fa-theme mr-2"></i>{{list.pickup_phone}}
                            </p>
                            <p
                                style="margin-top: 15px;margin-bottom: 1rem;font-size: 14px;color: #7a7a7a;font-weight: bold;">
                                <i class="fas fa-map-marker-alt fa-theme mr-2"></i>{{list.pick_address}}
                            </p>
                            <!-- <p style="margin-top: 0;margin-bottom: 1rem;font-size: 14px;color: #7a7a7a;font-weight: bold;" *ngIf="listOrders?.otherDetails?.delivery_logic == 2">
                                <i class="fab fa-product-hunt fa-theme mr-2"></i>
                                <ng-container *ngFor="let element of list.items;let i=index">
                                    <span *ngIf="i>0">,</span>{{element.items.name}}
                                </ng-container>
                            </p> -->
                            <p
                                style="margin-top: 0;margin-bottom: 1rem;font-size: 14px;color: #7a7a7a;font-weight: bold;">
                                <i class="fas fa-clock fa-theme mr-2"></i>{{list.picktime | date:'medium' }}
                            </p>
                            <i style="position: absolute;left: 45px;"
                                *ngIf="listOrders?.otherDetails?.delivery_logic == 2"
                                class="fas fa-sitemap fa-theme mr-2"></i>
                            <div class="col-md-12" *ngIf="listOrders?.otherDetails?.delivery_logic == 2"
                                style="margin: -3px 0px 5px 8px;">
                                <table style="width: 100%;">
                                    <tr>
                                        <th style="width: 50%;">{{'Items' | translate}}</th>
                                        <th style="text-align: center;">{{'Quantity' | translate}}</th>
                                    </tr>
                                    <tr *ngFor="let item of list.items;">
                                        <td style="font-size: 14px;color: #7a7a7a;font-weight: bold;">
                                            {{item.items.name}}</td>
                                        <td
                                            style="text-align: center;font-size: 14px;color: #7a7a7a;font-weight: bold;">
                                            {{item.quantity}}</td>
                                    </tr>
                                </table>
                            </div>
                        </li>
                    </ul>
                    <ng-container *ngIf="!isExpired">
                        <div *ngIf="isUnallocated" style="width: 100%;" class="allocate__div">
                            <b>{{'Allocate to an Agent' | translate}}</b><br>
                            <mat-form-field appearance="outline" id="wrap" style="width: calc(60% - 12px);">
                                <!-- <mat-label>Delivery Agent</mat-label> -->
                                <mat-select [(ngModel)]="AgentID">
                                    <mat-option *ngFor="let list of employeeList" [value]="list.emp_id">
                                        {{list.first_name}} {{list.last_name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <button mat-raised-button color="primary" class="mr-2 allocate" value="yes"
                                (click)="AllocateAgent(orederId)"
                                style="width: calc(40% - 12px);">{{'Allocate'| translate}}</button>
                        </div>
                        <div *ngIf="listOrders?.orderStatusDropdown" style="width: 100%;">
                            <b>{{'Order Status' | translate}}</b><br>
                            <mat-form-field appearance="outline" id="wrap" style="width: calc(60% - 12px);">
                                <mat-select [(ngModel)]="TaskStatus">
                                    <mat-option value="Unallocated">{{'Unallocated' | translate}}</mat-option>
                                    <mat-option value="Allocated" *ngIf="allocateshow">{{'Allocated' | translate}}
                                    </mat-option>
                                    <!-- <mat-option value="Declined">{{'Declined' | translate}}</mat-option> -->
                                    <mat-option value="Started Ride">{{'Started Ride' | translate}}</mat-option>
                                    <!-- <mat-option value="In Supplier Place">{{'In Supplier Place' | translate}}</mat-option>
                                    <mat-option value="Products Picked up">{{'Products Picked up' | translate}}</mat-option> -->
                                </mat-select>
                            </mat-form-field>
                            <button mat-raised-button color="primary" class="mr-2 allocate" value="yes"
                                (click)="UpdateStatus(orederId)"
                                style="width: calc(40% - 12px);">{{'Update' | translate}}</button>
                        </div>
                    </ng-container>
                </div>
            </ng-template>
            <ng-template [ngSwitchCase]="'delivery'">
                <div class="row">
                    <div class="card-body">
                        <ul class="list-group list-group-flush list__detail_cont" style="width: 100%;"
                            *ngFor="let list of listOrders?.deliveryDetails;let i=index;">
                            <li class="list-group-item" style="border-bottom: 1px solid #ccc !important;">
                                <p class="float-right" *ngIf="!list.deliveryDropDown">
                                    <span class="badge"
                                        [ngClass]="{'badge-orange': list.status !='Unallocated','badge-secondary': list.status =='Unallocated'}"
                                        style="font-size: 14px;">
                                        {{ list.status }}
                                    </span><br>
                                </p>
                                <mat-form-field id="wrap" *ngIf="list.deliveryDropDown;else hide"
                                    style="float: right;height: 30px;width: 49%;">
                                    <mat-select [(ngModel)]="list.status"
                                        disabled="{{list.status == 'Delivered' || list.status == 'Delivered Back'}}"
                                        (selectionChange)="DeliveryTaskStatusChange($event.value,list)">
                                        <mat-option value="Delivered">{{'Delivered' | translate}}</mat-option>
                                        <mat-option value="Delivered Back">{{'Delivered Back' |translate}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <p style="margin-top: 0;margin-bottom: 1rem;font-size: 16px;"><i
                                        class="fas fa-user fa-theme mr-2"></i><span
                                        class="text-muted deldetails">{{'Receiver Name' | translate}} </span><br>
                                    <span class="deldetval"> {{list.receiver_name}}</span>
                                </p>
                            </li>
                            <li class="list-group-item" style="border-bottom: 1px solid #ccc !important;">
                                <p style="margin-top: 0;margin-bottom: 1rem;font-size: 16px;"><i
                                        class="fas fa-mobile fa-theme mr-2"></i><span
                                        class="text-muted deldetails">{{'Mobile' | translate}} </span><br>
                                    <span class="deldetval">{{list.cust_phone}}</span>
                                </p>
                            </li>
                            <li class="list-group-item" style="border-bottom: 1px solid #ccc !important;">
                                <p style="margin-top: 0;margin-bottom: 1rem;font-size: 16px;"><i
                                        class="fas fa-clock fa-theme mr-2"></i><span
                                        class="text-muted deldetails">{{'Delivery Time' | translate}} </span> <br>
                                    <span class="deldetval">{{list.schedule_date_time | date:'medium'}}</span>
                                </p>
                            </li>
                            <li class="list-group-item" style="border-bottom: 1px solid #ccc !important;">
                                <p style="margin-top: 0;margin-bottom: 1rem;font-size: 16px;"><i
                                        class="fas fa-map-marker-alt fa-theme mr-2"></i><span
                                        class="text-muted deldetails">{{'Address' | translate}} </span><br>
                                    <span class="deldetval">{{list.cust_address}}</span>
                                </p>
                            </li>
                            <li class="list-group-item" style="border-bottom: 1px solid #ccc !important;"
                                *ngIf="listOrders?.otherDetails?.delivery_logic != 2">
                                <!-- <p style="margin-top: 0;margin-bottom: 1rem;font-size: 16px;"><i style="position: absolute;top: 30px;" class="fas fa-sitemap fa-theme mr-2"></i><span class="text-muted deldetails" style="margin: 0 0 0 24px;">Items </span> <br>
                                    <ng-container *ngFor="let element of list.items;let i=index;">
                                        <span *ngIf="i>0">,</span> <span class="deldetval">{{element.items.name}}</span>
                                    </ng-container>
                                </p> -->
                                <i style="position: absolute;top: 8px;"
                                    class="fas fa-sitemap fa-theme mr-2 iconchange"></i>
                                <div class="col-md-12" style="margin-bottom: 1rem;margin-left: 10px;">
                                    <table style="width: 100%;">
                                        <tr>
                                            <th style="width: 50%;color: #aeaeae;">{{'Items' | translate}}</th>
                                            <th style="text-align: center;color: #aeaeae;">{{'Quantity' | translate}}
                                            </th>
                                        </tr>
                                        <tr *ngFor="let item of list.items;">
                                            <td style="font-size: 14px;font-weight: bold;">{{item.items.name}}</td>
                                            <td style="text-align: center;font-size: 14px;font-weight: bold;">
                                                {{item.quantity}}</td>
                                        </tr>
                                    </table>
                                </div>
                            </li>
                            <li class="list-group-item" style="border-bottom: 1px solid #ccc !important;">
                                <p style="margin-top: 0;margin-bottom: 1rem;font-size: 16px;"><i
                                        class="fas fa-money-bill fa-theme mr-2"></i><span
                                        class="text-muted deldetails">{{'Order Amount' | translate}} </span><br>
                                    <span class="deldetval">{{list.order_amount}}</span>
                                </p>
                            </li>
                            <li class="list-group-item" style="border-bottom: 1px solid #ccc !important;">
                                <p style="margin-top: 0;margin-bottom: 1rem;font-size: 16px;"><i
                                        class="fas fa-credit-card fa-theme mr-2"></i><span
                                        class="text-muted deldetails">{{'Payment Type' | translate}} </span><br>
                                    <span class="deldetval">{{list.payment_type==1? 'Online':'Cash on Delivery'}}</span>
                                </p>
                            </li>
                            <ng-container *ngIf="list.status == 'Delivered back'">
                                <li class="list-group-item" style="border-bottom: 1px solid #ccc !important;"
                                    *ngIf="list.reasons">
                                    <p style="margin-top: 0;margin-bottom: 1rem;font-size: 16px;"><i
                                            class="fas fa-question fa-theme mr-2"></i><span
                                            class="text-muted deldetails">{{'Reason' | translate}} </span><br>
                                        <span class="deldetval">{{list.reasons}}</span>
                                    </p>
                                </li>
                                <li class="list-group-item" style="border-bottom: 1px solid #ccc !important;"
                                    *ngIf="list.remarks">
                                    <p style="margin-top: 0;margin-bottom: 1rem;font-size: 16px;"><i
                                            class="fas fa-info fa-theme mr-2"></i><span
                                            class="text-muted deldetails">{{'Remark' | translate}} </span><br>
                                        <span class="deldetval">{{list.remarks}}</span>
                                    </p>
                                </li>
                            </ng-container>
                            <li class="list-group-item" style="margin-top: 1rem">
                                <div class="row">
                                    <!-- <div class="col" *ngIf="list.idproof">
                                        <img src="{{list.idproof}}" alt="..." class="img-thumbnail" style="width: 110px;height: 110px;">
                                    </div>
                                    <div class="col" *ngIf="list.signature">
                                        <img src="{{list.signature}}" alt="..." class="img-thumbnail" style="width: 110px;height: 110px;">
                                    </div> -->
                                </div>
                                <div style="text-align:center" *ngIf="listOrders?.deliveryImages[i]">
                                    <ng-image-slider #nav [images]="listOrders?.deliveryImages[i]" [infinite]="true"
                                        [autoSlide]="2" [imageSize]="{width: '100%', height: 200}" slideImage="1">
                                    </ng-image-slider>
                                </div>
                            </li>
                            <li class="list-group-item"></li>
                            <hr>
                            <!-- <li class="list-group-item">
                                <p style="margin-top: 0;margin-bottom: 1rem;font-size: 16px;"><i class="fas fa-weight fa-theme mr-2"></i><span class="text-muted">Product Weight :</span> 100kg</p>
                            </li>
                            <li class="list-group-item">
                                <p style="margin-top: 0;margin-bottom: 1rem;font-size: 16px;"><i class="fas fa-clock fa-theme mr-2"></i><span class="text-muted">Time Requirement :</span> Twelve hours delivery</p>
                            </li>
                            <li class="list-group-item">
                                <p style="margin-top: 0;margin-bottom: 1rem;font-size: 16px;"><i class="fas fa-tags fa-theme mr-2"></i><span class="text-muted">Price Estimation :</span> 22000</p>
                            </li> -->
                        </ul>
                    </div>
                </div>
            </ng-template>
            <ng-template [ngSwitchCase]="'driver'">
                <div class="row" *ngIf="listOrders">
                    <ul class="list-group list-group-flush" style="width: 100%;">
                        <li class="list-group-item">
                            <div class="row">
                                <div class="col-auto">
                                    <div class="avatar-circle"
                                        *ngIf="listOrders?.otherDetails?.profile_image.length === 2">
                                        <span
                                            class="initials">{{listOrders?.otherDetails?.driver_name[0] | uppercase }}</span>
                                    </div>
                                    <div class="avatar-circle-img"
                                        *ngIf="listOrders?.otherDetails?.profile_image.length > 2" [ngStyle]="{'background-image': 'url(' + listOrders?.otherDetails?.profile_image + ')', 
                                        'background-size': 'contain', 'background-position': 'center'}">
                                    </div>
                                </div>
                                <div class="col-auto" style="width: 80%;position: absolute;right: 0;">
                                    <!--<span class="badge badge-success" style="float: right;background-color: #7ec258;height: 20px;width: 20%;">Busy</span>-->
                                    <p class="text-info"
                                        style="color: #2ca49b !important;font-size: 14px;font-weight: bold;">
                                        {{'Emp ID' | translate}} : {{listOrders?.otherDetails?.emp_id}}</p>
                                    <p
                                        style="margin-top: 0;margin-bottom: 1rem;font-size: 14px;color: #898989;font-size: 14px;font-weight: bold;">
                                        <i
                                            class="fas fa-user fa-theme mr-2"></i>{{listOrders?.otherDetails?.driver_name}}
                                    </p>
                                    <p
                                        style="margin-top: 0;margin-bottom: 1rem;font-size: 14px;color: #898989;font-weight: bold;">
                                        <i
                                            class="fas fa-mobile fa-theme mr-2"></i>{{listOrders?.otherDetails?.driver_phone_no}}
                                    </p>
                                    <p
                                        style="margin-top: 0;margin-bottom: 1rem;font-size: 14px;color: #898989;font-weight: bold;">
                                        <i
                                            class="fas fa-envelope fa-theme mr-2"></i>{{listOrders?.otherDetails?.driver_email}}
                                    </p>
                                </div>
                                <div class="col-auto">

                                </div>
                            </div>
                        </li>
                        <!-- <li class="list-group-item">
                            <div class="row">
                                <div class="col">
                                <p style="margin-top: 0;margin-bottom: 1rem;font-size: 16px;">Active : No Data</p>
                                <p style="margin-top: 0;margin-bottom: 1rem;font-size: 16px;">Seen : No Data</p>
                                </div>
                                <div class="col">
                                <p style="margin-top: 0;margin-bottom: 1rem;font-size: 16px;">Speed : No Data</p>
                                <p style="margin-top: 0;margin-bottom: 1rem;font-size: 16px;">Battery : No Data</p>
                                </div>
                            </div>
                        </li> -->
                    </ul>
                </div>
            </ng-template>
            <ng-template [ngSwitchCase]="'history'">
                <div class="row" *ngIf="listOrders">
                    <ul class="history-timeline" *ngIf="listOrders.historyDetails">
                        <li>
                            <p style="font-size: 18px;">{{'Start' | translate}}</p>
                            <p style="margin-top: 0;margin-bottom: 1rem;padding-left: 33px;font-size: 16px;">
                                {{listOrders?.historyDetails?.distance}}. About
                                {{listOrders?.historyDetails?.time_taken}}
                            </p>
                            <!-- <p style="margin-top: 0;margin-bottom: 1rem;padding-left: 33px;font-size: 16px;">
                                16.6 km. About 21 mins
                            </p> -->
                        </li>
                        <li>
                            <p style="font-size: 18px;">{{'Destination' | translate}}</p>
                        </li>
                    </ul>
                </div>
            </ng-template>
        </div>
    </div>
</div>
<div class="modal full-size" tabindex="-1" role="dialog" [ngClass]="{ fade: !isDeleteModel, 'd-flex': isDeleteModel }">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title w-100">{{'Confirmation' | translate}}</h5>
                <a class="btn btn-sm btn-white dialog-close" (click)="onClose()"><span
                        aria-hidden="true">&times;</span></a>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-12" style="padding-bottom: 1px;">
                        <b>{{'Are you sure to Archive this Order?' | translate}}</b>
                    </div>
                </div>
                <div class="col-md-12 text-right" style="padding-bottom: 1px; padding-top: 5px;">
                    <button type="button" class="btn btn-sm btn-info rounded-0 mb-2 mr-2"
                        (click)="onClose()">{{'No' | translate}}</button>
                    <button type="button" class="btn btn-sm btn-outline-info rounded-0 mb-2 mr-2"
                        (click)="ArchiveOrder()">{{'Yes' | translate}}</button>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid" style="margin-top: 10px;">
  <div class="col text-left">
    <h5 class="mb-4 text-muted"><b>{{'Manager' | translate}} </b></h5>
  </div>
  <div class="col text-right">
    <button mat-raised-button color="primary" *ngIf="ButtonShow == true" routerLink="/add-manager"
      matTooltip="click here"><b>{{'Add Manager' | translate}}</b></button>
    <p *ngIf="ButtonShow == false" style="color: Red;"><b>{{'You Reached Maximum Limit' | translate}}</b></p>
  </div>
</div>
<div class="container-fluid">
  <div class="card-body">
    <div class="row px-3 py-0">
      <div class="col-12 bg-light py-3">
        <div class="row justify-content-between">
          <div class="col-md">
            <div class="row">
              <!-- <div class="col-auto pr-0 pt-2">
                  <label class="d-flex align-top">Show</label>
                </div>
                <div class="col-auto pr-0">
                  <select name="example_length" aria-controls="example"
                    class="form-control w-auto-select mx-2">
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                </div>
                <div class="col pt-2">
                  <label class="d-flex align-top">entries</label>
                </div> -->
            </div>
          </div>
          <div class="col-auto">
            <div class="row">
              <div class="col-auto">
                <div class="input-group mb-3">
                  <input type="text" class="form-control" (keyup)="applyFilter($event)"
                    placeholder="{{'Search' | translate}}" autocomplete="off">
                  <div class="input-group-append">
                    <span class="fa fa-search input-group-text pointer"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="card-body">
          <div class="table-responsive">
            <!-- <table class="table">
                <thead class="text-primary pointer">
                  <th>
                    <a href="#" class="sort">
                      <i class="fas fa-sort-amount-down"></i>
                    </a>
                    Emp ID
                  </th>
                  <th class="w-15">
                    <a href="#" class="sort">
                      <i class="fas fa-sort-amount-down"></i>
                    </a>
                    First Name
                  </th>
                  <th class="w-15">
                    <a href="#" class="sort">
                      <i class="fas fa-sort-amount-down"></i>
                    </a>
                    Last Name
                  </th>
                  <th class="w-10">
                    <a href="#" class="sort">
                      <i class="fas fa-sort-amount-down"></i>
                    </a>
                    Email
                  </th>
                  <th class="w-10">
                    <a href="#" class="sort">
                      <i class="fas fa-sort-amount-down"></i>
                    </a>
                    Contact No
                  </th>
                  <th class="w-5">
                    <span>Action</span>
                  </th>
                </thead>
                <tbody>
                  <tr *ngFor="let obj of Supervisordata">
                    <td>{{obj.id}}</td>
                    <td>{{obj.first_name}}</td>
                    <td>{{obj.last_name}}</td>
                    <td>{{obj.email}}</td>
                    <td>{{obj.phone}}</td>
                    <td>
                      <a class="btn btn-sm icon-btn rounded-0 mr-2 sort" [matMenuTriggerFor]="menu"  matTooltip="More">
                        <i class="fa fa-ellipsis-v"></i>
                      </a>
                      <mat-menu #menu="matMenu">
                        <button mat-menu-item (click)="password_change('Password Change')">Password</button>
                        <button mat-menu-item (click)="Edit(obj)">Edit</button>
                        <button mat-menu-item (click)="Delete(obj)">Delete</button>
                        <button mat-menu-item (click)="View(obj)">View</button>
                      </mat-menu>
                    </td>
                  </tr>
                </tbody>
              </table> -->
            <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8"
              style="width: 100%;border: 1px solid #ccc;">

              <!--- Note that these columns can be defined in any order.
                      The actual rendered columns are set as a property on the row definition" -->

              <!-- Position Column -->
              <ng-container matColumnDef="sup_id">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="tablehead"
                  style="background-color: #1e9e95;color: #fff;font-size: 15px;"> {{'EmpID' | translate}} </th>
                <td mat-cell *matCellDef="let element"> {{element.user_id}} </td>
              </ng-container>

              <!-- Name Column -->
              <ng-container matColumnDef="first_name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="tablehead"
                  style="background-color: #1e9e95;color: #fff;font-size: 15px;"> {{'First Name' | translate}} </th>
                <td mat-cell *matCellDef="let element"> {{element.first_name}} </td>
              </ng-container>

              <!-- Weight Column -->
              <ng-container matColumnDef="last_name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="tablehead"
                  style="background-color: #1e9e95;color: #fff;font-size: 15px;"> {{'Last Name' | translate}}</th>
                <td mat-cell *matCellDef="let element"> {{element.last_name}} </td>
              </ng-container>

              <!-- Symbol Column -->
              <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="tablehead"
                  style="background-color: #1e9e95;color: #fff;font-size: 15px;"> {{'Email' | translate}} </th>
                <td mat-cell *matCellDef="let element"> {{element.email}} </td>
              </ng-container>

              <ng-container matColumnDef="contact_no">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="tablehead"
                  style="background-color: #1e9e95;color: #fff;font-size: 15px;"> {{'Contact No' | translate}} </th>
                <td mat-cell *matCellDef="let element"> {{element.phone}} </td>
              </ng-container>

              <ng-container matColumnDef="Actions">
                <th mat-header-cell *matHeaderCellDef class="tablehead"
                  style="background-color: #1e9e95;color: #fff;font-size: 15px;text-align: center;">
                  {{'Actions' | translate}} </th>
                <td mat-cell *matCellDef="let element" style="text-align: center;">
                  <!-- <mat-icon title="Reset Password" class="action-icons" style="cursor: pointer;color: green;" (click)="password_change('Password Change',element.user_id)">lock</mat-icon> -->
                  <!-- <mat-icon title="Edit Manager" class="action-icons" style="cursor: pointer;color: green;vertical-align:middle;" (click)="EditManager(element.user_id)">edit</mat-icon> -->
                  <!-- <mat-icon title="Delete Delivery Agent" class="action-icons" style="cursor: pointer;color: green;" (click)="DeleteModel(element.user_id)">delete</mat-icon> -->
                  <mat-icon title="{{'View Manager' | translate}}" class="action-icons"
                    style="cursor: pointer;color: green;vertical-align:middle;" (click)="ViewManager(element.user_id)">
                    visibility</mat-icon>
                </td>
              </ng-container>
              <!-- <ng-container matColumnDef="Status">
                  <th mat-header-cell *matHeaderCellDef class="tablehead" style="background-color: #1e9e95;color: #fff;font-size: 15px;text-align: center;"> Status </th>
                  <td mat-cell *matCellDef="let element" style="text-align: center;">
                    <span class="badge" style="margin-left:10px;height: 25px;padding: 0;width: 60px;cursor: unset;color:red;" class="ng-star-inserted" *ngIf="element.is_active == 0"><b>Delete</b></span>
                    <span class="badge" style="margin-left:10px;height: 25px;padding: 0;width: 60px;cursor: unset;color:green;" class="ng-star-inserted" *ngIf="element.last_name != 'Admin' && element.is_active == 1"><b>Active</b></span>
                  </td>
              </ng-container> -->
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <div style="padding-top: 10px;" *ngIf="dataSource?.filteredData.length === 0">
              <p class="text-center">{{'No Records Found!' | translate}}</p>
              <hr>
            </div>
            <mat-paginator #paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders, HttpErrorResponse } from "@angular/common/http";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { ApiService } from './../../services/ApiService';
import { AppService } from './../../services/AppService';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

interface Active {
  value: string;
  name: string;
}

interface Stat {
  value: string;
  name: string;
}

interface GPS {
  value: string;
  name: string;
}


@Component({
  selector: 'app-add-item',
  templateUrl: './add-item.component.html',
  styleUrls: ['./add-item.component.css'],
})

export class AddItemComponent implements OnInit {
  ItemName: any;
  SelectLang: string;
  ItemNameReq: string;
  Close: string;
  constructor(
    private http: HttpClient,
    private router: Router,
    private loader: NgxSpinnerService,
    private toastr: ToastrService,
    public appService: AppService,
    public apiService: ApiService,
    private snackBar: MatSnackBar,
    public translate: TranslateService
  ) { }
  ngOnInit() {
    this.SelectLang = localStorage.getItem("language");
    this.translate.get('Item name is Required').subscribe((text: string) => {
      this.ItemNameReq= text
    });
    this.translate.get('Close').subscribe((text: string) => {
      this.Close = text
    });
    this.translate.use(this.SelectLang);
    console.log('add  item');
  }

  switchLang(lang: string) {
    localStorage.setItem("language",lang)
    this.translate.use(lang);
  }

  AddItem(){
    if(this.ItemName == undefined || this.ItemName == ""){
      this.snackBar.open(this.ItemNameReq,this.Close,{
        duration: 4000,
        verticalPosition:'bottom'
      })
      return 0;
    }
    this.loader.show();
    let payload = {
      data : {
        name : this.ItemName,
        loc_lat: "",
        loc_lng: "",
        uploads: [],
      } 
    }
    this.apiService
      .addItem(payload)
      .subscribe((response: any) => {
        if(response.status == "ok"){
          this.toastr.success(response.data);
          this.router.navigate(["item"]);
        }else{
          this.toastr.warning(response.data);
        }
        this.loader.hide();
      });
  }
  Cancel(){
    this.router.navigate(['/item']);
  }
}

import { Component, OnInit, HostListener, Directive, ChangeDetectorRef, NgZone, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders, HttpErrorResponse } from "@angular/common/http";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { ApiService } from './../../services/ApiService';
import { AppService } from './../../services/AppService';
import { AuthService } from './../../services/AuthService';
import { Config } from '../../config/config';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Location, Appearance, GermanAddress } from '@angular-material-extensions/google-maps-autocomplete';
import PlaceResult = google.maps.places.PlaceResult;
import { SearchCountryField } from 'custom-template/ngx-intl-tel-input/src/lib/enums/search-country-field.enum';
import { TooltipLabel } from 'custom-template/ngx-intl-tel-input/src/lib/enums/tooltip-label.enum';
import { CountryISO } from 'custom-template/ngx-intl-tel-input/src/lib/enums/country-iso.enum';
import { MapsAPILoader } from '@agm/core';

interface GPS {
  value: string;
  name: string;
}

@Component({
  selector: 'app-add-manager',
  templateUrl: './add-manager.component.html',
  styleUrls: ['./add-manager.component.css']
})
export class AddManagerComponent implements OnInit {
  @ViewChild('search',{ static: false }) searchElementRef: ElementRef;
  GPSTrack: GPS[] = [
    {value: '1', name: 'On'},
    {value: '0', name: 'Off'},
  ];

  Supervisorlist: any;
  // public latitude: number;
  // public longitude: number;
  FirstName: any;
  LastName: any;
  SupPhone: any;
  SupEmail: any;
  SupWhatsapp: any;
  SupActive: any;
  SupStatus: any;
  SupGpsTrack: any;
  SupAddress: any;
  role_id: number;
  Vehicles: string[] = ['Truck', 'Car', 'Bike', 'Bicycle'];
  VehicleType:any;
  VehicleModel: any;
  LicensePlate: any;
  fileToUpload: File = null;
  Filename: string;
  files: any = [];
  GPSTracking: GPS[];
  public imagePath;
  imgURLs: any;
  filename: any = [];
  ProfileImage: any=[];
  type: any;
  image: any;
  SearchCountryField = SearchCountryField;
	TooltipLabel = TooltipLabel;
	CountryISO = CountryISO;
	preferredCountries: CountryISO[] = [
    CountryISO.India,
    CountryISO.Canada,
  ];
  EmployeeGroup: FormGroup;
  empForm: any;
  EmpData: any;
  confphoneshow: boolean;
  empbyphone: boolean;
  SupConfNum: any;
  confbyphone: boolean;
  SelectedGPS: any;
  isEditable: boolean;

  latitude: any;
  longitude: any;
  value: any;
  zoom: number;
  address: string;
  private geoCoder;
  company_lat: number;
  company_lng: number;
  agmshowmap = 0;

  // @ViewChild('search', ) searchElementRef: ElementRef;
  // public 
  invalid_email: boolean;
  email_required: boolean;
  adddisable: boolean;
  baseUrl = Config.BASE_URL;
  apiUrl = this.baseUrl + "api/";

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private loader: NgxSpinnerService,
    private toastr: ToastrService,
    private authService: AuthService,
    public appService: AppService,
    public apiService: ApiService,
    private formBuilder: FormBuilder,
    private snackBar:MatSnackBar,
    public ref: ChangeDetectorRef,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
  ) { }

  ngOnInit(): void {

    this.SelectedGPS=this.GPSTrack[0].value
    this.EmployeeGroup = this.formBuilder.group({
      FirstName: ['', Validators.required],
      LastName: [''],
      SupPhone: ['', Validators.required],
      SupEmail: [''],
      SupWhatsapp: [''],
      // SupActive: ['', Validators.required],
      // SupStatus: ['', Validators.required],
      //SupGpsTrack: ['', Validators.required],
      SupAddress: ['', Validators.required],
      VehicleType: [''],
      VehicleModel: [''],
      LicensePlate: [''],
      SupConfNum:[''],
      image: [''],
    });
    this.GPSTracking = this.GPSTrack;
    this.SelectedGPS = this.GPSTracking[0].value;
    var role = localStorage.getItem("user_data");
    var userData = JSON.parse(role);
    // if(roleid.role == 'manager'){
    //   this.role_id = 2;
    // } else if(roleid.role == 'driver'){
    //   this.role_id = 1
    // }
    this.role_id = 4;

    if(userData.profile.country_code != "" ){
      this.preferredCountries = [userData.profile.country_code.toLowerCase()];
    } else {
      this.apiService
        .getIpInfo()
        .subscribe((response: any) => {
          if(response.country != '') {
            this.preferredCountries = [
              response.country.toLowerCase()
            ];
          } else {
            this.preferredCountries = [
              CountryISO.India,
              CountryISO.Singapore,
              CountryISO.UnitedArabEmirates,
              CountryISO.Canada,
            ];
          }
        });
    }

    this.mapsAPILoader.load().then(() => {
      this.setCurrentLocation();
      
      this.geoCoder = new google.maps.Geocoder;
      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();

          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          //set latitude, longitude and zoom
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.zoom = 12;
        });
      });
    });
  }

  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        if((this.latitude == "" || this.latitude == undefined) || (this.longitude == "" || this.longitude == undefined)){
          this.zoom = 8;
          this.latitude = position.coords.latitude;
          this.longitude = position.coords.longitude;
          this.getAddress(this.latitude, this.longitude);
        }else{
          this.zoom = 8;
          this.getAddress(this.latitude, this.longitude);
        }
      });
    }
  }

  showmap(value:number){
    this.agmshowmap = value;
  }

  onAutocompleteSelected(result: PlaceResult) {
    this.agmshowmap = 1;
    this.SupAddress = result.formatted_address
  }
  onLocationSelected(location: Location) {
    this.latitude = location.latitude;
    this.longitude = location.longitude;
  }

  changePreferredCountries() {
		this.preferredCountries = [
      CountryISO.India,
      CountryISO.Canada,
    ];
  }

  markerDragEnd($event: google.maps.MouseEvent) {
    this.latitude = $event.latLng.lat();
    this.longitude = $event.latLng.lng();
    this.getAddress(this.latitude, this.longitude);
  }

  getAddress(latitude, longitude) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;
          this.SupAddress = results[0].formatted_address;
          this.ref.detectChanges();
        } else {
          console.log('No results found');
        }
      } else {
        console.log('Geocoder failed due to: ' + status)
      }

    });
  }

  fileupload(event:any){
    var reader = new FileReader();
      this.imagePath = event.target.files;
      reader.readAsDataURL(event.target.files[0]); 
      reader.onload = (_event) => { 
        this.imgURLs = reader.result; 
      }
      for(var i=0;i<event.target.files.length;i++){
        var name = event.target.files[i];
        // this.name1 = event.target.files[i].name;
        if(event.target.files[i].type.indexOf('image')> -1){
          this.type = 'image';
        } else if(event.target.files[i].type.indexOf('application')> -1){
          this.type = 'application';
        } else if(event.target.files[i].type.indexOf('text')> -1){
          this.type = 'text';
        }
  
        this.filename.push(name);
        const formValue = new FormData();
      for (var file of this.filename) {
        formValue.append('files[]', file) // file.name is optional
      }
      this.apiService
        .supervisorFileUpload(formValue)
        .subscribe((response: any) => {
          this.ProfileImage = response.data
        })
      }
    }

  AddSupervisor(){
    this.adddisable = true;
    this.loader.show()
    this.empForm = this.EmployeeGroup.value

    /*if(this.invalid_email || this.email_required){
      this.snackBar.open("email is invalid","Close",{
        duration: 4000,loader
        verticalPosition:'bottom'
      })
      return 0;
    }*/

    if(this.empForm.FirstName == undefined || this.empForm.FirstName == ''){
      this.snackBar.open("First Name is Required","Close",{
        duration: 4000,
        verticalPosition:'bottom'
      })
      this.loader.hide()
      return 0;
    }

    // if(this.empForm.SupPhone != this.empForm.SupConfNum){
    //   this.snackBar.open("Phone Number is not Match","Close",{
    //     duration: 4000,
    //     verticalPosition:'bottom'
    //   })
    //   return 0;
    // }e164Number

    /*if(this.empForm.SupWhatsapp == undefined || this.empForm.SupWhatsapp == ''){
      this.snackBar.open("WhatsApp Number is Required","Close",{
        duration: 4000,
        verticalPosition:'bottom'
      })
      return 0;
    }*/

    // if(this.empForm.SupAddress == undefined || this.empForm.SupAddress == ''){
    //   this.snackBar.open("Address  is Required","Close",{
    //     duration: 4000,
    //     verticalPosition:'bottom'
    //   })
    //   return 0;
    // }

    var data = {
      data : {
        "role_id" : this.role_id,
        "first_name" : this.empForm.FirstName,
        "last_name" : this.empForm.LastName,
        "phone" : this.empForm.SupPhone,
        "email" : this.empForm.SupEmail,
        "whatsapp" : this.empForm.SupWhatsapp,
        "is_delete" : this.empForm.SupActive,
        "is_active" : this.empForm.SupStatus,
        //"zipcode" : this.empForm.SupGpsTrack,
        "zipcode" : "0",
        "street" : this.SupAddress,
        "vehicle_model" : this.empForm.VehicleModel,
        "vehicle_type" : this.empForm.VehicleType,
        "license_plate" : this.empForm.LicensePlate,
        "profile_image" :this.ProfileImage,
        "employee_lat" : this.latitude,
        "employee_lng" : this.longitude
      },
      "whatsapp" : this.empForm.SupWhatsapp,
    }
    this.apiService
      .addSupervisor(data)
      .subscribe((response: any) => {
        // this.Userlist = response.data;
        if(response.status == "ok"){
          this.toastr.success(response.data);
        this.loader.hide();
        this.router.navigate(["manager"]);
        }else{
          this.toastr.warning(response.data);
        this.loader.hide();
        }
      });
  }

  validateEmail(email) {
    //var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    var re =/^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

    /*if(re.test(email.target.value)){
      this.invalid_email = false;
      this.email_required = false;
    }
    else{
      this.invalid_email = true;
      if(email.target.value == ''){
        this.email_required = true
        this.invalid_email = false
      }
    }*/
  }

  Cancel(){
    this.router.navigate(["manager"]);
  }

  DeleteImg(){
    this.ProfileImage = [];
  }

  EmpByPhone(){
    if(this.SupPhone == undefined || this.SupPhone == ''){
      this.snackBar.open("Phone Number is Required","Close",{
        duration: 4000,
        verticalPosition:'bottom'
      })
      return 0;
    }else{
      this.confbyphone = true;
    }
    this.apiService
    .GetMgrByPhone(this.SupPhone)
    .subscribe((response: any) => {
      
      if(response.status == "ok"){
        this.EmpData = response.data;
        if(this.EmpData == null){
          this.isEditable = true;
          this.confphoneshow = true;
          this.empbyphone = false;
        }else{
          this.isEditable = false;
          this.confphoneshow = false;
          this.confbyphone = false;
          this.empbyphone = true;
          this.FirstName = this.EmpData.first_name;
          this.LastName = this.EmpData.last_name;
          this.SupWhatsapp = this.EmpData.whatsapp;
          this.SupEmail = this.EmpData.email;
          this.SupActive = this.EmpData.is_delete;
          this.SupStatus = this.EmpData.is_active;
          //this.SupGpsTrack = this.EmpData.zipcode;
          this.SupGpsTrack = "0";
          this.SupAddress = this.EmpData.street;
          this.LicensePlate = this.EmpData.license_plate;
          this.VehicleModel = this.EmpData.vehicle_model;
          this.VehicleType = this.EmpData.vehicle_type;
          // this.ProfileImage = JSON.parse(this.EmpData.profile_image);
        }
        // if(this.EmpData.data)
      }else{
        this.EmpData = [];
        this.confbyphone = false;
        this.toastr.warning(response.data);
        this.loader.hide();
      }
      
    })
 }

 ConfPhoneCheck(){
   if(this.SupConfNum == undefined || this.SupConfNum == ''){
    this.snackBar.open("Confirm Phone Number is Required","Close",{
      duration: 4000,
      verticalPosition:'bottom'
    })
    return 0;
  }
  if(this.SupPhone == this.SupConfNum){
    this.empbyphone = true;
    this.confbyphone = false;
    // this.SupWhatsapp = this.SupConfNum;
  }else{
    this.snackBar.open("Phone Number and Confirm Phone Number does not Match","Close",{
      duration: 4000,
      verticalPosition:'bottom'
    })
    return 0;
  }
}

EmpPhone(){
  this.empbyphone = false;
  this.confbyphone = false; 
  this.FirstName = "";
  this.LastName = "";
  // this.SupConfNum="";
  this.SupEmail = "";
  this.SupAddress = "";
  this.SupGpsTrack = "";
  this.VehicleModel = "";
  this.LicensePlate = "";
  this.VehicleType = "";
  this.ProfileImage = "";
}

EmpConfPhone(){
  this.empbyphone = false;
}
  
  omit_char(event: any) {
    const keyChar = event.key;
    let allowCharacter: boolean;
    if(event.target.value.length < 0 || event.target.value.length > 11 ){
      event.preventDefault();
    }
    if (keyChar === '-' && event.target.selectionStart !== 0) {
      allowCharacter = false;
    } else if (
      keyChar === 'Tab' ||
      keyChar === 'Enter' ||
      keyChar === 'Backspace' ||
      keyChar === 'ArrowLeft' ||
      keyChar === 'ArrowRight' ||
      keyChar === 'Delete'
    ) {
      allowCharacter = true;
    } else {
      allowCharacter = keyChar >= '0' && keyChar <= '9';
    }
    if (!allowCharacter) {
      event.preventDefault();
    }
  }

}

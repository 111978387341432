<div class="loader d-flex justify-content-center" *ngIf="loaderOne">
    <div class="spinner-border text-white d-flex align-self-center" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
  
  <div class="row">    
    <div class="col-md-12 mb-4">
      <div class="card border-0 rounded-0 h-100">
        <div class="card-title-hide">
			    <h5 class="text-info">{{paymentResponse}}</h5>
          <h7>You will be redirected to profile page after {{secondCounter}} seconds</h7>
        </div>
        <div class="card-body">
          <div class="card md-below mb-1 p-3">
            <h5 class="text-info">{{'Payment Information' | translate}}</h5>
            <div class="col-md-12">
              <ul class="list-group list-group-flush">
                <li class="list-group-item">
                  <b>{{'Payment ID' | translate}}</b><span style="float: right;text-transform: uppercase;">{{paymentDetails?.id}}</span>
                </li>
                
                <li class="list-group-item">
                  <b>{{'Sales Order ID' | translate}}</b><span style="float: right;text-transform: uppercase;">{{paymentDetails?.sales_order_id}}
                  </span>
                </li>
                <li class="list-group-item">
                  <b>{{'Status' | translate}}</b><span style="float: right;text-transform: uppercase;">{{paymentDetails?.status}}
                  </span>
                </li>
                <li class="list-group-item" *ngIf="paymentDetails?.status != 'Success'">
                  <b>{{'Failure Message' | translate}}</b><span style="float: right;text-transform: uppercase;">{{paymentDetails?.status_message}}</span>
                </li>
                <li class="list-group-item">
                  <b>{{'Username' | translate}}</b><span style="float: right;text-transform: uppercase;">{{paymentDetails?.user?.first_name}}</span>
                </li>
                <li class="list-group-item">
                    <b>{{'Purchased Plan' | translate}}</b><span style="float: right;text-transform: uppercase;">{{paymentDetails?.package?.name}}</span>
                </li>
                <li class="list-group-item">
                  <b>{{'Amount' | translate}}</b><span style="float: right;text-transform: uppercase;">{{paymentDetails?.amount}}</span>
                </li>
                <li class="list-group-item">
                  <b>{{'Currency' | translate}}</b><span style="float: right;text-transform: uppercase;">{{paymentDetails?.currency}}</span>
                </li>
                <li class="list-group-item">
                  <b>{{'Payment Date' | translate}}</b><span style="float: right;text-transform: uppercase;">{{paymentDetails?.created_at | date}}</span>
                </li>

              </ul>
            </div>
            
          </div>
        </div>
      </div>
    </div>
  </div>
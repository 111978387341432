import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { Config } from '../../config/config';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppService } from 'src/app/services/AppService';
import { ApiService } from 'src/app/services/ApiService';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-edit-item',
  templateUrl: './edit-item.component.html',
  styleUrls: ['./edit-item.component.css']
})
export class EditItemComponent implements OnInit {
  ItemName: any;
  itemId: string;
  ItemList: any;
  constructor(
    private http: HttpClient,
    private router: Router,
    private loader: NgxSpinnerService,
    private toastr: ToastrService,
    public appService: AppService,
    public apiService: ApiService,
    private snackBar: MatSnackBar,
  ) { }

  ngOnInit() {
    this.itemId = localStorage.getItem("item_id");
    this.apiService
      .itemListById(this.itemId)
      .subscribe((response: any) => {
        this.ItemList = response.data;
        this.ItemName = response.data.name;
      });
  }

  UpdateItem(){
    if(this.ItemName == undefined || this.ItemName == ''){
      this.snackBar.open("Item Name is Required","Close",{
        duration: 4000,
        verticalPosition:'bottom'
      })
      return 0;
    }
    this.loader.show();
    let payload = {
      data : {
        id: this.ItemList.id,
        name : this.ItemName,
        emp_id: this.ItemList.emp_id,
        is_active: this.ItemList.is_active,
      } 
    }
    this.apiService
      .editItem(this.itemId, payload)
      .subscribe((response: any) => {
        if(response.status == "ok"){
          this.toastr.success(response.data);
          this.router.navigate(["item"]);
        }else{
          this.toastr.warning(response.data);
        }
        this.loader.hide();
      });
  }
  Cancel(){
    this.router.navigate(['/item']);
  }
}

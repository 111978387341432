<div class="container-fluid">
  <div class="container">
    <div class="row" style="margin-top: 15px;">
      <form [formGroup]="CustFormGroup" class="col-md-12">
        <div class="col text-left">
          <p style="margin-top: 12px;font-size: 16px;"><i class="fa fa-arrow-left fa-theme mr-2 pointer" matTooltip="{{'Go Back' | translate}}" [matTooltipPosition]="'above'" [routerLink]="['/customer']"></i><b>{{'Add Customer' | translate}}</b></p>
          <!-- <h5 class="mb-4"><b>Add Customer </b></h5> -->
        </div>
        <div class="col-md-12 col-lg-6 mb-4 align-items-stretch" style="float: left;">
          <div class="form-group">
          <mat-form-field class="col-md-12" appearance="outline">
            <mat-label>{{'Name' | translate}}</mat-label>
            <input matInput placeholder="{{'Enter Name' | translate}}" maxlength="50" name="cus_name" formControlName="CustName" [(ngModel)]="CustName" required>
          </mat-form-field>
          </div>
          <div class="form-group">
          <mat-form-field class="col-md-12" appearance="outline">
            <label style="position: absolute;top: -5px;">{{'Phone Number' | translate}}<span> *</span></label>
            <!-- <input matInput placeholder="Enter Phone Number" name="cus_phone" [(ngModel)]="CustPhone" (keypress)="omit_char($event);" required> -->
            <ngx-mat-intl-tel-input
              [preferredCountries]="preferredCountries"
              [enableAutoCountrySelect]="false"
              [enablePlaceholder]="true"
              [searchCountryFlag]="true"
              [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
              [selectFirstCountry]="false"
              [selectedCountryISO]="CountryISO.India"
              [tooltipField]="TooltipLabel.Name"
              [(ngModel)]="CustPhone"
              formControlName="CustPhone"
              (keypress)="omit_char($event);"
              required
              [separateDialCode]="false" [enableSearch]="true"
              name="cus_phone" #phone
              >
            </ngx-mat-intl-tel-input>
          </mat-form-field>
          </div>
        </div>
      <div class="col-md-12 col-lg-6 mb-4 align-items-stretch" style="float: left;">
      <div class="form-group">
        <mat-form-field class="col-md-12" appearance="outline">
          <mat-label>{{'Email' | translate}}</mat-label>
          <input matInput placeholder="{{'Enter Email' | translate}}" name="cus_email" formControlName="CustEmail" [(ngModel)]="CustEmail" id='txtEmail' pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$">
        </mat-form-field>
        <!-- <div *ngIf="cus_email.invalid && (cus_email.dirty || cus_email.touched)">
          <p class="error-box margin-top-0 text-danger" *ngIf="cus_email.errors.email">
            Email must be a valid email address
          </p>
          <p class="error-box margin-top-0 text-danger" *ngIf="submitted && cus_email.errors">
            email address incorrect
          </p>
        </div> -->
      </div>
      <div class="form-group">
        <mat-form-field class="col-md-12" appearance="outline">
          <mat-label>{{'Address' | translate}}</mat-label>
          <input matInput placeholder="{{'Enter Address' | translate}}" formControlName="CustAddress" value="{{CustAddress}}"  matGoogleMapsAutocomplete
          required
          (onAutocompleteSelected)="onAutocompleteSelected($event)"
          (input)="onChange($event)"
          (onLocationSelected)="onLocationSelected($event)" style="width: 90%; border: none !important;outline: none !important;box-shadow: none !important;background: none !important;overflow: hidden;" #search>
          <!-- <input matInput type="text" class="form-control" (keydown.enter)="$event.preventDefault()" placeholder="Enter Address" autocorrect="off" autocapitalize="off" spellcheck="off" type="text" #search [value]="CustAddress" [(ngModel)]="CustAddress" formControlName="CustAddress" name="cus_address" required style="width: 90%; border: none !important;outline: none !important;box-shadow: none !important;background: none !important;overflow: hidden;"> -->
          
        </mat-form-field>
        <img src="./assets/img/location.svg" alt="Profile Edit" style="height: 30px;width: 30px;float: right;margin-top: -70px;overflow: hidden;position: relative;right:20px;" class="map-icon" (click)="showmap(1)" *ngIf="agmshowmap == 0">
            <img src="./assets/img/location-disabled.svg" alt="Profile Edit" style="height: 30px;width: 30px;float: right;margin-top: -70px;overflow: hidden;position: relative;right:20px;" class="map-icon" (click)="showmap(0)" *ngIf="agmshowmap == 1">
          <agm-map [latitude]="latitude" [longitude]="longitude" [zoom]="zoom" *ngIf="agmshowmap == 1">
            <agm-marker [latitude]="latitude" [longitude]="longitude" [markerDraggable]="true"
              (dragEnd)="markerDragEnd($event)"></agm-marker>
          </agm-map>
      </div>
      </div>
    </form>
  </div>
</div>
<div class="row">
  <div class="col text-right">
    <button type="button" (click)="Cancel()" class="btn btn-outline-info rounded-0 mb-2 mr-2"><b>{{'Cancel' | translate}}</b></button>
    <button type="button" (click)="AddCustomer()" class="btn btn-info rounded-0 mb-2"><b>{{'Add Customer' | translate}}</b></button>
  </div>
</div>

import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from './../../services/ApiService';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FormGroup } from '@angular/forms';
import { FormBuilder, Validators } from '@angular/forms';
import { NavigationExtras } from '@angular/router';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-customer-review',
  templateUrl: './customer-review.component.html',
  styleUrls: ['./customer-review.component.css'],
})
export class CustomerReviewComponent implements OnInit {
  @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  rating3: number;
  public form: FormGroup;
  displayedColumns = ['date', 'employee', 'rating'];
  userReviewList: any;
  dataSource: MatTableDataSource<any>;
  constructor(
    public apiService: ApiService,
    private fb: FormBuilder,
    private router: Router,
  ) {
    this.rating3 = 0;
    this.form = this.fb.group({
      rating1: ['', Validators.required],
      rating2: [4]
    });
   }

  ngOnInit() {
    this.apiService
      .EmployeeReview()
      .subscribe((response: any) => {
        this.userReviewList = response.data;
        this.dataSource = new MatTableDataSource(this.userReviewList);
        setTimeout(() => this.dataSource.paginator = this.paginator);
        this.dataSource.sort = this.sort;
      })
  }
  ApplyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  CustomeRating(userid){
    localStorage.setItem(
      "custuser_id",userid
    );
    this.router.navigate(['/customer-rating']);
  }
}

<div class="container">
  <form [formGroup]="drivingZoneGroup" class="col-md-12">
    <div class="row" style="margin-top: 15px;">
      <p><i class="fa fa-arrow-left fa-theme mr-2 pointer" matTooltip="Go back to driving zone list" [matTooltipPosition]="'below'"
          routerLink="/driving-zone"></i>Edit Driving Zone</p>
      <!-- <div class="col text-right">
         <button type="Submit" (click)="onDelete()" class="btn btn-sm btn-danger rounded-0 mb-2 mr-2  float-right">Delete</button>
      </div> -->
    </div>
    <div class="row">
      <div class="col-md-6 align-items-stretch">
        <div class="form-group">
          <label>Zone Name <span class="text-danger">*</span></label>
          <input type="text" class="form-control" style="height: 40px;" placeholder="Enter First Name"
            formControlName="zoneName">
        </div>
      </div>

      <div class="col-md-6 align-items-stretch">
        <div class="form-group tab-reg ">
          <label>Available Drivers <span class="text-danger">*</span></label>
          <ng-multiselect-dropdown [placeholder]="'Select driver'" [settings]="dropdownSettings"
            [data]="availableDrivers" [(ngModel)]="dataModel" (onSelect)="onItemSelect($event)"
            (onSelectAll)="onSelectAll($event)" formControlName="driverList">
          </ng-multiselect-dropdown>
        </div>
      </div>
    </div>
  </form>
  <div class="row" *ngIf="dataList">
    <div class="col-lg-12 col-md-12">
      <div class="map-marker">
        <app-map-part [fromWhere]="'editDrivingZone'" [resource]="dataList" (changeResource)="reback($event)"></app-map-part>
      </div>
    </div>
  </div>
  <div class="row" style="margin-top: 10px;">
    <div class="col text-right">
      <button type="button" (click)="Cancel()" class="btn btn-sm btn-outline-info rounded-0 mb-2 mr-2">Cancel</button>
      <button type="button" (click)="Submit()" class="btn btn-sm btn-info rounded-0 mb-2">Submit</button>
    </div>
  </div>
</div>
<div class="container-fluid" style="margin-top: 10px;">
  <div class="row">
    <div class="col text-left back_arrow" style="display: inline-block;">
      <p style="color: #000;margin-top: 12px"><i class="fa fa-arrow-left fa-theme mr-2 pointer"
          matTooltip="{{'Go Back' | translate}}" [matTooltipPosition]="'above'"
          [routerLink]="['/dasboard']"></i><b>{{'Add Order' | translate}}</b></p>
    </div>
    <div class="col text-right delivery__type" style="display: inline-block;">
      <button mat-raised-button color="primary" class="mr-5" [routerLink]="['/add-bulk-order']" >{{'Add Bulk Order' | translate}}</button>
      <mat-form-field appearance="outline" style="width: 275px;">
        <mat-label>{{'Delivery Type' | translate}}</mat-label>
        <mat-select [(ngModel)]="isDeliveryLogic" (selectionChange)="changeOption()">
          <mat-option [value]="1"><img src="">{{'Single Pickup and Multi Delivery' | translate}}</mat-option>
          <mat-option [value]="2"><img src="">{{'Multi Pickup and Single Delivery' | translate}}</mat-option>
          <mat-option [value]="3"><img src="">{{'Single Pickup and Single Delivery' | translate}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</div>
<div class="container-fluid">
  <div class="col-lg-12">
    <div [ngSwitch]="isDeliveryLogic">
      <ng-template [ngSwitchCase]="1">
        <order-logic-one [isLogic]="isDeliveryLogic" (comingChange)="changeBack($event)">
        </order-logic-one>
      </ng-template>
      <ng-template [ngSwitchCase]="2">
        <order-logic-two [isLogic]="isDeliveryLogic" (comingChange)="changeBack($event)">
        </order-logic-two>
      </ng-template>
      <ng-template [ngSwitchCase]="3">
        <order-logic-three [isLogic]="isDeliveryLogic" (comingChange)="changeBack($event)">
        </order-logic-three>
      </ng-template>
    </div>
  </div>
</div>
<!-- <div class="row">
  <div class="col-12" style="margin-right: 42px;">
    <div class="btn-group btn-nav btn-breadcrumb col-11 px-0">
      <button class="btn btn-success">
        1. Pickup Details
      </button>
      <button class="btn btn-outline-success">
        2. Delivery Details
      </button>
      <button class="btn btn-outline-success">
        3. Other Details
      </button>
    </div>
  </div>
</div> -->
import { Component, OnInit , ChangeDetectionStrategy} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders, HttpErrorResponse } from "@angular/common/http";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { FormGroup } from '@angular/forms';
import { ViewEncapsulation} from '@angular/core';
import { Location, Appearance, GermanAddress } from '@angular-material-extensions/google-maps-autocomplete';
import { FormControl } from '@angular/forms';
// import { InfoWindow } from '@agm/core/services/google-maps-types';
import { Image_Path } from 'src/app/config/config';
// import {} from '@types/googlemaps';
import PlaceResult = google.maps.places.PlaceResult;
import { ApiService } from './../../services/ApiService';
import { AppService } from './../../services/AppService';
import { AuthService } from './../../services/AuthService';
import { GeneralService } from './../../services/GeneralService';

@Component({
  selector: 'app-vehicle-map',
  templateUrl: './vehicle-map.component.html',
  styleUrls: ['./vehicle-map.component.css'],
})
export class VehicleMapComponent implements OnInit {
  public appearance = Appearance;
  public zoom: number;
  public latitude: number;
  public longitude: number;
  public selectedAddress: PlaceResult;
  public origin: any
  public destination: any

  public lat: Number = 12.949000;
  public lng: Number = 80.196800;

  public origin1: Object = { lat: 12.949000, lng: 80.196800 };
  public destination1: Object = { lat: 12.977370, lng: 80.209070 };
  
  public origin2: Object = { lat: 12.888520, lng: 80.227500 };
  public destination2: Object = { lat: 12.969710, lng: 80.184780 };
  public renderOptions = {
    suppressMarkers: true,
    // draggable: true,
  }
  public markerOptions = {
    origin: {
      icon: `${Image_Path.BASE_URL}collections/pin-1.png`,
      // infoWindow: `<h4>4356<h4>`
    },
    destination: {
        icon: `${Image_Path.BASE_URL}collections/pin-2.png`,
        // infoWindow: `<h4>3356<h4>`
    },
  };
  // public infoWindow: InfoWindow = undefined;
  // public obtainInfowindow(window: InfoWindow) {
  //     this.infoWindow = window
  // }
  public waypoints: any = []
  public change(event: any) {
    this.waypoints = event.request.waypoints;
  }
  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private loader: NgxSpinnerService,
    private toastr: ToastrService,
    private authService: AuthService,
    public appService: AppService,
    public apiService: ApiService
  ) {}

  ngOnInit() {
    this.getDirection();
  }
  getDirection() {
    this.origin = { lat: 24.799448, lng: 120.979021 }
    this.destination = { lat: 24.799524, lng: 120.975017 }
  
    // this.origin = 'Taipei Main Station'
    // this.destination = 'Taiwan Presidential Office'
  }
  // map integration start
  private setCurrentPosition() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 12;
      });
    }
  }
}

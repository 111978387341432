<div class="container-fluid">
  <!-- <div class="container-fluid">
    <div class="row">
      <div class="col-md-12 col-lg-auto">
        <mat-form-field>
          <mat-label>Select Order Status</mat-label>
          <mat-select>
            <mat-option value="option1">All Type</mat-option>
            <mat-option value="option2">Unallocated</mat-option>
            <mat-option value="option3">Allocated</mat-option>
            <mat-option value="option3">Completed</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-12 col-lg-auto">
        <mat-form-field>
          <mat-label>Enter a Date Range</mat-label>
          <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
            <input matStartDate formControlName="start" placeholder="Start date">
            <input matEndDate formControlName="end" placeholder="End date">
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        
          <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
          <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
        </mat-form-field>
      </div>
    </div> 
  </div> -->
  <div class="row text-center">
    <div class="col-md-4">
      <div class="btn-group">
        <div
          class="btn btn-info"
          mwlCalendarPreviousView
          [view]="view"
          [(viewDate)]="viewDate"
          (viewDateChange)="closeOpenMonthViewDay()"
        >
          {{'Previous' | translate}}
        </div>
        <div
          class="btn btn-outline-secondary"
          mwlCalendarToday
          [(viewDate)]="viewDate"
        >
          {{'Today' | translate}}
        </div>
        <div
          class="btn btn-info"
          mwlCalendarNextView
          [view]="view"
          [(viewDate)]="viewDate"
          (viewDateChange)="closeOpenMonthViewDay()"
        >
          {{'Next' | translate}}
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <h3>{{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}</h3>
    </div>
    <div class="col-md-4">
      <div class="btn-group">
        <div
          class="btn btn-info"
          (click)="setView(CalendarView.Month)"
          [class.active]="view === CalendarView.Month"
        >
          {{'Month' | translate}}
        </div>
        <div
          class="btn btn-info"
          (click)="setView(CalendarView.Week)"
          [class.active]="view === CalendarView.Week"
        >
          {{'Week' | translate}}
        </div>
        <div
          class="btn btn-info"
          (click)="setView(CalendarView.Day)"
          [class.active]="view === CalendarView.Day"
        >
          {{'Day' | translate}}
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid" *ngIf="events.length">
    <div class="card bg-light border" *ngIf="showME">
      <div class="card-body">
        <p class="mb-2">
          <span class="spinner-grow text-primary" role="status">
            <span class="sr-only">{{'Loading...' | translate}}</span>
          </span>
          {{'Color Note' | translate}}
          <a class="btn btn-sm btn-white dialog-close" (click)="closeME(false)"><span aria-hidden="true">&times;</span></a>
        </p>
        <ul class="list-group list-group-horizontal-sm">
          <li class="mv-mrleft" style="color: #993366;margin-left: 4rem;margin-right: 4rem;">
            <span style="font-size: 16px;">{{'Accepted' | translate}}</span>
          </li>
          <li style="color: #3f51b5;margin-right: 4rem;">
            <span style="font-size: 16px;">{{'Allocated' | translate}}</span>
          </li>
          <li style="color: #28a745;margin-right: 4rem;">
            <span style="font-size: 16px;">{{'Delivered' | translate}}</span>
          </li>
          <li style="color: #f7bf08;margin-right: 4rem;">
            <span style="font-size: 16px;">{{'In-Progress' | translate
            }}</span>
          </li>
          <li style="color: #993366;margin-right: 4rem;">
            <span style="font-size: 16px;">{{'Unallocated' | translate}}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <br *ngIf="!events.length">
  <div [ngSwitch]="view">
    <mwl-calendar-month-view
      *ngSwitchCase="CalendarView.Month"
      [viewDate]="viewDate"
      [events]="events"
      [refresh]="refresh"
      [activeDayIsOpen]="activeDayIsOpen"
      (dayClicked)="dayClicked($event.day)"
      (eventClicked)="handleEvent('Clicked', $event.event)"
      (eventTimesChanged)="eventTimesChanged($event)"
    >
    </mwl-calendar-month-view>
    <mwl-calendar-week-view
      *ngSwitchCase="CalendarView.Week"
      [viewDate]="viewDate"
      [events]="events"
      [refresh]="refresh"
      (eventClicked)="handleEvent('Clicked', $event.event)"
      (eventTimesChanged)="eventTimesChanged($event)"
    >
    </mwl-calendar-week-view>
    <mwl-calendar-day-view
      *ngSwitchCase="CalendarView.Day"
      [viewDate]="viewDate"
      [events]="events"
      [refresh]="refresh"
      (eventClicked)="handleEvent('Clicked', $event.event)"
      (eventTimesChanged)="eventTimesChanged($event)"
    >
    </mwl-calendar-day-view>
  </div>
</div>
<div class="modal full-size" tabindex="-1" role="dialog"
  [ngClass]="{ fade: !isOpenModal, 'd-flex': isOpenModal }" *ngIf="modalData">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <span (click)="orderDetails(modalData.event.data.id)" 
          matTooltip="Goto Order Details" [matTooltipPosition]="'above'">
          <b style="color: #3b9e95;font-size: 18px; cursor: pointer;">{{'Order ID' | translate}} : {{modalData.event.data.id}}</b>
        </span>
        <a class="btn btn-sm btn-white dialog-close" (click)="onCloseModal()">
          <span aria-hidden="true">&times;</span>
        </a>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12" style="padding-bottom: 1px;">
            <p>{{'Status' | translate}} : <b>{{modalData.event.data.status}}</b></p>
            <p>{{'Customer Name' | translate}} :
              <ng-container *ngIf="modalData?.event?.data?.cust_name; else nullValue">
                <b>{{modalData.event.data.cust_name}}</b>
              </ng-container>
            </p>
            <p>{{'Allocated Employee' | translate}} : 
              <ng-container *ngIf="modalData?.event?.data?.allocated_emp; else nullValue">
                <b>{{modalData.event.data.allocated_emp}}</b>
              </ng-container>
            </p>
            <p>{{'Start Date' | translate}} : <b>{{modalData.event.data.order_start_time  | date:'medium'}}</b></p>
            <p>{{'End Date' | translate}} : <b>{{modalData.event.data.order_end_time  | date:'medium'}}</b></p>
          </div>
          <ng-template #nullValue>
            <b> -</b>
          </ng-template>
        </div>
      </div>
      <!-- <div class="modal-footer">
        <div class="row">
          <div class="col-md-12" style="padding-bottom: 1px;">
            <button type="button" class="btn btn-sm btn-outline-info rounded-0 mb-2 mr-2" (click)="onCloseModal()">Cancel</button>
            <button type="button" class="btn btn-sm btn-info rounded-0 mb-2 mr-2">Add</button>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</div>
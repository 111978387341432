import { Component, OnInit, HostBinding } from '@angular/core';
import {
	SearchCountryField
} from 'custom-template/ngx-intl-tel-input/src/lib/enums/search-country-field.enum';
import { CountryISO } from 'custom-template/ngx-intl-tel-input/src/lib/enums/country-iso.enum';
import { TooltipLabel } from 'custom-template/ngx-intl-tel-input/src/lib/enums/tooltip-label.enum';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

@Component({
  selector: 'app-cs-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class CSFooterComponent implements OnInit {

  SearchCountryField = SearchCountryField;
	TooltipLabel = TooltipLabel;
	CountryISO = CountryISO;
	preferredCountries: CountryISO[] = [
    CountryISO.India,
    CountryISO.Canada,
  ];
  selectedCountry: any = CountryISO.India;
  SelectLang: any;
  
  @HostBinding('attr.dir') dir = 'ltr';
  constructor(public translate: TranslateService) { 
    translate.addLangs(['English', 'Arabic']);
    translate.setDefaultLang('English');
  }

  ngOnInit() {
    this.SelectLang = localStorage.getItem("language");
    if(this.SelectLang == '' || this.SelectLang == null){
      localStorage.setItem('language','English');
      this.SelectLang = localStorage.getItem('language')
      this.translate.use(this.SelectLang);
    }
    this.translate.use(this.SelectLang);
    if(this.SelectLang != null && this.SelectLang == 'Arabic') {
      this.dir = 'rtl';
    } else {
      this.dir = 'ltr';
    }
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      if(event.lang == 'Arabic') {
        this.dir = 'rtl';
      } else {
        this.dir = 'ltr';
      }
    });
  }

  changePreferredCountries() {
		this.preferredCountries = [
      CountryISO.India,
      CountryISO.Canada,
    ];
  }

}

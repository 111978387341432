import { Component, OnInit,HostListener, ViewChild, ElementRef, NgZone, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders, HttpErrorResponse } from "@angular/common/http";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from './../../services/ApiService';
import { AppService } from './../../services/AppService';
import { AuthService } from './../../services/AuthService';
import { Config } from '../../config/config';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Location, Appearance, GermanAddress } from '@angular-material-extensions/google-maps-autocomplete';
import PlaceResult = google.maps.places.PlaceResult;
import { SearchCountryField } from 'custom-template/ngx-intl-tel-input/src/lib/enums/search-country-field.enum';
import { TooltipLabel } from 'custom-template/ngx-intl-tel-input/src/lib/enums/tooltip-label.enum';
import { CountryISO } from 'custom-template/ngx-intl-tel-input/src/lib/enums/country-iso.enum';
import { MapsAPILoader } from '@agm/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-add-customer',
  templateUrl: './add-customer.component.html',
  styleUrls: ['./add-customer.component.css']
})
export class AddCustomerComponent implements OnInit {
  CustName: any;
  CustPhone: any;
  CustEmail: any;
  CustFormGroup: FormGroup;
  SearchCountryField = SearchCountryField;
	TooltipLabel = TooltipLabel;
  CountryISO = CountryISO;
	preferredCountries: CountryISO[] = [
    CountryISO.India,
    CountryISO.Canada,
  ];
  CustAddress: any;
  latitude: any;
  longitude: any;
  value: any;

  zoom: number;
  address: string;
  private geoCoder;
  company_lat: number;
  company_lng: number;

  agmshowmap = 0;
  SelLang:any;
  @ViewChild('search')
  public searchElementRef: ElementRef;
  SelectLang: string;
  InvEmail: string;
  InvPhone: string;
  ReqPhone: string;
  ReqAddress: string;
  Close: string;

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private loader: NgxSpinnerService,
    private toastr: ToastrService,
    private authService: AuthService,
    public appService: AppService,
    public apiService: ApiService,
    private snackBar:MatSnackBar,
    private formBuilder: FormBuilder,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    public ref: ChangeDetectorRef,
    public translate: TranslateService
  ) { }
  
  ngOnInit(): void {
    this.SelectLang = localStorage.getItem("language");
    this.translate.get('Customer Name is Required').subscribe((text: string) => {
      this.SelLang = text
    });
    this.translate.get('Invalid Email').subscribe((text: string) => {
      this.InvEmail = text
    });
    this.translate.get('Invalid Phone Number').subscribe((text: string) => {
      this.InvPhone = text
    });
    this.translate.get('Phone Number is Required').subscribe((text: string) => {
      this.ReqPhone = text
    });
    this.translate.get('Address is Required').subscribe((text: string) => {
      this.ReqAddress = text
    });
    this.translate.get('Close').subscribe((text: string) => {
      this.Close = text
    });
    this.translate.use(this.SelectLang);
    var emailpattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
    this.CustFormGroup = this.formBuilder.group({
      CustName: ['', Validators.required,],
      CustEmail: ['',],
      CustPhone: ['',Validators.maxLength(15)],
      CustAddress: ['', Validators.required],
    });
    var userData = JSON.parse(localStorage.getItem("user_data"));
    if(userData.profile.country_code != "") {
      this.preferredCountries = [userData.profile.country_code.toLowerCase()];
    } else {
      this.apiService
        .getIpInfo()
        .subscribe((response: any) => {
          if(response.country != '') {
            this.preferredCountries = [
              response.country.toLowerCase()
            ];
          } else {
            this.preferredCountries = [
              CountryISO.India,
              CountryISO.Singapore,
              CountryISO.UnitedArabEmirates,
              CountryISO.Canada,
            ];
          }
        });
    }

    this.mapsAPILoader.load().then(() => {
      this.setCurrentLocation();
      
      this.geoCoder = new google.maps.Geocoder;

      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();

          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          //set latitude, longitude and zoom
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.zoom = 12;
        });
      });
    });
  }

  switchLang(lang: string) {
    localStorage.setItem("language",lang)
    this.translate.use(lang);
  }

  showmap(value:number){
    this.agmshowmap = value;
  }

  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        if((this.latitude == "" || this.latitude == undefined) || (this.longitude == "" || this.longitude == undefined)){
          this.zoom = 8;
          this.latitude = position.coords.latitude;
          this.longitude = position.coords.longitude;
          // this.getAddress(this.latitude, this.longitude);
        }else{
          this.zoom = 8;
          // this.getAddress(this.latitude, this.longitude);
        }
      });
    }
  }


  markerDragEnd($event: google.maps.MouseEvent) {
    this.latitude = $event.latLng.lat();
    this.longitude = $event.latLng.lng();
    this.getAddress(this.latitude, this.longitude)
  }

  getAddress(latitude, longitude) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;
          this.latitude = latitude
          this.longitude = longitude
          this.CustFormGroup.patchValue({
            CustAddress: results[0].formatted_address
          });
          this.CustAddress = results[0].formatted_address;
        } else {
          console.log('No results found');
        }
      } else {
        console.log('Geocoder failed due to: ' + status);
      }

    });
  }

  onChange(result: PlaceResult){
    let val = result['target']['value']
    if(val ==''){
      this.CustFormGroup.value.CustAddress = result.formatted_address
      this.CustAddress = result.formatted_address
    }
    // navigator.geolocation.getCurrentPosition((position) => {
    //   if((this.latitude == "" || this.latitude == undefined) || (this.longitude == "" || this.longitude == undefined)){
    //     this.zoom = 8;
    //     this.latitude = position.coords.latitude;
    //     this.longitude = position.coords.longitude;
    //     this.getAddress(this.latitude, this.longitude);
    //   }else{
    //     this.zoom = 8;
    //     this.getAddress(this.latitude, this.longitude);
    //   }
    // });
  }

  onAutocompleteSelected(result: PlaceResult) {
    this.CustFormGroup.value.CustAddress = result.formatted_address
    this.CustAddress = result.formatted_address
    // navigator.geolocation.getCurrentPosition((position) => {
    //   if((this.latitude == "" || this.latitude == undefined) || (this.longitude == "" || this.longitude == undefined)){
    //     this.zoom = 8;
    //     this.latitude = position.coords.latitude;
    //     this.longitude = position.coords.longitude;
    //     this.getAddress(this.latitude, this.longitude);
    //   }else{
    //     this.zoom = 8;
    //     this.getAddress(this.latitude, this.longitude);
    //   }
    // });
  }
  pick_location(result: PlaceResult) {
    this.CustFormGroup.value.CustAddress = result.formatted_address
    this.CustAddress = result.formatted_address
    // navigator.geolocation.getCurrentPosition((position) => {
    //   if((this.latitude == "" || this.latitude == undefined) || (this.longitude == "" || this.longitude == undefined)){
    //     this.zoom = 8;
    //     this.latitude = position.coords.latitude;
    //     this.longitude = position.coords.longitude;
    //     this.getAddress(this.latitude, this.longitude);
    //   }else{
    //     this.zoom = 8;
    //     this.getAddress(this.latitude, this.longitude);
    //   }
    // });
  }
  onLocationSelected(location: Location) {
    this.latitude = location.latitude;
    this.longitude = location.longitude;
  }
  AddCustomer(){
    if(this.CustFormGroup.value.CustName == undefined || this.CustFormGroup.value.CustName == ''){
      this.snackBar.open(this.SelLang,this.Close,{
        duration: 4000,
        verticalPosition:'bottom'
      })
      return 0;
    }

    if(this.CustFormGroup.value.CustEmail != ''){
      if(this.CustFormGroup.value.CustEmail != undefined){
        if (!/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/.test(this.CustFormGroup.value.CustEmail)) {
          this.snackBar.open(this.InvEmail,this.Close,{
            duration: 4000,
            verticalPosition:'bottom'
          })
          return 0;
        }
      }
    }
    if(this.CustFormGroup.value.CustPhone == null){
      this.snackBar.open(this.InvPhone,this.Close,{
        duration: 4000,
        verticalPosition:'bottom'
      })
      return 0;
    }
    if(this.CustFormGroup.value.CustPhone == undefined || this.CustFormGroup.value.CustPhone == ''){
      this.snackBar.open(this.ReqPhone,this.Close,{
        duration: 4000,
        verticalPosition:'bottom'
      })
      return 0;
    }
    if(this.CustFormGroup.value.CustAddress == undefined || this.CustFormGroup.value.CustAddress == ''){
      this.snackBar.open(this.ReqAddress,this.Close,{
        duration: 4000,
        verticalPosition:'bottom'
      })
      return 0;
    }
    var data = { 
      "data" : {
        "uploads":[],
        "name":this.CustName,
        "contact_no":this.CustPhone,
        "email":this.CustEmail,
        "address":this.CustAddress,
        "loc_lat" : this.latitude,
        "loc_lng" : this.longitude
      }
    }
    this.apiService
      .addCustomer(data)
      .subscribe((response: any) => {
      // this.Userlist = response.data;
      if(response.status == "ok"){
        this.toastr.success(response.data);
      this.loader.hide();
      this.router.navigate(["customer"]);
      }else{
        this.toastr.warning(response.data);
      this.loader.hide();
      }
    })
  }
  Cancel(){
    this.router.navigate(["customer"]);
  }

  changePreferredCountries() {
		this.preferredCountries = [
      CountryISO.India,
      CountryISO.Canada,
    ];
  }

//   handleInput(event:any) {
//     if (event.which === 32 && !this.value.length)
//         event.preventDefault();
// }

  omit_char(event: any) {
    const keyChar = event.key;
    let allowCharacter: boolean;
    if(event.target.value.length < 0 || event.target.value.length > 11 ){
      event.preventDefault();
    }
    if (keyChar === '-' && event.target.selectionStart !== 0) {
      allowCharacter = false;
    } else if (
      keyChar === 'Tab' ||
      keyChar === 'Enter' ||
      keyChar === 'Backspace' ||
      keyChar === 'ArrowLeft' ||
      keyChar === 'ArrowRight' ||
      keyChar === 'Delete'
    ) {
      allowCharacter = true;
    } else {
      allowCharacter = keyChar >= '0' && keyChar <= '9';
    }
    if (!allowCharacter) {
      event.preventDefault();
    }
  }
}

import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders, HttpErrorResponse } from "@angular/common/http";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { FormControl, FormGroup } from '@angular/forms';
import { ApiService } from './../../../services/ApiService';
import { AppService } from './../../../services/AppService';
import { AuthService } from './../../../services/AuthService';
import { Config } from '../../../config/config';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as moment from 'moment-timezone';
import { Angular5Csv } from 'angular5-csv/dist/Angular5-csv';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-mileage-report',
  templateUrl: './mileage-report.component.html',
  styleUrls: ['./mileage-report.component.css'],
})
export class MileageReportComponent implements OnInit {
  @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns = ['name','id','start date','end date','distance'];
  dataSource: MatTableDataSource<any>;
  isDeleteModel = false;
  deleteItem: any;
  range = new FormGroup({
    start: new FormControl(new Date()),
    end: new FormControl(new Date())
  });
  employeeList: any;
  employeeID: any = "all";
  orderID: any;
  milageReportList: any;
  SelectLang: string;
  EmpReq: string;
  DateReq: string;
  Close: string;
  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private loader: NgxSpinnerService,
    private toastr: ToastrService,
    private authService: AuthService,
    public appService: AppService,
    public apiService: ApiService,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    public translate: TranslateService
  ) { }

  ngOnInit() {    
    this.SelectLang = localStorage.getItem("language");
    this.translate.get('Please select employee from list').subscribe((text: string) => {
      this.EmpReq= text
    });
    this.translate.get('Please select an appropriate date range').subscribe((text: string) => {
      this.DateReq = text
    });
    this.translate.get('Close').subscribe((text: string) => {
      this.Close = text
    });
    this.translate.use(this.SelectLang);
    this.apiService
      .userActiveLists()
      .subscribe((response: any) => {
        this.employeeList = response.data;
      });
    let payload = {
      data: {
        start_date: this.range.value.start,
        end_date: this.range.value.end,
        emp_id: this.employeeID,
        order_id: this.orderID,
      }
    }
    this.apiService
      .milageReport(payload, this.employeeID)
      .subscribe((response: any) => {
        this.milageReportList = response.data;
        this.dataSource = new MatTableDataSource(this.milageReportList);
        setTimeout(() => this.dataSource.paginator = this.paginator);
        this.dataSource.sort = this.sort;
      });
  }

  switchLang(lang: string) {
    localStorage.setItem("language",lang)
    this.translate.use(lang);
  }

  EditItem(id: any) {
    localStorage.setItem("item_id", id);
    this.router.navigate(["item-edit"]);
  }
  onClose(){
    this.deleteItem = undefined;
    this.isDeleteModel = false;
  }
  ApplyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  filterData(){
    let startDate = moment(this.range.value.start).format("YYYY-MM-DD");
    let endDate = moment(this.range.value.end).format("YYYY-MM-DD");
    if(this.employeeID == undefined || this.employeeID == ''){
      this.snackBar.open(this.EmpReq,this.Close,{
        duration: 4000,
        verticalPosition:'bottom'
      })
      return 0;
    }
    if(this.range.value.start == undefined || this.range.value.start == '' ){
      this.snackBar.open(this.DateReq,this.Close,{
        duration: 4000,
        verticalPosition:'bottom'
      })
      return 0;
    }
    if(this.range.value.end == undefined || this.range.value.end == '' ){
      this.snackBar.open(this.DateReq,this.Close,{
        duration: 4000,
        verticalPosition:'bottom'
      })
      return 0;
    }
    if(this.orderID){
      let orderidpayload = {
        data : {
          order_id : this.orderID,
          start_date: startDate,
          end_date: endDate,
        }
      }
      this.loader.show();
    this.apiService
      .milageReportOrderFilter(orderidpayload,this.employeeID)
      .subscribe((response: any) => {
        this.milageReportList = response.data;
        this.dataSource = new MatTableDataSource(this.milageReportList);
        setTimeout(() => this.dataSource.paginator = this.paginator);
        this.loader.hide();
      });
    }else{
      let payload = {
        data: {
          start_date: startDate,
          end_date: endDate,
          emp_id: this.employeeID,
          // order_id: this.orderID,
        }
      }
      this.loader.show();
      this.apiService
        .milageReport(payload, this.employeeID)
        .subscribe((response: any) => {
          this.milageReportList = response.data;
          this.dataSource = new MatTableDataSource(this.milageReportList);
          setTimeout(() => this.dataSource.paginator = this.paginator);
          this.loader.hide();
        });
    }
    
  }
  downloadCustomertable() {
    var ary: any = [];
    for (var i = 0; i < this.milageReportList.length; i++) {
      ary.push({
        order_id: this.milageReportList[i]['order_id'],
        start_date: this.milageReportList[i]['picktime'],
        end_date: this.milageReportList[i]['schedule_date_time'],
        distance_covered: this.milageReportList[i]['distance'],
      });
    }
    var options = {
      showLabels: true, 
      showTitle: true,
      title: 'Customer Mileage Report',
      headers: [
        'Order ID',
        'Start Date',
        'End Date',
        'Distance Covered',
      ],
      nullToEmptyString: true,
    };
    new Angular5Csv(ary, `Mileage Report ${new Date()}`, options);
  }
}